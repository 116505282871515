import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: any; output: any }
  DateTime: { input: any; output: any }
  JSON: { input: any; output: any }
  Long: { input: any; output: any }
  Upload: { input: any; output: any }
}

export type IAbout = {
  __typename?: 'About'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  ourTeam?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  phone?: Maybe<Scalars['Long']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  what?: Maybe<Scalars['String']['output']>
  who?: Maybe<Scalars['String']['output']>
}

export type IAboutOurTeamArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IAboutEntity = {
  __typename?: 'AboutEntity'
  attributes?: Maybe<IAbout>
  id?: Maybe<Scalars['ID']['output']>
}

export type IAboutEntityResponse = {
  __typename?: 'AboutEntityResponse'
  data?: Maybe<IAboutEntity>
}

export type IAboutInput = {
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  ourTeam?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  phone?: InputMaybe<Scalars['Long']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  what?: InputMaybe<Scalars['String']['input']>
  who?: InputMaybe<Scalars['String']['input']>
}

export type IAnnouncement = {
  __typename?: 'Announcement'
  announcement: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  optionalLink?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  solution?: Maybe<ISolutionEntityResponse>
  solution_request?: Maybe<ISolutionRequestEntityResponse>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IAnnouncementEntity = {
  __typename?: 'AnnouncementEntity'
  attributes?: Maybe<IAnnouncement>
  id?: Maybe<Scalars['ID']['output']>
}

export type IAnnouncementEntityResponse = {
  __typename?: 'AnnouncementEntityResponse'
  data?: Maybe<IAnnouncementEntity>
}

export type IAnnouncementEntityResponseCollection = {
  __typename?: 'AnnouncementEntityResponseCollection'
  data: Array<IAnnouncementEntity>
  meta: IResponseCollectionMeta
}

export type IAnnouncementFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IAnnouncementFiltersInput>>>
  announcement?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IAnnouncementFiltersInput>
  optionalLink?: InputMaybe<IStringFilterInput>
  or?: InputMaybe<Array<InputMaybe<IAnnouncementFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  solution?: InputMaybe<ISolutionFiltersInput>
  solution_request?: InputMaybe<ISolutionRequestFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IAnnouncementInput = {
  announcement?: InputMaybe<Scalars['String']['input']>
  optionalLink?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  solution?: InputMaybe<Scalars['ID']['input']>
  solution_request?: InputMaybe<Scalars['ID']['input']>
}

export type IAnnouncementItem = {
  __typename?: 'AnnouncementItem'
  announcement: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  optionalLink: Scalars['String']['output']
  solution: Scalars['String']['output']
}

export type IArticleData = {
  __typename?: 'ArticleData'
  author?: Maybe<Scalars['String']['output']>
  content?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  image?: Maybe<IUploadFileEntityResponse>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  publishedTime?: Maybe<Scalars['Date']['output']>
  slug: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IArticleDataEntity = {
  __typename?: 'ArticleDataEntity'
  attributes?: Maybe<IArticleData>
  id?: Maybe<Scalars['ID']['output']>
}

export type IArticleDataEntityResponse = {
  __typename?: 'ArticleDataEntityResponse'
  data?: Maybe<IArticleDataEntity>
}

export type IArticleDataEntityResponseCollection = {
  __typename?: 'ArticleDataEntityResponseCollection'
  data: Array<IArticleDataEntity>
  meta: IResponseCollectionMeta
}

export type IArticleDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IArticleDataFiltersInput>>>
  author?: InputMaybe<IStringFilterInput>
  content?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IArticleDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IArticleDataFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  publishedTime?: InputMaybe<IDateFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IArticleDataInput = {
  author?: InputMaybe<Scalars['String']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  publishedTime?: InputMaybe<Scalars['Date']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IAttribute = {
  __typename?: 'Attribute'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IAttributeEntity = {
  __typename?: 'AttributeEntity'
  attributes?: Maybe<IAttribute>
  id?: Maybe<Scalars['ID']['output']>
}

export type IAttributeEntityResponse = {
  __typename?: 'AttributeEntityResponse'
  data?: Maybe<IAttributeEntity>
}

export type IAttributeEntityResponseCollection = {
  __typename?: 'AttributeEntityResponseCollection'
  data: Array<IAttributeEntity>
  meta: IResponseCollectionMeta
}

export type IAttributeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IAttributeFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IAttributeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IAttributeFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IAttributeInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IAttributeRelationResponseCollection = {
  __typename?: 'AttributeRelationResponseCollection'
  data: Array<IAttributeEntity>
}

export type IAudience = {
  __typename?: 'Audience'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IAudienceEntity = {
  __typename?: 'AudienceEntity'
  attributes?: Maybe<IAudience>
  id?: Maybe<Scalars['ID']['output']>
}

export type IAudienceEntityResponse = {
  __typename?: 'AudienceEntityResponse'
  data?: Maybe<IAudienceEntity>
}

export type IAudienceEntityResponseCollection = {
  __typename?: 'AudienceEntityResponseCollection'
  data: Array<IAudienceEntity>
  meta: IResponseCollectionMeta
}

export type IAudienceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IAudienceFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IAudienceFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IAudienceFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IAudienceInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IAudienceRelationResponseCollection = {
  __typename?: 'AudienceRelationResponseCollection'
  data: Array<IAudienceEntity>
}

export type IAuthor = {
  __typename?: 'Author'
  avatar?: Maybe<IUploadFileEntityResponse>
  bio?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  jobTitle?: Maybe<Scalars['String']['output']>
  lastName: Scalars['String']['output']
  linkedin?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  subjectMatters?: Maybe<ISubjectMatterRelationResponseCollection>
  themes?: Maybe<IThemeRelationResponseCollection>
  twitter?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IAuthorContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IAuthorSubjectMattersArgs = {
  filters?: InputMaybe<ISubjectMatterFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IAuthorThemesArgs = {
  filters?: InputMaybe<IThemeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IAuthorEntity = {
  __typename?: 'AuthorEntity'
  attributes?: Maybe<IAuthor>
  id?: Maybe<Scalars['ID']['output']>
}

export type IAuthorEntityResponse = {
  __typename?: 'AuthorEntityResponse'
  data?: Maybe<IAuthorEntity>
}

export type IAuthorEntityResponseCollection = {
  __typename?: 'AuthorEntityResponseCollection'
  data: Array<IAuthorEntity>
  meta: IResponseCollectionMeta
}

export type IAuthorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IAuthorFiltersInput>>>
  bio?: InputMaybe<IStringFilterInput>
  company?: InputMaybe<IStringFilterInput>
  contactEmail?: InputMaybe<IStringFilterInput>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  displayName?: InputMaybe<IStringFilterInput>
  firstName?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  jobTitle?: InputMaybe<IStringFilterInput>
  lastName?: InputMaybe<IStringFilterInput>
  linkedin?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IAuthorFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IAuthorFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  subjectMatters?: InputMaybe<ISubjectMatterFiltersInput>
  themes?: InputMaybe<IThemeFiltersInput>
  twitter?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IAuthorInput = {
  avatar?: InputMaybe<Scalars['ID']['input']>
  bio?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  contactEmail?: InputMaybe<Scalars['String']['input']>
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  displayName?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  subjectMatters?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  themes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  twitter?: InputMaybe<Scalars['String']['input']>
}

export type IAutosuggestData = {
  __typename?: 'AutosuggestData'
  categories: Array<ISuggest>
  companies: Array<ISuggest>
  contents: Array<ISuggest>
  events: Array<ISuggest>
  solutions: Array<ISuggest>
  success: Scalars['Boolean']['output']
}

export type IBlogTaxonomy = {
  __typename?: 'BlogTaxonomy'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  latestContent?: Maybe<IContentEntityResponse>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  showVendorTopics?: Maybe<Scalars['Boolean']['output']>
  slug?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IBlogTaxonomyContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IBlogTaxonomyEntity = {
  __typename?: 'BlogTaxonomyEntity'
  attributes?: Maybe<IBlogTaxonomy>
  id?: Maybe<Scalars['ID']['output']>
}

export type IBlogTaxonomyEntityResponse = {
  __typename?: 'BlogTaxonomyEntityResponse'
  data?: Maybe<IBlogTaxonomyEntity>
}

export type IBlogTaxonomyEntityResponseCollection = {
  __typename?: 'BlogTaxonomyEntityResponseCollection'
  data: Array<IBlogTaxonomyEntity>
  meta: IResponseCollectionMeta
}

export type IBlogTaxonomyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IBlogTaxonomyFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  latestContent?: InputMaybe<IContentFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IBlogTaxonomyFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IBlogTaxonomyFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  showVendorTopics?: InputMaybe<IBooleanFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IBlogTaxonomyInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  latestContent?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  showVendorTopics?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type IBlogTaxonomyRelationResponseCollection = {
  __typename?: 'BlogTaxonomyRelationResponseCollection'
  data: Array<IBlogTaxonomyEntity>
}

export type IBooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  contains?: InputMaybe<Scalars['Boolean']['input']>
  containsi?: InputMaybe<Scalars['Boolean']['input']>
  endsWith?: InputMaybe<Scalars['Boolean']['input']>
  eq?: InputMaybe<Scalars['Boolean']['input']>
  eqi?: InputMaybe<Scalars['Boolean']['input']>
  gt?: InputMaybe<Scalars['Boolean']['input']>
  gte?: InputMaybe<Scalars['Boolean']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  lt?: InputMaybe<Scalars['Boolean']['input']>
  lte?: InputMaybe<Scalars['Boolean']['input']>
  ne?: InputMaybe<Scalars['Boolean']['input']>
  nei?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<IBooleanFilterInput>
  notContains?: InputMaybe<Scalars['Boolean']['input']>
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  startsWith?: InputMaybe<Scalars['Boolean']['input']>
}

export type IChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload'
  jwt?: Maybe<Scalars['String']['output']>
  user: IUsersPermissionsMe
}

export type IChangePasswordRequest = {
  confirmPassword: Scalars['String']['input']
  identifier: Scalars['String']['input']
  newPassword: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type ICollection = {
  __typename?: 'Collection'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  featuredOnHomepage?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug?: Maybe<Scalars['String']['output']>
  solutions?: Maybe<ISolutionRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ICollectionContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ICollectionSolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ICollectionEntity = {
  __typename?: 'CollectionEntity'
  attributes?: Maybe<ICollection>
  id?: Maybe<Scalars['ID']['output']>
}

export type ICollectionEntityResponse = {
  __typename?: 'CollectionEntityResponse'
  data?: Maybe<ICollectionEntity>
}

export type ICollectionEntityResponseCollection = {
  __typename?: 'CollectionEntityResponseCollection'
  data: Array<ICollectionEntity>
  meta: IResponseCollectionMeta
}

export type ICollectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ICollectionFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  featuredOnHomepage?: InputMaybe<IBooleanFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ICollectionFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ICollectionFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  solutions?: InputMaybe<ISolutionFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ICollectionInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  featuredOnHomepage?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  solutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type ICollectionRelationResponseCollection = {
  __typename?: 'CollectionRelationResponseCollection'
  data: Array<ICollectionEntity>
}

export type ICompany = {
  __typename?: 'Company'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  hqs?: Maybe<IHqRelationResponseCollection>
  iltaSponsorships?: Maybe<IIltaSponsorshipRelationResponseCollection>
  logo?: Maybe<IUploadFileEntityResponse>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  regionsServed?: Maybe<IRegionsServedRelationResponseCollection>
  services?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Int']['output']>
  slug: Scalars['String']['output']
  solutions?: Maybe<ISolutionRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  website?: Maybe<Scalars['String']['output']>
  yearFounded?: Maybe<Scalars['Date']['output']>
}

export type ICompanyHqsArgs = {
  filters?: InputMaybe<IHqFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ICompanyIltaSponsorshipsArgs = {
  filters?: InputMaybe<IIltaSponsorshipFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ICompanyRegionsServedArgs = {
  filters?: InputMaybe<IRegionsServedFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ICompanySolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ICompanyEntity = {
  __typename?: 'CompanyEntity'
  attributes?: Maybe<ICompany>
  id?: Maybe<Scalars['ID']['output']>
}

export type ICompanyEntityResponse = {
  __typename?: 'CompanyEntityResponse'
  data?: Maybe<ICompanyEntity>
}

export type ICompanyEntityResponseCollection = {
  __typename?: 'CompanyEntityResponseCollection'
  data: Array<ICompanyEntity>
  meta: IResponseCollectionMeta
}

export type ICompanyFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ICompanyFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  hqs?: InputMaybe<IHqFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  iltaSponsorships?: InputMaybe<IIltaSponsorshipFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ICompanyFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ICompanyFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  regionsServed?: InputMaybe<IRegionsServedFiltersInput>
  services?: InputMaybe<IStringFilterInput>
  size?: InputMaybe<IIntFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  solutions?: InputMaybe<ISolutionFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  website?: InputMaybe<IStringFilterInput>
  yearFounded?: InputMaybe<IDateFilterInput>
}

export type ICompanyInput = {
  description?: InputMaybe<Scalars['String']['input']>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  iltaSponsorships?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  logo?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  regionsServed?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  services?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  solutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Date']['input']>
}

export type ICompanyRelationResponseCollection = {
  __typename?: 'CompanyRelationResponseCollection'
  data: Array<ICompanyEntity>
}

export type IComponentAboutPropsTeamMember = {
  __typename?: 'ComponentAboutPropsTeamMember'
  avatar?: Maybe<IUploadFileEntityResponse>
  bio?: Maybe<Scalars['String']['output']>
  externalLink?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  role?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type IComponentAboutPropsTeamMemberFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>>>
  bio?: InputMaybe<IStringFilterInput>
  externalLink?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>>>
  role?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
}

export type IComponentAboutPropsTeamMemberInput = {
  avatar?: InputMaybe<Scalars['ID']['input']>
  bio?: InputMaybe<Scalars['String']['input']>
  externalLink?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IComponentAlspPropsAlspEnhanced = {
  __typename?: 'ComponentAlspPropsAlspEnhanced'
  callToAction?: Maybe<IComponentGlobalCallToAction>
  dedicatedResources?: Maybe<Array<Maybe<IComponentConPropsDedicatedResource>>>
  engagementLen?: Maybe<Scalars['Int']['output']>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricing?: Maybe<Array<Maybe<IComponentGlobalPricingModel>>>
  pricingData?: Maybe<Scalars['String']['output']>
  screenshots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  serviceBreakdown?: Maybe<Scalars['String']['output']>
  specialists?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  stories?: Maybe<Array<Maybe<IComponentGlobalSuccessStory>>>
  technology?: Maybe<Scalars['String']['output']>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  valueProposition?: Maybe<Scalars['String']['output']>
  vendorResources?: Maybe<Array<Maybe<IComponentVendorPropsPoC>>>
  videos?: Maybe<Array<Maybe<IComponentVendorPropsVideo>>>
}

export type IComponentAlspPropsAlspEnhancedDedicatedResourcesArgs = {
  filters?: InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedPricingArgs = {
  filters?: InputMaybe<IComponentGlobalPricingModelFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedScreenshotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedSpecialistsArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedStoriesArgs = {
  filters?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedTestimonialsArgs = {
  filters?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedVendorResourcesArgs = {
  filters?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentAlspPropsAlspEnhancedVideosArgs = {
  filters?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentBillingAddressBillingAddress = {
  __typename?: 'ComponentBillingAddressBillingAddress'
  addressLine1: Scalars['String']['output']
  addressLine2?: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  country?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  state?: Maybe<Scalars['String']['output']>
  zipcode: Scalars['String']['output']
}

export type IComponentBillingAddressBillingAddressFiltersInput = {
  addressLine1?: InputMaybe<IStringFilterInput>
  addressLine2?: InputMaybe<IStringFilterInput>
  and?: InputMaybe<Array<InputMaybe<IComponentBillingAddressBillingAddressFiltersInput>>>
  city?: InputMaybe<IStringFilterInput>
  country?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentBillingAddressBillingAddressFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentBillingAddressBillingAddressFiltersInput>>>
  state?: InputMaybe<IStringFilterInput>
  zipcode?: InputMaybe<IStringFilterInput>
}

export type IComponentBillingAddressBillingAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>
  addressLine2?: InputMaybe<Scalars['String']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  zipcode?: InputMaybe<Scalars['String']['input']>
}

export type IComponentConPropsConEnhancedListing = {
  __typename?: 'ComponentConPropsConEnhancedListing'
  callToAction?: Maybe<IComponentGlobalCallToAction>
  dedicatedResources?: Maybe<Array<Maybe<IComponentConPropsDedicatedResource>>>
  engagementLen?: Maybe<Scalars['Int']['output']>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricing?: Maybe<Array<Maybe<IComponentGlobalPricingModel>>>
  pricingData?: Maybe<Scalars['String']['output']>
  screenshots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  serviceBreakdown?: Maybe<Scalars['String']['output']>
  specialists?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  stories?: Maybe<Array<Maybe<IComponentGlobalSuccessStory>>>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  valueProposition?: Maybe<Scalars['String']['output']>
  vendorResources?: Maybe<Array<Maybe<IComponentVendorPropsPoC>>>
  videos?: Maybe<Array<Maybe<IComponentVendorPropsVideo>>>
}

export type IComponentConPropsConEnhancedListingDedicatedResourcesArgs = {
  filters?: InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingPricingArgs = {
  filters?: InputMaybe<IComponentGlobalPricingModelFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingScreenshotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingSpecialistsArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingStoriesArgs = {
  filters?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingTestimonialsArgs = {
  filters?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingVendorResourcesArgs = {
  filters?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsConEnhancedListingVideosArgs = {
  filters?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentConPropsDedicatedResource = {
  __typename?: 'ComponentConPropsDedicatedResource'
  count?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  type?: Maybe<IEnum_Componentconpropsdedicatedresource_Type>
}

export type IComponentConPropsDedicatedResourceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>>>
  count?: InputMaybe<IIntFilterInput>
  not?: InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>>>
  type?: InputMaybe<IStringFilterInput>
}

export type IComponentConPropsDedicatedResourceInput = {
  count?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<IEnum_Componentconpropsdedicatedresource_Type>
}

export type IComponentContentContentWriter = {
  __typename?: 'ComponentContentContentWriter'
  author?: Maybe<IAuthorEntityResponse>
  expert?: Maybe<IExpertEntityResponse>
  id: Scalars['ID']['output']
  oneTimeAuthorName?: Maybe<Scalars['String']['output']>
  solution?: Maybe<ISolutionEntityResponse>
  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
}

export type IComponentContentContentWriterFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentContentContentWriterFiltersInput>>>
  author?: InputMaybe<IAuthorFiltersInput>
  expert?: InputMaybe<IExpertFiltersInput>
  not?: InputMaybe<IComponentContentContentWriterFiltersInput>
  oneTimeAuthorName?: InputMaybe<IStringFilterInput>
  or?: InputMaybe<Array<InputMaybe<IComponentContentContentWriterFiltersInput>>>
  solution?: InputMaybe<ISolutionFiltersInput>
  writerOption?: InputMaybe<IStringFilterInput>
}

export type IComponentContentContentWriterInput = {
  author?: InputMaybe<Scalars['ID']['input']>
  expert?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  oneTimeAuthorName?: InputMaybe<Scalars['String']['input']>
  solution?: InputMaybe<Scalars['ID']['input']>
  writerOption?: InputMaybe<IEnum_Componentcontentcontentwriter_Writeroption>
}

export type IComponentContentDownloadMaterial = {
  __typename?: 'ComponentContentDownloadMaterial'
  downloadButtonName?: Maybe<Scalars['String']['output']>
  files?: Maybe<IUploadFileEntityResponse>
  format?: Maybe<IEnum_Componentcontentdownloadmaterial_Format>
  id: Scalars['ID']['output']
  isEnabled?: Maybe<Scalars['Boolean']['output']>
  lastUpdated?: Maybe<Scalars['Date']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type IComponentContentDownloadMaterialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentContentDownloadMaterialFiltersInput>>>
  downloadButtonName?: InputMaybe<IStringFilterInput>
  format?: InputMaybe<IStringFilterInput>
  isEnabled?: InputMaybe<IBooleanFilterInput>
  lastUpdated?: InputMaybe<IDateFilterInput>
  not?: InputMaybe<IComponentContentDownloadMaterialFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentContentDownloadMaterialFiltersInput>>>
  title?: InputMaybe<IStringFilterInput>
}

export type IComponentContentDownloadMaterialInput = {
  downloadButtonName?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<Scalars['ID']['input']>
  format?: InputMaybe<IEnum_Componentcontentdownloadmaterial_Format>
  id?: InputMaybe<Scalars['ID']['input']>
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>
  lastUpdated?: InputMaybe<Scalars['Date']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IComponentContentFeaturedCategories = {
  __typename?: 'ComponentContentFeaturedCategories'
  id: Scalars['ID']['output']
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  topics?: Maybe<ITopicRelationResponseCollection>
}

export type IComponentContentFeaturedCategoriesSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentContentFeaturedCategoriesTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentContentFeaturedCategoriesFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentContentFeaturedCategoriesFiltersInput>>>
  not?: InputMaybe<IComponentContentFeaturedCategoriesFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentContentFeaturedCategoriesFiltersInput>>>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  topics?: InputMaybe<ITopicFiltersInput>
}

export type IComponentContentFeaturedCategoriesInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  topics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type IComponentGlobalArticle = {
  __typename?: 'ComponentGlobalArticle'
  date?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<IUploadFileEntityResponse>
  link?: Maybe<Scalars['String']['output']>
  publishedBy?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type IComponentGlobalArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentGlobalArticleFiltersInput>>>
  date?: InputMaybe<IDateFilterInput>
  link?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentGlobalArticleFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentGlobalArticleFiltersInput>>>
  publishedBy?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
}

export type IComponentGlobalArticleInput = {
  date?: InputMaybe<Scalars['Date']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  publishedBy?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IComponentGlobalCallToAction = {
  __typename?: 'ComponentGlobalCallToAction'
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type IComponentGlobalCallToActionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentGlobalCallToActionFiltersInput>>>
  label?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentGlobalCallToActionFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentGlobalCallToActionFiltersInput>>>
  url?: InputMaybe<IStringFilterInput>
}

export type IComponentGlobalCallToActionInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type IComponentGlobalPicture = {
  __typename?: 'ComponentGlobalPicture'
  id: Scalars['ID']['output']
  image?: Maybe<IUploadFileEntityResponse>
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type IComponentGlobalPictureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureFiltersInput>>>
  not?: InputMaybe<IComponentGlobalPictureFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureFiltersInput>>>
  summary?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
}

export type IComponentGlobalPictureInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<Scalars['ID']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IComponentGlobalPricingModel = {
  __typename?: 'ComponentGlobalPricingModel'
  exampleTypeAccount?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  pricingModel?: Maybe<Scalars['String']['output']>
  startPrice?: Maybe<Scalars['Int']['output']>
}

export type IComponentGlobalPricingModelFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentGlobalPricingModelFiltersInput>>>
  exampleTypeAccount?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentGlobalPricingModelFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentGlobalPricingModelFiltersInput>>>
  pricingModel?: InputMaybe<IStringFilterInput>
  startPrice?: InputMaybe<IIntFilterInput>
}

export type IComponentGlobalSuccessStory = {
  __typename?: 'ComponentGlobalSuccessStory'
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type IComponentGlobalSuccessStoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentGlobalSuccessStoryFiltersInput>>>
  link?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentGlobalSuccessStoryFiltersInput>>>
}

export type IComponentGlobalSuccessStoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type IComponentGlobalTestimonial = {
  __typename?: 'ComponentGlobalTestimonial'
  avatar?: Maybe<IUploadFileEntityResponse>
  company?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  role?: Maybe<Scalars['String']['output']>
}

export type IComponentGlobalTestimonialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialFiltersInput>>>
  company?: InputMaybe<IStringFilterInput>
  description?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialFiltersInput>>>
  role?: InputMaybe<IStringFilterInput>
}

export type IComponentGlobalTestimonialInput = {
  avatar?: InputMaybe<Scalars['ID']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
}

export type IComponentInvitationsInvitations = {
  __typename?: 'ComponentInvitationsInvitations'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  status?: Maybe<IEnum_Componentinvitationsinvitations_Status>
}

export type IComponentInvitationsInvitationsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentInvitationsInvitationsFiltersInput>>>
  email?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentInvitationsInvitationsFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentInvitationsInvitationsFiltersInput>>>
  status?: InputMaybe<IStringFilterInput>
  token?: InputMaybe<IStringFilterInput>
  tokenExpired?: InputMaybe<IBooleanFilterInput>
}

export type IComponentInvitationsInvitationsInput = {
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<IEnum_Componentinvitationsinvitations_Status>
  token?: InputMaybe<Scalars['String']['input']>
  tokenExpired?: InputMaybe<Scalars['Boolean']['input']>
}

export type IComponentRegistrationContactRegistrationContact = {
  __typename?: 'ComponentRegistrationContactRegistrationContact'
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  jobTitle: Scalars['String']['output']
  name: Scalars['String']['output']
  phone?: Maybe<Scalars['String']['output']>
}

export type IComponentRegistrationContactRegistrationContactFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>>>
  email?: InputMaybe<IStringFilterInput>
  jobTitle?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>>>
  phone?: InputMaybe<IStringFilterInput>
  token?: InputMaybe<IStringFilterInput>
  tokenExpired?: InputMaybe<IBooleanFilterInput>
}

export type IComponentRegistrationContactRegistrationContactInput = {
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  token?: InputMaybe<Scalars['String']['input']>
  tokenExpired?: InputMaybe<Scalars['Boolean']['input']>
}

export type IComponentRequestEnhancedListReq = {
  __typename?: 'ComponentRequestEnhancedListReq'
  callToActionText?: Maybe<Scalars['String']['output']>
  callToActionUrl?: Maybe<Scalars['String']['output']>
  competitor?: Maybe<Scalars['String']['output']>
  dedicatedResources?: Maybe<Array<Maybe<IComponentConPropsDedicatedResource>>>
  engagementLen?: Maybe<Scalars['Int']['output']>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  productVideos?: Maybe<Array<Maybe<IComponentVendorPropsVideo>>>
  screenShots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  serviceBreakdown?: Maybe<Scalars['String']['output']>
  specialists?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  technology?: Maybe<Scalars['String']['output']>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  valueProposition?: Maybe<Scalars['String']['output']>
  vendorResources?: Maybe<Array<Maybe<IComponentVendorPropsPoC>>>
}

export type IComponentRequestEnhancedListReqDedicatedResourcesArgs = {
  filters?: InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqProductVideosArgs = {
  filters?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqScreenShotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqSpecialistsArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqTestimonialsArgs = {
  filters?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqVendorResourcesArgs = {
  filters?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedListReqFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentRequestEnhancedListReqFiltersInput>>>
  callToActionText?: InputMaybe<IStringFilterInput>
  callToActionUrl?: InputMaybe<IStringFilterInput>
  competitor?: InputMaybe<IStringFilterInput>
  dedicatedResources?: InputMaybe<IComponentConPropsDedicatedResourceFiltersInput>
  engagementLen?: InputMaybe<IIntFilterInput>
  founders?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  not?: InputMaybe<IComponentRequestEnhancedListReqFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentRequestEnhancedListReqFiltersInput>>>
  pricingData?: InputMaybe<IStringFilterInput>
  productVideos?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  screenShots?: InputMaybe<IComponentGlobalPictureFiltersInput>
  serviceBreakdown?: InputMaybe<IStringFilterInput>
  specialists?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  technology?: InputMaybe<IStringFilterInput>
  testimonials?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
  vendorResources?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
}

export type IComponentRequestEnhancedListReqInput = {
  callToActionText?: InputMaybe<Scalars['String']['input']>
  callToActionUrl?: InputMaybe<Scalars['String']['input']>
  competitor?: InputMaybe<Scalars['String']['input']>
  dedicatedResources?: InputMaybe<Array<InputMaybe<IComponentConPropsDedicatedResourceInput>>>
  engagementLen?: InputMaybe<Scalars['Int']['input']>
  founders?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  id?: InputMaybe<Scalars['ID']['input']>
  pricingData?: InputMaybe<Scalars['String']['input']>
  productVideos?: InputMaybe<Array<InputMaybe<IComponentVendorPropsVideoInput>>>
  screenShots?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureInput>>>
  serviceBreakdown?: InputMaybe<Scalars['String']['input']>
  specialists?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  technology?: InputMaybe<Scalars['String']['input']>
  testimonials?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialInput>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
  vendorResources?: InputMaybe<Array<InputMaybe<IComponentVendorPropsPoCInput>>>
}

export type IComponentRequestEnhancedVendorReq = {
  __typename?: 'ComponentRequestEnhancedVendorReq'
  callToActionText?: Maybe<Scalars['String']['output']>
  callToActionUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  productVideos?: Maybe<Array<Maybe<IComponentVendorPropsVideo>>>
  screenShots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  vendorResources?: Maybe<Array<Maybe<IComponentVendorPropsPoC>>>
}

export type IComponentRequestEnhancedVendorReqProductVideosArgs = {
  filters?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedVendorReqScreenShotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedVendorReqTestimonialsArgs = {
  filters?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedVendorReqVendorResourcesArgs = {
  filters?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestEnhancedVendorReqFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentRequestEnhancedVendorReqFiltersInput>>>
  callToActionText?: InputMaybe<IStringFilterInput>
  callToActionUrl?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentRequestEnhancedVendorReqFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentRequestEnhancedVendorReqFiltersInput>>>
  pricingData?: InputMaybe<IStringFilterInput>
  productVideos?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  screenShots?: InputMaybe<IComponentGlobalPictureFiltersInput>
  testimonials?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  vendorResources?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
}

export type IComponentRequestEnhancedVendorReqInput = {
  callToActionText?: InputMaybe<Scalars['String']['input']>
  callToActionUrl?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  pricingData?: InputMaybe<Scalars['String']['input']>
  productVideos?: InputMaybe<Array<InputMaybe<IComponentVendorPropsVideoInput>>>
  screenShots?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureInput>>>
  testimonials?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialInput>>>
  vendorResources?: InputMaybe<Array<InputMaybe<IComponentVendorPropsPoCInput>>>
}

export type IComponentRequestListingReq = {
  __typename?: 'ComponentRequestListingReq'
  audiences?: Maybe<IDemographicRelationResponseCollection>
  ceoEmail?: Maybe<Scalars['String']['output']>
  ceoName?: Maybe<Scalars['String']['output']>
  hqs?: Maybe<IHqRelationResponseCollection>
  id: Scalars['ID']['output']
  isCreate?: Maybe<Scalars['Boolean']['output']>
  languages?: Maybe<IPlatformLanguageRelationResponseCollection>
  listingName?: Maybe<Scalars['String']['output']>
  logo?: Maybe<IUploadFileEntityResponse>
  longDescription?: Maybe<Scalars['String']['output']>
  marketEmail?: Maybe<Scalars['String']['output']>
  marketName?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  offices?: Maybe<IOfficeRelationResponseCollection>
  partners?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  regionsServed?: Maybe<IRegionsServedRelationResponseCollection>
  serviceName?: Maybe<Scalars['String']['output']>
  serviceOfferings?: Maybe<ISubTopicRelationResponseCollection>
  shortDescription?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Int']['output']>
  userEmail?: Maybe<Scalars['String']['output']>
  userName?: Maybe<Scalars['String']['output']>
  userRole?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  yearFounded?: Maybe<Scalars['Int']['output']>
}

export type IComponentRequestListingReqAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestListingReqHqsArgs = {
  filters?: InputMaybe<IHqFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestListingReqLanguagesArgs = {
  filters?: InputMaybe<IPlatformLanguageFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestListingReqOfficesArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestListingReqRegionsServedArgs = {
  filters?: InputMaybe<IRegionsServedFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestListingReqServiceOfferingsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestListingReqFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentRequestListingReqFiltersInput>>>
  audiences?: InputMaybe<IDemographicFiltersInput>
  ceoEmail?: InputMaybe<IStringFilterInput>
  ceoName?: InputMaybe<IStringFilterInput>
  hqs?: InputMaybe<IHqFiltersInput>
  isCreate?: InputMaybe<IBooleanFilterInput>
  languages?: InputMaybe<IPlatformLanguageFiltersInput>
  listingName?: InputMaybe<IStringFilterInput>
  longDescription?: InputMaybe<IStringFilterInput>
  marketEmail?: InputMaybe<IStringFilterInput>
  marketName?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentRequestListingReqFiltersInput>
  notes?: InputMaybe<IStringFilterInput>
  offices?: InputMaybe<IOfficeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentRequestListingReqFiltersInput>>>
  partners?: InputMaybe<IStringFilterInput>
  phone?: InputMaybe<IStringFilterInput>
  regionsServed?: InputMaybe<IRegionsServedFiltersInput>
  serviceName?: InputMaybe<IStringFilterInput>
  serviceOfferings?: InputMaybe<ISubTopicFiltersInput>
  shortDescription?: InputMaybe<IStringFilterInput>
  size?: InputMaybe<IIntFilterInput>
  userEmail?: InputMaybe<IStringFilterInput>
  userName?: InputMaybe<IStringFilterInput>
  userRole?: InputMaybe<IStringFilterInput>
  website?: InputMaybe<IStringFilterInput>
  yearFounded?: InputMaybe<IIntFilterInput>
}

export type IComponentRequestListingReqInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  ceoEmail?: InputMaybe<Scalars['String']['input']>
  ceoName?: InputMaybe<Scalars['String']['input']>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  isCreate?: InputMaybe<Scalars['Boolean']['input']>
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  listingName?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['ID']['input']>
  longDescription?: InputMaybe<Scalars['String']['input']>
  marketEmail?: InputMaybe<Scalars['String']['input']>
  marketName?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  offices?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  partners?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  regionsServed?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  serviceName?: InputMaybe<Scalars['String']['input']>
  serviceOfferings?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  userEmail?: InputMaybe<Scalars['String']['input']>
  userName?: InputMaybe<Scalars['String']['input']>
  userRole?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Int']['input']>
}

export type IComponentRequestVendorReq = {
  __typename?: 'ComponentRequestVendorReq'
  audiences?: Maybe<IDemographicRelationResponseCollection>
  ceoEmail?: Maybe<Scalars['String']['output']>
  ceoName?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  competitor?: Maybe<Scalars['String']['output']>
  deployments?: Maybe<IInstallationRelationResponseCollection>
  email?: Maybe<Scalars['String']['output']>
  existingCustomers?: Maybe<IExistingCustomerRelationResponseCollection>
  hqs?: Maybe<IHqRelationResponseCollection>
  id: Scalars['ID']['output']
  integrations?: Maybe<IIntegrationRelationResponseCollection>
  isCreate?: Maybe<Scalars['Boolean']['output']>
  isOpenJobPosition?: Maybe<Scalars['Boolean']['output']>
  languages?: Maybe<IPlatformLanguageRelationResponseCollection>
  logo?: Maybe<IUploadFileEntityResponse>
  longDescription?: Maybe<Scalars['String']['output']>
  marketEmail?: Maybe<Scalars['String']['output']>
  marketName?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  offices?: Maybe<IOfficeRelationResponseCollection>
  practiceAreas?: Maybe<IPracticeAreaRelationResponseCollection>
  productName?: Maybe<Scalars['String']['output']>
  shortDescription?: Maybe<Scalars['String']['output']>
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  typeOfUsers?: Maybe<ITypeOfUserRelationResponseCollection>
  website?: Maybe<Scalars['String']['output']>
  yearFounded?: Maybe<Scalars['Int']['output']>
}

export type IComponentRequestVendorReqAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqDeploymentsArgs = {
  filters?: InputMaybe<IInstallationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqExistingCustomersArgs = {
  filters?: InputMaybe<IExistingCustomerFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqHqsArgs = {
  filters?: InputMaybe<IHqFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqIntegrationsArgs = {
  filters?: InputMaybe<IIntegrationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqLanguagesArgs = {
  filters?: InputMaybe<IPlatformLanguageFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqOfficesArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqPracticeAreasArgs = {
  filters?: InputMaybe<IPracticeAreaFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqTypeOfUsersArgs = {
  filters?: InputMaybe<ITypeOfUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentRequestVendorReqFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentRequestVendorReqFiltersInput>>>
  audiences?: InputMaybe<IDemographicFiltersInput>
  ceoEmail?: InputMaybe<IStringFilterInput>
  ceoName?: InputMaybe<IStringFilterInput>
  companyName?: InputMaybe<IStringFilterInput>
  competitor?: InputMaybe<IStringFilterInput>
  deployments?: InputMaybe<IInstallationFiltersInput>
  email?: InputMaybe<IStringFilterInput>
  existingCustomers?: InputMaybe<IExistingCustomerFiltersInput>
  hqs?: InputMaybe<IHqFiltersInput>
  integrations?: InputMaybe<IIntegrationFiltersInput>
  isCreate?: InputMaybe<IBooleanFilterInput>
  isOpenJobPosition?: InputMaybe<IBooleanFilterInput>
  languages?: InputMaybe<IPlatformLanguageFiltersInput>
  longDescription?: InputMaybe<IStringFilterInput>
  marketEmail?: InputMaybe<IStringFilterInput>
  marketName?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentRequestVendorReqFiltersInput>
  notes?: InputMaybe<IStringFilterInput>
  offices?: InputMaybe<IOfficeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentRequestVendorReqFiltersInput>>>
  practiceAreas?: InputMaybe<IPracticeAreaFiltersInput>
  productName?: InputMaybe<IStringFilterInput>
  shortDescription?: InputMaybe<IStringFilterInput>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  typeOfUsers?: InputMaybe<ITypeOfUserFiltersInput>
  website?: InputMaybe<IStringFilterInput>
  yearFounded?: InputMaybe<IIntFilterInput>
}

export type IComponentRequestVendorReqInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  ceoEmail?: InputMaybe<Scalars['String']['input']>
  ceoName?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  competitor?: InputMaybe<Scalars['String']['input']>
  deployments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  email?: InputMaybe<Scalars['String']['input']>
  existingCustomers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isCreate?: InputMaybe<Scalars['Boolean']['input']>
  isOpenJobPosition?: InputMaybe<Scalars['Boolean']['input']>
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  logo?: InputMaybe<Scalars['ID']['input']>
  longDescription?: InputMaybe<Scalars['String']['input']>
  marketEmail?: InputMaybe<Scalars['String']['input']>
  marketName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  offices?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  practiceAreas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  productName?: InputMaybe<Scalars['String']['input']>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  typeOfUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Int']['input']>
}

export type IComponentSolutionAlspData = {
  __typename?: 'ComponentSolutionAlspData'
  audiences?: Maybe<IDemographicRelationResponseCollection>
  enhancedListingData?: Maybe<IComponentSolutionPropsAlspEnhanced>
  fundingAmount?: Maybe<IEnum_Componentsolutionalspdata_Fundingamount>
  fundingInfo?: Maybe<IEnum_Componentsolutionalspdata_Fundinginfo>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  partners?: Maybe<Scalars['String']['output']>
  security?: Maybe<ISecurityRelationResponseCollection>
  serviceName?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Int']['output']>
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionAlspDataAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionAlspDataSecurityArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionAlspDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionAlspDataFiltersInput>>>
  audiences?: InputMaybe<IDemographicFiltersInput>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsAlspEnhancedFiltersInput>
  fundingAmount?: InputMaybe<IStringFilterInput>
  fundingInfo?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionAlspDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionAlspDataFiltersInput>>>
  partners?: InputMaybe<IStringFilterInput>
  security?: InputMaybe<ISecurityFiltersInput>
  serviceName?: InputMaybe<IStringFilterInput>
  size?: InputMaybe<IIntFilterInput>
  valueProposition?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionAlspDataInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsAlspEnhancedInput>
  fundingAmount?: InputMaybe<IEnum_Componentsolutionalspdata_Fundingamount>
  fundingInfo?: InputMaybe<IEnum_Componentsolutionalspdata_Fundinginfo>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  partners?: InputMaybe<Scalars['String']['input']>
  security?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  serviceName?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  valueProposition?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionConsultantData = {
  __typename?: 'ComponentSolutionConsultantData'
  audiences?: Maybe<IDemographicRelationResponseCollection>
  enhancedListingData?: Maybe<IComponentSolutionPropsConsultantEnhanced>
  fundingAmount?: Maybe<IEnum_Componentsolutionconsultantdata_Fundingamount>
  fundingInfo?: Maybe<IEnum_Componentsolutionconsultantdata_Fundinginfo>
  id: Scalars['ID']['output']
  integrations?: Maybe<IIntegrationRelationResponseCollection>
  name?: Maybe<Scalars['String']['output']>
  partners?: Maybe<Scalars['String']['output']>
  practiceAreas?: Maybe<IPracticeAreaRelationResponseCollection>
  security?: Maybe<ISecurityRelationResponseCollection>
  serviceName?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Int']['output']>
  typeOfUser?: Maybe<ITypeOfUserRelationResponseCollection>
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionConsultantDataAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionConsultantDataIntegrationsArgs = {
  filters?: InputMaybe<IIntegrationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionConsultantDataPracticeAreasArgs = {
  filters?: InputMaybe<IPracticeAreaFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionConsultantDataSecurityArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionConsultantDataTypeOfUserArgs = {
  filters?: InputMaybe<ITypeOfUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionConsultantDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionConsultantDataFiltersInput>>>
  audiences?: InputMaybe<IDemographicFiltersInput>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsConsultantEnhancedFiltersInput>
  fundingAmount?: InputMaybe<IStringFilterInput>
  fundingInfo?: InputMaybe<IStringFilterInput>
  integrations?: InputMaybe<IIntegrationFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionConsultantDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionConsultantDataFiltersInput>>>
  partners?: InputMaybe<IStringFilterInput>
  practiceAreas?: InputMaybe<IPracticeAreaFiltersInput>
  security?: InputMaybe<ISecurityFiltersInput>
  serviceName?: InputMaybe<IStringFilterInput>
  size?: InputMaybe<IIntFilterInput>
  typeOfUser?: InputMaybe<ITypeOfUserFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionConsultantDataInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsConsultantEnhancedInput>
  fundingAmount?: InputMaybe<IEnum_Componentsolutionconsultantdata_Fundingamount>
  fundingInfo?: InputMaybe<IEnum_Componentsolutionconsultantdata_Fundinginfo>
  id?: InputMaybe<Scalars['ID']['input']>
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  partners?: InputMaybe<Scalars['String']['input']>
  practiceAreas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  security?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  serviceName?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  typeOfUser?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionProductData = {
  __typename?: 'ComponentSolutionProductData'
  attributes?: Maybe<IAttributeRelationResponseCollection>
  audiences?: Maybe<IDemographicRelationResponseCollection>
  dataHostingLocations?: Maybe<IOfficeRelationResponseCollection>
  deployments?: Maybe<IInstallationRelationResponseCollection>
  enhancedListingData?: Maybe<IComponentSolutionPropsProductEnhanced>
  fundingAmount?: Maybe<IEnum_Componentsolutionproductdata_Fundingamount>
  fundingInfo?: Maybe<IEnum_Componentsolutionproductdata_Fundinginfo>
  id: Scalars['ID']['output']
  integrations?: Maybe<IIntegrationRelationResponseCollection>
  name?: Maybe<Scalars['String']['output']>
  practiceAreas?: Maybe<IPracticeAreaRelationResponseCollection>
  security?: Maybe<ISecurityRelationResponseCollection>
  tool?: Maybe<Scalars['String']['output']>
  typeOfUser?: Maybe<ITypeOfUserRelationResponseCollection>
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionProductDataAttributesArgs = {
  filters?: InputMaybe<IAttributeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataDataHostingLocationsArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataDeploymentsArgs = {
  filters?: InputMaybe<IInstallationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataIntegrationsArgs = {
  filters?: InputMaybe<IIntegrationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataPracticeAreasArgs = {
  filters?: InputMaybe<IPracticeAreaFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataSecurityArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataTypeOfUserArgs = {
  filters?: InputMaybe<ITypeOfUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionProductDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionProductDataFiltersInput>>>
  attributes?: InputMaybe<IAttributeFiltersInput>
  audiences?: InputMaybe<IDemographicFiltersInput>
  dataHostingLocations?: InputMaybe<IOfficeFiltersInput>
  deployments?: InputMaybe<IInstallationFiltersInput>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsProductEnhancedFiltersInput>
  fundingAmount?: InputMaybe<IStringFilterInput>
  fundingInfo?: InputMaybe<IStringFilterInput>
  integrations?: InputMaybe<IIntegrationFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionProductDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionProductDataFiltersInput>>>
  practiceAreas?: InputMaybe<IPracticeAreaFiltersInput>
  security?: InputMaybe<ISecurityFiltersInput>
  tool?: InputMaybe<IStringFilterInput>
  typeOfUser?: InputMaybe<ITypeOfUserFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionProductDataInput = {
  attributes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  dataHostingLocations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  deployments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsProductEnhancedInput>
  fundingAmount?: InputMaybe<IEnum_Componentsolutionproductdata_Fundingamount>
  fundingInfo?: InputMaybe<IEnum_Componentsolutionproductdata_Fundinginfo>
  id?: InputMaybe<Scalars['ID']['input']>
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  practiceAreas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  security?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  tool?: InputMaybe<Scalars['String']['input']>
  typeOfUser?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionPropsAlspEnhanced = {
  __typename?: 'ComponentSolutionPropsAlspEnhanced'
  averageLengthOfEngagement?: Maybe<Scalars['Int']['output']>
  callToAction?: Maybe<IComponentGlobalCallToAction>
  caseStudies?: Maybe<Array<Maybe<IComponentGlobalSuccessStory>>>
  dedicatedResources?: Maybe<Array<Maybe<IComponentSolutionPropsDedicatedResource>>>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  screenshots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  serviceDescription?: Maybe<Scalars['String']['output']>
  specialists?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  technologyPartners?: Maybe<Scalars['String']['output']>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  valueProposition?: Maybe<Scalars['String']['output']>
  videos?: Maybe<Array<Maybe<IComponentSolutionPropsVideo>>>
}

export type IComponentSolutionPropsAlspEnhancedCaseStudiesArgs = {
  filters?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedDedicatedResourcesArgs = {
  filters?: InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedScreenshotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedSpecialistsArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedTestimonialsArgs = {
  filters?: InputMaybe<ITestimonialsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedVideosArgs = {
  filters?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsAlspEnhancedFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsAlspEnhancedFiltersInput>>>
  averageLengthOfEngagement?: InputMaybe<IIntFilterInput>
  callToAction?: InputMaybe<IComponentGlobalCallToActionFiltersInput>
  caseStudies?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  dedicatedResources?: InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>
  founders?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  not?: InputMaybe<IComponentSolutionPropsAlspEnhancedFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsAlspEnhancedFiltersInput>>>
  pricingData?: InputMaybe<IStringFilterInput>
  screenshots?: InputMaybe<IComponentGlobalPictureFiltersInput>
  serviceDescription?: InputMaybe<IStringFilterInput>
  specialists?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  technologyPartners?: InputMaybe<IStringFilterInput>
  testimonials?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
  videos?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
}

export type IComponentSolutionPropsAlspEnhancedInput = {
  averageLengthOfEngagement?: InputMaybe<Scalars['Int']['input']>
  callToAction?: InputMaybe<IComponentGlobalCallToActionInput>
  caseStudies?: InputMaybe<Array<InputMaybe<IComponentGlobalSuccessStoryInput>>>
  dedicatedResources?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsDedicatedResourceInput>>>
  founders?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  id?: InputMaybe<Scalars['ID']['input']>
  pricingData?: InputMaybe<Scalars['String']['input']>
  screenshots?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureInput>>>
  serviceDescription?: InputMaybe<Scalars['String']['input']>
  specialists?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  technologyPartners?: InputMaybe<Scalars['String']['input']>
  testimonials?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialInput>>>
  videos?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVideoInput>>>
}

export type IComponentSolutionPropsConsolidation = {
  __typename?: 'ComponentSolutionPropsConsolidation'
  amount?: Maybe<Scalars['Int']['output']>
  article?: Maybe<Scalars['String']['output']>
  articleLink?: Maybe<Scalars['String']['output']>
  buyer?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  originalTargetName?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionPropsConsolidationFiltersInput = {
  amount?: InputMaybe<IIntFilterInput>
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsConsolidationFiltersInput>>>
  article?: InputMaybe<IStringFilterInput>
  articleLink?: InputMaybe<IStringFilterInput>
  buyer?: InputMaybe<IStringFilterInput>
  date?: InputMaybe<IDateFilterInput>
  enableInActiveDir?: InputMaybe<IBooleanFilterInput>
  not?: InputMaybe<IComponentSolutionPropsConsolidationFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsConsolidationFiltersInput>>>
  originalTargetName?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionPropsConsolidationInput = {
  amount?: InputMaybe<Scalars['Int']['input']>
  article?: InputMaybe<Scalars['String']['input']>
  articleLink?: InputMaybe<Scalars['String']['input']>
  buyer?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['Date']['input']>
  enableInActiveDir?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  originalTargetName?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionPropsConsultantEnhanced = {
  __typename?: 'ComponentSolutionPropsConsultantEnhanced'
  averageLengthOfEngagement?: Maybe<Scalars['Int']['output']>
  callToAction?: Maybe<IComponentGlobalCallToAction>
  caseStudies?: Maybe<Array<Maybe<IComponentGlobalSuccessStory>>>
  dedicatedResources?: Maybe<Array<Maybe<IComponentSolutionPropsDedicatedResource>>>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  screenshots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  serviceDescription?: Maybe<Scalars['String']['output']>
  specialists?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  technologyPartners?: Maybe<Scalars['String']['output']>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  valueProposition?: Maybe<Scalars['String']['output']>
  videos?: Maybe<Array<Maybe<IComponentSolutionPropsVideo>>>
}

export type IComponentSolutionPropsConsultantEnhancedCaseStudiesArgs = {
  filters?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedDedicatedResourcesArgs = {
  filters?: InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedScreenshotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedSpecialistsArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedTestimonialsArgs = {
  filters?: InputMaybe<ITestimonialsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedVideosArgs = {
  filters?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsConsultantEnhancedFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsConsultantEnhancedFiltersInput>>>
  averageLengthOfEngagement?: InputMaybe<IIntFilterInput>
  callToAction?: InputMaybe<IComponentGlobalCallToActionFiltersInput>
  caseStudies?: InputMaybe<IComponentGlobalSuccessStoryFiltersInput>
  dedicatedResources?: InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>
  founders?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  not?: InputMaybe<IComponentSolutionPropsConsultantEnhancedFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsConsultantEnhancedFiltersInput>>>
  pricingData?: InputMaybe<IStringFilterInput>
  screenshots?: InputMaybe<IComponentGlobalPictureFiltersInput>
  serviceDescription?: InputMaybe<IStringFilterInput>
  specialists?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  technologyPartners?: InputMaybe<IStringFilterInput>
  testimonials?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
  videos?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
}

export type IComponentSolutionPropsConsultantEnhancedInput = {
  averageLengthOfEngagement?: InputMaybe<Scalars['Int']['input']>
  callToAction?: InputMaybe<IComponentGlobalCallToActionInput>
  caseStudies?: InputMaybe<Array<InputMaybe<IComponentGlobalSuccessStoryInput>>>
  dedicatedResources?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsDedicatedResourceInput>>>
  founders?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  id?: InputMaybe<Scalars['ID']['input']>
  pricingData?: InputMaybe<Scalars['String']['input']>
  screenshots?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureInput>>>
  serviceDescription?: InputMaybe<Scalars['String']['input']>
  specialists?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  technologyPartners?: InputMaybe<Scalars['String']['input']>
  testimonials?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialInput>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVideoInput>>>
}

export type IComponentSolutionPropsDedicatedResource = {
  __typename?: 'ComponentSolutionPropsDedicatedResource'
  count?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  type?: Maybe<IEnum_Componentsolutionpropsdedicatedresource_Type>
}

export type IComponentSolutionPropsDedicatedResourceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>>>
  count?: InputMaybe<IIntFilterInput>
  not?: InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsDedicatedResourceFiltersInput>>>
  type?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionPropsDedicatedResourceInput = {
  count?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<IEnum_Componentsolutionpropsdedicatedresource_Type>
}

export type IComponentSolutionPropsGraveyard = {
  __typename?: 'ComponentSolutionPropsGraveyard'
  date?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
}

export type IComponentSolutionPropsGraveyardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsGraveyardFiltersInput>>>
  date?: InputMaybe<IDateFilterInput>
  not?: InputMaybe<IComponentSolutionPropsGraveyardFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsGraveyardFiltersInput>>>
}

export type IComponentSolutionPropsGraveyardInput = {
  date?: InputMaybe<Scalars['Date']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IComponentSolutionPropsProductEnhanced = {
  __typename?: 'ComponentSolutionPropsProductEnhanced'
  callToAction?: Maybe<IComponentGlobalCallToAction>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  screenshots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  videos?: Maybe<Array<Maybe<IComponentSolutionPropsVideo>>>
}

export type IComponentSolutionPropsProductEnhancedFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsProductEnhancedScreenshotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsProductEnhancedVideosArgs = {
  filters?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionPropsProductEnhancedFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsProductEnhancedFiltersInput>>>
  callToAction?: InputMaybe<IComponentGlobalCallToActionFiltersInput>
  founders?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  not?: InputMaybe<IComponentSolutionPropsProductEnhancedFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsProductEnhancedFiltersInput>>>
  pricingData?: InputMaybe<IStringFilterInput>
  screenshots?: InputMaybe<IComponentGlobalPictureFiltersInput>
  testimonials?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  videos?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
}

export type IComponentSolutionPropsProductEnhancedInput = {
  callToAction?: InputMaybe<IComponentGlobalCallToActionInput>
  founders?: InputMaybe<Array<InputMaybe<IComponentAboutPropsTeamMemberInput>>>
  id?: InputMaybe<Scalars['ID']['input']>
  pricingData?: InputMaybe<Scalars['String']['input']>
  screenshots?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureInput>>>
  testimonials?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialInput>>>
  videos?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVideoInput>>>
}

export type IComponentSolutionPropsVendorResource = {
  __typename?: 'ComponentSolutionPropsVendorResource'
  content?: Maybe<IContentEntityResponse>
  external?: Maybe<IComponentSolutionPropsVendorResourceExternal>
  id: Scalars['ID']['output']
  type?: Maybe<IEnum_Componentsolutionpropsvendorresource_Type>
}

export type IComponentSolutionPropsVendorResourceExternal = {
  __typename?: 'ComponentSolutionPropsVendorResourceExternal'
  author?: Maybe<Scalars['String']['output']>
  documentType?: Maybe<IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype>
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  publishedDate?: Maybe<Scalars['Date']['output']>
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionPropsVendorResourceExternalFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVendorResourceExternalFiltersInput>>>
  author?: InputMaybe<IStringFilterInput>
  documentType?: InputMaybe<IStringFilterInput>
  link?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionPropsVendorResourceExternalFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVendorResourceExternalFiltersInput>>>
  publishedDate?: InputMaybe<IDateFilterInput>
  summary?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionPropsVendorResourceExternalInput = {
  author?: InputMaybe<Scalars['String']['input']>
  documentType?: InputMaybe<IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype>
  id?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  publishedDate?: InputMaybe<Scalars['Date']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionPropsVendorResourceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>>>
  content?: InputMaybe<IContentFiltersInput>
  external?: InputMaybe<IComponentSolutionPropsVendorResourceExternalFiltersInput>
  not?: InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>>>
  type?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionPropsVendorResourceInput = {
  content?: InputMaybe<Scalars['ID']['input']>
  external?: InputMaybe<IComponentSolutionPropsVendorResourceExternalInput>
  id?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<IEnum_Componentsolutionpropsvendorresource_Type>
}

export type IComponentSolutionPropsVideo = {
  __typename?: 'ComponentSolutionPropsVideo'
  id: Scalars['ID']['output']
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionPropsVideoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVideoFiltersInput>>>
  not?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVideoFiltersInput>>>
  summary?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  url?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionPropsVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionRequestAlspData = {
  __typename?: 'ComponentSolutionRequestAlspData'
  audiences?: Maybe<IDemographicRelationResponseCollection>
  enhancedListingData?: Maybe<IComponentSolutionPropsAlspEnhanced>
  fundingAmount?: Maybe<IEnum_Componentsolutionrequestalspdata_Fundingamount>
  fundingInfo?: Maybe<IEnum_Componentsolutionrequestalspdata_Fundinginfo>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  partners?: Maybe<Scalars['String']['output']>
  security?: Maybe<ISecurityRelationResponseCollection>
  serviceName?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Int']['output']>
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionRequestAlspDataAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestAlspDataSecurityArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestAlspDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestAlspDataFiltersInput>>>
  audiences?: InputMaybe<IDemographicFiltersInput>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsAlspEnhancedFiltersInput>
  fundingAmount?: InputMaybe<IStringFilterInput>
  fundingInfo?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionRequestAlspDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestAlspDataFiltersInput>>>
  partners?: InputMaybe<IStringFilterInput>
  security?: InputMaybe<ISecurityFiltersInput>
  serviceName?: InputMaybe<IStringFilterInput>
  size?: InputMaybe<IIntFilterInput>
  valueProposition?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionRequestAlspDataInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsAlspEnhancedInput>
  fundingAmount?: InputMaybe<IEnum_Componentsolutionrequestalspdata_Fundingamount>
  fundingInfo?: InputMaybe<IEnum_Componentsolutionrequestalspdata_Fundinginfo>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  partners?: InputMaybe<Scalars['String']['input']>
  security?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  serviceName?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  valueProposition?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionRequestConsultantData = {
  __typename?: 'ComponentSolutionRequestConsultantData'
  audiences?: Maybe<IDemographicRelationResponseCollection>
  enhancedListingData?: Maybe<IComponentSolutionPropsConsultantEnhanced>
  fundingAmount?: Maybe<IEnum_Componentsolutionrequestconsultantdata_Fundingamount>
  fundingInfo?: Maybe<IEnum_Componentsolutionrequestconsultantdata_Fundinginfo>
  id: Scalars['ID']['output']
  integrations?: Maybe<IIntegrationRelationResponseCollection>
  name?: Maybe<Scalars['String']['output']>
  partners?: Maybe<Scalars['String']['output']>
  practiceAreas?: Maybe<IPracticeAreaRelationResponseCollection>
  security?: Maybe<ISecurityRelationResponseCollection>
  serviceName?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['Int']['output']>
  typeOfUser?: Maybe<ITypeOfUserRelationResponseCollection>
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionRequestConsultantDataAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestConsultantDataIntegrationsArgs = {
  filters?: InputMaybe<IIntegrationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestConsultantDataPracticeAreasArgs = {
  filters?: InputMaybe<IPracticeAreaFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestConsultantDataSecurityArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestConsultantDataTypeOfUserArgs = {
  filters?: InputMaybe<ITypeOfUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestConsultantDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestConsultantDataFiltersInput>>>
  audiences?: InputMaybe<IDemographicFiltersInput>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsConsultantEnhancedFiltersInput>
  fundingAmount?: InputMaybe<IStringFilterInput>
  fundingInfo?: InputMaybe<IStringFilterInput>
  integrations?: InputMaybe<IIntegrationFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionRequestConsultantDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestConsultantDataFiltersInput>>>
  partners?: InputMaybe<IStringFilterInput>
  practiceAreas?: InputMaybe<IPracticeAreaFiltersInput>
  security?: InputMaybe<ISecurityFiltersInput>
  serviceName?: InputMaybe<IStringFilterInput>
  size?: InputMaybe<IIntFilterInput>
  typeOfUser?: InputMaybe<ITypeOfUserFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionRequestConsultantDataInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  enhancedListingData?: InputMaybe<IComponentSolutionPropsConsultantEnhancedInput>
  fundingAmount?: InputMaybe<IEnum_Componentsolutionrequestconsultantdata_Fundingamount>
  fundingInfo?: InputMaybe<IEnum_Componentsolutionrequestconsultantdata_Fundinginfo>
  id?: InputMaybe<Scalars['ID']['input']>
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  partners?: InputMaybe<Scalars['String']['input']>
  practiceAreas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  security?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  serviceName?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  typeOfUser?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionRequestProductData = {
  __typename?: 'ComponentSolutionRequestProductData'
  attributes?: Maybe<IAttributeRelationResponseCollection>
  audiences?: Maybe<IDemographicRelationResponseCollection>
  dataHosting?: Maybe<IOfficeRelationResponseCollection>
  enhancedListingData?: Maybe<IComponentSolutionRequestPropsProductEnhanced>
  fundingAmount?: Maybe<IEnum_Componentsolutionrequestproductdata_Fundingamount>
  fundingInfo?: Maybe<IEnum_Componentsolutionrequestproductdata_Fundinginfo>
  id: Scalars['ID']['output']
  integrations?: Maybe<IIntegrationRelationResponseCollection>
  name?: Maybe<Scalars['String']['output']>
  practiceAreas?: Maybe<IPracticeAreaRelationResponseCollection>
  security?: Maybe<ISecurityRelationResponseCollection>
  deployment?: Maybe<IInstallationRelationResponseCollection>
  tool?: Maybe<Scalars['String']['output']>
  typeOfUser?: Maybe<ITypeOfUserRelationResponseCollection>
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type IComponentSolutionRequestProductDataAttributesArgs = {
  filters?: InputMaybe<IAttributeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataAudiencesArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataDataHostingArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataIntegrationsArgs = {
  filters?: InputMaybe<IIntegrationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataPracticeAreasArgs = {
  filters?: InputMaybe<IPracticeAreaFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataSecurityArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataTypeOfUserArgs = {
  filters?: InputMaybe<ITypeOfUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestProductDataFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestProductDataFiltersInput>>>
  attributes?: InputMaybe<IAttributeFiltersInput>
  audiences?: InputMaybe<IDemographicFiltersInput>
  dataHosting?: InputMaybe<IOfficeFiltersInput>
  enhancedListingData?: InputMaybe<IComponentSolutionRequestPropsProductEnhancedFiltersInput>
  fundingAmount?: InputMaybe<IStringFilterInput>
  fundingInfo?: InputMaybe<IStringFilterInput>
  integrations?: InputMaybe<IIntegrationFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentSolutionRequestProductDataFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestProductDataFiltersInput>>>
  practiceAreas?: InputMaybe<IPracticeAreaFiltersInput>
  security?: InputMaybe<ISecurityFiltersInput>
  tool?: InputMaybe<IStringFilterInput>
  typeOfUser?: InputMaybe<ITypeOfUserFiltersInput>
  valueProposition?: InputMaybe<IStringFilterInput>
}

export type IComponentSolutionRequestProductDataInput = {
  attributes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  dataHosting?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  enhancedListingData?: InputMaybe<IComponentSolutionRequestPropsProductEnhancedInput>
  fundingAmount?: InputMaybe<IEnum_Componentsolutionrequestproductdata_Fundingamount>
  fundingInfo?: InputMaybe<IEnum_Componentsolutionrequestproductdata_Fundinginfo>
  id?: InputMaybe<Scalars['ID']['input']>
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  practiceAreas?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  security?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  deployments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  tool?: InputMaybe<Scalars['String']['input']>
  typeOfUser?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
}

export type IComponentSolutionRequestPropsProductEnhanced = {
  __typename?: 'ComponentSolutionRequestPropsProductEnhanced'
  callToAction?: Maybe<IComponentGlobalCallToAction>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  screenshots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  videos?: Maybe<Array<Maybe<IComponentSolutionPropsVideo>>>
}

export type IComponentSolutionRequestPropsProductEnhancedFoundersArgs = {
  filters?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestPropsProductEnhancedScreenshotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestPropsProductEnhancedVideosArgs = {
  filters?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentSolutionRequestPropsProductEnhancedFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestPropsProductEnhancedFiltersInput>>>
  callToAction?: InputMaybe<IComponentGlobalCallToActionFiltersInput>
  founders?: InputMaybe<IComponentAboutPropsTeamMemberFiltersInput>
  not?: InputMaybe<IComponentSolutionRequestPropsProductEnhancedFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentSolutionRequestPropsProductEnhancedFiltersInput>>>
  pricingData?: InputMaybe<IStringFilterInput>
  screenshots?: InputMaybe<IComponentGlobalPictureFiltersInput>
  videos?: InputMaybe<IComponentSolutionPropsVideoFiltersInput>
}

export type IComponentSolutionRequestPropsProductEnhancedInput = {
  callToAction?: InputMaybe<IComponentGlobalCallToActionInput>
  id?: InputMaybe<Scalars['ID']['input']>
  pricingData?: InputMaybe<Scalars['String']['input']>
  screenshots?: InputMaybe<Array<InputMaybe<IComponentGlobalPictureInput>>>
  videos?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVideoInput>>>
  testimonials?: InputMaybe<Array<InputMaybe<IComponentGlobalTestimonialInput>>>
  valueProposition?: InputMaybe<Scalars['String']['input']>
  founders?: InputMaybe<Array<InputMaybe<Pick<IComponentAboutPropsTeamMemberInput, 'name'>>>>
}

export type IComponentTestimonialsPropsAuthor = {
  __typename?: 'ComponentTestimonialsPropsAuthor'
  avatar?: Maybe<IUploadFileEntityResponse>
  company?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  role?: Maybe<Scalars['String']['output']>
}

export type IComponentTestimonialsPropsAuthorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentTestimonialsPropsAuthorFiltersInput>>>
  company?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentTestimonialsPropsAuthorFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentTestimonialsPropsAuthorFiltersInput>>>
  role?: InputMaybe<IStringFilterInput>
}

export type IComponentTestimonialsPropsAuthorInput = {
  avatar?: InputMaybe<Scalars['ID']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
}

export type IComponentTestimonialsTestimonials = {
  __typename?: 'ComponentTestimonialsTestimonials'
  author?: Maybe<IComponentTestimonialsPropsAuthor>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export type IComponentVendorPropsConsolidation = {
  __typename?: 'ComponentVendorPropsConsolidation'
  amount?: Maybe<Scalars['Int']['output']>
  article?: Maybe<Scalars['String']['output']>
  articleLink?: Maybe<Scalars['String']['output']>
  buyer?: Maybe<Scalars['String']['output']>
  date?: Maybe<Scalars['Date']['output']>
  enableInActiveDir?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
}

export type IComponentVendorPropsEnhancedlisting = {
  __typename?: 'ComponentVendorPropsEnhancedlisting'
  callToAction?: Maybe<IComponentGlobalCallToAction>
  id: Scalars['ID']['output']
  pricingData?: Maybe<Scalars['String']['output']>
  screenShots?: Maybe<Array<Maybe<IComponentGlobalPicture>>>
  testimonials?: Maybe<Array<Maybe<IComponentGlobalTestimonial>>>
  vendorResources?: Maybe<Array<Maybe<IComponentVendorPropsPoC>>>
  videos?: Maybe<Array<Maybe<IComponentVendorPropsVideo>>>
}

export type IComponentVendorPropsEnhancedlistingScreenShotsArgs = {
  filters?: InputMaybe<IComponentGlobalPictureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentVendorPropsEnhancedlistingTestimonialsArgs = {
  filters?: InputMaybe<IComponentGlobalTestimonialFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentVendorPropsEnhancedlistingVendorResourcesArgs = {
  filters?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentVendorPropsEnhancedlistingVideosArgs = {
  filters?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IComponentVendorPropsGraveyard = {
  __typename?: 'ComponentVendorPropsGraveyard'
  date?: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
}

export type IComponentVendorPropsPoC = {
  __typename?: 'ComponentVendorPropsPoC'
  author?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  publishedDate?: Maybe<Scalars['Date']['output']>
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<IEnum_Componentvendorpropspoc_Type>
}

export type IComponentVendorPropsPoCFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentVendorPropsPoCFiltersInput>>>
  author?: InputMaybe<IStringFilterInput>
  link?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IComponentVendorPropsPoCFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentVendorPropsPoCFiltersInput>>>
  publishedDate?: InputMaybe<IDateFilterInput>
  summary?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  type?: InputMaybe<IStringFilterInput>
}

export type IComponentVendorPropsPoCInput = {
  author?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  publishedDate?: InputMaybe<Scalars['Date']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<IEnum_Componentvendorpropspoc_Type>
}

export type IComponentVendorPropsPricingResource = {
  __typename?: 'ComponentVendorPropsPricingResource'
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  link?: Maybe<Scalars['String']['output']>
}

export type IComponentVendorPropsVideo = {
  __typename?: 'ComponentVendorPropsVideo'
  id: Scalars['ID']['output']
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type IComponentVendorPropsVideoFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IComponentVendorPropsVideoFiltersInput>>>
  not?: InputMaybe<IComponentVendorPropsVideoFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IComponentVendorPropsVideoFiltersInput>>>
  summary?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  url?: InputMaybe<IStringFilterInput>
}

export type IComponentVendorPropsVideoInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type IConsultantListingRequest = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  ceoEmail?: InputMaybe<Scalars['String']['input']>
  ceoName?: InputMaybe<Scalars['String']['input']>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  isCreate?: InputMaybe<Scalars['Boolean']['input']>
  lanuages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  listingName?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['Upload']['input']>
  longDescription?: InputMaybe<Scalars['String']['input']>
  marketEmail?: InputMaybe<Scalars['String']['input']>
  marketName?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  offices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  phone?: InputMaybe<Scalars['String']['input']>
  regionsServed?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  serviceName?: InputMaybe<Scalars['String']['input']>
  serviceOfferings?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Int']['input']>
  userEmail?: InputMaybe<Scalars['String']['input']>
  userName?: InputMaybe<Scalars['String']['input']>
  userRole?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Int']['input']>
}

export type IContactHistory = {
  __typename?: 'ContactHistory'
  company?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  message: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IContactHistoryEntity = {
  __typename?: 'ContactHistoryEntity'
  attributes?: Maybe<IContactHistory>
  id?: Maybe<Scalars['ID']['output']>
}

export type IContactHistoryEntityResponse = {
  __typename?: 'ContactHistoryEntityResponse'
  data?: Maybe<IContactHistoryEntity>
}

export type IContactHistoryEntityResponseCollection = {
  __typename?: 'ContactHistoryEntityResponseCollection'
  data: Array<IContactHistoryEntity>
  meta: IResponseCollectionMeta
}

export type IContactHistoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IContactHistoryFiltersInput>>>
  company?: InputMaybe<IStringFilterInput>
  country?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  email?: InputMaybe<IStringFilterInput>
  firstName?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  lastName?: InputMaybe<IStringFilterInput>
  message?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IContactHistoryFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IContactHistoryFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IContactHistoryInput = {
  company?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IContent = {
  __typename?: 'Content'
  audiences?: Maybe<IPremiumAudienceRelationResponseCollection>
  authorVendor?: Maybe<ISolutionEntityResponse>
  blogTaxonomies?: Maybe<IBlogTaxonomyRelationResponseCollection>
  collections?: Maybe<ICollectionRelationResponseCollection>
  contentType?: Maybe<IEnum_Content_Contenttype>
  content_download?: Maybe<IContentDownloadRelationResponseCollection>
  content_visit?: Maybe<IContentVisitRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  defaultImage?: Maybe<IUploadFileRelationResponseCollection>
  documentType?: Maybe<IEnum_Content_Documenttype>
  downloadMaterial?: Maybe<IComponentContentDownloadMaterial>
  isLatestBlog?: Maybe<Scalars['Boolean']['output']>
  isMaintained?: Maybe<Scalars['Boolean']['output']>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  lastMaintained?: Maybe<Scalars['Date']['output']>
  phase?: Maybe<IPhaseEntityResponse>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  publishedTime?: Maybe<Scalars['Date']['output']>
  resourceSolutions?: Maybe<ISolutionRelationResponseCollection>
  resource_invitations?: Maybe<IResourceInvitationRelationResponseCollection>
  slug: Scalars['String']['output']
  snippet: Scalars['String']['output']
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  subjectMatters?: Maybe<ISubjectMatterRelationResponseCollection>
  themes?: Maybe<IThemeRelationResponseCollection>
  title: Scalars['String']['output']
  topics?: Maybe<ITopicRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  vendorTopics?: Maybe<IVendorTopicRelationResponseCollection>
  writer?: Maybe<IComponentContentContentWriter>
}

export type IContentAudiencesArgs = {
  filters?: InputMaybe<IPremiumAudienceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentBlogTaxonomiesArgs = {
  filters?: InputMaybe<IBlogTaxonomyFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentCollectionsArgs = {
  filters?: InputMaybe<ICollectionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentContent_DownloadArgs = {
  filters?: InputMaybe<IContentDownloadFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentContent_VisitArgs = {
  filters?: InputMaybe<IContentVisitFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentDefaultImageArgs = {
  filters?: InputMaybe<IUploadFileFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentResourceSolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentResource_InvitationsArgs = {
  filters?: InputMaybe<IResourceInvitationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentSubjectMattersArgs = {
  filters?: InputMaybe<ISubjectMatterFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentThemesArgs = {
  filters?: InputMaybe<IThemeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentVendorTopicsArgs = {
  filters?: InputMaybe<IVendorTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IContentDownload = {
  __typename?: 'ContentDownload'
  content?: Maybe<IContentEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  downloaded_at?: Maybe<Scalars['DateTime']['output']>
  downloaded_by?: Maybe<IUsersPermissionsUserEntityResponse>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IContentDownloadEntity = {
  __typename?: 'ContentDownloadEntity'
  attributes?: Maybe<IContentDownload>
  id?: Maybe<Scalars['ID']['output']>
}

export type IContentDownloadEntityResponse = {
  __typename?: 'ContentDownloadEntityResponse'
  data?: Maybe<IContentDownloadEntity>
}

export type IContentDownloadEntityResponseCollection = {
  __typename?: 'ContentDownloadEntityResponseCollection'
  data: Array<IContentDownloadEntity>
  meta: IResponseCollectionMeta
}

export type IContentDownloadFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IContentDownloadFiltersInput>>>
  content?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  downloaded_at?: InputMaybe<IDateTimeFilterInput>
  downloaded_by?: InputMaybe<IUsersPermissionsUserFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IContentDownloadFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IContentDownloadFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IContentDownloadInput = {
  content?: InputMaybe<Scalars['ID']['input']>
  downloaded_at?: InputMaybe<Scalars['DateTime']['input']>
  downloaded_by?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IContentDownloadRelationResponseCollection = {
  __typename?: 'ContentDownloadRelationResponseCollection'
  data: Array<IContentDownloadEntity>
}

export type IContentEntity = {
  __typename?: 'ContentEntity'
  attributes?: Maybe<IContent>
  id?: Maybe<Scalars['ID']['output']>
}

export type IContentEntityBySlug = {
  __typename?: 'ContentEntityBySlug'
  content: IContentEntity
  isInvited?: Maybe<Scalars['Boolean']['output']>
  resourcePublishedAt?: Maybe<Scalars['String']['output']>
  vendorSelfContent?: Maybe<Scalars['Boolean']['output']>
}

export type IContentEntityResponse = {
  __typename?: 'ContentEntityResponse'
  data?: Maybe<IContentEntity>
}

export type IContentEntityResponseCollection = {
  __typename?: 'ContentEntityResponseCollection'
  data: Array<IContentEntity>
  meta: IResponseCollectionMeta
}

export type IContentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IContentFiltersInput>>>
  audiences?: InputMaybe<IPremiumAudienceFiltersInput>
  authorVendor?: InputMaybe<ISolutionFiltersInput>
  blogTaxonomies?: InputMaybe<IBlogTaxonomyFiltersInput>
  collections?: InputMaybe<ICollectionFiltersInput>
  content?: InputMaybe<IStringFilterInput>
  contentType?: InputMaybe<IStringFilterInput>
  content_download?: InputMaybe<IContentDownloadFiltersInput>
  content_visit?: InputMaybe<IContentVisitFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  documentType?: InputMaybe<IStringFilterInput>
  downloadMaterial?: InputMaybe<IComponentContentDownloadMaterialFiltersInput>
  featuredCategories?: InputMaybe<IComponentContentFeaturedCategoriesFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  includeInKnowHow?: InputMaybe<IBooleanFilterInput>
  includeInLegalTechForLawyers?: InputMaybe<IBooleanFilterInput>
  isLatestBlog?: InputMaybe<IBooleanFilterInput>
  isMaintained?: InputMaybe<IBooleanFilterInput>
  isPremium?: InputMaybe<IBooleanFilterInput>
  isTrending?: InputMaybe<IBooleanFilterInput>
  lastMaintained?: InputMaybe<IDateFilterInput>
  not?: InputMaybe<IContentFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IContentFiltersInput>>>
  phase?: InputMaybe<IPhaseFiltersInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  publishedTime?: InputMaybe<IDateFilterInput>
  resourceSolutions?: InputMaybe<ISolutionFiltersInput>
  resource_invitations?: InputMaybe<IResourceInvitationFiltersInput>
  slug?: InputMaybe<IStringFilterInput>
  snippet?: InputMaybe<IStringFilterInput>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  subjectMatters?: InputMaybe<ISubjectMatterFiltersInput>
  themes?: InputMaybe<IThemeFiltersInput>
  title?: InputMaybe<IStringFilterInput>
  topics?: InputMaybe<ITopicFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  vendorTopics?: InputMaybe<IVendorTopicFiltersInput>
  writer?: InputMaybe<IComponentContentContentWriterFiltersInput>
}

export type IContentId = {
  content_id: Scalars['ID']['input']
}

export type IContentInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  authorVendor?: InputMaybe<Scalars['ID']['input']>
  blogTaxonomies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  content?: InputMaybe<Scalars['String']['input']>
  contentType?: InputMaybe<IEnum_Content_Contenttype>
  content_download?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  content_visit?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  defaultImage?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  documentType?: InputMaybe<IEnum_Content_Documenttype>
  downloadMaterial?: InputMaybe<IComponentContentDownloadMaterialInput>
  featuredCategories?: InputMaybe<IComponentContentFeaturedCategoriesInput>
  includeInKnowHow?: InputMaybe<Scalars['Boolean']['input']>
  includeInLegalTechForLawyers?: InputMaybe<Scalars['Boolean']['input']>
  isLatestBlog?: InputMaybe<Scalars['Boolean']['input']>
  isMaintained?: InputMaybe<Scalars['Boolean']['input']>
  isPremium?: InputMaybe<Scalars['Boolean']['input']>
  isTrending?: InputMaybe<Scalars['Boolean']['input']>
  lastMaintained?: InputMaybe<Scalars['Date']['input']>
  phase?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  publishedTime?: InputMaybe<Scalars['Date']['input']>
  resourceSolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  resource_invitations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  slug?: InputMaybe<Scalars['String']['input']>
  snippet?: InputMaybe<Scalars['String']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  subjectMatters?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  themes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  title?: InputMaybe<Scalars['String']['input']>
  topics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  vendorTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  writer?: InputMaybe<IComponentContentContentWriterInput>
}

export type IContentItem = {
  __typename?: 'ContentItem'
  contentType?: Maybe<IEnum_Content_Contenttype>
  defaultImage?: Maybe<Scalars['String']['output']>
  documentType?: Maybe<IEnum_Content_Documenttype>
  id: Scalars['Int']['output']
  isMaintained?: Maybe<Scalars['Boolean']['output']>
  lastMaintainedAt?: Maybe<Scalars['DateTime']['output']>
  publishedAt: Scalars['DateTime']['output']
  publishedTime: Scalars['DateTime']['output']
  slug: Scalars['String']['output']
  snippet: Scalars['String']['output']
  title: Scalars['String']['output']
  writer?: Maybe<IContentItemWriter>
}

export type IContentItemWriter = {
  __typename?: 'ContentItemWriter'
  avatar?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
}

export type IContentRelationResponseCollection = {
  __typename?: 'ContentRelationResponseCollection'
  data: Array<IContentEntity>
}

export type IContentSearchResult = {
  __typename?: 'ContentSearchResult'
  contents?: Maybe<Array<Maybe<IContentItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type IContentSearchReturnType = {
  __typename?: 'ContentSearchReturnType'
  data?: Maybe<IContentSearchResult>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type IContentVisit = {
  __typename?: 'ContentVisit'
  content?: Maybe<IContentEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  visited_at?: Maybe<Scalars['DateTime']['output']>
  visited_by?: Maybe<IUsersPermissionsUserEntityResponse>
}

export type IContentVisitDownloadCountResult = {
  __typename?: 'ContentVisitDownloadCountResult'
  downloads?: Maybe<Array<Maybe<IContentDownload>>>
  visits?: Maybe<Array<Maybe<IContentVisit>>>
}

export type IContentVisitEntity = {
  __typename?: 'ContentVisitEntity'
  attributes?: Maybe<IContentVisit>
  id?: Maybe<Scalars['ID']['output']>
}

export type IContentVisitEntityResponse = {
  __typename?: 'ContentVisitEntityResponse'
  data?: Maybe<IContentVisitEntity>
}

export type IContentVisitEntityResponseCollection = {
  __typename?: 'ContentVisitEntityResponseCollection'
  data: Array<IContentVisitEntity>
  meta: IResponseCollectionMeta
}

export type IContentVisitFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IContentVisitFiltersInput>>>
  content?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IContentVisitFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IContentVisitFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  visited_at?: InputMaybe<IDateTimeFilterInput>
  visited_by?: InputMaybe<IUsersPermissionsUserFiltersInput>
}

export type IContentVisitInput = {
  content?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  visited_at?: InputMaybe<Scalars['DateTime']['input']>
  visited_by?: InputMaybe<Scalars['ID']['input']>
}

export type IContentVisitRelationResponseCollection = {
  __typename?: 'ContentVisitRelationResponseCollection'
  data: Array<IContentVisitEntity>
}

export type IDateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
  contains?: InputMaybe<Scalars['Date']['input']>
  containsi?: InputMaybe<Scalars['Date']['input']>
  endsWith?: InputMaybe<Scalars['Date']['input']>
  eq?: InputMaybe<Scalars['Date']['input']>
  eqi?: InputMaybe<Scalars['Date']['input']>
  gt?: InputMaybe<Scalars['Date']['input']>
  gte?: InputMaybe<Scalars['Date']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
  lt?: InputMaybe<Scalars['Date']['input']>
  lte?: InputMaybe<Scalars['Date']['input']>
  ne?: InputMaybe<Scalars['Date']['input']>
  nei?: InputMaybe<Scalars['Date']['input']>
  not?: InputMaybe<IDateFilterInput>
  notContains?: InputMaybe<Scalars['Date']['input']>
  notContainsi?: InputMaybe<Scalars['Date']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>
  startsWith?: InputMaybe<Scalars['Date']['input']>
}

export type IDateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  contains?: InputMaybe<Scalars['DateTime']['input']>
  containsi?: InputMaybe<Scalars['DateTime']['input']>
  endsWith?: InputMaybe<Scalars['DateTime']['input']>
  eq?: InputMaybe<Scalars['DateTime']['input']>
  eqi?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  ne?: InputMaybe<Scalars['DateTime']['input']>
  nei?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<IDateTimeFilterInput>
  notContains?: InputMaybe<Scalars['DateTime']['input']>
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  startsWith?: InputMaybe<Scalars['DateTime']['input']>
}

export type IDemographic = {
  __typename?: 'Demographic'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IDemographicEntity = {
  __typename?: 'DemographicEntity'
  attributes?: Maybe<IDemographic>
  id?: Maybe<Scalars['ID']['output']>
}

export type IDemographicEntityResponse = {
  __typename?: 'DemographicEntityResponse'
  data?: Maybe<IDemographicEntity>
}

export type IDemographicEntityResponseCollection = {
  __typename?: 'DemographicEntityResponseCollection'
  data: Array<IDemographicEntity>
  meta: IResponseCollectionMeta
}

export type IDemographicFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IDemographicFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IDemographicFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IDemographicFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IDemographicInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IDemographicRelationResponseCollection = {
  __typename?: 'DemographicRelationResponseCollection'
  data: Array<IDemographicEntity>
}

export type IDuration = {
  __typename?: 'Duration'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  days?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IDurationEntity = {
  __typename?: 'DurationEntity'
  attributes?: Maybe<IDuration>
  id?: Maybe<Scalars['ID']['output']>
}

export type IDurationEntityResponse = {
  __typename?: 'DurationEntityResponse'
  data?: Maybe<IDurationEntity>
}

export type IDurationEntityResponseCollection = {
  __typename?: 'DurationEntityResponseCollection'
  data: Array<IDurationEntity>
  meta: IResponseCollectionMeta
}

export type IDurationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IDurationFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  days?: InputMaybe<IIntFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IDurationFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IDurationFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IDurationInput = {
  days?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export enum IEnum_Componentconpropsdedicatedresource_Type {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export enum IEnum_Componentcontentcontentwriter_Writeroption {
  Author = 'Author',
  Expert = 'Expert',
  OneTimeAuthor = 'One_time_author',
  Vendor = 'Vendor',
}

export enum IEnum_Componentcontentdownloadmaterial_Format {
  Excel = 'Excel',
  Pdf = 'Pdf',
  Word = 'Word',
}

export enum IEnum_Componentinvitationsinvitations_Status {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Sent = 'Sent',
}

export enum IEnum_Componentsolutionalspdata_Fundingamount {
  FiveToTenM = 'Five_To_Ten_M',
  LessThanOneM = 'Less_than_One_M',
  MoreThanFiftyM = 'More_Than_Fifty_M',
  NA = 'N_A',
  OneToFiveM = 'One_To_Five_M',
  TenToTwentyFiveM = 'Ten_To_TwentyFive_M',
  TwentyFiveToFiftyM = 'TwentyFive_To_Fifty_M',
}

export enum IEnum_Componentsolutionalspdata_Fundinginfo {
  NA = 'N_A',
  Other = 'Other',
  PreSeed = 'Pre_Seed',
  Seed = 'Seed',
  SeriesA = 'Series_A',
  SeriesB = 'Series_B',
  SeriesC = 'Series_C',
}

export enum IEnum_Componentsolutionconsultantdata_Fundingamount {
  FiveToTenM = 'Five_To_Ten_M',
  LessThanOneM = 'Less_than_One_M',
  MoreThanFiftyM = 'More_Than_Fifty_M',
  NA = 'N_A',
  OneToFiveM = 'One_To_Five_M',
  TenToTwentyFiveM = 'Ten_To_TwentyFive_M',
  TwentyFiveToFiftyM = 'TwentyFive_To_Fifty_M',
}

export enum IEnum_Componentsolutionconsultantdata_Fundinginfo {
  NA = 'N_A',
  Other = 'Other',
  PreSeed = 'Pre_Seed',
  Seed = 'Seed',
  SeriesA = 'Series_A',
  SeriesB = 'Series_B',
  SeriesC = 'Series_C',
}

export enum IEnum_Componentsolutionproductdata_Fundingamount {
  FiveToTenM = 'Five_To_Ten_M',
  LessThanOneM = 'Less_than_One_M',
  OneToFiveM = 'One_To_Five_M',
  TenToTwentyFiveM = 'Ten_To_TwentyFive_M',
  TwentyFiveToFiftyM = 'TwentyFive_To_Fifty_M',
  FiftyToHundredM = 'Fifty_To_Hundred_M',
  MoreThanHundredM = 'More_Than_Hundred_M',
  NA = 'N_A',
}

export enum IEnum_Componentsolutionproductdata_Fundinginfo {
  NA = 'N_A',
  Other = 'Other',
  PreSeed = 'Pre_Seed',
  Seed = 'Seed',
  SeriesA = 'Series_A',
  SeriesB = 'Series_B',
  SeriesC = 'Series_C',
  SeriesD = 'Series_D',
}

export enum IEnum_Componentsolutionpropsdedicatedresource_Type {
  FullTime = 'FullTime',
  PartTime = 'PartTime',
}

export enum IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype {
  Article = 'Article',
  CaseStudy = 'Case_Study',
  Checklist = 'Checklist',
  PracticalGuidance = 'Practical_Guidance',
  PressRelease = 'Press_Release',
  ProductBriefing = 'Product_Briefing',
  WhitePaper = 'White_Paper',
}

export enum IEnum_Componentsolutionpropsvendorresource_Type {
  Content = 'Content',
  External = 'External',
}

export enum IEnum_Componentsolutionrequestalspdata_Fundingamount {
  FiveToTenM = 'Five_To_Ten_M',
  LessThanOneM = 'Less_than_One_M',
  MoreThanFiftyM = 'More_Than_Fifty_M',
  NA = 'N_A',
  OneToFiveM = 'One_To_Five_M',
  TenToTwentyFiveM = 'Ten_To_TwentyFive_M',
  TwentyFiveToFiftyM = 'TwentyFive_To_Fifty_M',
}

export enum IEnum_Componentsolutionrequestalspdata_Fundinginfo {
  NA = 'N_A',
  Other = 'Other',
  PreSeed = 'Pre_Seed',
  Seed = 'Seed',
  SeriesA = 'Series_A',
  SeriesB = 'Series_B',
  SeriesC = 'Series_C',
}

export enum IEnum_Componentsolutionrequestconsultantdata_Fundingamount {
  FiveToTenM = 'Five_To_Ten_M',
  LessThanOneM = 'Less_than_One_M',
  MoreThanFiftyM = 'More_Than_Fifty_M',
  NA = 'N_A',
  OneToFiveM = 'One_To_Five_M',
  TenToTwentyFiveM = 'Ten_To_TwentyFive_M',
  TwentyFiveToFiftyM = 'TwentyFive_To_Fifty_M',
}

export enum IEnum_Componentsolutionrequestconsultantdata_Fundinginfo {
  NA = 'N_A',
  Other = 'Other',
  PreSeed = 'Pre_Seed',
  Seed = 'Seed',
  SeriesA = 'Series_A',
  SeriesB = 'Series_B',
  SeriesC = 'Series_C',
}

export enum IEnum_Componentsolutionrequestproductdata_Fundingamount {
  FiveToTenM = 'Five_To_Ten_M',
  LessThanOneM = 'Less_than_One_M',
  OneToFiveM = 'One_To_Five_M',
  TenToTwentyFiveM = 'Ten_To_TwentyFive_M',
  TwentyFiveToFiftyM = 'TwentyFive_To_Fifty_M',
  FiftyToHundredM = 'Fifty_To_Hundred_M',
  MoreThanHundredM = 'More_Than_Hundred_M',
  NA = 'N_A',
}

export enum IEnum_Componentsolutionrequestproductdata_Fundinginfo {
  NA = 'N_A',
  Other = 'Other',
  PreSeed = 'Pre_Seed',
  Seed = 'Seed',
  SeriesA = 'Series_A',
  SeriesB = 'Series_B',
  SeriesC = 'Series_C',
  SeriesD = 'Series_D',
}

export enum IEnum_Componentvendorpropspoc_Type {
  Article = 'Article',
  CaseStudy = 'CaseStudy',
  PressRelease = 'PressRelease',
  Whitepaper = 'Whitepaper',
}

export enum IEnum_Content_Contenttype {
  Blog = 'Blog',
  BusinessCase = 'Business_Case',
  BuyersGuide = 'Buyers_Guide',
  CompetitiveAnalysis = 'Competitive_Analysis',
  EvaluationFramework = 'Evaluation_Framework',
  IndustryAnalysis = 'Industry_Analysis',
  KnowHow = 'KnowHow',
  LandscapeArticle = 'Landscape_Article',
  LegaltechResourcesForLawyers = 'Legaltech_Resources_for_Lawyers',
  PilotGuide = 'Pilot_Guide',
  RequirementsTemplate = 'Requirements_Template',
  ThoughtLeadership = 'Thought_Leadership',
  VendorAnalysis = 'Vendor_Analysis',
  VendorResource = 'Vendor_Resource',
  VendorContent = 'Vendor_Content',
}

export enum IEnum_Content_Documenttype {
  Article = 'Article',
  CaseStudy = 'Case_Study',
  Checklist = 'Checklist',
  PracticalGuidance = 'Practical_Guidance',
  PressRelease = 'Press_Release',
  ProductBriefing = 'Product_Briefing',
  WhitePaper = 'White_Paper',
  Review = 'Review',
  Template = 'Template',
}

export enum IEnum_Listingrequest_Basicorenhanced {
  Basic = 'Basic',
  Enhanced = 'Enhanced',
}

export enum IEnum_Listingrequest_Createorupdate {
  Create = 'Create',
  Update = 'Update',
}

export enum IEnum_Listingrequest_Listingtype {
  Alsp = 'ALSP',
  Consultant = 'Consultant',
  Vendor = 'Vendor',
}

export enum IEnum_Listingrequest_Status {
  Assigned = 'Assigned',
  Completed = 'Completed',
  Initial = 'Initial',
  Review = 'Review',
}

export enum IEnum_Premiumorganization_Status {
  Paid = 'Paid',
  RegisteredNotPaid = 'Registered_Not_Paid',
  Reviewing = 'Reviewing',
}

export enum IEnum_Premiumpricingplan_Companysize {
  AlspInEarlyStage = 'ALSP_in_early_stage',
  AlspInMatureStage = 'ALSP_in_mature_stage',
  ConsultantWithLessThan_25Consultants = 'Consultant_with_less_than_25_consultants',
  ConsultantWithMoreThan_25Consultants = 'Consultant_with_more_than_25_consultants',
  Employees_1To_10 = 'Employees_1_to_10',
  Employees_11To_50 = 'Employees_11_to_50',
  Employees_51To_200 = 'Employees_51_to_200',
  Employees_201To_500 = 'Employees_201_to_500',
  Employees_501To_1000 = 'Employees_501_to_1000',
  EmployeesMoreThan_1000 = 'Employees_more_than_1000',
  LawFirmWith_5To_25Lawyers = 'Law_firm_with_5_to_25_lawyers',
  LawFirmWith_25To_100Lawyers = 'Law_firm_with_25_to_100_lawyers',
  LawFirmWith_100To_250Lawyers = 'Law_firm_with_100_to_250_lawyers',
  LawFirmWith_250To_1000Lawyers = 'Law_firm_with_250_to_1000_lawyers',
  LawFirmWithLessThan_5Lawyers = 'Law_firm_with_less_than_5_lawyers',
  LawFirmWithMoreThan_1000Lawyers = 'Law_firm_with_more_than_1000_lawyers',
  LegalTechVendorInEarlyStage = 'Legal_Tech_Vendor_in_early_stage',
  LegalTechVendorInMatureStage = 'Legal_Tech_Vendor_in_mature_stage',
  LegalDepartmentWith_5To_25Lawyers = 'Legal_department_with_5_to_25_lawyers',
  LegalDepartmentWith_25To_100Lawyers = 'Legal_department_with_25_to_100_lawyers',
  LegalDepartmentWithLessThan_5Lawyers = 'Legal_department_with_less_than_5_lawyers',
  LegalDepartmentWithMoreThan_100Lawyers = 'Legal_department_with_more_than_100_lawyers',
  Other = 'Other',
}

export enum IEnum_Premiumpricingplan_Companytype {
  Alsp = 'ALSP',
  Consultant = 'Consultant',
  LawDepartment = 'Law_department',
  LawFirm = 'Law_firm',
  Other = 'Other',
  Vendor = 'Vendor',
}

export enum IEnum_Review_Status {
  Approved = 'Approved',
  InReview = 'InReview',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
}

export enum IEnum_Solutionrequest_Type {
  Alsp = 'ALSP',
  Consultancy = 'Consultancy',
  Product = 'Product',
}

export enum IEnum_Solution_Item_Type {
  Alsp = 'ALSP',
  Company = 'Company',
  Consultancy = 'Consultancy',
  Product = 'Product',
}

export enum IEnum_Solution_Producttype {
  Consolidation = 'consolidation',
  Default = 'default',
  Graveyard = 'graveyard',
}

export enum IEnum_Solution_Type {
  Alsp = 'ALSP',
  Consultancy = 'Consultancy',
  Product = 'Product',
}

export enum IEnum_Userspermissionsuser_Companysize {
  AlspInEarlyStage = 'ALSP_in_early_stage',
  AlspInMatureStage = 'ALSP_in_mature_stage',
  ConsultantWithLessThan_25Consultants = 'Consultant_with_less_than_25_consultants',
  ConsultantWithMoreThan_25Consultants = 'Consultant_with_more_than_25_consultants',
  Employees_1To_10 = 'Employees_1_to_10',
  Employees_11To_50 = 'Employees_11_to_50',
  Employees_51To_200 = 'Employees_51_to_200',
  Employees_201To_500 = 'Employees_201_to_500',
  Employees_501To_1000 = 'Employees_501_to_1000',
  EmployeesMoreThan_1000 = 'Employees_more_than_1000',
  LawFirmWith_5To_25Lawyers = 'Law_firm_with_5_to_25_lawyers',
  LawFirmWith_25To_100Lawyers = 'Law_firm_with_25_to_100_lawyers',
  LawFirmWith_100To_250Lawyers = 'Law_firm_with_100_to_250_lawyers',
  LawFirmWith_250To_1000Lawyers = 'Law_firm_with_250_to_1000_lawyers',
  LawFirmWithLessThan_5Lawyers = 'Law_firm_with_less_than_5_lawyers',
  LawFirmWithMoreThan_1000Lawyers = 'Law_firm_with_more_than_1000_lawyers',
  LegalTechVendorInEarlyStage = 'Legal_Tech_Vendor_in_early_stage',
  LegalTechVendorInMatureStage = 'Legal_Tech_Vendor_in_mature_stage',
  LegalDepartmentWith_5To_25Lawyers = 'Legal_department_with_5_to_25_lawyers',
  LegalDepartmentWith_25To_100Lawyers = 'Legal_department_with_25_to_100_lawyers',
  LegalDepartmentWithLessThan_5Lawyers = 'Legal_department_with_less_than_5_lawyers',
  LegalDepartmentWithMoreThan_100Lawyers = 'Legal_department_with_more_than_100_lawyers',
  Other = 'Other',
  VendorOrganizationWith_10To_20 = 'Vendor_organization_with_10_to_20',
  VendorOrganizationWith_20To_50 = 'Vendor_organization_with_20_to_50',
  VendorOrganizationWithLessThan_10 = 'Vendor_organization_with_less_than_10',
  VendorOrganizationWithMoreThan_50 = 'Vendor_organization_with_more_than_50',
}

export enum IEnum_Userspermissionsuser_Companytype {
  Alsp = 'ALSP',
  Consultant = 'Consultant',
  LawDepartment = 'Law_department',
  LawFirm = 'Law_firm',
  Other = 'Other',
  Vendor = 'Vendor',
}

export enum IEnum_Vendor_Companysize {
  VendorOrganizationWith_10To_20 = 'Vendor_organization_with_10_to_20',
  VendorOrganizationWith_20To_50 = 'Vendor_organization_with_20_to_50',
  VendorOrganizationWithLessThan_10 = 'Vendor_organization_with_less_than_10',
  VendorOrganizationWithMoreThan_50 = 'Vendor_organization_with_more_than_50',
}

export enum IEnum_Vendor_Status {
  Paid = 'Paid',
  RegisteredNotPaid = 'Registered_Not_Paid',
  Reviewing = 'Reviewing',
}

export type IEmailReceiver = {
  __typename?: 'EmailReceiver'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  enable?: Maybe<Scalars['Boolean']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IEmailReceiverEntity = {
  __typename?: 'EmailReceiverEntity'
  attributes?: Maybe<IEmailReceiver>
  id?: Maybe<Scalars['ID']['output']>
}

export type IEmailReceiverEntityResponse = {
  __typename?: 'EmailReceiverEntityResponse'
  data?: Maybe<IEmailReceiverEntity>
}

export type IEmailReceiverEntityResponseCollection = {
  __typename?: 'EmailReceiverEntityResponseCollection'
  data: Array<IEmailReceiverEntity>
  meta: IResponseCollectionMeta
}

export type IEmailReceiverFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IEmailReceiverFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  email?: InputMaybe<IStringFilterInput>
  enable?: InputMaybe<IBooleanFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IEmailReceiverFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IEmailReceiverFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IEmailReceiverInput = {
  email?: InputMaybe<Scalars['String']['input']>
  enable?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IEnhancedRequestContent = {
  __typename?: 'EnhancedRequestContent'
  callToAction?: Maybe<Scalars['String']['output']>
  competitor?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  moreVisibility?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  screenshots?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IEnhancedRequestContentEntity = {
  __typename?: 'EnhancedRequestContentEntity'
  attributes?: Maybe<IEnhancedRequestContent>
  id?: Maybe<Scalars['ID']['output']>
}

export type IEnhancedRequestContentEntityResponse = {
  __typename?: 'EnhancedRequestContentEntityResponse'
  data?: Maybe<IEnhancedRequestContentEntity>
}

export type IEnhancedRequestContentInput = {
  callToAction?: InputMaybe<Scalars['String']['input']>
  competitor?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  moreVisibility?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  screenshots?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IEvent = {
  __typename?: 'Event'
  audiences?: Maybe<IAudienceRelationResponseCollection>
  city?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  date?: Maybe<Scalars['Date']['output']>
  description?: Maybe<Scalars['String']['output']>
  durationId?: Maybe<IDurationEntityResponse>
  featured?: Maybe<Scalars['Boolean']['output']>
  features?: Maybe<IEventFeatureRelationResponseCollection>
  formatId?: Maybe<IEventFormatEntityResponse>
  logo?: Maybe<IUploadFileEntityResponse>
  notes?: Maybe<Scalars['String']['output']>
  organizer: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  recurrenceId?: Maybe<IRecurrenceEntityResponse>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  website: Scalars['String']['output']
}

export type IEventAudiencesArgs = {
  filters?: InputMaybe<IAudienceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IEventFeaturesArgs = {
  filters?: InputMaybe<IEventFeatureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IEventEntity = {
  __typename?: 'EventEntity'
  attributes?: Maybe<IEvent>
  id?: Maybe<Scalars['ID']['output']>
}

export type IEventEntityResponse = {
  __typename?: 'EventEntityResponse'
  data?: Maybe<IEventEntity>
}

export type IEventEntityResponseCollection = {
  __typename?: 'EventEntityResponseCollection'
  data: Array<IEventEntity>
  meta: IResponseCollectionMeta
}

export type IEventFeature = {
  __typename?: 'EventFeature'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IEventFeatureEntity = {
  __typename?: 'EventFeatureEntity'
  attributes?: Maybe<IEventFeature>
  id?: Maybe<Scalars['ID']['output']>
}

export type IEventFeatureEntityResponse = {
  __typename?: 'EventFeatureEntityResponse'
  data?: Maybe<IEventFeatureEntity>
}

export type IEventFeatureEntityResponseCollection = {
  __typename?: 'EventFeatureEntityResponseCollection'
  data: Array<IEventFeatureEntity>
  meta: IResponseCollectionMeta
}

export type IEventFeatureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IEventFeatureFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IEventFeatureFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IEventFeatureFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IEventFeatureInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IEventFeatureRelationResponseCollection = {
  __typename?: 'EventFeatureRelationResponseCollection'
  data: Array<IEventFeatureEntity>
}

export type IEventFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IEventFiltersInput>>>
  audiences?: InputMaybe<IAudienceFiltersInput>
  city?: InputMaybe<IStringFilterInput>
  country?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  date?: InputMaybe<IDateFilterInput>
  description?: InputMaybe<IStringFilterInput>
  durationId?: InputMaybe<IDurationFiltersInput>
  featured?: InputMaybe<IBooleanFilterInput>
  features?: InputMaybe<IEventFeatureFiltersInput>
  formatId?: InputMaybe<IEventFormatFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IEventFiltersInput>
  notes?: InputMaybe<IStringFilterInput>
  or?: InputMaybe<Array<InputMaybe<IEventFiltersInput>>>
  organizer?: InputMaybe<IStringFilterInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  recurrenceId?: InputMaybe<IRecurrenceFiltersInput>
  slug?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  website?: InputMaybe<IStringFilterInput>
}

export type IEventFormat = {
  __typename?: 'EventFormat'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IEventFormatEntity = {
  __typename?: 'EventFormatEntity'
  attributes?: Maybe<IEventFormat>
  id?: Maybe<Scalars['ID']['output']>
}

export type IEventFormatEntityResponse = {
  __typename?: 'EventFormatEntityResponse'
  data?: Maybe<IEventFormatEntity>
}

export type IEventFormatEntityResponseCollection = {
  __typename?: 'EventFormatEntityResponseCollection'
  data: Array<IEventFormatEntity>
  meta: IResponseCollectionMeta
}

export type IEventFormatFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IEventFormatFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IEventFormatFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IEventFormatFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IEventFormatInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IEventInput = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['Date']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  durationId?: InputMaybe<Scalars['ID']['input']>
  featured?: InputMaybe<Scalars['Boolean']['input']>
  features?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  formatId?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<Scalars['ID']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  organizer?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  recurrenceId?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type IEventSearchResult = {
  __typename?: 'EventSearchResult'
  events?: Maybe<Array<Maybe<IEvent>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type IEventSearchReturnType = {
  __typename?: 'EventSearchReturnType'
  data?: Maybe<IEventSearchResult>
  success?: Maybe<Scalars['Boolean']['output']>
}

export type IExistingCustomer = {
  __typename?: 'ExistingCustomer'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IExistingCustomerEntity = {
  __typename?: 'ExistingCustomerEntity'
  attributes?: Maybe<IExistingCustomer>
  id?: Maybe<Scalars['ID']['output']>
}

export type IExistingCustomerEntityResponse = {
  __typename?: 'ExistingCustomerEntityResponse'
  data?: Maybe<IExistingCustomerEntity>
}

export type IExistingCustomerEntityResponseCollection = {
  __typename?: 'ExistingCustomerEntityResponseCollection'
  data: Array<IExistingCustomerEntity>
  meta: IResponseCollectionMeta
}

export type IExistingCustomerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IExistingCustomerFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IExistingCustomerFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IExistingCustomerFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IExistingCustomerInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IExistingCustomerRelationResponseCollection = {
  __typename?: 'ExistingCustomerRelationResponseCollection'
  data: Array<IExistingCustomerEntity>
}

export type IExpert = {
  __typename?: 'Expert'
  avatar?: Maybe<IUploadFileEntityResponse>
  bio?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  experience?: Maybe<Scalars['String']['output']>
  expertiseSubTopics?: Maybe<ISubTopicRelationResponseCollection>
  expertiseTopics?: Maybe<ITopicRelationResponseCollection>
  firstName: Scalars['String']['output']
  jobTitle?: Maybe<Scalars['String']['output']>
  lastName: Scalars['String']['output']
  linkedin?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  twitter?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IExpertContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IExpertExpertiseSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IExpertExpertiseTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IExpertEntity = {
  __typename?: 'ExpertEntity'
  attributes?: Maybe<IExpert>
  id?: Maybe<Scalars['ID']['output']>
}

export type IExpertEntityResponse = {
  __typename?: 'ExpertEntityResponse'
  data?: Maybe<IExpertEntity>
}

export type IExpertEntityResponseCollection = {
  __typename?: 'ExpertEntityResponseCollection'
  data: Array<IExpertEntity>
  meta: IResponseCollectionMeta
}

export type IExpertFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IExpertFiltersInput>>>
  bio?: InputMaybe<IStringFilterInput>
  company?: InputMaybe<IStringFilterInput>
  contactEmail?: InputMaybe<IStringFilterInput>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  displayName?: InputMaybe<IStringFilterInput>
  experience?: InputMaybe<IStringFilterInput>
  expertiseSubTopics?: InputMaybe<ISubTopicFiltersInput>
  expertiseTopics?: InputMaybe<ITopicFiltersInput>
  firstName?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  jobTitle?: InputMaybe<IStringFilterInput>
  lastName?: InputMaybe<IStringFilterInput>
  linkedin?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IExpertFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IExpertFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  twitter?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IExpertInput = {
  avatar?: InputMaybe<Scalars['ID']['input']>
  bio?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  contactEmail?: InputMaybe<Scalars['String']['input']>
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  displayName?: InputMaybe<Scalars['String']['input']>
  experience?: InputMaybe<Scalars['String']['input']>
  expertiseSubTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  expertiseTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  firstName?: InputMaybe<Scalars['String']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
}

export type IFeature = {
  __typename?: 'Feature'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IFeatureSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IFeatureEntity = {
  __typename?: 'FeatureEntity'
  attributes?: Maybe<IFeature>
  id?: Maybe<Scalars['ID']['output']>
}

export type IFeatureEntityResponse = {
  __typename?: 'FeatureEntityResponse'
  data?: Maybe<IFeatureEntity>
}

export type IFeatureEntityResponseCollection = {
  __typename?: 'FeatureEntityResponseCollection'
  data: Array<IFeatureEntity>
  meta: IResponseCollectionMeta
}

export type IFeatureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IFeatureFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  internalName?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IFeatureFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IFeatureFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IFeatureInput = {
  internalName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type IFeatureRelationResponseCollection = {
  __typename?: 'FeatureRelationResponseCollection'
  data: Array<IFeatureEntity>
}

export type IFileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>
  caption?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type IFloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  contains?: InputMaybe<Scalars['Float']['input']>
  containsi?: InputMaybe<Scalars['Float']['input']>
  endsWith?: InputMaybe<Scalars['Float']['input']>
  eq?: InputMaybe<Scalars['Float']['input']>
  eqi?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  ne?: InputMaybe<Scalars['Float']['input']>
  nei?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<IFloatFilterInput>
  notContains?: InputMaybe<Scalars['Float']['input']>
  notContainsi?: InputMaybe<Scalars['Float']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  startsWith?: InputMaybe<Scalars['Float']['input']>
}

export type IFollowingSubTopic = {
  __typename?: 'FollowingSubTopic'
  attributes?: Maybe<ISubTopic>
  id?: Maybe<Scalars['ID']['output']>
}

export type IFollowingSubTopicEntity = {
  __typename?: 'FollowingSubTopicEntity'
  data?: Maybe<Array<Maybe<IFollowingSubTopic>>>
}

export type IFollowingTopic = {
  __typename?: 'FollowingTopic'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  featuredOnHome?: Maybe<Scalars['Boolean']['output']>
  iconName?: Maybe<Scalars['String']['output']>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  subTopics?: Maybe<IFollowingSubTopicEntity>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IFollowingTopicEntity = {
  __typename?: 'FollowingTopicEntity'
  attributes?: Maybe<IFollowingTopic>
  id?: Maybe<Scalars['ID']['output']>
}

export type IGenericMorph =
  | IAbout
  | IAnnouncement
  | IArticleData
  | IAttribute
  | IAudience
  | IAuthor
  | IBlogTaxonomy
  | ICollection
  | ICompany
  | IComponentAboutPropsTeamMember
  | IComponentAlspPropsAlspEnhanced
  | IComponentBillingAddressBillingAddress
  | IComponentConPropsConEnhancedListing
  | IComponentConPropsDedicatedResource
  | IComponentContentContentWriter
  | IComponentContentDownloadMaterial
  | IComponentContentFeaturedCategories
  | IComponentGlobalArticle
  | IComponentGlobalCallToAction
  | IComponentGlobalPicture
  | IComponentGlobalPricingModel
  | IComponentGlobalSuccessStory
  | IComponentGlobalTestimonial
  | IComponentInvitationsInvitations
  | IComponentRegistrationContactRegistrationContact
  | IComponentRequestEnhancedListReq
  | IComponentRequestEnhancedVendorReq
  | IComponentRequestListingReq
  | IComponentRequestVendorReq
  | IComponentSolutionAlspData
  | IComponentSolutionConsultantData
  | IComponentSolutionProductData
  | IComponentSolutionPropsAlspEnhanced
  | IComponentSolutionPropsConsolidation
  | IComponentSolutionPropsConsultantEnhanced
  | IComponentSolutionPropsDedicatedResource
  | IComponentSolutionPropsGraveyard
  | IComponentSolutionPropsProductEnhanced
  | IComponentSolutionPropsVendorResource
  | IComponentSolutionPropsVendorResourceExternal
  | IComponentSolutionPropsVideo
  | IComponentSolutionRequestAlspData
  | IComponentSolutionRequestConsultantData
  | IComponentSolutionRequestProductData
  | IComponentSolutionRequestPropsProductEnhanced
  | IComponentTestimonialsPropsAuthor
  | IComponentTestimonialsTestimonials
  | IComponentVendorPropsConsolidation
  | IComponentVendorPropsEnhancedlisting
  | IComponentVendorPropsGraveyard
  | IComponentVendorPropsPoC
  | IComponentVendorPropsPricingResource
  | IComponentVendorPropsVideo
  | IContactHistory
  | IContent
  | IContentDownload
  | IContentVisit
  | IDemographic
  | IDuration
  | IEmailReceiver
  | IEnhancedRequestContent
  | IEvent
  | IEventFeature
  | IEventFormat
  | IExistingCustomer
  | IExpert
  | IFeature
  | IHq
  | II18NLocale
  | IIltaData
  | IIltaSponsorship
  | IInstallation
  | IIntegration
  | IListingRequest
  | INewsletterCategory
  | INewsletterSubscription
  | IOffice
  | IPhase
  | IPlatformLanguage
  | IPracticeArea
  | IPremiumAudience
  | IPremiumOrganization
  | IPremiumPricingPlan
  | IPrivacyPolicy
  | IRecordLockingOpenEntity
  | IRecurrence
  | IRegionsServed
  | IResourceInvitation
  | IReview
  | ISecurity
  | ISlugUpdate
  | ISolution
  | ISolutionRequest
  | ISubTopic
  | ISubjectMatter
  | ITermsConditions
  | ITestimonials
  | ITheme
  | ITopic
  | ITypeOfUser
  | IUploadFile
  | IUploadFolder
  | IUsersPermissionsPermission
  | IUsersPermissionsRole
  | IUsersPermissionsUser
  | IVendor
  | IVendorTopic

export type IHq = {
  __typename?: 'Hq'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IHqEntity = {
  __typename?: 'HqEntity'
  attributes?: Maybe<IHq>
  id?: Maybe<Scalars['ID']['output']>
}

export type IHqEntityResponse = {
  __typename?: 'HqEntityResponse'
  data?: Maybe<IHqEntity>
}

export type IHqEntityResponseCollection = {
  __typename?: 'HqEntityResponseCollection'
  data: Array<IHqEntity>
  meta: IResponseCollectionMeta
}

export type IHqFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IHqFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IHqFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IHqFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IHqInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IHqRelationResponseCollection = {
  __typename?: 'HqRelationResponseCollection'
  data: Array<IHqEntity>
}

export type II18NLocale = {
  __typename?: 'I18NLocale'
  code?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type II18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity'
  attributes?: Maybe<II18NLocale>
  id?: Maybe<Scalars['ID']['output']>
}

export type II18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse'
  data?: Maybe<II18NLocaleEntity>
}

export type II18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection'
  data: Array<II18NLocaleEntity>
  meta: IResponseCollectionMeta
}

export type II18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<II18NLocaleFiltersInput>>>
  code?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<II18NLocaleFiltersInput>
  or?: InputMaybe<Array<InputMaybe<II18NLocaleFiltersInput>>>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IIdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  contains?: InputMaybe<Scalars['ID']['input']>
  containsi?: InputMaybe<Scalars['ID']['input']>
  endsWith?: InputMaybe<Scalars['ID']['input']>
  eq?: InputMaybe<Scalars['ID']['input']>
  eqi?: InputMaybe<Scalars['ID']['input']>
  gt?: InputMaybe<Scalars['ID']['input']>
  gte?: InputMaybe<Scalars['ID']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  lt?: InputMaybe<Scalars['ID']['input']>
  lte?: InputMaybe<Scalars['ID']['input']>
  ne?: InputMaybe<Scalars['ID']['input']>
  nei?: InputMaybe<Scalars['ID']['input']>
  not?: InputMaybe<IIdFilterInput>
  notContains?: InputMaybe<Scalars['ID']['input']>
  notContainsi?: InputMaybe<Scalars['ID']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  startsWith?: InputMaybe<Scalars['ID']['input']>
}

export type IIltaData = {
  __typename?: 'IltaData'
  bulkUploadIltaSponsorship?: Maybe<IUploadFileEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IIltaDataEntity = {
  __typename?: 'IltaDataEntity'
  attributes?: Maybe<IIltaData>
  id?: Maybe<Scalars['ID']['output']>
}

export type IIltaDataEntityResponse = {
  __typename?: 'IltaDataEntityResponse'
  data?: Maybe<IIltaDataEntity>
}

export type IIltaDataInput = {
  bulkUploadIltaSponsorship?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IIltaSponsorship = {
  __typename?: 'IltaSponsorship'
  companies?: Maybe<ICompanyRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  level?: Maybe<Scalars['Int']['output']>
  logo?: Maybe<IUploadFileEntityResponse>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IIltaSponsorshipCompaniesArgs = {
  filters?: InputMaybe<ICompanyFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IIltaSponsorshipEntity = {
  __typename?: 'IltaSponsorshipEntity'
  attributes?: Maybe<IIltaSponsorship>
  id?: Maybe<Scalars['ID']['output']>
}

export type IIltaSponsorshipEntityResponse = {
  __typename?: 'IltaSponsorshipEntityResponse'
  data?: Maybe<IIltaSponsorshipEntity>
}

export type IIltaSponsorshipEntityResponseCollection = {
  __typename?: 'IltaSponsorshipEntityResponseCollection'
  data: Array<IIltaSponsorshipEntity>
  meta: IResponseCollectionMeta
}

export type IIltaSponsorshipFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IIltaSponsorshipFiltersInput>>>
  companies?: InputMaybe<ICompanyFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  level?: InputMaybe<IIntFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IIltaSponsorshipFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IIltaSponsorshipFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IIltaSponsorshipInput = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  level?: InputMaybe<Scalars['Int']['input']>
  logo?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IIltaSponsorshipRelationResponseCollection = {
  __typename?: 'IltaSponsorshipRelationResponseCollection'
  data: Array<IIltaSponsorshipEntity>
}

export type IInstallation = {
  __typename?: 'Installation'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IInstallationEntity = {
  __typename?: 'InstallationEntity'
  attributes?: Maybe<IInstallation>
  id?: Maybe<Scalars['ID']['output']>
}

export type IInstallationEntityResponse = {
  __typename?: 'InstallationEntityResponse'
  data?: Maybe<IInstallationEntity>
}

export type IInstallationEntityResponseCollection = {
  __typename?: 'InstallationEntityResponseCollection'
  data: Array<IInstallationEntity>
  meta: IResponseCollectionMeta
}

export type IInstallationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IInstallationFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IInstallationFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IInstallationFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IInstallationInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IInstallationRelationResponseCollection = {
  __typename?: 'InstallationRelationResponseCollection'
  data: Array<IInstallationEntity>
}

export type IIntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  contains?: InputMaybe<Scalars['Int']['input']>
  containsi?: InputMaybe<Scalars['Int']['input']>
  endsWith?: InputMaybe<Scalars['Int']['input']>
  eq?: InputMaybe<Scalars['Int']['input']>
  eqi?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  ne?: InputMaybe<Scalars['Int']['input']>
  nei?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<IIntFilterInput>
  notContains?: InputMaybe<Scalars['Int']['input']>
  notContainsi?: InputMaybe<Scalars['Int']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  startsWith?: InputMaybe<Scalars['Int']['input']>
}

export type IIntegration = {
  __typename?: 'Integration'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IIntegrationEntity = {
  __typename?: 'IntegrationEntity'
  attributes?: Maybe<IIntegration>
  id?: Maybe<Scalars['ID']['output']>
}

export type IIntegrationEntityResponse = {
  __typename?: 'IntegrationEntityResponse'
  data?: Maybe<IIntegrationEntity>
}

export type IIntegrationEntityResponseCollection = {
  __typename?: 'IntegrationEntityResponseCollection'
  data: Array<IIntegrationEntity>
  meta: IResponseCollectionMeta
}

export type IIntegrationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IIntegrationFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IIntegrationFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IIntegrationFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IIntegrationInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IIntegrationRelationResponseCollection = {
  __typename?: 'IntegrationRelationResponseCollection'
  data: Array<IIntegrationEntity>
}

export type IInvitationInput = {
  businessEmail: Scalars['String']['input']
  companyLawFirmName: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type IJsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  contains?: InputMaybe<Scalars['JSON']['input']>
  containsi?: InputMaybe<Scalars['JSON']['input']>
  endsWith?: InputMaybe<Scalars['JSON']['input']>
  eq?: InputMaybe<Scalars['JSON']['input']>
  eqi?: InputMaybe<Scalars['JSON']['input']>
  gt?: InputMaybe<Scalars['JSON']['input']>
  gte?: InputMaybe<Scalars['JSON']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  lt?: InputMaybe<Scalars['JSON']['input']>
  lte?: InputMaybe<Scalars['JSON']['input']>
  ne?: InputMaybe<Scalars['JSON']['input']>
  nei?: InputMaybe<Scalars['JSON']['input']>
  not?: InputMaybe<IJsonFilterInput>
  notContains?: InputMaybe<Scalars['JSON']['input']>
  notContainsi?: InputMaybe<Scalars['JSON']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>
  startsWith?: InputMaybe<Scalars['JSON']['input']>
}

export type IListingRequest = {
  __typename?: 'ListingRequest'
  alspReq?: Maybe<IComponentRequestListingReq>
  basicOrEnhanced?: Maybe<IEnum_Listingrequest_Basicorenhanced>
  companyName?: Maybe<Scalars['String']['output']>
  consultantReq?: Maybe<IComponentRequestListingReq>
  createOrUpdate?: Maybe<IEnum_Listingrequest_Createorupdate>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  enhancedAlspReq?: Maybe<IComponentRequestEnhancedListReq>
  enhancedConsultantReq?: Maybe<IComponentRequestEnhancedListReq>
  enhancedVendorReq?: Maybe<IComponentRequestEnhancedVendorReq>
  listingType?: Maybe<IEnum_Listingrequest_Listingtype>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  status?: Maybe<IEnum_Listingrequest_Status>
  submittedEmail?: Maybe<Scalars['String']['output']>
  submittedName?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  vendorReq?: Maybe<IComponentRequestVendorReq>
}

export type IListingRequestData = {
  alspReq?: InputMaybe<IConsultantListingRequest>
  consultantReq?: InputMaybe<IConsultantListingRequest>
  enhancedAlspReq?: InputMaybe<IComponentRequestEnhancedListReqInput>
  enhancedConsultantReq?: InputMaybe<IComponentRequestEnhancedListReqInput>
  enhancedVendorReq?: InputMaybe<IComponentRequestEnhancedVendorReqInput>
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  listingType?: InputMaybe<IEnum_Listingrequest_Listingtype>
  vendorReq?: InputMaybe<IVendorListingRequest>
}

export type IListingRequestEntity = {
  __typename?: 'ListingRequestEntity'
  attributes?: Maybe<IListingRequest>
  id?: Maybe<Scalars['ID']['output']>
}

export type IListingRequestEntityResponse = {
  __typename?: 'ListingRequestEntityResponse'
  data?: Maybe<IListingRequestEntity>
}

export type IListingRequestEntityResponseCollection = {
  __typename?: 'ListingRequestEntityResponseCollection'
  data: Array<IListingRequestEntity>
  meta: IResponseCollectionMeta
}

export type IListingRequestFiltersInput = {
  alspReq?: InputMaybe<IComponentRequestListingReqFiltersInput>
  and?: InputMaybe<Array<InputMaybe<IListingRequestFiltersInput>>>
  basicOrEnhanced?: InputMaybe<IStringFilterInput>
  companyName?: InputMaybe<IStringFilterInput>
  consultantReq?: InputMaybe<IComponentRequestListingReqFiltersInput>
  createOrUpdate?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  enhancedAlspReq?: InputMaybe<IComponentRequestEnhancedListReqFiltersInput>
  enhancedConsultantReq?: InputMaybe<IComponentRequestEnhancedListReqFiltersInput>
  enhancedVendorReq?: InputMaybe<IComponentRequestEnhancedVendorReqFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  listingType?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IListingRequestFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IListingRequestFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  status?: InputMaybe<IStringFilterInput>
  submittedEmail?: InputMaybe<IStringFilterInput>
  submittedName?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  vendorReq?: InputMaybe<IComponentRequestVendorReqFiltersInput>
}

export type IListingRequestInput = {
  alspReq?: InputMaybe<IComponentRequestListingReqInput>
  basicOrEnhanced?: InputMaybe<IEnum_Listingrequest_Basicorenhanced>
  companyName?: InputMaybe<Scalars['String']['input']>
  consultantReq?: InputMaybe<IComponentRequestListingReqInput>
  createOrUpdate?: InputMaybe<IEnum_Listingrequest_Createorupdate>
  enhancedAlspReq?: InputMaybe<IComponentRequestEnhancedListReqInput>
  enhancedConsultantReq?: InputMaybe<IComponentRequestEnhancedListReqInput>
  enhancedVendorReq?: InputMaybe<IComponentRequestEnhancedVendorReqInput>
  listingType?: InputMaybe<IEnum_Listingrequest_Listingtype>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<IEnum_Listingrequest_Status>
  submittedEmail?: InputMaybe<Scalars['String']['input']>
  submittedName?: InputMaybe<Scalars['String']['input']>
  vendorReq?: InputMaybe<IComponentRequestVendorReqInput>
}

export type ILocations = {
  __typename?: 'Locations'
  createdAt?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type IMutation = {
  __typename?: 'Mutation'
  /** change user password request */
  changeMyPassword?: Maybe<IChangePasswordPayload>
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<IUsersPermissionsLoginPayload>
  createAnnouncement?: Maybe<IAnnouncementEntityResponse>
  createArticleData?: Maybe<IArticleDataEntityResponse>
  createAttribute?: Maybe<IAttributeEntityResponse>
  createAudience?: Maybe<IAudienceEntityResponse>
  createAuthor?: Maybe<IAuthorEntityResponse>
  createBlogTaxonomy?: Maybe<IBlogTaxonomyEntityResponse>
  createCollection?: Maybe<ICollectionEntityResponse>
  createCompany?: Maybe<ICompanyEntityResponse>
  createContactHistory?: Maybe<IContactHistoryEntityResponse>
  createContent?: Maybe<IContentEntityResponse>
  createContentDownload?: Maybe<IContentDownloadEntityResponse>
  createContentVisit?: Maybe<IContentVisitEntityResponse>
  createDemographic?: Maybe<IDemographicEntityResponse>
  createDuration?: Maybe<IDurationEntityResponse>
  createEmailReceiver?: Maybe<IEmailReceiverEntityResponse>
  createEvent?: Maybe<IEventEntityResponse>
  createEventFeature?: Maybe<IEventFeatureEntityResponse>
  createEventFormat?: Maybe<IEventFormatEntityResponse>
  createExistingCustomer?: Maybe<IExistingCustomerEntityResponse>
  createExpert?: Maybe<IExpertEntityResponse>
  createFeature?: Maybe<IFeatureEntityResponse>
  createHq?: Maybe<IHqEntityResponse>
  createIltaSponsorship?: Maybe<IIltaSponsorshipEntityResponse>
  createInstallation?: Maybe<IInstallationEntityResponse>
  createIntegration?: Maybe<IIntegrationEntityResponse>
  createListingRequest?: Maybe<IListingRequestEntityResponse>
  /** create listing request */
  createListingRequestData?: Maybe<IListingRequest>
  createNewsletterCategory?: Maybe<INewsletterCategoryEntityResponse>
  createNewsletterSubscription?: Maybe<INewsletterSubscriptionEntityResponse>
  createOffice?: Maybe<IOfficeEntityResponse>
  createPhase?: Maybe<IPhaseEntityResponse>
  createPlatformLanguage?: Maybe<IPlatformLanguageEntityResponse>
  createPracticeArea?: Maybe<IPracticeAreaEntityResponse>
  createPremiumAudience?: Maybe<IPremiumAudienceEntityResponse>
  createPremiumOrganization?: Maybe<IPremiumOrganizationEntityResponse>
  createPremiumPricingPlan?: Maybe<IPremiumPricingPlanEntityResponse>
  createRecordLockingOpenEntity?: Maybe<IRecordLockingOpenEntityEntityResponse>
  createRecurrence?: Maybe<IRecurrenceEntityResponse>
  createRegionsServed?: Maybe<IRegionsServedEntityResponse>
  createResourceInvitation?: Maybe<IResourceInvitationEntityResponse>
  createReview?: Maybe<IReviewEntityResponse>
  createSecurity?: Maybe<ISecurityEntityResponse>
  createSlugUpdate?: Maybe<ISlugUpdateEntityResponse>
  createSolution?: Maybe<ISolutionEntityResponse>
  createSolutionRequest?: Maybe<ISolutionRequestEntityResponse>
  createSubTopic?: Maybe<ISubTopicEntityResponse>
  createSubjectMatter?: Maybe<ISubjectMatterEntityResponse>
  createTestimonials?: Maybe<ITestimonialsEntityResponse>
  createTheme?: Maybe<IThemeEntityResponse>
  createTopic?: Maybe<ITopicEntityResponse>
  createTypeOfUser?: Maybe<ITypeOfUserEntityResponse>
  createUploadFile?: Maybe<IUploadFileEntityResponse>
  createUploadFolder?: Maybe<IUploadFolderEntityResponse>
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<IUsersPermissionsCreateRolePayload>
  /** Create a new user */
  createUsersPermissionsUser: IUsersPermissionsUserEntityResponse
  createVendor?: Maybe<IVendorEntityResponse>
  createVendorTopic?: Maybe<IVendorTopicEntityResponse>
  deleteAbout?: Maybe<IAboutEntityResponse>
  deleteAnnouncement?: Maybe<IAnnouncementEntityResponse>
  deleteArticleData?: Maybe<IArticleDataEntityResponse>
  deleteAttribute?: Maybe<IAttributeEntityResponse>
  deleteAudience?: Maybe<IAudienceEntityResponse>
  deleteAuthor?: Maybe<IAuthorEntityResponse>
  deleteBlogTaxonomy?: Maybe<IBlogTaxonomyEntityResponse>
  deleteCollection?: Maybe<ICollectionEntityResponse>
  deleteCompany?: Maybe<ICompanyEntityResponse>
  deleteContactHistory?: Maybe<IContactHistoryEntityResponse>
  deleteContent?: Maybe<IContentEntityResponse>
  deleteContentDownload?: Maybe<IContentDownloadEntityResponse>
  deleteContentVisit?: Maybe<IContentVisitEntityResponse>
  deleteDemographic?: Maybe<IDemographicEntityResponse>
  deleteDuration?: Maybe<IDurationEntityResponse>
  deleteEmailReceiver?: Maybe<IEmailReceiverEntityResponse>
  deleteEnhancedRequestContent?: Maybe<IEnhancedRequestContentEntityResponse>
  deleteEvent?: Maybe<IEventEntityResponse>
  deleteEventFeature?: Maybe<IEventFeatureEntityResponse>
  deleteEventFormat?: Maybe<IEventFormatEntityResponse>
  deleteExistingCustomer?: Maybe<IExistingCustomerEntityResponse>
  deleteExpert?: Maybe<IExpertEntityResponse>
  deleteFeature?: Maybe<IFeatureEntityResponse>
  deleteHq?: Maybe<IHqEntityResponse>
  deleteIltaData?: Maybe<IIltaDataEntityResponse>
  deleteIltaSponsorship?: Maybe<IIltaSponsorshipEntityResponse>
  deleteInstallation?: Maybe<IInstallationEntityResponse>
  deleteIntegration?: Maybe<IIntegrationEntityResponse>
  deleteListingRequest?: Maybe<IListingRequestEntityResponse>
  deleteNewsletterCategory?: Maybe<INewsletterCategoryEntityResponse>
  deleteNewsletterSubscription?: Maybe<INewsletterSubscriptionEntityResponse>
  deleteOffice?: Maybe<IOfficeEntityResponse>
  deletePhase?: Maybe<IPhaseEntityResponse>
  deletePlatformLanguage?: Maybe<IPlatformLanguageEntityResponse>
  deletePracticeArea?: Maybe<IPracticeAreaEntityResponse>
  deletePremiumAudience?: Maybe<IPremiumAudienceEntityResponse>
  deletePremiumOrganization?: Maybe<IPremiumOrganizationEntityResponse>
  deletePremiumPricingPlan?: Maybe<IPremiumPricingPlanEntityResponse>
  deletePrivacyPolicy?: Maybe<IPrivacyPolicyEntityResponse>
  deleteRecordLockingOpenEntity?: Maybe<IRecordLockingOpenEntityEntityResponse>
  deleteRecurrence?: Maybe<IRecurrenceEntityResponse>
  deleteRegionsServed?: Maybe<IRegionsServedEntityResponse>
  deleteResourceInvitation?: Maybe<IResourceInvitationEntityResponse>
  deleteReview?: Maybe<IReviewEntityResponse>
  deleteSecurity?: Maybe<ISecurityEntityResponse>
  deleteSlugUpdate?: Maybe<ISlugUpdateEntityResponse>
  deleteSolution?: Maybe<ISolutionEntityResponse>
  deleteSolutionRequest?: Maybe<ISolutionRequestEntityResponse>
  deleteSubTopic?: Maybe<ISubTopicEntityResponse>
  deleteSubjectMatter?: Maybe<ISubjectMatterEntityResponse>
  deleteTermsConditions?: Maybe<ITermsConditionsEntityResponse>
  deleteTestimonials?: Maybe<ITestimonialsEntityResponse>
  deleteTheme?: Maybe<IThemeEntityResponse>
  deleteTopic?: Maybe<ITopicEntityResponse>
  deleteTypeOfUser?: Maybe<ITypeOfUserEntityResponse>
  deleteUploadFile?: Maybe<IUploadFileEntityResponse>
  deleteUploadFolder?: Maybe<IUploadFolderEntityResponse>
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<IUsersPermissionsDeleteRolePayload>
  /** Delete an existing user */
  deleteUsersPermissionsUser: IUsersPermissionsUserEntityResponse
  deleteVendor?: Maybe<IVendorEntityResponse>
  deleteVendorTopic?: Maybe<IVendorTopicEntityResponse>
  /** Download A Content */
  downloadContent?: Maybe<Scalars['Boolean']['output']>
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<IUsersPermissionsLoginPayload>
  /** follow a vendor/alsp/consultant */
  followSolution?: Maybe<Scalars['Boolean']['output']>
  /** Request a reset password token */
  forgotPassword?: Maybe<IUsersPermissionsPasswordPayload>
  login: IUsersPermissionsLoginPayload
  multipleUpload: Array<Maybe<IUploadFileEntityResponse>>
  /** Register a user */
  register: IUsersPermissionsLoginPayload
  /** Registers a new premium organization */
  registerPremiumOrganization?: Maybe<IPremiumOrganization>
  /** Registers a new vendor organization */
  registerVendorOrganization?: Maybe<IVendor>
  removeFile?: Maybe<IUploadFileEntityResponse>
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<IUsersPermissionsLoginPayload>
  /** Resource Invitation */
  shareContents?: Maybe<Scalars['Boolean']['output']>
  /** Submit a request to upgrade from the basic to the enhanced version */
  submitEnhancedRequest?: Maybe<Scalars['Boolean']['output']>
  /** Submit newsletter subscripion */
  submitNewsletterSubscription?: Maybe<Scalars['Boolean']['output']>
  /** submit a vendor/alsp/consultant review */
  submitReview?: Maybe<Scalars['Boolean']['output']>
  updateAbout?: Maybe<IAboutEntityResponse>
  updateAnnouncement?: Maybe<IAnnouncementEntityResponse>
  updateArticleData?: Maybe<IArticleDataEntityResponse>
  updateAttribute?: Maybe<IAttributeEntityResponse>
  updateAudience?: Maybe<IAudienceEntityResponse>
  updateAuthor?: Maybe<IAuthorEntityResponse>
  updateBlogTaxonomy?: Maybe<IBlogTaxonomyEntityResponse>
  updateCollection?: Maybe<ICollectionEntityResponse>
  updateCompany?: Maybe<ICompanyEntityResponse>
  updateContactHistory?: Maybe<IContactHistoryEntityResponse>
  updateContent?: Maybe<IContentEntityResponse>
  updateContentDownload?: Maybe<IContentDownloadEntityResponse>
  updateContentVisit?: Maybe<IContentVisitEntityResponse>
  updateDemographic?: Maybe<IDemographicEntityResponse>
  updateDuration?: Maybe<IDurationEntityResponse>
  updateEmailReceiver?: Maybe<IEmailReceiverEntityResponse>
  updateEnhancedRequestContent?: Maybe<IEnhancedRequestContentEntityResponse>
  updateEvent?: Maybe<IEventEntityResponse>
  updateEventFeature?: Maybe<IEventFeatureEntityResponse>
  updateEventFormat?: Maybe<IEventFormatEntityResponse>
  updateExistingCustomer?: Maybe<IExistingCustomerEntityResponse>
  updateExpert?: Maybe<IExpertEntityResponse>
  updateFeature?: Maybe<IFeatureEntityResponse>
  updateFileInfo: IUploadFileEntityResponse
  updateHq?: Maybe<IHqEntityResponse>
  updateIltaData?: Maybe<IIltaDataEntityResponse>
  updateIltaSponsorship?: Maybe<IIltaSponsorshipEntityResponse>
  updateInstallation?: Maybe<IInstallationEntityResponse>
  updateIntegration?: Maybe<IIntegrationEntityResponse>
  updateListingRequest?: Maybe<IListingRequestEntityResponse>
  updateNewsletterCategory?: Maybe<INewsletterCategoryEntityResponse>
  updateNewsletterSubscription?: Maybe<INewsletterSubscriptionEntityResponse>
  updateOffice?: Maybe<IOfficeEntityResponse>
  updatePhase?: Maybe<IPhaseEntityResponse>
  updatePlatformLanguage?: Maybe<IPlatformLanguageEntityResponse>
  updatePracticeArea?: Maybe<IPracticeAreaEntityResponse>
  updatePremiumAudience?: Maybe<IPremiumAudienceEntityResponse>
  updatePremiumOrganization?: Maybe<IPremiumOrganizationEntityResponse>
  updatePremiumPricingPlan?: Maybe<IPremiumPricingPlanEntityResponse>
  updatePrivacyPolicy?: Maybe<IPrivacyPolicyEntityResponse>
  updateRecordLockingOpenEntity?: Maybe<IRecordLockingOpenEntityEntityResponse>
  updateRecurrence?: Maybe<IRecurrenceEntityResponse>
  updateRegionsServed?: Maybe<IRegionsServedEntityResponse>
  updateResourceInvitation?: Maybe<IResourceInvitationEntityResponse>
  updateReview?: Maybe<IReviewEntityResponse>
  updateSecurity?: Maybe<ISecurityEntityResponse>
  updateSlugUpdate?: Maybe<ISlugUpdateEntityResponse>
  updateSolution?: Maybe<ISolutionEntityResponse>
  updateSolutionRequest?: Maybe<ISolutionRequestEntityResponse>
  updateSubTopic?: Maybe<ISubTopicEntityResponse>
  updateSubjectMatter?: Maybe<ISubjectMatterEntityResponse>
  updateTermsConditions?: Maybe<ITermsConditionsEntityResponse>
  updateTestimonials?: Maybe<ITestimonialsEntityResponse>
  updateTheme?: Maybe<IThemeEntityResponse>
  updateTopic?: Maybe<ITopicEntityResponse>
  updateTypeOfUser?: Maybe<ITypeOfUserEntityResponse>
  updateUploadFile?: Maybe<IUploadFileEntityResponse>
  updateUploadFolder?: Maybe<IUploadFolderEntityResponse>
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<IUsersPermissionsUpdateRolePayload>
  /** Update an existing user */
  updateUsersPermissionsUser: IUsersPermissionsUserEntityResponse
  updateVendor?: Maybe<IVendorEntityResponse>
  updateVendorTopic?: Maybe<IVendorTopicEntityResponse>
  upload: IUploadFileEntityResponse
  /** Visit A Content */
  visitContent?: Maybe<Scalars['Boolean']['output']>
}

export type IMutationChangeMyPasswordArgs = {
  input?: InputMaybe<IChangePasswordRequest>
}

export type IMutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input']
  password: Scalars['String']['input']
  passwordConfirmation: Scalars['String']['input']
}

export type IMutationCreateAnnouncementArgs = {
  data: IAnnouncementInput
}

export type IMutationCreateArticleDataArgs = {
  data: IArticleDataInput
}

export type IMutationCreateAttributeArgs = {
  data: IAttributeInput
}

export type IMutationCreateAudienceArgs = {
  data: IAudienceInput
}

export type IMutationCreateAuthorArgs = {
  data: IAuthorInput
}

export type IMutationCreateBlogTaxonomyArgs = {
  data: IBlogTaxonomyInput
}

export type IMutationCreateCollectionArgs = {
  data: ICollectionInput
}

export type IMutationCreateCompanyArgs = {
  data: ICompanyInput
}

export type IMutationCreateContactHistoryArgs = {
  data: IContactHistoryInput
}

export type IMutationCreateContentArgs = {
  data: IContentInput
}

export type IMutationCreateContentDownloadArgs = {
  data: IContentDownloadInput
}

export type IMutationCreateContentVisitArgs = {
  data: IContentVisitInput
}

export type IMutationCreateDemographicArgs = {
  data: IDemographicInput
}

export type IMutationCreateDurationArgs = {
  data: IDurationInput
}

export type IMutationCreateEmailReceiverArgs = {
  data: IEmailReceiverInput
}

export type IMutationCreateEventArgs = {
  data: IEventInput
}

export type IMutationCreateEventFeatureArgs = {
  data: IEventFeatureInput
}

export type IMutationCreateEventFormatArgs = {
  data: IEventFormatInput
}

export type IMutationCreateExistingCustomerArgs = {
  data: IExistingCustomerInput
}

export type IMutationCreateExpertArgs = {
  data: IExpertInput
}

export type IMutationCreateFeatureArgs = {
  data: IFeatureInput
}

export type IMutationCreateHqArgs = {
  data: IHqInput
}

export type IMutationCreateIltaSponsorshipArgs = {
  data: IIltaSponsorshipInput
}

export type IMutationCreateInstallationArgs = {
  data: IInstallationInput
}

export type IMutationCreateIntegrationArgs = {
  data: IIntegrationInput
}

export type IMutationCreateListingRequestArgs = {
  data: IListingRequestInput
}

export type IMutationCreateListingRequestDataArgs = {
  data?: InputMaybe<IListingRequestData>
}

export type IMutationCreateNewsletterCategoryArgs = {
  data: INewsletterCategoryInput
}

export type IMutationCreateNewsletterSubscriptionArgs = {
  data: INewsletterSubscriptionInput
}

export type IMutationCreateOfficeArgs = {
  data: IOfficeInput
}

export type IMutationCreatePhaseArgs = {
  data: IPhaseInput
}

export type IMutationCreatePlatformLanguageArgs = {
  data: IPlatformLanguageInput
}

export type IMutationCreatePracticeAreaArgs = {
  data: IPracticeAreaInput
}

export type IMutationCreatePremiumAudienceArgs = {
  data: IPremiumAudienceInput
}

export type IMutationCreatePremiumOrganizationArgs = {
  data: IPremiumOrganizationInput
}

export type IMutationCreatePremiumPricingPlanArgs = {
  data: IPremiumPricingPlanInput
}

export type IMutationCreateRecordLockingOpenEntityArgs = {
  data: IRecordLockingOpenEntityInput
}

export type IMutationCreateRecurrenceArgs = {
  data: IRecurrenceInput
}

export type IMutationCreateRegionsServedArgs = {
  data: IRegionsServedInput
}

export type IMutationCreateResourceInvitationArgs = {
  data: IResourceInvitationInput
}

export type IMutationCreateReviewArgs = {
  data: IReviewInput
}

export type IMutationCreateSecurityArgs = {
  data: ISecurityInput
}

export type IMutationCreateSlugUpdateArgs = {
  data: ISlugUpdateInput
}

export type IMutationCreateSolutionArgs = {
  data: ISolutionInput
}

export type IMutationCreateSolutionRequestArgs = {
  data: ISolutionRequestInput
}

export type IMutationCreateSubTopicArgs = {
  data: ISubTopicInput
}

export type IMutationCreateSubjectMatterArgs = {
  data: ISubjectMatterInput
}

export type IMutationCreateTestimonialsArgs = {
  data: ITestimonialsInput
}

export type IMutationCreateThemeArgs = {
  data: IThemeInput
}

export type IMutationCreateTopicArgs = {
  data: ITopicInput
}

export type IMutationCreateTypeOfUserArgs = {
  data: ITypeOfUserInput
}

export type IMutationCreateUploadFileArgs = {
  data: IUploadFileInput
}

export type IMutationCreateUploadFolderArgs = {
  data: IUploadFolderInput
}

export type IMutationCreateUsersPermissionsRoleArgs = {
  data: IUsersPermissionsRoleInput
}

export type IMutationCreateUsersPermissionsUserArgs = {
  data: IUsersPermissionsUserInput
}

export type IMutationCreateVendorArgs = {
  data: IVendorInput
}

export type IMutationCreateVendorTopicArgs = {
  data: IVendorTopicInput
}

export type IMutationDeleteAnnouncementArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteArticleDataArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteAttributeArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteAudienceArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteAuthorArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteBlogTaxonomyArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteCollectionArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteCompanyArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteContactHistoryArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteContentArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteContentDownloadArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteContentVisitArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteDemographicArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteDurationArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteEmailReceiverArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteEventArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteEventFeatureArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteEventFormatArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteExistingCustomerArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteExpertArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteFeatureArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteHqArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteIltaSponsorshipArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteInstallationArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteIntegrationArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteListingRequestArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteNewsletterCategoryArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteNewsletterSubscriptionArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteOfficeArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeletePhaseArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeletePlatformLanguageArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeletePracticeAreaArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeletePremiumAudienceArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeletePremiumOrganizationArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeletePremiumPricingPlanArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteRecordLockingOpenEntityArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteRecurrenceArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteRegionsServedArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteResourceInvitationArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteReviewArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteSecurityArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteSlugUpdateArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteSolutionArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteSolutionRequestArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteSubTopicArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteSubjectMatterArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteTestimonialsArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteThemeArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteTopicArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteTypeOfUserArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteVendorArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDeleteVendorTopicArgs = {
  id: Scalars['ID']['input']
}

export type IMutationDownloadContentArgs = {
  data: IContentId
}

export type IMutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input']
}

export type IMutationFollowSolutionArgs = {
  follow?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type IMutationForgotPasswordArgs = {
  email: Scalars['String']['input']
}

export type IMutationLoginArgs = {
  input: IUsersPermissionsLoginInput
}

export type IMutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>
  files: Array<InputMaybe<Scalars['Upload']['input']>>
  ref?: InputMaybe<Scalars['String']['input']>
  refId?: InputMaybe<Scalars['ID']['input']>
}

export type IMutationRegisterArgs = {
  input: IUsersPermissionsRegisterInput
}

export type IMutationRegisterPremiumOrganizationArgs = {
  data: IRegisterPremiumOrganizationInput
}

export type IMutationRegisterVendorOrganizationArgs = {
  data: IRegisterVendorOrganizationInput
}

export type IMutationRemoveFileArgs = {
  id: Scalars['ID']['input']
}

export type IMutationResetPasswordArgs = {
  code: Scalars['String']['input']
  password: Scalars['String']['input']
  passwordConfirmation: Scalars['String']['input']
}

export type IMutationShareContentsArgs = {
  data: IResourceInvitationInput
}

export type IMutationSubmitEnhancedRequestArgs = {
  data: ISolutionId
}

export type IMutationSubmitNewsletterSubscriptionArgs = {
  data: INewsletterSubscriptionInput
}

export type IMutationSubmitReviewArgs = {
  data: IReviewInput
}

export type IMutationUpdateAboutArgs = {
  data: IAboutInput
}

export type IMutationUpdateAnnouncementArgs = {
  data: IAnnouncementInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateArticleDataArgs = {
  data: IArticleDataInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateAttributeArgs = {
  data: IAttributeInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateAudienceArgs = {
  data: IAudienceInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateAuthorArgs = {
  data: IAuthorInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateBlogTaxonomyArgs = {
  data: IBlogTaxonomyInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateCollectionArgs = {
  data: ICollectionInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateCompanyArgs = {
  data: ICompanyInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateContactHistoryArgs = {
  data: IContactHistoryInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateContentArgs = {
  data: IContentInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateContentDownloadArgs = {
  data: IContentDownloadInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateContentVisitArgs = {
  data: IContentVisitInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateDemographicArgs = {
  data: IDemographicInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateDurationArgs = {
  data: IDurationInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateEmailReceiverArgs = {
  data: IEmailReceiverInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateEnhancedRequestContentArgs = {
  data: IEnhancedRequestContentInput
}

export type IMutationUpdateEventArgs = {
  data: IEventInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateEventFeatureArgs = {
  data: IEventFeatureInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateEventFormatArgs = {
  data: IEventFormatInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateExistingCustomerArgs = {
  data: IExistingCustomerInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateExpertArgs = {
  data: IExpertInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateFeatureArgs = {
  data: IFeatureInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input']
  info?: InputMaybe<IFileInfoInput>
}

export type IMutationUpdateHqArgs = {
  data: IHqInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateIltaDataArgs = {
  data: IIltaDataInput
}

export type IMutationUpdateIltaSponsorshipArgs = {
  data: IIltaSponsorshipInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateInstallationArgs = {
  data: IInstallationInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateIntegrationArgs = {
  data: IIntegrationInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateListingRequestArgs = {
  data: IListingRequestInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateNewsletterCategoryArgs = {
  data: INewsletterCategoryInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateNewsletterSubscriptionArgs = {
  data: INewsletterSubscriptionInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateOfficeArgs = {
  data: IOfficeInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePhaseArgs = {
  data: IPhaseInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePlatformLanguageArgs = {
  data: IPlatformLanguageInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePracticeAreaArgs = {
  data: IPracticeAreaInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePremiumAudienceArgs = {
  data: IPremiumAudienceInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePremiumOrganizationArgs = {
  data: IPremiumOrganizationInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePremiumPricingPlanArgs = {
  data: IPremiumPricingPlanInput
  id: Scalars['ID']['input']
}

export type IMutationUpdatePrivacyPolicyArgs = {
  data: IPrivacyPolicyInput
}

export type IMutationUpdateRecordLockingOpenEntityArgs = {
  data: IRecordLockingOpenEntityInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateRecurrenceArgs = {
  data: IRecurrenceInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateRegionsServedArgs = {
  data: IRegionsServedInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateResourceInvitationArgs = {
  data: IResourceInvitationInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateReviewArgs = {
  data: IReviewInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateSecurityArgs = {
  data: ISecurityInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateSlugUpdateArgs = {
  data: ISlugUpdateInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateSolutionArgs = {
  data: ISolutionInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateSolutionRequestArgs = {
  data: ISolutionRequestInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateSubTopicArgs = {
  data: ISubTopicInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateSubjectMatterArgs = {
  data: ISubjectMatterInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateTermsConditionsArgs = {
  data: ITermsConditionsInput
}

export type IMutationUpdateTestimonialsArgs = {
  data: ITestimonialsInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateThemeArgs = {
  data: IThemeInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateTopicArgs = {
  data: ITopicInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateTypeOfUserArgs = {
  data: ITypeOfUserInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateUploadFileArgs = {
  data: IUploadFileInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateUploadFolderArgs = {
  data: IUploadFolderInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateUsersPermissionsRoleArgs = {
  data: IUsersPermissionsRoleInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateUsersPermissionsUserArgs = {
  data: IUsersPermissionsUserInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateVendorArgs = {
  data: IVendorInput
  id: Scalars['ID']['input']
}

export type IMutationUpdateVendorTopicArgs = {
  data: IVendorTopicInput
  id: Scalars['ID']['input']
}

export type IMutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>
  file: Scalars['Upload']['input']
  info?: InputMaybe<IFileInfoInput>
  ref?: InputMaybe<Scalars['String']['input']>
  refId?: InputMaybe<Scalars['ID']['input']>
}

export type IMutationVisitContentArgs = {
  data: IContentId
}

export type IMySharedContentsFilters = {
  contentType?: InputMaybe<Scalars['String']['input']>
  subTopicIds?: InputMaybe<Scalars['String']['input']>
}

export type IMySharedContentsReturnType = {
  __typename?: 'MySharedContentsReturnType'
  contents: Array<IContentEntity>
  total: Scalars['Int']['output']
}

export type INewsletterCategory = {
  __typename?: 'NewsletterCategory'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type INewsletterCategoryEntity = {
  __typename?: 'NewsletterCategoryEntity'
  attributes?: Maybe<INewsletterCategory>
  id?: Maybe<Scalars['ID']['output']>
}

export type INewsletterCategoryEntityResponse = {
  __typename?: 'NewsletterCategoryEntityResponse'
  data?: Maybe<INewsletterCategoryEntity>
}

export type INewsletterCategoryEntityResponseCollection = {
  __typename?: 'NewsletterCategoryEntityResponseCollection'
  data: Array<INewsletterCategoryEntity>
  meta: IResponseCollectionMeta
}

export type INewsletterCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<INewsletterCategoryFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<INewsletterCategoryFiltersInput>
  or?: InputMaybe<Array<InputMaybe<INewsletterCategoryFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type INewsletterCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type INewsletterCategoryRelationResponseCollection = {
  __typename?: 'NewsletterCategoryRelationResponseCollection'
  data: Array<INewsletterCategoryEntity>
}

export type INewsletterSubscription = {
  __typename?: 'NewsletterSubscription'
  categories?: Maybe<INewsletterCategoryRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user?: Maybe<IUsersPermissionsUserEntityResponse>
}

export type INewsletterSubscriptionCategoriesArgs = {
  filters?: InputMaybe<INewsletterCategoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type INewsletterSubscriptionEntity = {
  __typename?: 'NewsletterSubscriptionEntity'
  attributes?: Maybe<INewsletterSubscription>
  id?: Maybe<Scalars['ID']['output']>
}

export type INewsletterSubscriptionEntityResponse = {
  __typename?: 'NewsletterSubscriptionEntityResponse'
  data?: Maybe<INewsletterSubscriptionEntity>
}

export type INewsletterSubscriptionEntityResponseCollection = {
  __typename?: 'NewsletterSubscriptionEntityResponseCollection'
  data: Array<INewsletterSubscriptionEntity>
  meta: IResponseCollectionMeta
}

export type INewsletterSubscriptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<INewsletterSubscriptionFiltersInput>>>
  categories?: InputMaybe<INewsletterCategoryFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  email?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<INewsletterSubscriptionFiltersInput>
  or?: InputMaybe<Array<InputMaybe<INewsletterSubscriptionFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  user?: InputMaybe<IUsersPermissionsUserFiltersInput>
}

export type INewsletterSubscriptionInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  companyName: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  user?: InputMaybe<Scalars['ID']['input']>
}

export type IOffice = {
  __typename?: 'Office'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IOfficeEntity = {
  __typename?: 'OfficeEntity'
  attributes?: Maybe<IOffice>
  id?: Maybe<Scalars['ID']['output']>
}

export type IOfficeEntityResponse = {
  __typename?: 'OfficeEntityResponse'
  data?: Maybe<IOfficeEntity>
}

export type IOfficeEntityResponseCollection = {
  __typename?: 'OfficeEntityResponseCollection'
  data: Array<IOfficeEntity>
  meta: IResponseCollectionMeta
}

export type IOfficeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IOfficeFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IOfficeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IOfficeFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IOfficeInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IOfficeRelationResponseCollection = {
  __typename?: 'OfficeRelationResponseCollection'
  data: Array<IOfficeEntity>
}

export type IPagination = {
  __typename?: 'Pagination'
  page: Scalars['Int']['output']
  pageCount: Scalars['Int']['output']
  pageSize: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type IPaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
}

export type IPhase = {
  __typename?: 'Phase'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  priority: Scalars['Int']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  title: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPhaseContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPhaseEntity = {
  __typename?: 'PhaseEntity'
  attributes?: Maybe<IPhase>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPhaseEntityResponse = {
  __typename?: 'PhaseEntityResponse'
  data?: Maybe<IPhaseEntity>
}

export type IPhaseEntityResponseCollection = {
  __typename?: 'PhaseEntityResponseCollection'
  data: Array<IPhaseEntity>
  meta: IResponseCollectionMeta
}

export type IPhaseFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IPhaseFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IPhaseFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IPhaseFiltersInput>>>
  priority?: InputMaybe<IIntFilterInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  title?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IPhaseInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  priority?: InputMaybe<Scalars['Int']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IPlatformLanguage = {
  __typename?: 'PlatformLanguage'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPlatformLanguageEntity = {
  __typename?: 'PlatformLanguageEntity'
  attributes?: Maybe<IPlatformLanguage>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPlatformLanguageEntityResponse = {
  __typename?: 'PlatformLanguageEntityResponse'
  data?: Maybe<IPlatformLanguageEntity>
}

export type IPlatformLanguageEntityResponseCollection = {
  __typename?: 'PlatformLanguageEntityResponseCollection'
  data: Array<IPlatformLanguageEntity>
  meta: IResponseCollectionMeta
}

export type IPlatformLanguageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IPlatformLanguageFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IPlatformLanguageFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IPlatformLanguageFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IPlatformLanguageInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IPlatformLanguageRelationResponseCollection = {
  __typename?: 'PlatformLanguageRelationResponseCollection'
  data: Array<IPlatformLanguageEntity>
}

export type IPopularTopic = {
  __typename?: 'PopularTopic'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  solutions: Array<IPopularTopicListing>
}

export type IPopularTopicListing = {
  __typename?: 'PopularTopicListing'
  id: Scalars['Int']['output']
  logo?: Maybe<IPopularTopicListingLogo>
  slug: Scalars['String']['output']
  type?: Maybe<IEnum_Solution_Type>
}

export type IPopularTopicListingLogo = {
  __typename?: 'PopularTopicListingLogo'
  url?: Maybe<Scalars['String']['output']>
}

export type IPracticeArea = {
  __typename?: 'PracticeArea'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPracticeAreaEntity = {
  __typename?: 'PracticeAreaEntity'
  attributes?: Maybe<IPracticeArea>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPracticeAreaEntityResponse = {
  __typename?: 'PracticeAreaEntityResponse'
  data?: Maybe<IPracticeAreaEntity>
}

export type IPracticeAreaEntityResponseCollection = {
  __typename?: 'PracticeAreaEntityResponseCollection'
  data: Array<IPracticeAreaEntity>
  meta: IResponseCollectionMeta
}

export type IPracticeAreaFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IPracticeAreaFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IPracticeAreaFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IPracticeAreaFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IPracticeAreaInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IPracticeAreaRelationResponseCollection = {
  __typename?: 'PracticeAreaRelationResponseCollection'
  data: Array<IPracticeAreaEntity>
}

export type IPremiumAudience = {
  __typename?: 'PremiumAudience'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  shouldShowOnHomepage?: Maybe<Scalars['Boolean']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPremiumAudienceContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPremiumAudienceEntity = {
  __typename?: 'PremiumAudienceEntity'
  attributes?: Maybe<IPremiumAudience>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPremiumAudienceEntityResponse = {
  __typename?: 'PremiumAudienceEntityResponse'
  data?: Maybe<IPremiumAudienceEntity>
}

export type IPremiumAudienceEntityResponseCollection = {
  __typename?: 'PremiumAudienceEntityResponseCollection'
  data: Array<IPremiumAudienceEntity>
  meta: IResponseCollectionMeta
}

export type IPremiumAudienceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IPremiumAudienceFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IPremiumAudienceFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IPremiumAudienceFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  shouldShowOnHomepage?: InputMaybe<IBooleanFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IPremiumAudienceInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  shouldShowOnHomepage?: InputMaybe<Scalars['Boolean']['input']>
}

export type IPremiumAudienceRelationResponseCollection = {
  __typename?: 'PremiumAudienceRelationResponseCollection'
  data: Array<IPremiumAudienceEntity>
}

export type IPremiumOrganization = {
  __typename?: 'PremiumOrganization'
  billingAddress: IComponentBillingAddressBillingAddress
  bulkUploadInvitation?: Maybe<IUploadFileEntityResponse>
  cognitoProviderId?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  emailDomain: Scalars['String']['output']
  invitations?: Maybe<Array<Maybe<IComponentInvitationsInvitations>>>
  isVendor?: Maybe<Scalars['Boolean']['output']>
  leadSource?: Maybe<Scalars['String']['output']>
  members?: Maybe<IUsersPermissionsUserRelationResponseCollection>
  name: Scalars['String']['output']
  newsletterCategories?: Maybe<INewsletterCategoryRelationResponseCollection>
  pricingPlan?: Maybe<IPremiumPricingPlanEntityResponse>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  registrationContact: Array<Maybe<IComponentRegistrationContactRegistrationContact>>
  shouldSendResponse?: Maybe<Scalars['Boolean']['output']>
  solutions?: Maybe<ISolutionRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPremiumOrganizationInvitationsArgs = {
  filters?: InputMaybe<IComponentInvitationsInvitationsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPremiumOrganizationMembersArgs = {
  filters?: InputMaybe<IUsersPermissionsUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPremiumOrganizationNewsletterCategoriesArgs = {
  filters?: InputMaybe<INewsletterCategoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPremiumOrganizationRegistrationContactArgs = {
  filters?: InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPremiumOrganizationSolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IPremiumOrganizationEntity = {
  __typename?: 'PremiumOrganizationEntity'
  attributes?: Maybe<IPremiumOrganization>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPremiumOrganizationEntityResponse = {
  __typename?: 'PremiumOrganizationEntityResponse'
  data?: Maybe<IPremiumOrganizationEntity>
}

export type IPremiumOrganizationEntityResponseCollection = {
  __typename?: 'PremiumOrganizationEntityResponseCollection'
  data: Array<IPremiumOrganizationEntity>
  meta: IResponseCollectionMeta
}

export type IPremiumOrganizationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IPremiumOrganizationFiltersInput>>>
  billingAddress?: InputMaybe<IComponentBillingAddressBillingAddressFiltersInput>
  cognitoProviderId?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  emailDomain?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  invitations?: InputMaybe<IComponentInvitationsInvitationsFiltersInput>
  isVendor?: InputMaybe<IBooleanFilterInput>
  leadSource?: InputMaybe<IStringFilterInput>
  members?: InputMaybe<IUsersPermissionsUserFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  newsletterCategories?: InputMaybe<INewsletterCategoryFiltersInput>
  not?: InputMaybe<IPremiumOrganizationFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IPremiumOrganizationFiltersInput>>>
  pricingPlan?: InputMaybe<IPremiumPricingPlanFiltersInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  registrationContact?: InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>
  shouldSendResponse?: InputMaybe<IBooleanFilterInput>
  solutions?: InputMaybe<ISolutionFiltersInput>
  status?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IPremiumOrganizationInput = {
  billingAddress?: InputMaybe<IComponentBillingAddressBillingAddressInput>
  bulkUploadInvitation?: InputMaybe<Scalars['ID']['input']>
  cognitoProviderId?: InputMaybe<Scalars['String']['input']>
  emailDomain?: InputMaybe<Scalars['String']['input']>
  invitations?: InputMaybe<Array<InputMaybe<IComponentInvitationsInvitationsInput>>>
  isVendor?: InputMaybe<Scalars['Boolean']['input']>
  leadSource?: InputMaybe<Scalars['String']['input']>
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  newsletterCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  pricingPlan?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  registrationContact?: InputMaybe<Array<InputMaybe<IComponentRegistrationContactRegistrationContactInput>>>
  shouldSendResponse?: InputMaybe<Scalars['Boolean']['input']>
  solutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  status?: InputMaybe<IEnum_Premiumorganization_Status>
}

export type IPremiumPricingPlan = {
  __typename?: 'PremiumPricingPlan'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  isActive?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  pricePerYear: Scalars['Int']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  stripeRedirectUrl: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPremiumPricingPlanEntity = {
  __typename?: 'PremiumPricingPlanEntity'
  attributes?: Maybe<IPremiumPricingPlan>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPremiumPricingPlanEntityResponse = {
  __typename?: 'PremiumPricingPlanEntityResponse'
  data?: Maybe<IPremiumPricingPlanEntity>
}

export type IPremiumPricingPlanEntityResponseCollection = {
  __typename?: 'PremiumPricingPlanEntityResponseCollection'
  data: Array<IPremiumPricingPlanEntity>
  meta: IResponseCollectionMeta
}

export type IPremiumPricingPlanFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IPremiumPricingPlanFiltersInput>>>
  companySize?: InputMaybe<IStringFilterInput>
  companyType?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  isActive?: InputMaybe<IBooleanFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IPremiumPricingPlanFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IPremiumPricingPlanFiltersInput>>>
  premiumOrganizations?: InputMaybe<IPremiumOrganizationFiltersInput>
  pricePerYear?: InputMaybe<IIntFilterInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  stripeRedirectUrl?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IPremiumPricingPlanInput = {
  companySize?: InputMaybe<IEnum_Premiumpricingplan_Companysize>
  companyType?: InputMaybe<IEnum_Premiumpricingplan_Companytype>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  premiumOrganizations?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  pricePerYear?: InputMaybe<Scalars['Int']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  stripeRedirectUrl?: InputMaybe<Scalars['String']['input']>
}

export type IPrivacyPolicy = {
  __typename?: 'PrivacyPolicy'
  content?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  date?: Maybe<Scalars['Date']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  summary?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IPrivacyPolicyEntity = {
  __typename?: 'PrivacyPolicyEntity'
  attributes?: Maybe<IPrivacyPolicy>
  id?: Maybe<Scalars['ID']['output']>
}

export type IPrivacyPolicyEntityResponse = {
  __typename?: 'PrivacyPolicyEntityResponse'
  data?: Maybe<IPrivacyPolicyEntity>
}

export type IPrivacyPolicyInput = {
  content?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['Date']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
}

export enum IPublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type IQuery = {
  __typename?: 'Query'
  about?: Maybe<IAboutEntityResponse>
  announcement?: Maybe<IAnnouncementEntityResponse>
  announcements?: Maybe<IAnnouncementEntityResponseCollection>
  articleData?: Maybe<IArticleDataEntityResponse>
  articleDatas?: Maybe<IArticleDataEntityResponseCollection>
  attribute?: Maybe<IAttributeEntityResponse>
  attributes?: Maybe<IAttributeEntityResponseCollection>
  audience?: Maybe<IAudienceEntityResponse>
  audiences?: Maybe<IAudienceEntityResponseCollection>
  author?: Maybe<IAuthorEntityResponse>
  authors?: Maybe<IAuthorEntityResponseCollection>
  autosuggest: IAutosuggestData
  blogTaxonomy?: Maybe<IBlogTaxonomyEntityResponse>
  blogTaxonomys?: Maybe<IBlogTaxonomyEntityResponseCollection>
  collection?: Maybe<ICollectionEntityResponse>
  collections?: Maybe<ICollectionEntityResponseCollection>
  companies?: Maybe<ICompanyEntityResponseCollection>
  company?: Maybe<ICompanyEntityResponse>
  contactHistories?: Maybe<IContactHistoryEntityResponseCollection>
  contactHistory?: Maybe<IContactHistoryEntityResponse>
  content?: Maybe<IContentEntityResponse>
  /** Return article contents */
  contentBySlug: IContentEntityBySlug
  contentDownload?: Maybe<IContentDownloadEntityResponse>
  contentDownloads?: Maybe<IContentDownloadEntityResponseCollection>
  /** Return filtered content */
  contentResults: IContentSearchReturnType
  contentVisit?: Maybe<IContentVisitEntityResponse>
  /** Aggregation for the Content Visit */
  contentVisitDownloads: Array<ISharedResourceEntity>
  contentVisits?: Maybe<IContentVisitEntityResponseCollection>
  contents?: Maybe<IContentEntityResponseCollection>
  demographic?: Maybe<IDemographicEntityResponse>
  demographics?: Maybe<IDemographicEntityResponseCollection>
  /** Return download material file */
  downloadMaterialFile: IDownloadMaterialFileEntity
  duration?: Maybe<IDurationEntityResponse>
  durations?: Maybe<IDurationEntityResponseCollection>
  emailReceiver?: Maybe<IEmailReceiverEntityResponse>
  emailReceivers?: Maybe<IEmailReceiverEntityResponseCollection>
  enhancedRequestContent?: Maybe<IEnhancedRequestContentEntityResponse>
  event?: Maybe<IEventEntityResponse>
  eventFeature?: Maybe<IEventFeatureEntityResponse>
  eventFeatures?: Maybe<IEventFeatureEntityResponseCollection>
  eventFormat?: Maybe<IEventFormatEntityResponse>
  eventFormats?: Maybe<IEventFormatEntityResponseCollection>
  /** Return filtered events */
  eventResults: IEventSearchReturnType
  events?: Maybe<IEventEntityResponseCollection>
  existingCustomer?: Maybe<IExistingCustomerEntityResponse>
  existingCustomers?: Maybe<IExistingCustomerEntityResponseCollection>
  expert?: Maybe<IExpertEntityResponse>
  experts?: Maybe<IExpertEntityResponseCollection>
  feature?: Maybe<IFeatureEntityResponse>
  /** Return article contents */
  featuredContents: Array<IContentEntity>
  features?: Maybe<IFeatureEntityResponseCollection>
  /** Return filtered solution */
  findAnnouncementBySolutionId: ISearchAnnouncementsResult
  /** folloing topics */
  followingTopics?: Maybe<Array<Maybe<IFollowingTopicEntity>>>
  hq?: Maybe<IHqEntityResponse>
  hqs?: Maybe<IHqEntityResponseCollection>
  i18NLocale?: Maybe<II18NLocaleEntityResponse>
  i18NLocales?: Maybe<II18NLocaleEntityResponseCollection>
  iltaData?: Maybe<IIltaDataEntityResponse>
  iltaSponsorship?: Maybe<IIltaSponsorshipEntityResponse>
  iltaSponsorships?: Maybe<IIltaSponsorshipEntityResponseCollection>
  installation?: Maybe<IInstallationEntityResponse>
  installations?: Maybe<IInstallationEntityResponseCollection>
  integration?: Maybe<IIntegrationEntityResponse>
  integrations?: Maybe<IIntegrationEntityResponseCollection>
  /** invited users list */
  invitedUsers: IInvitedUsersResult
  /** is following a vendor/alsp/consultant */
  isFollow?: Maybe<Scalars['Boolean']['output']>
  listingRequest?: Maybe<IListingRequestEntityResponse>
  listingRequests?: Maybe<IListingRequestEntityResponseCollection>
  /** Return locations for event filtering */
  location?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  me?: Maybe<IUsersPermissionsMe>
  /** query for non-premium users to return available topics/subTopics */
  myResourcesProductTypes: IShareableTopicsReturnType
  /** Return the list of my resources shared by premium users */
  mySharedContents: IMySharedContentsReturnType
  newsletterCategories?: Maybe<INewsletterCategoryEntityResponseCollection>
  newsletterCategory?: Maybe<INewsletterCategoryEntityResponse>
  newsletterSubscription?: Maybe<INewsletterSubscriptionEntityResponse>
  newsletterSubscriptions?: Maybe<INewsletterSubscriptionEntityResponseCollection>
  office?: Maybe<IOfficeEntityResponse>
  offices?: Maybe<IOfficeEntityResponseCollection>
  /** Return organizations for event filtering */
  organization?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  phase?: Maybe<IPhaseEntityResponse>
  phases?: Maybe<IPhaseEntityResponseCollection>
  platformLanguage?: Maybe<IPlatformLanguageEntityResponse>
  platformLanguages?: Maybe<IPlatformLanguageEntityResponseCollection>
  /** Return a list of popular topics */
  popularTopics: Array<IPopularTopic>
  practiceArea?: Maybe<IPracticeAreaEntityResponse>
  practiceAreas?: Maybe<IPracticeAreaEntityResponseCollection>
  premiumAudience?: Maybe<IPremiumAudienceEntityResponse>
  premiumAudiences?: Maybe<IPremiumAudienceEntityResponseCollection>
  premiumOrganization?: Maybe<IPremiumOrganizationEntityResponse>
  premiumOrganizations?: Maybe<IPremiumOrganizationEntityResponseCollection>
  premiumPricingPlan?: Maybe<IPremiumPricingPlanEntityResponse>
  premiumPricingPlans?: Maybe<IPremiumPricingPlanEntityResponseCollection>
  privacyPolicy?: Maybe<IPrivacyPolicyEntityResponse>
  recordLockingOpenEntities?: Maybe<IRecordLockingOpenEntityEntityResponseCollection>
  recordLockingOpenEntity?: Maybe<IRecordLockingOpenEntityEntityResponse>
  recurrence?: Maybe<IRecurrenceEntityResponse>
  recurrences?: Maybe<IRecurrenceEntityResponseCollection>
  regionsServed?: Maybe<IRegionsServedEntityResponse>
  regionsServeds?: Maybe<IRegionsServedEntityResponseCollection>
  resourceInvitation?: Maybe<IResourceInvitationEntityResponse>
  resourceInvitations?: Maybe<IResourceInvitationEntityResponseCollection>
  review?: Maybe<IReviewEntityResponse>
  reviews?: Maybe<IReviewEntityResponseCollection>
  /** Return filtered solution */
  searchSolutions: ISearchSolutionsResult
  /** Return filtered solution for a specific vendor */
  searchVendorSolutions: ISearchSolutionsResult
  securities?: Maybe<ISecurityEntityResponseCollection>
  security?: Maybe<ISecurityEntityResponse>
  /** Return all the resources that you could share */
  shareableContents: IShareableContentsReturnType
  /** Return all the topics/subTopics which are related to the contents that you could share */
  shareableTopics: IShareableTopicsReturnType
  /** Return similar solutions */
  similarSolutions: ISimilarSolutionsResult
  slugUpdate?: Maybe<ISlugUpdateEntityResponse>
  slugUpdates?: Maybe<ISlugUpdateEntityResponseCollection>
  solution?: Maybe<ISolutionEntityResponse>
  solutionRequest?: Maybe<ISolutionRequestEntityResponse>
  solutionRequests?: Maybe<ISolutionRequestEntityResponseCollection>
  solutions?: Maybe<ISolutionEntityResponseCollection>
  /** Return the count of solutions */
  solutionsCount: Scalars['Int']['output']
  subTopic?: Maybe<ISubTopicEntityResponse>
  subTopics?: Maybe<ISubTopicEntityResponseCollection>
  subjectMatter?: Maybe<ISubjectMatterEntityResponse>
  subjectMatters?: Maybe<ISubjectMatterEntityResponseCollection>
  termsConditions?: Maybe<ITermsConditionsEntityResponse>
  testimonials?: Maybe<ITestimonialsEntityResponse>
  testimonialsPlural?: Maybe<ITestimonialsEntityResponseCollection>
  theme?: Maybe<IThemeEntityResponse>
  themes?: Maybe<IThemeEntityResponseCollection>
  topic?: Maybe<ITopicEntityResponse>
  topics?: Maybe<ITopicEntityResponseCollection>
  /** Return trending contents */
  trendingContents: Array<IContentEntity>
  typeOfUser?: Maybe<ITypeOfUserEntityResponse>
  typeOfUsers?: Maybe<ITypeOfUserEntityResponseCollection>
  uploadFile?: Maybe<IUploadFileEntityResponse>
  uploadFiles?: Maybe<IUploadFileEntityResponseCollection>
  uploadFolder?: Maybe<IUploadFolderEntityResponse>
  uploadFolders?: Maybe<IUploadFolderEntityResponseCollection>
  userInfo?: Maybe<IUsersPermissionsUser>
  usersPermissionsRole?: Maybe<IUsersPermissionsRoleEntityResponse>
  usersPermissionsRoles?: Maybe<IUsersPermissionsRoleEntityResponseCollection>
  usersPermissionsUser?: Maybe<IUsersPermissionsUserEntityResponse>
  usersPermissionsUsers?: Maybe<IUsersPermissionsUserEntityResponseCollection>
  vendor?: Maybe<IVendorEntityResponse>
  vendorTopic?: Maybe<IVendorTopicEntityResponse>
  vendorTopics?: Maybe<IVendorTopicEntityResponseCollection>
  vendors?: Maybe<IVendorEntityResponseCollection>
}

export type IQueryAboutArgs = {
  publicationState?: InputMaybe<IPublicationState>
}

export type IQueryAnnouncementArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryAnnouncementsArgs = {
  filters?: InputMaybe<IAnnouncementFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryArticleDataArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryArticleDatasArgs = {
  filters?: InputMaybe<IArticleDataFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryAttributeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryAttributesArgs = {
  filters?: InputMaybe<IAttributeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryAudienceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryAudiencesArgs = {
  filters?: InputMaybe<IAudienceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryAuthorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryAuthorsArgs = {
  filters?: InputMaybe<IAuthorFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryAutosuggestArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>
}

export type IQueryBlogTaxonomyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryBlogTaxonomysArgs = {
  filters?: InputMaybe<IBlogTaxonomyFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryCollectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryCollectionsArgs = {
  filters?: InputMaybe<ICollectionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryCompaniesArgs = {
  filters?: InputMaybe<ICompanyFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryCompanyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryContactHistoriesArgs = {
  filters?: InputMaybe<IContactHistoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryContactHistoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryContentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryContentBySlugArgs = {
  slug: Scalars['String']['input']
}

export type IQueryContentDownloadArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryContentDownloadsArgs = {
  filters?: InputMaybe<IContentDownloadFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryContentResultsArgs = {
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['JSON']['input']>
}

export type IQueryContentVisitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryContentVisitsArgs = {
  filters?: InputMaybe<IContentVisitFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryDemographicArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryDemographicsArgs = {
  filters?: InputMaybe<IDemographicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryDownloadMaterialFileArgs = {
  id: Scalars['ID']['input']
}

export type IQueryDurationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryDurationsArgs = {
  filters?: InputMaybe<IDurationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryEmailReceiverArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryEmailReceiversArgs = {
  filters?: InputMaybe<IEmailReceiverFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryEnhancedRequestContentArgs = {
  publicationState?: InputMaybe<IPublicationState>
}

export type IQueryEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryEventFeatureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryEventFeaturesArgs = {
  filters?: InputMaybe<IEventFeatureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryEventFormatArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryEventFormatsArgs = {
  filters?: InputMaybe<IEventFormatFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryEventResultsArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>
}

export type IQueryEventsArgs = {
  filters?: InputMaybe<IEventFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryExistingCustomerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryExistingCustomersArgs = {
  filters?: InputMaybe<IExistingCustomerFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryExpertArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryExpertsArgs = {
  filters?: InputMaybe<IExpertFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryFeatureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryFeaturedContentsArgs = {
  contentType: IEnum_Content_Contenttype
  limit: Scalars['Int']['input']
  start: Scalars['Int']['input']
  subTopicSlug: Scalars['String']['input']
}

export type IQueryFeaturesArgs = {
  filters?: InputMaybe<IFeatureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryFindAnnouncementBySolutionIdArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>
}

export type IQueryHqArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryHqsArgs = {
  filters?: InputMaybe<IHqFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryI18NLocalesArgs = {
  filters?: InputMaybe<II18NLocaleFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryIltaDataArgs = {
  publicationState?: InputMaybe<IPublicationState>
}

export type IQueryIltaSponsorshipArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryIltaSponsorshipsArgs = {
  filters?: InputMaybe<IIltaSponsorshipFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryInstallationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryInstallationsArgs = {
  filters?: InputMaybe<IInstallationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryIntegrationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryIntegrationsArgs = {
  filters?: InputMaybe<IIntegrationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryInvitedUsersArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>
}

export type IQueryIsFollowArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type IQueryListingRequestArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryListingRequestsArgs = {
  filters?: InputMaybe<IListingRequestFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryMySharedContentsArgs = {
  filters: IMySharedContentsFilters
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type IQueryNewsletterCategoriesArgs = {
  filters?: InputMaybe<INewsletterCategoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryNewsletterCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryNewsletterSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryNewsletterSubscriptionsArgs = {
  filters?: InputMaybe<INewsletterSubscriptionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryOfficeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryOfficesArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPhaseArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPhasesArgs = {
  filters?: InputMaybe<IPhaseFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPlatformLanguageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPlatformLanguagesArgs = {
  filters?: InputMaybe<IPlatformLanguageFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPopularTopicsArgs = {
  vendorLimit: Scalars['Int']['input']
}

export type IQueryPracticeAreaArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPracticeAreasArgs = {
  filters?: InputMaybe<IPracticeAreaFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPremiumAudienceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPremiumAudiencesArgs = {
  filters?: InputMaybe<IPremiumAudienceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPremiumOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPremiumOrganizationsArgs = {
  filters?: InputMaybe<IPremiumOrganizationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPremiumPricingPlanArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryPremiumPricingPlansArgs = {
  filters?: InputMaybe<IPremiumPricingPlanFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryPrivacyPolicyArgs = {
  publicationState?: InputMaybe<IPublicationState>
}

export type IQueryRecordLockingOpenEntitiesArgs = {
  filters?: InputMaybe<IRecordLockingOpenEntityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryRecordLockingOpenEntityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryRecurrenceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryRecurrencesArgs = {
  filters?: InputMaybe<IRecurrenceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryRegionsServedArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryRegionsServedsArgs = {
  filters?: InputMaybe<IRegionsServedFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryResourceInvitationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryResourceInvitationsArgs = {
  filters?: InputMaybe<IResourceInvitationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryReviewArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryReviewsArgs = {
  filters?: InputMaybe<IReviewFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQuerySearchSolutionsArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>
}

export type IQuerySearchVendorSolutionsArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>
}

export type IQuerySecuritiesArgs = {
  filters?: InputMaybe<ISecurityFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQuerySecurityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryShareableContentsArgs = {
  filters: IMySharedContentsFilters
  limit: Scalars['Int']['input']
  offset: Scalars['Int']['input']
}

export type IQuerySimilarSolutionsArgs = {
  from?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['Int']['input']>
}

export type IQuerySlugUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQuerySlugUpdatesArgs = {
  filters?: InputMaybe<ISlugUpdateFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQuerySolutionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQuerySolutionRequestArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQuerySolutionRequestsArgs = {
  filters?: InputMaybe<ISolutionRequestFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type AllSolutionRequestsQueryVariable = Exact<{
  filters?: InputMaybe<ISolutionRequestFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}>

export type IQuerySolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQuerySolutionsCountArgs = {
  where?: InputMaybe<Scalars['JSON']['input']>
}

export type IQuerySubTopicArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQuerySubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQuerySubjectMatterArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQuerySubjectMattersArgs = {
  filters?: InputMaybe<ISubjectMatterFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryTermsConditionsArgs = {
  publicationState?: InputMaybe<IPublicationState>
}

export type IQueryTestimonialsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryTestimonialsPluralArgs = {
  filters?: InputMaybe<ITestimonialsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryThemeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryThemesArgs = {
  filters?: InputMaybe<IThemeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryTopicArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryTrendingContentsArgs = {
  audience?: InputMaybe<Scalars['String']['input']>
}

export type IQueryTypeOfUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryTypeOfUsersArgs = {
  filters?: InputMaybe<ITypeOfUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryUploadFilesArgs = {
  filters?: InputMaybe<IUploadFileFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryUploadFoldersArgs = {
  filters?: InputMaybe<IUploadFolderFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryUserInfoArgs = {
  token?: InputMaybe<Scalars['String']['input']>
}

export type IQueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<IUsersPermissionsRoleFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<IUsersPermissionsUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryVendorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryVendorTopicArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IQueryVendorTopicsArgs = {
  filters?: InputMaybe<IVendorTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IQueryVendorsArgs = {
  filters?: InputMaybe<IVendorFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IRecordLockingOpenEntity = {
  __typename?: 'RecordLockingOpenEntity'
  connectionId?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  entityIdentifier?: Maybe<Scalars['String']['output']>
  entityType?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  user?: Maybe<Scalars['String']['output']>
}

export type IRecordLockingOpenEntityEntity = {
  __typename?: 'RecordLockingOpenEntityEntity'
  attributes?: Maybe<IRecordLockingOpenEntity>
  id?: Maybe<Scalars['ID']['output']>
}

export type IRecordLockingOpenEntityEntityResponse = {
  __typename?: 'RecordLockingOpenEntityEntityResponse'
  data?: Maybe<IRecordLockingOpenEntityEntity>
}

export type IRecordLockingOpenEntityEntityResponseCollection = {
  __typename?: 'RecordLockingOpenEntityEntityResponseCollection'
  data: Array<IRecordLockingOpenEntityEntity>
  meta: IResponseCollectionMeta
}

export type IRecordLockingOpenEntityFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IRecordLockingOpenEntityFiltersInput>>>
  connectionId?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  entityIdentifier?: InputMaybe<IStringFilterInput>
  entityType?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IRecordLockingOpenEntityFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IRecordLockingOpenEntityFiltersInput>>>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  user?: InputMaybe<IStringFilterInput>
}

export type IRecordLockingOpenEntityInput = {
  connectionId?: InputMaybe<Scalars['String']['input']>
  entityIdentifier?: InputMaybe<Scalars['String']['input']>
  entityType?: InputMaybe<Scalars['String']['input']>
  user?: InputMaybe<Scalars['String']['input']>
}

export type IRecurrence = {
  __typename?: 'Recurrence'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IRecurrenceEntity = {
  __typename?: 'RecurrenceEntity'
  attributes?: Maybe<IRecurrence>
  id?: Maybe<Scalars['ID']['output']>
}

export type IRecurrenceEntityResponse = {
  __typename?: 'RecurrenceEntityResponse'
  data?: Maybe<IRecurrenceEntity>
}

export type IRecurrenceEntityResponseCollection = {
  __typename?: 'RecurrenceEntityResponseCollection'
  data: Array<IRecurrenceEntity>
  meta: IResponseCollectionMeta
}

export type IRecurrenceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IRecurrenceFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IRecurrenceFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IRecurrenceFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IRecurrenceInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IRegionsServed = {
  __typename?: 'RegionsServed'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IRegionsServedEntity = {
  __typename?: 'RegionsServedEntity'
  attributes?: Maybe<IRegionsServed>
  id?: Maybe<Scalars['ID']['output']>
}

export type IRegionsServedEntityResponse = {
  __typename?: 'RegionsServedEntityResponse'
  data?: Maybe<IRegionsServedEntity>
}

export type IRegionsServedEntityResponseCollection = {
  __typename?: 'RegionsServedEntityResponseCollection'
  data: Array<IRegionsServedEntity>
  meta: IResponseCollectionMeta
}

export type IRegionsServedFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IRegionsServedFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IRegionsServedFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IRegionsServedFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IRegionsServedInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IRegionsServedRelationResponseCollection = {
  __typename?: 'RegionsServedRelationResponseCollection'
  data: Array<IRegionsServedEntity>
}

export type IRegisterPremiumOrganizationInput = {
  billingAddress: IComponentBillingAddressBillingAddressInput
  emailDomain: Scalars['String']['input']
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  leadSource?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  pricingPlan: Scalars['ID']['input']
  registrationContact: Array<InputMaybe<IComponentRegistrationContactRegistrationContactInput>>
  shouldSendResponse?: InputMaybe<Scalars['Boolean']['input']>
}

export type IRegisterVendorOrganizationInput = {
  companySize?: InputMaybe<Scalars['String']['input']>
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  registrationContact: Array<InputMaybe<IComponentRegistrationContactRegistrationContactInput>>
  shouldSendResponse?: InputMaybe<Scalars['Boolean']['input']>
}

export type IResourceInvitation = {
  __typename?: 'ResourceInvitation'
  businessEmail: Scalars['String']['output']
  companyLawFirmName: Scalars['String']['output']
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  shared_by?: Maybe<IUsersPermissionsUserEntityResponse>
  shared_to?: Maybe<IUsersPermissionsUserEntityResponse>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IResourceInvitationContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IResourceInvitationEntity = {
  __typename?: 'ResourceInvitationEntity'
  attributes?: Maybe<IResourceInvitation>
  businessEmail: Scalars['String']['output']
  companyLawFirmName: Scalars['String']['output']
  contents: Array<Maybe<ISharedResourceEntity>>
  dateInvited: Scalars['Date']['output']
  id?: Maybe<Scalars['ID']['output']>
  name: Scalars['String']['output']
  status: Scalars['Boolean']['output']
}

export type IResourceInvitationEntityResponse = {
  __typename?: 'ResourceInvitationEntityResponse'
  data?: Maybe<IResourceInvitationEntity>
}

export type IResourceInvitationEntityResponseCollection = {
  __typename?: 'ResourceInvitationEntityResponseCollection'
  data: Array<IResourceInvitationEntity>
  meta: IResponseCollectionMeta
}

export type IResourceInvitationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IResourceInvitationFiltersInput>>>
  businessEmail?: InputMaybe<IStringFilterInput>
  companyLawFirmName?: InputMaybe<IStringFilterInput>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IResourceInvitationFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IResourceInvitationFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  shared_by?: InputMaybe<IUsersPermissionsUserFiltersInput>
  shared_to?: InputMaybe<IUsersPermissionsUserFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IResourceInvitationInput = {
  businessEmail?: InputMaybe<Scalars['String']['input']>
  companyLawFirmName?: InputMaybe<Scalars['String']['input']>
  content_ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  invitations?: InputMaybe<Array<IInvitationInput>>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  shared_by?: InputMaybe<Scalars['ID']['input']>
  shared_to?: InputMaybe<Scalars['ID']['input']>
}

export type IVendorNameInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type IResourceInvitationRelationResponseCollection = {
  __typename?: 'ResourceInvitationRelationResponseCollection'
  data: Array<IResourceInvitationEntity>
}

export type IResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta'
  pagination: IPagination
}

export type IReview = {
  __typename?: 'Review'
  anonymous?: Maybe<Scalars['Boolean']['output']>
  content?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  ip?: Maybe<Scalars['String']['output']>
  notes?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  rating?: Maybe<Scalars['Float']['output']>
  solution?: Maybe<ISolutionEntityResponse>
  solutionDispName?: Maybe<Scalars['String']['output']>
  status?: Maybe<IEnum_Review_Status>
  submittedBy?: Maybe<IUsersPermissionsUserEntityResponse>
  submitterSubTitle?: Maybe<Scalars['String']['output']>
  submitterTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IReviewEntity = {
  __typename?: 'ReviewEntity'
  attributes?: Maybe<IReview>
  id?: Maybe<Scalars['ID']['output']>
}

export type IReviewEntityResponse = {
  __typename?: 'ReviewEntityResponse'
  data?: Maybe<IReviewEntity>
}

export type IReviewEntityResponseCollection = {
  __typename?: 'ReviewEntityResponseCollection'
  data: Array<IReviewEntity>
  meta: IResponseCollectionMeta
}

export type IReviewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IReviewFiltersInput>>>
  anonymous?: InputMaybe<IBooleanFilterInput>
  content?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  ip?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IReviewFiltersInput>
  notes?: InputMaybe<IStringFilterInput>
  or?: InputMaybe<Array<InputMaybe<IReviewFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  rating?: InputMaybe<IFloatFilterInput>
  solution?: InputMaybe<ISolutionFiltersInput>
  solutionDispName?: InputMaybe<IStringFilterInput>
  status?: InputMaybe<IStringFilterInput>
  submittedBy?: InputMaybe<IUsersPermissionsUserFiltersInput>
  submitterSubTitle?: InputMaybe<IStringFilterInput>
  submitterTitle?: InputMaybe<IStringFilterInput>
  title?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IReviewInput = {
  anonymous?: InputMaybe<Scalars['Boolean']['input']>
  content?: InputMaybe<Scalars['String']['input']>
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  ip?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  solution?: InputMaybe<Scalars['ID']['input']>
  solutionDispName?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<IEnum_Review_Status>
  submittedBy?: InputMaybe<Scalars['ID']['input']>
  submitterSubTitle?: InputMaybe<Scalars['String']['input']>
  submitterTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IReviewRelationResponseCollection = {
  __typename?: 'ReviewRelationResponseCollection'
  data: Array<IReviewEntity>
}

export type ISearchAnnouncementsResult = {
  __typename?: 'SearchAnnouncementsResult'
  announcement?: Maybe<IAnnouncementItem>
}

export type ISearchSolutionsResult = {
  __typename?: 'SearchSolutionsResult'
  solutions?: Maybe<Array<Maybe<ISolutionItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type ISecurity = {
  __typename?: 'Security'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ISecurityEntity = {
  __typename?: 'SecurityEntity'
  attributes?: Maybe<ISecurity>
  id?: Maybe<Scalars['ID']['output']>
}

export type ISecurityEntityResponse = {
  __typename?: 'SecurityEntityResponse'
  data?: Maybe<ISecurityEntity>
}

export type ISecurityEntityResponseCollection = {
  __typename?: 'SecurityEntityResponseCollection'
  data: Array<ISecurityEntity>
  meta: IResponseCollectionMeta
}

export type ISecurityFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ISecurityFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ISecurityFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ISecurityFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ISecurityInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ISecurityRelationResponseCollection = {
  __typename?: 'SecurityRelationResponseCollection'
  data: Array<ISecurityEntity>
}

export type IShareableContentsReturnType = {
  __typename?: 'ShareableContentsReturnType'
  contents?: Maybe<Array<IContentEntity>>
  total?: Maybe<Scalars['Int']['output']>
}

export type IShareableTopicInfo = {
  __typename?: 'ShareableTopicInfo'
  id: Scalars['Int']['output']
  isPremium?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  subTopics?: Maybe<Array<ISubTopicInfo>>
}

export type IShareableTopicsReturnType = {
  __typename?: 'ShareableTopicsReturnType'
  topics: Array<IShareableTopicInfo>
}

export type ISharedResourceEntity = {
  __typename?: 'SharedResourceEntity'
  id?: Maybe<Scalars['ID']['output']>
  sharedResources: IContentVisitDownloadCountResult
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type ISimilarSolutionItem = {
  __typename?: 'SimilarSolutionItem'
  displayName?: Maybe<Scalars['String']['output']>
  logo?: Maybe<Scalars['String']['output']>
  rating?: Maybe<Scalars['Float']['output']>
  reviewCnt?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  type?: Maybe<IEnum_Solution_Item_Type>
}

export type ISimilarSolutionsResult = {
  __typename?: 'SimilarSolutionsResult'
  solutions: Array<ISimilarSolutionItem>
}

export type ISlugUpdate = {
  __typename?: 'SlugUpdate'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  newSlug?: Maybe<Scalars['String']['output']>
  oldSlug?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ISlugUpdateEntity = {
  __typename?: 'SlugUpdateEntity'
  attributes?: Maybe<ISlugUpdate>
  id?: Maybe<Scalars['ID']['output']>
}

export type ISlugUpdateEntityResponse = {
  __typename?: 'SlugUpdateEntityResponse'
  data?: Maybe<ISlugUpdateEntity>
}

export type ISlugUpdateEntityResponseCollection = {
  __typename?: 'SlugUpdateEntityResponseCollection'
  data: Array<ISlugUpdateEntity>
  meta: IResponseCollectionMeta
}

export type ISlugUpdateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ISlugUpdateFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  newSlug?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ISlugUpdateFiltersInput>
  oldSlug?: InputMaybe<IStringFilterInput>
  or?: InputMaybe<Array<InputMaybe<ISlugUpdateFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  type?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ISlugUpdateInput = {
  newSlug?: InputMaybe<Scalars['String']['input']>
  oldSlug?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ISolution = {
  __typename?: 'Solution'
  alspData?: Maybe<IComponentSolutionAlspData>
  announcement?: Maybe<IAnnouncementEntityResponse>
  articles?: Maybe<Array<Maybe<IComponentGlobalArticle>>>
  authoredContents?: Maybe<IContentRelationResponseCollection>
  collections?: Maybe<ICollectionRelationResponseCollection>
  company?: Maybe<ICompanyEntityResponse>
  consolidationData?: Maybe<IComponentSolutionPropsConsolidation>
  consultantData?: Maybe<IComponentSolutionConsultantData>
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  existingCustomers?: Maybe<IExistingCustomerRelationResponseCollection>
  features?: Maybe<IFeatureRelationResponseCollection>
  graveyardData?: Maybe<IComponentSolutionPropsGraveyard>
  hqs?: Maybe<IHqRelationResponseCollection>
  iltaSponsorships?: Maybe<IIltaSponsorshipRelationResponseCollection>
  isEnhanced?: Maybe<Scalars['Boolean']['output']>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  languages?: Maybe<IPlatformLanguageRelationResponseCollection>
  linkedin?: Maybe<Scalars['String']['output']>
  logo?: Maybe<IUploadFileEntityResponse>
  longDescription?: Maybe<Scalars['String']['output']>
  offices?: Maybe<IOfficeRelationResponseCollection>
  premiumOrganization?: Maybe<IPremiumOrganizationEntityResponse>
  productData?: Maybe<IComponentSolutionProductData>
  productType?: Maybe<IEnum_Solution_Producttype>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  rating?: Maybe<Scalars['Float']['output']>
  regionsServed?: Maybe<IRegionsServedRelationResponseCollection>
  reviews?: Maybe<IReviewRelationResponseCollection>
  shortDescription?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  testimonials?: Maybe<ITestimonialsRelationResponseCollection>
  testimonialsData?: Maybe<Scalars['JSON']['output']>
  topics?: Maybe<ITopicRelationResponseCollection>
  twitter?: Maybe<Scalars['String']['output']>
  type: IEnum_Solution_Type
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  vendor?: Maybe<IVendorEntityResponse>
  vendorResources?: Maybe<Array<Maybe<IComponentSolutionPropsVendorResource>>>
  website?: Maybe<Scalars['String']['output']>
  yearFounded?: Maybe<Scalars['Int']['output']>
  founders?: Maybe<Array<Maybe<IComponentAboutPropsTeamMember>>>
}

export type ISolutionArticlesArgs = {
  filters?: InputMaybe<IComponentGlobalArticleFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionAuthoredContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionCollectionsArgs = {
  filters?: InputMaybe<ICollectionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionExistingCustomersArgs = {
  filters?: InputMaybe<IExistingCustomerFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionFeaturesArgs = {
  filters?: InputMaybe<IFeatureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionHqsArgs = {
  filters?: InputMaybe<IHqFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionIltaSponsorshipsArgs = {
  filters?: InputMaybe<IIltaSponsorshipFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionLanguagesArgs = {
  filters?: InputMaybe<IPlatformLanguageFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionOfficesArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRegionsServedArgs = {
  filters?: InputMaybe<IRegionsServedFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionReviewsArgs = {
  filters?: InputMaybe<IReviewFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionTestimonialsArgs = {
  filters?: InputMaybe<ITestimonialsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionVendorResourcesArgs = {
  filters?: InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionEntity = {
  __typename?: 'SolutionEntity'
  attributes?: Maybe<ISolution>
  id?: Maybe<Scalars['ID']['output']>
}

export type ISolutionEntityResponse = {
  __typename?: 'SolutionEntityResponse'
  data?: Maybe<ISolutionEntity>
}

export type ISolutionEntityResponseCollection = {
  __typename?: 'SolutionEntityResponseCollection'
  data: Array<ISolutionEntity>
  meta: IResponseCollectionMeta
}

export type ISolutionFiltersInput = {
  adminNote?: InputMaybe<IStringFilterInput>
  alspData?: InputMaybe<IComponentSolutionAlspDataFiltersInput>
  and?: InputMaybe<Array<InputMaybe<ISolutionFiltersInput>>>
  announcement?: InputMaybe<IAnnouncementFiltersInput>
  articles?: InputMaybe<IComponentGlobalArticleFiltersInput>
  authoredContents?: InputMaybe<IContentFiltersInput>
  collections?: InputMaybe<ICollectionFiltersInput>
  company?: InputMaybe<ICompanyFiltersInput>
  consolidationData?: InputMaybe<IComponentSolutionPropsConsolidationFiltersInput>
  consultantData?: InputMaybe<IComponentSolutionConsultantDataFiltersInput>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  displayName?: InputMaybe<IStringFilterInput>
  existingCustomers?: InputMaybe<IExistingCustomerFiltersInput>
  featured?: InputMaybe<IBooleanFilterInput>
  featuredOnHome?: InputMaybe<IBooleanFilterInput>
  features?: InputMaybe<IFeatureFiltersInput>
  followedUsers?: InputMaybe<IUsersPermissionsUserFiltersInput>
  graveyardData?: InputMaybe<IComponentSolutionPropsGraveyardFiltersInput>
  hqs?: InputMaybe<IHqFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  iltaSponsorships?: InputMaybe<IIltaSponsorshipFiltersInput>
  isEnhanced?: InputMaybe<IBooleanFilterInput>
  isPremium?: InputMaybe<IBooleanFilterInput>
  languages?: InputMaybe<IPlatformLanguageFiltersInput>
  linkedin?: InputMaybe<IStringFilterInput>
  longDescription?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ISolutionFiltersInput>
  offices?: InputMaybe<IOfficeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ISolutionFiltersInput>>>
  premiumOrganization?: InputMaybe<IPremiumOrganizationFiltersInput>
  productData?: InputMaybe<IComponentSolutionProductDataFiltersInput>
  productType?: InputMaybe<IStringFilterInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  rating?: InputMaybe<IFloatFilterInput>
  regionsServed?: InputMaybe<IRegionsServedFiltersInput>
  reviews?: InputMaybe<IReviewFiltersInput>
  shortDescription?: InputMaybe<IStringFilterInput>
  similarSolutions?: InputMaybe<ISolutionFiltersInput>
  slug?: InputMaybe<IStringFilterInput>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  testimonials?: InputMaybe<ITestimonialsFiltersInput>
  testimonialsData?: InputMaybe<IJsonFilterInput>
  topics?: InputMaybe<ITopicFiltersInput>
  twitter?: InputMaybe<IStringFilterInput>
  type?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  vendor?: InputMaybe<IVendorFiltersInput>
  vendorResources?: InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>
  website?: InputMaybe<IStringFilterInput>
  yearFounded?: InputMaybe<IIntFilterInput>
}

export type ISolutionId = {
  solution_id: Scalars['ID']['input']
}

export type ISolutionInput = {
  adminNote?: InputMaybe<Scalars['String']['input']>
  alspData?: InputMaybe<IComponentSolutionAlspDataInput>
  announcement?: InputMaybe<Scalars['ID']['input']>
  articles?: InputMaybe<Array<InputMaybe<IComponentGlobalArticleInput>>>
  authoredContents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  collections?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  company?: InputMaybe<Scalars['ID']['input']>
  consolidationData?: InputMaybe<IComponentSolutionPropsConsolidationInput>
  consultantData?: InputMaybe<IComponentSolutionConsultantDataInput>
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  displayName?: InputMaybe<Scalars['String']['input']>
  existingCustomers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  featured?: InputMaybe<Scalars['Boolean']['input']>
  featuredOnHome?: InputMaybe<Scalars['Boolean']['input']>
  features?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  followedUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  graveyardData?: InputMaybe<IComponentSolutionPropsGraveyardInput>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  iltaSponsorships?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isEnhanced?: InputMaybe<Scalars['Boolean']['input']>
  isPremium?: InputMaybe<Scalars['Boolean']['input']>
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  linkedin?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['ID']['input']>
  longDescription?: InputMaybe<Scalars['String']['input']>
  offices?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  premiumOrganization?: InputMaybe<Scalars['ID']['input']>
  productData?: InputMaybe<IComponentSolutionProductDataInput>
  productType?: InputMaybe<IEnum_Solution_Producttype>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  regionsServed?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  reviews?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  similarSolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  slug?: InputMaybe<Scalars['String']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  testimonials?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  testimonialsData?: InputMaybe<Scalars['JSON']['input']>
  topics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  twitter?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<IEnum_Solution_Type>
  vendor?: InputMaybe<Scalars['ID']['input']>
  vendorResources?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVendorResourceInput>>>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Int']['input']>
}

export type ISolutionItem = {
  __typename?: 'SolutionItem'
  consolidation?: Maybe<IComponentSolutionPropsConsolidation>
  dataHostingLocations?: Maybe<Array<Maybe<ILocations>>>
  description?: Maybe<Scalars['String']['output']>
  displayName: Scalars['String']['output']
  follow: Scalars['Boolean']['output']
  graveyard?: Maybe<IComponentSolutionPropsGraveyard>
  id: Scalars['ID']['output']
  iltaSponsorshipLogo?: Maybe<Scalars['String']['output']>
  isCompany: Scalars['Boolean']['output']
  isEnhanced?: Maybe<Scalars['Boolean']['output']>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  logo?: Maybe<Scalars['String']['output']>
  offices?: Maybe<Array<Maybe<ILocations>>>
  rating?: Maybe<Scalars['Float']['output']>
  reviewCnt?: Maybe<Scalars['Int']['output']>
  security?: Maybe<Array<Maybe<ISecurity>>>
  slug: Scalars['String']['output']
  subTopics: Array<Scalars['String']['output']>
  type: IEnum_Solution_Item_Type
  valueProposition?: Maybe<Scalars['String']['output']>
}

export type ISolutionRelationResponseCollection = {
  __typename?: 'SolutionRelationResponseCollection'
  data: Array<ISolutionEntity>
}

export type ISolutionRequest = {
  __typename?: 'SolutionRequest'
  alspData?: Maybe<IComponentSolutionRequestAlspData>
  announcement?: Maybe<IAnnouncementEntityResponse>
  articles?: Maybe<Array<Maybe<IComponentGlobalArticle>>>
  consultantData?: Maybe<IComponentSolutionRequestConsultantData>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  existingCustomers?: Maybe<IExistingCustomerRelationResponseCollection>
  features?: Maybe<IFeatureRelationResponseCollection>
  hqs?: Maybe<IHqRelationResponseCollection>
  isEnhanced?: Maybe<Scalars['Boolean']['output']>
  languages?: Maybe<IPlatformLanguageRelationResponseCollection>
  linkedin?: Maybe<Scalars['String']['output']>
  logo?: Maybe<IUploadFileEntityResponse>
  longDescription?: Maybe<Scalars['String']['output']>
  offices?: Maybe<IOfficeRelationResponseCollection>
  productData?: Maybe<IComponentSolutionRequestProductData>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  regionsServed?: Maybe<IRegionsServedRelationResponseCollection>
  shortDescription?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  submittedBy?: Maybe<IUsersPermissionsUserEntityResponse>
  testimonials?: Maybe<ITestimonialsRelationResponseCollection>
  testimonialsData?: Maybe<Scalars['JSON']['output']>
  topics?: Maybe<ITopicRelationResponseCollection>
  twitter?: Maybe<Scalars['String']['output']>
  type: IEnum_Solutionrequest_Type
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  vendorResources?: Maybe<Array<Maybe<IComponentSolutionPropsVendorResource>>>
  website?: Maybe<Scalars['String']['output']>
  yearFounded?: Maybe<Scalars['Int']['output']>
}

export type ISolutionRequestArticlesArgs = {
  filters?: InputMaybe<IComponentGlobalArticleFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestExistingCustomersArgs = {
  filters?: InputMaybe<IExistingCustomerFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestFeaturesArgs = {
  filters?: InputMaybe<IFeatureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestHqsArgs = {
  filters?: InputMaybe<IHqFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestLanguagesArgs = {
  filters?: InputMaybe<IPlatformLanguageFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestOfficesArgs = {
  filters?: InputMaybe<IOfficeFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestRegionsServedArgs = {
  filters?: InputMaybe<IRegionsServedFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestTestimonialsArgs = {
  filters?: InputMaybe<ITestimonialsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestVendorResourcesArgs = {
  filters?: InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISolutionRequestEntity = {
  __typename?: 'SolutionRequestEntity'
  attributes?: Maybe<ISolutionRequest>
  id?: Maybe<Scalars['ID']['output']>
}

export type ISolutionRequestEntityResponse = {
  __typename?: 'SolutionRequestEntityResponse'
  data?: Maybe<ISolutionRequestEntity>
}

export type ISolutionRequestEntityResponseCollection = {
  __typename?: 'SolutionRequestEntityResponseCollection'
  data: Array<ISolutionRequestEntity>
  meta: IResponseCollectionMeta
}

export type ISolutionRequestFiltersInput = {
  alspData?: InputMaybe<IComponentSolutionRequestAlspDataFiltersInput>
  and?: InputMaybe<Array<InputMaybe<ISolutionRequestFiltersInput>>>
  announcement?: InputMaybe<IAnnouncementFiltersInput>
  articles?: InputMaybe<IComponentGlobalArticleFiltersInput>
  consultantData?: InputMaybe<IComponentSolutionRequestConsultantDataFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  existingCustomers?: InputMaybe<IExistingCustomerFiltersInput>
  features?: InputMaybe<IFeatureFiltersInput>
  gReCaptchaToken?: InputMaybe<IStringFilterInput>
  hqs?: InputMaybe<IHqFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  isEnhanced?: InputMaybe<IBooleanFilterInput>
  languages?: InputMaybe<IPlatformLanguageFiltersInput>
  linkedin?: InputMaybe<IStringFilterInput>
  longDescription?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ISolutionRequestFiltersInput>
  offices?: InputMaybe<IOfficeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ISolutionRequestFiltersInput>>>
  productData?: InputMaybe<IComponentSolutionRequestProductDataFiltersInput>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  regionsServed?: InputMaybe<IRegionsServedFiltersInput>
  shortDescription?: InputMaybe<IStringFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  submittedBy?: InputMaybe<IUsersPermissionsUserFiltersInput>
  testimonials?: InputMaybe<ITestimonialsFiltersInput>
  testimonialsData?: InputMaybe<IJsonFilterInput>
  topics?: InputMaybe<ITopicFiltersInput>
  twitter?: InputMaybe<IStringFilterInput>
  type?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  vendorResources?: InputMaybe<IComponentSolutionPropsVendorResourceFiltersInput>
  website?: InputMaybe<IStringFilterInput>
  yearFounded?: InputMaybe<IIntFilterInput>
}

export type ISolutionRequestInput = {
  alspData?: InputMaybe<IComponentSolutionRequestAlspDataInput>
  announcement?: InputMaybe<Scalars['ID']['input']>
  articles?: InputMaybe<Array<InputMaybe<IComponentGlobalArticleInput>>>
  consultantData?: InputMaybe<IComponentSolutionRequestConsultantDataInput>
  existingCustomers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  features?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isEnhanced?: InputMaybe<Scalars['Boolean']['input']>
  languages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  linkedin?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['ID']['input']>
  longDescription?: InputMaybe<Scalars['String']['input']>
  offices?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  productData?: InputMaybe<IComponentSolutionRequestProductDataInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  regionsServed?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  submittedBy?: InputMaybe<Scalars['ID']['input']>
  testimonials?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  testimonialsData?: InputMaybe<Scalars['JSON']['input']>
  topics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  twitter?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<IEnum_Solutionrequest_Type>
  vendorResources?: InputMaybe<Array<InputMaybe<IComponentSolutionPropsVendorResourceInput>>>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Int']['input']>
}

export type ISolutionRequestRelationResponseCollection = {
  __typename?: 'SolutionRequestRelationResponseCollection'
  data: Array<ISolutionRequestEntity>
}

export type IStringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  contains?: InputMaybe<Scalars['String']['input']>
  containsi?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  eq?: InputMaybe<Scalars['String']['input']>
  eqi?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  ne?: InputMaybe<Scalars['String']['input']>
  nei?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<IStringFilterInput>
  notContains?: InputMaybe<Scalars['String']['input']>
  notContainsi?: InputMaybe<Scalars['String']['input']>
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  notNull?: InputMaybe<Scalars['Boolean']['input']>
  null?: InputMaybe<Scalars['Boolean']['input']>
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type ISubTopic = {
  __typename?: 'SubTopic'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  expert?: Maybe<IExpertEntityResponse>
  features?: Maybe<IFeatureRelationResponseCollection>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  solutions?: Maybe<ISolutionRequestRelationResponseCollection>
  topics?: Maybe<ITopicRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ISubTopicContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISubTopicFeaturesArgs = {
  filters?: InputMaybe<IFeatureFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISubTopicSolutionsArgs = {
  filters?: InputMaybe<ISolutionRequestFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISubTopicTopicsArgs = {
  filters?: InputMaybe<ITopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISubTopicEntity = {
  __typename?: 'SubTopicEntity'
  attributes?: Maybe<ISubTopic>
  id?: Maybe<Scalars['ID']['output']>
}

export type ISubTopicEntityResponse = {
  __typename?: 'SubTopicEntityResponse'
  data?: Maybe<ISubTopicEntity>
}

export type ISubTopicEntityResponseCollection = {
  __typename?: 'SubTopicEntityResponseCollection'
  data: Array<ISubTopicEntity>
  meta: IResponseCollectionMeta
}

export type ISubTopicFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ISubTopicFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  expert?: InputMaybe<IExpertFiltersInput>
  featuredOnHome?: InputMaybe<IBooleanFilterInput>
  features?: InputMaybe<IFeatureFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  isPremium?: InputMaybe<IBooleanFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ISubTopicFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ISubTopicFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  solutions?: InputMaybe<ISolutionRequestFiltersInput>
  topics?: InputMaybe<ITopicFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ISubTopicInfo = {
  __typename?: 'SubTopicInfo'
  id?: Maybe<Scalars['Int']['output']>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
}

export type ISubTopicInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  expert?: InputMaybe<Scalars['ID']['input']>
  featuredOnHome?: InputMaybe<Scalars['Boolean']['input']>
  features?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isPremium?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  solutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  topics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type ISubTopicRelationResponseCollection = {
  __typename?: 'SubTopicRelationResponseCollection'
  data: Array<ISubTopicEntity>
}

export type ISubjectMatter = {
  __typename?: 'SubjectMatter'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ISubjectMatterContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ISubjectMatterEntity = {
  __typename?: 'SubjectMatterEntity'
  attributes?: Maybe<ISubjectMatter>
  id?: Maybe<Scalars['ID']['output']>
}

export type ISubjectMatterEntityResponse = {
  __typename?: 'SubjectMatterEntityResponse'
  data?: Maybe<ISubjectMatterEntity>
}

export type ISubjectMatterEntityResponseCollection = {
  __typename?: 'SubjectMatterEntityResponseCollection'
  data: Array<ISubjectMatterEntity>
  meta: IResponseCollectionMeta
}

export type ISubjectMatterFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ISubjectMatterFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ISubjectMatterFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ISubjectMatterFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ISubjectMatterInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type ISubjectMatterRelationResponseCollection = {
  __typename?: 'SubjectMatterRelationResponseCollection'
  data: Array<ISubjectMatterEntity>
}

export type ISuggest = {
  __typename?: 'Suggest'
  id: Scalars['Int']['output']
  keyword: Scalars['String']['output']
  logo?: Maybe<Scalars['String']['output']>
  model: ISuggestModel
  slug: Scalars['String']['output']
}

export enum ISuggestModel {
  AlsPs = 'ALSPs',
  Companies = 'Companies',
  Consultants = 'Consultants',
  Contents = 'Contents',
  Events = 'Events',
  SubTopics = 'SubTopics',
  Topics = 'Topics',
  Vendors = 'Vendors',
}

export type ITaxonomy = {
  id?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ITermsConditions = {
  __typename?: 'TermsConditions'
  content?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  date?: Maybe<Scalars['Date']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  summary?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ITermsConditionsEntity = {
  __typename?: 'TermsConditionsEntity'
  attributes?: Maybe<ITermsConditions>
  id?: Maybe<Scalars['ID']['output']>
}

export type ITermsConditionsEntityResponse = {
  __typename?: 'TermsConditionsEntityResponse'
  data?: Maybe<ITermsConditionsEntity>
}

export type ITermsConditionsInput = {
  content?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['Date']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  summary?: InputMaybe<Scalars['String']['input']>
}

export type ITestimonials = {
  __typename?: 'Testimonials'
  author?: Maybe<IComponentTestimonialsPropsAuthor>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ITestimonialsEntity = {
  __typename?: 'TestimonialsEntity'
  attributes?: Maybe<ITestimonials>
  id?: Maybe<Scalars['ID']['output']>
}

export type ITestimonialsEntityResponse = {
  __typename?: 'TestimonialsEntityResponse'
  data?: Maybe<ITestimonialsEntity>
}

export type ITestimonialsEntityResponseCollection = {
  __typename?: 'TestimonialsEntityResponseCollection'
  data: Array<ITestimonialsEntity>
  meta: IResponseCollectionMeta
}

export type ITestimonialsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ITestimonialsFiltersInput>>>
  author?: InputMaybe<IComponentTestimonialsPropsAuthorFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<ITestimonialsFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ITestimonialsFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ITestimonialsInput = {
  author?: InputMaybe<IComponentTestimonialsPropsAuthorInput>
  description?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ITestimonialsRelationResponseCollection = {
  __typename?: 'TestimonialsRelationResponseCollection'
  data: Array<ITestimonialsEntity>
}

export type ITheme = {
  __typename?: 'Theme'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IThemeContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IThemeEntity = {
  __typename?: 'ThemeEntity'
  attributes?: Maybe<ITheme>
  id?: Maybe<Scalars['ID']['output']>
}

export type IThemeEntityResponse = {
  __typename?: 'ThemeEntityResponse'
  data?: Maybe<IThemeEntity>
}

export type IThemeEntityResponseCollection = {
  __typename?: 'ThemeEntityResponseCollection'
  data: Array<IThemeEntity>
  meta: IResponseCollectionMeta
}

export type IThemeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IThemeFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IThemeFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IThemeFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IThemeInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type IThemeRelationResponseCollection = {
  __typename?: 'ThemeRelationResponseCollection'
  data: Array<IThemeEntity>
}

export type ITopic = {
  __typename?: 'Topic'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  expert?: Maybe<IExpertEntityResponse>
  iconName?: Maybe<Scalars['String']['output']>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  slug: Scalars['String']['output']
  solutions?: Maybe<ISolutionRequestRelationResponseCollection>
  subTopics?: Maybe<ISubTopicRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ITopicContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ITopicSolutionsArgs = {
  filters?: InputMaybe<ISolutionRequestFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ITopicSubTopicsArgs = {
  filters?: InputMaybe<ISubTopicFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type ITopicEntity = {
  __typename?: 'TopicEntity'
  attributes?: Maybe<ITopic>
  id?: Maybe<Scalars['ID']['output']>
}

export type ITopicEntityResponse = {
  __typename?: 'TopicEntityResponse'
  data?: Maybe<ITopicEntity>
}

export type ITopicEntityResponseCollection = {
  __typename?: 'TopicEntityResponseCollection'
  data: Array<ITopicEntity>
  meta: IResponseCollectionMeta
}

export type ITopicFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ITopicFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  expert?: InputMaybe<IExpertFiltersInput>
  featuredOnHome?: InputMaybe<IBooleanFilterInput>
  iconName?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  isPremium?: InputMaybe<IBooleanFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ITopicFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ITopicFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  slug?: InputMaybe<IStringFilterInput>
  solutions?: InputMaybe<ISolutionRequestFiltersInput>
  subTopics?: InputMaybe<ISubTopicFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ITopicInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  expert?: InputMaybe<Scalars['ID']['input']>
  featuredOnHome?: InputMaybe<Scalars['Boolean']['input']>
  iconName?: InputMaybe<Scalars['String']['input']>
  isPremium?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  solutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type ITopicRelationResponseCollection = {
  __typename?: 'TopicRelationResponseCollection'
  data: Array<ITopicEntity>
}

export type ITypeOfUser = {
  __typename?: 'TypeOfUser'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type ITypeOfUserEntity = {
  __typename?: 'TypeOfUserEntity'
  attributes?: Maybe<ITypeOfUser>
  id?: Maybe<Scalars['ID']['output']>
}

export type ITypeOfUserEntityResponse = {
  __typename?: 'TypeOfUserEntityResponse'
  data?: Maybe<ITypeOfUserEntity>
}

export type ITypeOfUserEntityResponseCollection = {
  __typename?: 'TypeOfUserEntityResponseCollection'
  data: Array<ITypeOfUserEntity>
  meta: IResponseCollectionMeta
}

export type ITypeOfUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ITypeOfUserFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<ITypeOfUserFiltersInput>
  or?: InputMaybe<Array<InputMaybe<ITypeOfUserFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type ITypeOfUserInput = {
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ITypeOfUserRelationResponseCollection = {
  __typename?: 'TypeOfUserRelationResponseCollection'
  data: Array<ITypeOfUserEntity>
}

export type IUploadFile = {
  __typename?: 'UploadFile'
  alternativeText?: Maybe<Scalars['String']['output']>
  caption?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  ext?: Maybe<Scalars['String']['output']>
  formats?: Maybe<Scalars['JSON']['output']>
  hash: Scalars['String']['output']
  height?: Maybe<Scalars['Int']['output']>
  mime: Scalars['String']['output']
  name: Scalars['String']['output']
  previewUrl?: Maybe<Scalars['String']['output']>
  provider: Scalars['String']['output']
  provider_metadata?: Maybe<Scalars['JSON']['output']>
  related?: Maybe<Array<Maybe<IGenericMorph>>>
  size: Scalars['Float']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  url: Scalars['String']['output']
  width?: Maybe<Scalars['Int']['output']>
}

export type IUploadFileEntity = {
  __typename?: 'UploadFileEntity'
  attributes?: Maybe<IUploadFile>
  id?: Maybe<Scalars['ID']['output']>
}

export type IUploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse'
  data?: Maybe<IUploadFileEntity>
}

export type IUploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection'
  data: Array<IUploadFileEntity>
  meta: IResponseCollectionMeta
}

export type IUploadFileFiltersInput = {
  alternativeText?: InputMaybe<IStringFilterInput>
  and?: InputMaybe<Array<InputMaybe<IUploadFileFiltersInput>>>
  caption?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  ext?: InputMaybe<IStringFilterInput>
  folder?: InputMaybe<IUploadFolderFiltersInput>
  folderPath?: InputMaybe<IStringFilterInput>
  formats?: InputMaybe<IJsonFilterInput>
  hash?: InputMaybe<IStringFilterInput>
  height?: InputMaybe<IIntFilterInput>
  id?: InputMaybe<IIdFilterInput>
  mime?: InputMaybe<IStringFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IUploadFileFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IUploadFileFiltersInput>>>
  previewUrl?: InputMaybe<IStringFilterInput>
  provider?: InputMaybe<IStringFilterInput>
  provider_metadata?: InputMaybe<IJsonFilterInput>
  size?: InputMaybe<IFloatFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  url?: InputMaybe<IStringFilterInput>
  width?: InputMaybe<IIntFilterInput>
}

export type IUploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>
  caption?: InputMaybe<Scalars['String']['input']>
  ext?: InputMaybe<Scalars['String']['input']>
  folder?: InputMaybe<Scalars['ID']['input']>
  folderPath?: InputMaybe<Scalars['String']['input']>
  formats?: InputMaybe<Scalars['JSON']['input']>
  hash?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Int']['input']>
  mime?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  previewUrl?: InputMaybe<Scalars['String']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Int']['input']>
}

export type IUploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection'
  data: Array<IUploadFileEntity>
}

export type IUploadFolder = {
  __typename?: 'UploadFolder'
  children?: Maybe<IUploadFolderRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  files?: Maybe<IUploadFileRelationResponseCollection>
  name: Scalars['String']['output']
  parent?: Maybe<IUploadFolderEntityResponse>
  path: Scalars['String']['output']
  pathId: Scalars['Int']['output']
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IUploadFolderChildrenArgs = {
  filters?: InputMaybe<IUploadFolderFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUploadFolderFilesArgs = {
  filters?: InputMaybe<IUploadFileFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUploadFolderEntity = {
  __typename?: 'UploadFolderEntity'
  attributes?: Maybe<IUploadFolder>
  id?: Maybe<Scalars['ID']['output']>
}

export type IUploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse'
  data?: Maybe<IUploadFolderEntity>
}

export type IUploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection'
  data: Array<IUploadFolderEntity>
  meta: IResponseCollectionMeta
}

export type IUploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IUploadFolderFiltersInput>>>
  children?: InputMaybe<IUploadFolderFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  files?: InputMaybe<IUploadFileFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IUploadFolderFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IUploadFolderFiltersInput>>>
  parent?: InputMaybe<IUploadFolderFiltersInput>
  path?: InputMaybe<IStringFilterInput>
  pathId?: InputMaybe<IIntFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IUploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<Scalars['ID']['input']>
  path?: InputMaybe<Scalars['String']['input']>
  pathId?: InputMaybe<Scalars['Int']['input']>
}

export type IUploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection'
  data: Array<IUploadFolderEntity>
}

export type IUsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload'
  ok: Scalars['Boolean']['output']
}

export type IUsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload'
  ok: Scalars['Boolean']['output']
}

export type IUsersPermissionsLoginInput = {
  identifier: Scalars['String']['input']
  password: Scalars['String']['input']
  provider?: Scalars['String']['input']
}

export type IUsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload'
  jwt?: Maybe<Scalars['String']['output']>
  user: IUsersPermissionsMe
}

export type IUsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe'
  blocked?: Maybe<Scalars['Boolean']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  confirmed?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  role?: Maybe<IUsersPermissionsMeRole>
  username: Scalars['String']['output']
}

export type IUsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole'
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  type?: Maybe<Scalars['String']['output']>
}

export type IUsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload'
  ok: Scalars['Boolean']['output']
}

export type IUsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission'
  action: Scalars['String']['output']
  createdAt?: Maybe<Scalars['DateTime']['output']>
  role?: Maybe<IUsersPermissionsRoleEntityResponse>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IUsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity'
  attributes?: Maybe<IUsersPermissionsPermission>
  id?: Maybe<Scalars['ID']['output']>
}

export type IUsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<IStringFilterInput>
  and?: InputMaybe<Array<InputMaybe<IUsersPermissionsPermissionFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  not?: InputMaybe<IUsersPermissionsPermissionFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IUsersPermissionsPermissionFiltersInput>>>
  role?: InputMaybe<IUsersPermissionsRoleFiltersInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IUsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection'
  data: Array<IUsersPermissionsPermissionEntity>
}

export type IUsersPermissionsRegisterInput = {
  email: Scalars['String']['input']
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

export type IUsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  description?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  permissions?: Maybe<IUsersPermissionsPermissionRelationResponseCollection>
  type?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  users?: Maybe<IUsersPermissionsUserRelationResponseCollection>
}

export type IUsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<IUsersPermissionsPermissionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<IUsersPermissionsUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity'
  attributes?: Maybe<IUsersPermissionsRole>
  id?: Maybe<Scalars['ID']['output']>
}

export type IUsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse'
  data?: Maybe<IUsersPermissionsRoleEntity>
}

export type IUsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection'
  data: Array<IUsersPermissionsRoleEntity>
  meta: IResponseCollectionMeta
}

export type IUsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IUsersPermissionsRoleFiltersInput>>>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  description?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IUsersPermissionsRoleFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IUsersPermissionsRoleFiltersInput>>>
  permissions?: InputMaybe<IUsersPermissionsPermissionFiltersInput>
  type?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  users?: InputMaybe<IUsersPermissionsUserFiltersInput>
}

export type IUsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  type?: InputMaybe<Scalars['String']['input']>
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type IUsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload'
  ok: Scalars['Boolean']['output']
}

export type IUsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser'
  blocked?: Maybe<Scalars['Boolean']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companySize?: Maybe<IEnum_Userspermissionsuser_Companysize>
  companyType?: Maybe<IEnum_Userspermissionsuser_Companytype>
  confirmed?: Maybe<Scalars['Boolean']['output']>
  country?: Maybe<IOfficeEntityResponse>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  external_id?: Maybe<Scalars['String']['output']>
  followingSolutions?: Maybe<ISolutionRelationResponseCollection>
  isPremium?: Maybe<Scalars['Boolean']['output']>
  isVendor?: Maybe<Scalars['Boolean']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  premiumOrganization?: Maybe<IPremiumOrganizationEntityResponse>
  provider?: Maybe<Scalars['String']['output']>
  resource_invitations_shared_by?: Maybe<IResourceInvitationRelationResponseCollection>
  resource_invitations_shared_to?: Maybe<IResourceInvitationRelationResponseCollection>
  reviews?: Maybe<IReviewRelationResponseCollection>
  role?: Maybe<IUsersPermissionsRoleEntityResponse>
  subscribeNewsletterCategories?: Maybe<INewsletterCategoryRelationResponseCollection>
  subscribed?: Maybe<Scalars['Boolean']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  username: Scalars['String']['output']
  vendor?: Maybe<IVendorEntityResponse>
}

export type IUsersPermissionsUserFollowingSolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsUserResource_Invitations_Shared_ByArgs = {
  filters?: InputMaybe<IResourceInvitationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsUserResource_Invitations_Shared_ToArgs = {
  filters?: InputMaybe<IResourceInvitationFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsUserReviewsArgs = {
  filters?: InputMaybe<IReviewFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsUserSubscribeNewsletterCategoriesArgs = {
  filters?: InputMaybe<INewsletterCategoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IUsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity'
  attributes?: Maybe<IUsersPermissionsUser>
  id?: Maybe<Scalars['ID']['output']>
}

export type IUsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse'
  data?: Maybe<IUsersPermissionsUserEntity>
}

export type IUsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection'
  data: Array<IUsersPermissionsUserEntity>
  meta: IResponseCollectionMeta
}

export type IUsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IUsersPermissionsUserFiltersInput>>>
  blocked?: InputMaybe<IBooleanFilterInput>
  companyName?: InputMaybe<IStringFilterInput>
  companySize?: InputMaybe<IStringFilterInput>
  companyType?: InputMaybe<IStringFilterInput>
  confirmationToken?: InputMaybe<IStringFilterInput>
  confirmed?: InputMaybe<IBooleanFilterInput>
  country?: InputMaybe<IOfficeFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  email?: InputMaybe<IStringFilterInput>
  external_id?: InputMaybe<IStringFilterInput>
  followingSolutions?: InputMaybe<ISolutionFiltersInput>
  id?: InputMaybe<IIdFilterInput>
  isPremium?: InputMaybe<IBooleanFilterInput>
  isVendor?: InputMaybe<IBooleanFilterInput>
  jobTitle?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IUsersPermissionsUserFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IUsersPermissionsUserFiltersInput>>>
  password?: InputMaybe<IStringFilterInput>
  premiumOrganization?: InputMaybe<IPremiumOrganizationFiltersInput>
  provider?: InputMaybe<IStringFilterInput>
  resetPasswordToken?: InputMaybe<IStringFilterInput>
  resource_invitations_shared_by?: InputMaybe<IResourceInvitationFiltersInput>
  resource_invitations_shared_to?: InputMaybe<IResourceInvitationFiltersInput>
  reviews?: InputMaybe<IReviewFiltersInput>
  role?: InputMaybe<IUsersPermissionsRoleFiltersInput>
  subscribeNewsletterCategories?: InputMaybe<INewsletterCategoryFiltersInput>
  subscribed?: InputMaybe<IBooleanFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  username?: InputMaybe<IStringFilterInput>
  vendor?: InputMaybe<IVendorFiltersInput>
}

export type IUsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companySize?: InputMaybe<IEnum_Userspermissionsuser_Companysize>
  companyType?: InputMaybe<IEnum_Userspermissionsuser_Companytype>
  confirmationToken?: InputMaybe<Scalars['String']['input']>
  confirmed?: InputMaybe<Scalars['Boolean']['input']>
  country?: InputMaybe<Scalars['ID']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  external_id?: InputMaybe<Scalars['String']['input']>
  followingSolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  isPremium?: InputMaybe<Scalars['Boolean']['input']>
  isVendor?: InputMaybe<Scalars['Boolean']['input']>
  jobTitle?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  premiumOrganization?: InputMaybe<Scalars['ID']['input']>
  provider?: InputMaybe<Scalars['String']['input']>
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>
  resource_invitations_shared_by?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  resource_invitations_shared_to?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  reviews?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  role?: InputMaybe<Scalars['ID']['input']>
  subscribeNewsletterCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  subscribed?: InputMaybe<Scalars['Boolean']['input']>
  userToken?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
  vendor?: InputMaybe<Scalars['ID']['input']>
}

export type IUsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection'
  data: Array<IUsersPermissionsUserEntity>
}

export type IVendor = {
  __typename?: 'Vendor'
  companySize?: Maybe<IEnum_Vendor_Companysize>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  invitations?: Maybe<Array<Maybe<IComponentInvitationsInvitations>>>
  name: Scalars['String']['output']
  newsletterCategories?: Maybe<INewsletterCategoryRelationResponseCollection>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  registrationContact: Array<Maybe<IComponentRegistrationContactRegistrationContact>>
  solutions?: Maybe<ISolutionRelationResponseCollection>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
  users?: Maybe<IUsersPermissionsUserRelationResponseCollection>
}

export type IVendorInvitationsArgs = {
  filters?: InputMaybe<IComponentInvitationsInvitationsFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IVendorNewsletterCategoriesArgs = {
  filters?: InputMaybe<INewsletterCategoryFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IVendorRegistrationContactArgs = {
  filters?: InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IVendorSolutionsArgs = {
  filters?: InputMaybe<ISolutionFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IVendorUsersArgs = {
  filters?: InputMaybe<IUsersPermissionsUserFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IVendorEntity = {
  __typename?: 'VendorEntity'
  attributes?: Maybe<IVendor>
  id?: Maybe<Scalars['ID']['output']>
}

export type IVendorEntityResponse = {
  __typename?: 'VendorEntityResponse'
  data?: Maybe<IVendorEntity>
}

export type IVendorEntityResponseCollection = {
  __typename?: 'VendorEntityResponseCollection'
  data: Array<IVendorEntity>
  meta: IResponseCollectionMeta
}

export type IVendorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IVendorFiltersInput>>>
  companySize?: InputMaybe<IStringFilterInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  email?: InputMaybe<IStringFilterInput>
  id?: InputMaybe<IIdFilterInput>
  invitations?: InputMaybe<IComponentInvitationsInvitationsFiltersInput>
  name?: InputMaybe<IStringFilterInput>
  newsletterCategories?: InputMaybe<INewsletterCategoryFiltersInput>
  not?: InputMaybe<IVendorFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IVendorFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  registrationContact?: InputMaybe<IComponentRegistrationContactRegistrationContactFiltersInput>
  solutions?: InputMaybe<ISolutionFiltersInput>
  status?: InputMaybe<IStringFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
  users?: InputMaybe<IUsersPermissionsUserFiltersInput>
}

export type IVendorInput = {
  companySize?: InputMaybe<IEnum_Vendor_Companysize>
  email?: InputMaybe<Scalars['String']['input']>
  invitations?: InputMaybe<Array<InputMaybe<IComponentInvitationsInvitationsInput>>>
  name?: InputMaybe<Scalars['String']['input']>
  newsletterCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  registrationContact?: InputMaybe<Array<InputMaybe<IComponentRegistrationContactRegistrationContactInput>>>
  solutions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  status?: InputMaybe<IEnum_Vendor_Status>
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type IVendorListingRequest = {
  audiences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  ceoEmail?: InputMaybe<Scalars['String']['input']>
  ceoName?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  competitor?: InputMaybe<Scalars['String']['input']>
  deployments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  email?: InputMaybe<Scalars['String']['input']>
  existingCustomers?: InputMaybe<Array<InputMaybe<ITaxonomy>>>
  hqs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  integrations?: InputMaybe<Array<InputMaybe<ITaxonomy>>>
  isCreate?: InputMaybe<Scalars['Boolean']['input']>
  isOpenJobPosition?: InputMaybe<Scalars['Boolean']['input']>
  lanuages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  logo?: InputMaybe<Scalars['Upload']['input']>
  longDescription?: InputMaybe<Scalars['String']['input']>
  marketEmail?: InputMaybe<Scalars['String']['input']>
  marketName?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  notes?: InputMaybe<Scalars['String']['input']>
  offices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  practiceAreas?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  productName?: InputMaybe<Scalars['String']['input']>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  subTopics?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  typeOfUsers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  website?: InputMaybe<Scalars['String']['input']>
  yearFounded?: InputMaybe<Scalars['Int']['input']>
}

export type IVendorTopic = {
  __typename?: 'VendorTopic'
  contents?: Maybe<IContentRelationResponseCollection>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type IVendorTopicContentsArgs = {
  filters?: InputMaybe<IContentFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type IVendorTopicEntity = {
  __typename?: 'VendorTopicEntity'
  attributes?: Maybe<IVendorTopic>
  id?: Maybe<Scalars['ID']['output']>
}

export type IVendorTopicEntityResponse = {
  __typename?: 'VendorTopicEntityResponse'
  data?: Maybe<IVendorTopicEntity>
}

export type IVendorTopicEntityResponseCollection = {
  __typename?: 'VendorTopicEntityResponseCollection'
  data: Array<IVendorTopicEntity>
  meta: IResponseCollectionMeta
}

export type IVendorTopicFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IVendorTopicFiltersInput>>>
  contents?: InputMaybe<IContentFiltersInput>
  createdAt?: InputMaybe<IDateTimeFilterInput>
  id?: InputMaybe<IIdFilterInput>
  name?: InputMaybe<IStringFilterInput>
  not?: InputMaybe<IVendorTopicFiltersInput>
  or?: InputMaybe<Array<InputMaybe<IVendorTopicFiltersInput>>>
  publishedAt?: InputMaybe<IDateTimeFilterInput>
  updatedAt?: InputMaybe<IDateTimeFilterInput>
}

export type IVendorTopicInput = {
  contents?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IVendorTopicRelationResponseCollection = {
  __typename?: 'VendorTopicRelationResponseCollection'
  data: Array<IVendorTopicEntity>
}

export type IDownloadMaterialFileEntity = {
  __typename?: 'downloadMaterialFileEntity'
  url?: Maybe<Scalars['String']['output']>
}

export type IInvitedUsersResult = {
  __typename?: 'invitedUsersResult'
  invitations?: Maybe<Array<Maybe<IResourceInvitationEntity>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type ISearchContentsTotalQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type ISearchContentsTotalQuery = {
  __typename?: 'Query'
  contentResults: {
    __typename?: 'ContentSearchReturnType'
    success?: boolean | null
    data?: { __typename?: 'ContentSearchResult'; total?: number | null } | null
  }
}

export type IAllAuthorsQueryVariables = Exact<{ [key: string]: never }>

export type IAllAuthorsQuery = {
  __typename?: 'Query'
  authors?: {
    __typename?: 'AuthorEntityResponseCollection'
    data: Array<{
      __typename?: 'AuthorEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Author'
        firstName: string
        lastName: string
        displayName?: string | null
        jobTitle?: string | null
        company?: string | null
        slug: string
        avatar?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IAllAuthorSlugsQueryVariables = Exact<{ [key: string]: never }>

export type IAllAuthorSlugsQuery = {
  __typename?: 'Query'
  authors?: {
    __typename?: 'AuthorEntityResponseCollection'
    data: Array<{
      __typename?: 'AuthorEntity'
      id?: string | null
      attributes?: { __typename?: 'Author'; slug: string } | null
    }>
  } | null
}

export type IAllBlogTaxonomySlugsQueryVariables = Exact<{ [key: string]: never }>

export type IAllBlogTaxonomySlugsQuery = {
  __typename?: 'Query'
  blogTaxonomys?: {
    __typename?: 'BlogTaxonomyEntityResponseCollection'
    data: Array<{
      __typename?: 'BlogTaxonomyEntity'
      id?: string | null
      attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null } | null
    }>
  } | null
}

export type IAllCollectionSlugQueryVariables = Exact<{ [key: string]: never }>

export type IAllCollectionSlugQuery = {
  __typename?: 'Query'
  collections?: {
    __typename?: 'CollectionEntityResponseCollection'
    data: Array<{
      __typename?: 'CollectionEntity'
      id?: string | null
      attributes?: { __typename?: 'Collection'; slug?: string | null } | null
    }>
  } | null
}

export type IAllContentSlugsQueryVariables = Exact<{
  start: Scalars['Int']['input']
}>

export type IAllContentSlugsQuery = {
  __typename?: 'Query'
  contents?: {
    __typename?: 'ContentEntityResponseCollection'
    data: Array<{
      __typename?: 'ContentEntity'
      id?: string | null
      attributes?: { __typename?: 'Content'; slug: string } | null
    }>
  } | null
}

export type IAllIndustryAnalysisQueryVariables = Exact<{
  start: Scalars['Int']['input']
}>

export type IAllIndustryAnalysisQuery = {
  __typename?: 'Query'
  contents?: {
    __typename?: 'ContentEntityResponseCollection'
    data: Array<{
      __typename?: 'ContentEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Content'
        title: string
        snippet: string
        createdAt?: any | null
        publishedTime?: any | null
        contentType?: IEnum_Content_Contenttype | null
        documentType?: IEnum_Content_Documenttype | null
        isMaintained?: boolean | null
        lastMaintained?: any | null
        slug: string
        defaultImage?: {
          __typename?: 'UploadFileRelationResponseCollection'
          data: Array<{
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          }>
        } | null
        blogTaxonomies?: {
          __typename?: 'BlogTaxonomyRelationResponseCollection'
          data: Array<{
            __typename?: 'BlogTaxonomyEntity'
            id?: string | null
            attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
          }>
        } | null
        audiences?: {
          __typename?: 'PremiumAudienceRelationResponseCollection'
          data: Array<{
            __typename?: 'PremiumAudienceEntity'
            id?: string | null
            attributes?: { __typename?: 'PremiumAudience'; name: string } | null
          }>
        } | null
        subjectMatters?: {
          __typename?: 'SubjectMatterRelationResponseCollection'
          data: Array<{
            __typename?: 'SubjectMatterEntity'
            id?: string | null
            attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
          }>
        } | null
        writer?: {
          __typename?: 'ComponentContentContentWriter'
          writerOption: IEnum_Componentcontentcontentwriter_Writeroption
          oneTimeAuthorName?: string | null
          author?: {
            __typename?: 'AuthorEntityResponse'
            data?: {
              __typename?: 'AuthorEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Author'
                firstName: string
                lastName: string
                jobTitle?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          expert?: {
            __typename?: 'ExpertEntityResponse'
            data?: {
              __typename?: 'ExpertEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Expert'
                firstName: string
                lastName: string
                displayName?: string | null
                title?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                type: IEnum_Solution_Type
                shortDescription?: string | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IAllIndustryAnalysisSlugsQueryVariables = Exact<{
  start: Scalars['Int']['input']
}>

export type IAllIndustryAnalysisSlugsQuery = {
  __typename?: 'Query'
  contents?: {
    __typename?: 'ContentEntityResponseCollection'
    data: Array<{
      __typename?: 'ContentEntity'
      id?: string | null
      attributes?: { __typename?: 'Content'; slug: string } | null
    }>
  } | null
}

export type IAllSolutionRequestsItemsFragment = { __typename?: 'SolutionRequestEntity'; id?: string | null }

export type IAllSolutionRequestsSlugsQueryVariables = Exact<{
  start: Scalars['Int']['input']
}>

export type IEmailReceiversQueryVariables = Exact<{
  filters?: InputMaybe<IEmailReceiverFiltersInput>
  pagination?: InputMaybe<IPaginationArg>
  publicationState?: InputMaybe<IPublicationState>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}>

export type IAllSolutionRequestsSlugsQuery = {
  __typename?: 'Query'
  solutionRequests?: {
    __typename?: 'SolutionRequestEntityResponseCollection'
    data: Array<{ __typename?: 'SolutionRequestEntity'; id?: string | null }>
  } | null
}

export type IEmailReceiversQuery = {
  emailReceivers: any
  __typename?: 'Query'
  emailReceiver?: {
    __typename?: 'EmailReceiverEntityResponseCollection'
    data: Array<{ __typename?: 'EmailReceiverEntity'; id?: string | null }>
  } | null
}

export type IAllSolutionSlugsQueryVariables = Exact<{
  type: Scalars['String']['input']
  start: Scalars['Int']['input']
}>

export type IAllSolutionSlugsQuery = {
  __typename?: 'Query'
  solutions?: {
    __typename?: 'SolutionEntityResponseCollection'
    data: Array<{
      __typename?: 'SolutionEntity'
      attributes?: { __typename?: 'Solution'; slug?: string | null } | null
    }>
  } | null
}

export type IAlspBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
  includeIlta: Scalars['Boolean']['input']
}>

export type IAlspBySlugQuery = {
  __typename?: 'Query'
  solutions?: {
    __typename?: 'SolutionEntityResponseCollection'
    data: Array<{
      __typename?: 'SolutionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Solution'
        displayName?: string | null
        type: IEnum_Solution_Type
        website?: string | null
        linkedin?: string | null
        twitter?: string | null
        isEnhanced?: boolean | null
        isPremium?: boolean | null
        slug?: string | null
        shortDescription?: string | null
        longDescription?: string | null
        rating?: number | null
        yearFounded?: number | null
        createdAt?: any | null
        publishedAt?: any | null
        alspData?: {
          __typename?: 'ComponentSolutionAlspData'
          name?: string | null
          serviceName?: string | null
          size?: number | null
          partners?: string | null
          security?: {
            __typename?: 'SecurityRelationResponseCollection'
            data: Array<{
              __typename?: 'SecurityEntity'
              id?: string | null
              attributes?: { __typename?: 'Security'; name: string } | null
            }>
          } | null
          audiences?: {
            __typename?: 'DemographicRelationResponseCollection'
            data: Array<{
              __typename?: 'DemographicEntity'
              attributes?: { __typename?: 'Demographic'; name: string } | null
            }>
          } | null
          enhancedListingData?: {
            __typename?: 'ComponentSolutionPropsAlspEnhanced'
            serviceDescription?: string | null
            technologyPartners?: string | null
            averageLengthOfEngagement?: number | null
            pricingData?: string | null
            callToAction?: {
              __typename?: 'ComponentGlobalCallToAction'
              label?: string | null
              url?: string | null
            } | null
            dedicatedResources?: Array<{
              __typename?: 'ComponentSolutionPropsDedicatedResource'
              id: string
              count?: number | null
              type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
            } | null> | null
            specialists?: Array<{
              __typename?: 'ComponentAboutPropsTeamMember'
              id: string
              name?: string | null
              role?: string | null
              bio?: string | null
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
            screenshots?: Array<{
              __typename?: 'ComponentGlobalPicture'
              id: string
              title?: string | null
              summary?: string | null
              image?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  id?: string | null
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
            videos?: Array<{
              __typename?: 'ComponentSolutionPropsVideo'
              id: string
              title?: string | null
              summary?: string | null
              url?: string | null
            } | null> | null
            testimonials?: Array<{
              __typename?: 'ComponentGlobalTestimonial'
              id: string
              description?: string | null
              name?: string | null
              role?: string | null
              company?: string | null
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
          } | null
        } | null
        founders?: Array<{
          __typename?: 'ComponentAboutPropsTeamMember'
          id: string
          name?: string | null
          role?: string | null
          bio?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              id?: string | null
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        vendor?: {
          __typename?: 'VendorEntityResponse'
          data?: {
            __typename?: 'VendorEntity'
            attributes?: {
              __typename?: 'Vendor'
              users?: {
                __typename?: 'UsersPermissionsUserRelationResponseCollection'
                data: Array<{
                  __typename?: 'UsersPermissionsUserEntity'
                  attributes?: { __typename?: 'UsersPermissionsUser'; external_id?: string | null } | null
                }>
              } | null
            } | null
          } | null
        } | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
          }>
        } | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              features?: {
                __typename?: 'FeatureRelationResponseCollection'
                data: Array<{
                  __typename?: 'FeatureEntity'
                  attributes?: { __typename?: 'Feature'; name?: string | null } | null
                }>
              } | null
            } | null
          }>
        } | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            id?: string | null
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        company?: {
          __typename?: 'CompanyEntityResponse'
          data?: {
            __typename?: 'CompanyEntity'
            attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
          } | null
        } | null
        reviews?: {
          __typename?: 'ReviewRelationResponseCollection'
          data: Array<{
            __typename?: 'ReviewEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Review'
              anonymous?: boolean | null
              rating?: number | null
              title?: string | null
              content?: string | null
              submitterTitle?: string | null
              submitterSubTitle?: string | null
              solutionDispName?: string | null
              createdAt?: any | null
              updatedAt?: any | null
              publishedAt?: any | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    rating?: number | null
                    company?: {
                      __typename?: 'CompanyEntityResponse'
                      data?: {
                        __typename?: 'CompanyEntity'
                        attributes?: { __typename?: 'Company'; slug: string } | null
                      } | null
                    } | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
        offices?: {
          __typename?: 'OfficeRelationResponseCollection'
          data: Array<{
            __typename?: 'OfficeEntity'
            id?: string | null
            attributes?: { __typename?: 'Office'; name: string } | null
          }>
        } | null
        hqs?: {
          __typename?: 'HqRelationResponseCollection'
          data: Array<{
            __typename?: 'HqEntity'
            id?: string | null
            attributes?: { __typename?: 'Hq'; name: string } | null
          }>
        } | null
        regionsServed?: {
          __typename?: 'RegionsServedRelationResponseCollection'
          data: Array<{
            __typename?: 'RegionsServedEntity'
            attributes?: { __typename?: 'RegionsServed'; name: string } | null
          }>
        } | null
        languages?: {
          __typename?: 'PlatformLanguageRelationResponseCollection'
          data: Array<{
            __typename?: 'PlatformLanguageEntity'
            id?: string | null
            attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
          }>
        } | null
        articles?: Array<{
          __typename?: 'ComponentGlobalArticle'
          id: string
          title?: string | null
          publishedBy?: string | null
          date?: any | null
          link?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        vendorResources?: Array<{
          __typename?: 'ComponentSolutionPropsVendorResource'
          id: string
          type?: IEnum_Componentsolutionpropsvendorresource_Type | null
          content?: {
            __typename?: 'ContentEntityResponse'
            data?: {
              __typename?: 'ContentEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Content'
                title: string
                snippet: string
                createdAt?: any | null
                publishedTime?: any | null
                contentType?: IEnum_Content_Contenttype | null
                documentType?: IEnum_Content_Documenttype | null
                isMaintained?: boolean | null
                lastMaintained?: any | null
                slug: string
                defaultImage?: {
                  __typename?: 'UploadFileRelationResponseCollection'
                  data: Array<{
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  }>
                } | null
                blogTaxonomies?: {
                  __typename?: 'BlogTaxonomyRelationResponseCollection'
                  data: Array<{
                    __typename?: 'BlogTaxonomyEntity'
                    id?: string | null
                    attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
                  }>
                } | null
                audiences?: {
                  __typename?: 'PremiumAudienceRelationResponseCollection'
                  data: Array<{
                    __typename?: 'PremiumAudienceEntity'
                    id?: string | null
                    attributes?: { __typename?: 'PremiumAudience'; name: string } | null
                  }>
                } | null
                subjectMatters?: {
                  __typename?: 'SubjectMatterRelationResponseCollection'
                  data: Array<{
                    __typename?: 'SubjectMatterEntity'
                    id?: string | null
                    attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
                  }>
                } | null
                writer?: {
                  __typename?: 'ComponentContentContentWriter'
                  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
                  oneTimeAuthorName?: string | null
                  author?: {
                    __typename?: 'AuthorEntityResponse'
                    data?: {
                      __typename?: 'AuthorEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Author'
                        firstName: string
                        lastName: string
                        jobTitle?: string | null
                        company?: string | null
                        bio?: string | null
                        slug: string
                        avatar?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                  expert?: {
                    __typename?: 'ExpertEntityResponse'
                    data?: {
                      __typename?: 'ExpertEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Expert'
                        firstName: string
                        lastName: string
                        displayName?: string | null
                        title?: string | null
                        company?: string | null
                        bio?: string | null
                        slug: string
                        avatar?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                  solution?: {
                    __typename?: 'SolutionEntityResponse'
                    data?: {
                      __typename?: 'SolutionEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Solution'
                        displayName?: string | null
                        slug?: string | null
                        type: IEnum_Solution_Type
                        shortDescription?: string | null
                        logo?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          external?: {
            __typename?: 'ComponentSolutionPropsVendorResourceExternal'
            id: string
            title?: string | null
            summary?: string | null
            link?: string | null
            publishedDate?: any | null
            author?: string | null
            documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
          } | null
        } | null> | null
        features?: {
          __typename?: 'FeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'FeatureEntity'
            attributes?: { __typename?: 'Feature'; name?: string | null } | null
          }>
        } | null
        existingCustomers?: {
          __typename?: 'ExistingCustomerRelationResponseCollection'
          data: Array<{
            __typename?: 'ExistingCustomerEntity'
            id?: string | null
            attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
          }>
        } | null
        iltaSponsorships?: {
          __typename?: 'IltaSponsorshipRelationResponseCollection'
          data: Array<{
            __typename?: 'IltaSponsorshipEntity'
            attributes?: {
              __typename?: 'IltaSponsorship'
              name?: string | null
              logo?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          }>
        } | null
        announcement?: {
          __typename?: 'AnnouncementEntityResponse'
          data?: {
            __typename?: 'AnnouncementEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Announcement'
              announcement: string
              optionalLink?: string | null
              publishedAt?: any | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IAuthorBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IAuthorBySlugQuery = {
  __typename?: 'Query'
  authors?: {
    __typename?: 'AuthorEntityResponseCollection'
    data: Array<{
      __typename?: 'AuthorEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Author'
        firstName: string
        lastName: string
        jobTitle?: string | null
        company?: string | null
        bio?: string | null
        linkedin?: string | null
        twitter?: string | null
        slug: string
        avatar?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        subjectMatters?: {
          __typename?: 'SubjectMatterRelationResponseCollection'
          data: Array<{
            __typename?: 'SubjectMatterEntity'
            id?: string | null
            attributes?: { __typename?: 'SubjectMatter'; name?: string | null; slug: string } | null
          }>
        } | null
        themes?: {
          __typename?: 'ThemeRelationResponseCollection'
          data: Array<{
            __typename?: 'ThemeEntity'
            id?: string | null
            attributes?: { __typename?: 'Theme'; name?: string | null; slug: string } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IAutosuggestQueryVariables = Exact<{
  keyword: Scalars['String']['input']
}>

export type IAutosuggestQuery = {
  __typename?: 'Query'
  autosuggest: {
    __typename?: 'AutosuggestData'
    success: boolean
    solutions: Array<{
      __typename?: 'Suggest'
      keyword: string
      model: ISuggestModel
      id: number
      logo?: string | null
      slug: string
    }>
    events: Array<{
      __typename?: 'Suggest'
      keyword: string
      model: ISuggestModel
      id: number
      logo?: string | null
      slug: string
    }>
    categories: Array<{
      __typename?: 'Suggest'
      keyword: string
      model: ISuggestModel
      id: number
      logo?: string | null
      slug: string
    }>
    companies: Array<{
      __typename?: 'Suggest'
      keyword: string
      model: ISuggestModel
      id: number
      logo?: string | null
      slug: string
    }>
    contents: Array<{
      __typename?: 'Suggest'
      keyword: string
      model: ISuggestModel
      id: number
      logo?: string | null
      slug: string
    }>
  }
}

export type IBlogTaxonomiesQueryVariables = Exact<{ [key: string]: never }>

export type IBlogTaxonomiesQuery = {
  __typename?: 'Query'
  blogTaxonomys?: {
    __typename?: 'BlogTaxonomyEntityResponseCollection'
    data: Array<{
      __typename?: 'BlogTaxonomyEntity'
      id?: string | null
      attributes?: {
        __typename?: 'BlogTaxonomy'
        name?: string | null
        slug?: string | null
        description?: string | null
      } | null
    }>
  } | null
}

export type IBlogTaxonomyBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IBlogTaxonomyBySlugQuery = {
  __typename?: 'Query'
  blogTaxonomys?: {
    __typename?: 'BlogTaxonomyEntityResponseCollection'
    data: Array<{
      __typename?: 'BlogTaxonomyEntity'
      id?: string | null
      attributes?: {
        __typename?: 'BlogTaxonomy'
        showVendorTopics?: boolean | null
        name?: string | null
        slug?: string | null
        description?: string | null
        publishedAt?: any | null
        latestContent?: {
          __typename?: 'ContentEntityResponse'
          data?: {
            __typename?: 'ContentEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Content'
              title: string
              snippet: string
              createdAt?: any | null
              publishedTime?: any | null
              contentType?: IEnum_Content_Contenttype | null
              documentType?: IEnum_Content_Documenttype | null
              isMaintained?: boolean | null
              lastMaintained?: any | null
              slug: string
              defaultImage?: {
                __typename?: 'UploadFileRelationResponseCollection'
                data: Array<{
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                }>
              } | null
              blogTaxonomies?: {
                __typename?: 'BlogTaxonomyRelationResponseCollection'
                data: Array<{
                  __typename?: 'BlogTaxonomyEntity'
                  id?: string | null
                  attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
                }>
              } | null
              audiences?: {
                __typename?: 'PremiumAudienceRelationResponseCollection'
                data: Array<{
                  __typename?: 'PremiumAudienceEntity'
                  id?: string | null
                  attributes?: { __typename?: 'PremiumAudience'; name: string } | null
                }>
              } | null
              subjectMatters?: {
                __typename?: 'SubjectMatterRelationResponseCollection'
                data: Array<{
                  __typename?: 'SubjectMatterEntity'
                  id?: string | null
                  attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
                }>
              } | null
              writer?: {
                __typename?: 'ComponentContentContentWriter'
                writerOption: IEnum_Componentcontentcontentwriter_Writeroption
                oneTimeAuthorName?: string | null
                author?: {
                  __typename?: 'AuthorEntityResponse'
                  data?: {
                    __typename?: 'AuthorEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Author'
                      firstName: string
                      lastName: string
                      jobTitle?: string | null
                      company?: string | null
                      bio?: string | null
                      slug: string
                      avatar?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
                expert?: {
                  __typename?: 'ExpertEntityResponse'
                  data?: {
                    __typename?: 'ExpertEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Expert'
                      firstName: string
                      lastName: string
                      displayName?: string | null
                      title?: string | null
                      company?: string | null
                      bio?: string | null
                      slug: string
                      avatar?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
                solution?: {
                  __typename?: 'SolutionEntityResponse'
                  data?: {
                    __typename?: 'SolutionEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Solution'
                      displayName?: string | null
                      slug?: string | null
                      type: IEnum_Solution_Type
                      shortDescription?: string | null
                      logo?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type ICarouselSolutionsBySubTopicSlugQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type ICarouselSolutionsBySubTopicSlugQuery = {
  __typename?: 'Query'
  searchSolutions: {
    __typename?: 'SearchSolutionsResult'
    total?: number | null
    solutions?: Array<{
      __typename?: 'SolutionItem'
      id: string
      slug: string
      displayName: string
      logo?: string | null
      subTopics: Array<string>
      isEnhanced?: boolean | null
      isPremium?: boolean | null
    } | null> | null
  }
}

export type IChangeMyPasswordMutationVariables = Exact<{
  input: IChangePasswordRequest
}>

export type IChangeMyPasswordMutation = {
  __typename?: 'Mutation'
  changeMyPassword?: {
    __typename?: 'ChangePasswordPayload'
    jwt?: string | null
    user: { __typename?: 'UsersPermissionsMe'; id: string; username: string; email?: string | null }
  } | null
}

export type IConsultancyBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
  includeIlta: Scalars['Boolean']['input']
}>

export type IConsultancyBySlugQuery = {
  __typename?: 'Query'
  solutions?: {
    __typename?: 'SolutionEntityResponseCollection'
    data: Array<{
      __typename?: 'SolutionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Solution'
        displayName?: string | null
        type: IEnum_Solution_Type
        website?: string | null
        linkedin?: string | null
        twitter?: string | null
        isEnhanced?: boolean | null
        isPremium?: boolean | null
        slug?: string | null
        shortDescription?: string | null
        longDescription?: string | null
        rating?: number | null
        yearFounded?: number | null
        createdAt?: any | null
        publishedAt?: any | null
        consultantData?: {
          __typename?: 'ComponentSolutionConsultantData'
          name?: string | null
          serviceName?: string | null
          size?: number | null
          partners?: string | null
          security?: {
            __typename?: 'SecurityRelationResponseCollection'
            data: Array<{
              __typename?: 'SecurityEntity'
              id?: string | null
              attributes?: { __typename?: 'Security'; name: string } | null
            }>
          } | null
          audiences?: {
            __typename?: 'DemographicRelationResponseCollection'
            data: Array<{
              __typename?: 'DemographicEntity'
              attributes?: { __typename?: 'Demographic'; name: string } | null
            }>
          } | null
          enhancedListingData?: {
            __typename?: 'ComponentSolutionPropsConsultantEnhanced'
            serviceDescription?: string | null
            technologyPartners?: string | null
            averageLengthOfEngagement?: number | null
            valueProposition?: string | null
            pricingData?: string | null
            callToAction?: {
              __typename?: 'ComponentGlobalCallToAction'
              label?: string | null
              url?: string | null
            } | null
            dedicatedResources?: Array<{
              __typename?: 'ComponentSolutionPropsDedicatedResource'
              id: string
              count?: number | null
              type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
            } | null> | null
            caseStudies?: Array<{
              __typename?: 'ComponentGlobalSuccessStory'
              id: string
              name?: string | null
              link?: string | null
            } | null> | null
            specialists?: Array<{
              __typename?: 'ComponentAboutPropsTeamMember'
              id: string
              name?: string | null
              role?: string | null
              bio?: string | null
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
            screenshots?: Array<{
              __typename?: 'ComponentGlobalPicture'
              id: string
              title?: string | null
              summary?: string | null
              image?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  id?: string | null
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
            videos?: Array<{
              __typename?: 'ComponentSolutionPropsVideo'
              id: string
              title?: string | null
              summary?: string | null
              url?: string | null
            } | null> | null
            testimonials?: Array<{
              __typename?: 'ComponentGlobalTestimonial'
              id: string
              description?: string | null
              name?: string | null
              role?: string | null
              company?: string | null
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
          } | null
        } | null
        founders?: Array<{
          __typename?: 'ComponentAboutPropsTeamMember'
          id: string
          name?: string | null
          role?: string | null
          bio?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              id?: string | null
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        vendor?: {
          __typename?: 'VendorEntityResponse'
          data?: {
            __typename?: 'VendorEntity'
            attributes?: {
              __typename?: 'Vendor'
              users?: {
                __typename?: 'UsersPermissionsUserRelationResponseCollection'
                data: Array<{
                  __typename?: 'UsersPermissionsUserEntity'
                  attributes?: { __typename?: 'UsersPermissionsUser'; external_id?: string | null } | null
                }>
              } | null
            } | null
          } | null
        } | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
          }>
        } | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              features?: {
                __typename?: 'FeatureRelationResponseCollection'
                data: Array<{
                  __typename?: 'FeatureEntity'
                  attributes?: { __typename?: 'Feature'; name?: string | null } | null
                }>
              } | null
            } | null
          }>
        } | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            id?: string | null
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        company?: {
          __typename?: 'CompanyEntityResponse'
          data?: {
            __typename?: 'CompanyEntity'
            attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
          } | null
        } | null
        reviews?: {
          __typename?: 'ReviewRelationResponseCollection'
          data: Array<{
            __typename?: 'ReviewEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Review'
              anonymous?: boolean | null
              rating?: number | null
              title?: string | null
              content?: string | null
              submitterTitle?: string | null
              submitterSubTitle?: string | null
              solutionDispName?: string | null
              createdAt?: any | null
              updatedAt?: any | null
              publishedAt?: any | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    rating?: number | null
                    company?: {
                      __typename?: 'CompanyEntityResponse'
                      data?: {
                        __typename?: 'CompanyEntity'
                        attributes?: { __typename?: 'Company'; slug: string } | null
                      } | null
                    } | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
        offices?: {
          __typename?: 'OfficeRelationResponseCollection'
          data: Array<{
            __typename?: 'OfficeEntity'
            id?: string | null
            attributes?: { __typename?: 'Office'; name: string } | null
          }>
        } | null
        hqs?: {
          __typename?: 'HqRelationResponseCollection'
          data: Array<{
            __typename?: 'HqEntity'
            id?: string | null
            attributes?: { __typename?: 'Hq'; name: string } | null
          }>
        } | null
        regionsServed?: {
          __typename?: 'RegionsServedRelationResponseCollection'
          data: Array<{
            __typename?: 'RegionsServedEntity'
            attributes?: { __typename?: 'RegionsServed'; name: string } | null
          }>
        } | null
        languages?: {
          __typename?: 'PlatformLanguageRelationResponseCollection'
          data: Array<{
            __typename?: 'PlatformLanguageEntity'
            id?: string | null
            attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
          }>
        } | null
        articles?: Array<{
          __typename?: 'ComponentGlobalArticle'
          id: string
          title?: string | null
          publishedBy?: string | null
          date?: any | null
          link?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        vendorResources?: Array<{
          __typename?: 'ComponentSolutionPropsVendorResource'
          id: string
          type?: IEnum_Componentsolutionpropsvendorresource_Type | null
          content?: {
            __typename?: 'ContentEntityResponse'
            data?: {
              __typename?: 'ContentEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Content'
                title: string
                snippet: string
                createdAt?: any | null
                publishedTime?: any | null
                contentType?: IEnum_Content_Contenttype | null
                documentType?: IEnum_Content_Documenttype | null
                isMaintained?: boolean | null
                lastMaintained?: any | null
                slug: string
                defaultImage?: {
                  __typename?: 'UploadFileRelationResponseCollection'
                  data: Array<{
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  }>
                } | null
                blogTaxonomies?: {
                  __typename?: 'BlogTaxonomyRelationResponseCollection'
                  data: Array<{
                    __typename?: 'BlogTaxonomyEntity'
                    id?: string | null
                    attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
                  }>
                } | null
                audiences?: {
                  __typename?: 'PremiumAudienceRelationResponseCollection'
                  data: Array<{
                    __typename?: 'PremiumAudienceEntity'
                    id?: string | null
                    attributes?: { __typename?: 'PremiumAudience'; name: string } | null
                  }>
                } | null
                subjectMatters?: {
                  __typename?: 'SubjectMatterRelationResponseCollection'
                  data: Array<{
                    __typename?: 'SubjectMatterEntity'
                    id?: string | null
                    attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
                  }>
                } | null
                writer?: {
                  __typename?: 'ComponentContentContentWriter'
                  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
                  oneTimeAuthorName?: string | null
                  author?: {
                    __typename?: 'AuthorEntityResponse'
                    data?: {
                      __typename?: 'AuthorEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Author'
                        firstName: string
                        lastName: string
                        jobTitle?: string | null
                        company?: string | null
                        bio?: string | null
                        slug: string
                        avatar?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                  expert?: {
                    __typename?: 'ExpertEntityResponse'
                    data?: {
                      __typename?: 'ExpertEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Expert'
                        firstName: string
                        lastName: string
                        displayName?: string | null
                        title?: string | null
                        company?: string | null
                        bio?: string | null
                        slug: string
                        avatar?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                  solution?: {
                    __typename?: 'SolutionEntityResponse'
                    data?: {
                      __typename?: 'SolutionEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Solution'
                        displayName?: string | null
                        slug?: string | null
                        type: IEnum_Solution_Type
                        shortDescription?: string | null
                        logo?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          external?: {
            __typename?: 'ComponentSolutionPropsVendorResourceExternal'
            id: string
            title?: string | null
            summary?: string | null
            link?: string | null
            publishedDate?: any | null
            author?: string | null
            documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
          } | null
        } | null> | null
        features?: {
          __typename?: 'FeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'FeatureEntity'
            attributes?: { __typename?: 'Feature'; name?: string | null } | null
          }>
        } | null
        existingCustomers?: {
          __typename?: 'ExistingCustomerRelationResponseCollection'
          data: Array<{
            __typename?: 'ExistingCustomerEntity'
            id?: string | null
            attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
          }>
        } | null
        iltaSponsorships?: {
          __typename?: 'IltaSponsorshipRelationResponseCollection'
          data: Array<{
            __typename?: 'IltaSponsorshipEntity'
            attributes?: {
              __typename?: 'IltaSponsorship'
              name?: string | null
              logo?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          }>
        } | null
        announcement?: {
          __typename?: 'AnnouncementEntityResponse'
          data?: {
            __typename?: 'AnnouncementEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Announcement'
              announcement: string
              optionalLink?: string | null
              publishedAt?: any | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IContentBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IContentBySlugQuery = {
  __typename?: 'Query'
  contentBySlug: {
    __typename: 'ContentEntityBySlug'
    isInvited?: boolean | null
    resourcePublishedAt?: string | any | Date | null
    vendorSelfContent?: boolean | null
    content: {
      __typename: 'ContentEntity'
      id?: string | null
      attributes?: {
        __typename: 'Content'
        title: string
        publishedAt?: any | null
        createdAt?: any | null
        publishedTime?: any | null
        lastMaintained?: any | null
        isMaintained?: boolean | null
        contentType?: IEnum_Content_Contenttype | null
        slug: string
        snippet: string
        isPremium?: boolean | null
        isLatestBlog?: boolean | null
        defaultImage?: {
          __typename: 'UploadFileRelationResponseCollection'
          data: Array<{ __typename: 'UploadFileEntity'; attributes?: { __typename: 'UploadFile'; url: string } | null }>
        } | null
        blogTaxonomies?: {
          __typename: 'BlogTaxonomyRelationResponseCollection'
          data: Array<{
            __typename: 'BlogTaxonomyEntity'
            id?: string | null
            attributes?: { __typename: 'BlogTaxonomy'; name?: string | null; slug?: string | null } | null
          }>
        } | null
        subjectMatters?: {
          __typename: 'SubjectMatterRelationResponseCollection'
          data: Array<{
            __typename: 'SubjectMatterEntity'
            id?: string | null
            attributes?: { __typename: 'SubjectMatter'; name?: string | null; slug: string } | null
          }>
        } | null
        downloadMaterial?: {
          __typename: 'ComponentContentDownloadMaterial'
          title?: string | null
          isEnabled?: boolean | null
          format?: IEnum_Componentcontentdownloadmaterial_Format | null
          lastUpdated?: any | null
          downloadButtonName?: string | null
          files?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; name: string } | null
            } | null
          } | null
        } | null
        writer?: {
          __typename: 'ComponentContentContentWriter'
          writerOption: IEnum_Componentcontentcontentwriter_Writeroption
          oneTimeAuthorName?: string | null
          author?: {
            __typename?: 'AuthorEntityResponse'
            data?: {
              __typename?: 'AuthorEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Author'
                firstName: string
                lastName: string
                jobTitle?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          expert?: {
            __typename?: 'ExpertEntityResponse'
            data?: {
              __typename?: 'ExpertEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Expert'
                firstName: string
                lastName: string
                displayName?: string | null
                title?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                type: IEnum_Solution_Type
                shortDescription?: string | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    }
  }
}

export type IContentVisitDownloadsQueryVariables = Exact<{ [key: string]: never }>

export type IContentVisitDownloadsQuery = {
  __typename?: 'Query'
  contentVisitDownloads: Array<{
    __typename?: 'SharedResourceEntity'
    id?: string | null
    title?: string | null
    slug?: string | null
    sharedResources: {
      __typename?: 'ContentVisitDownloadCountResult'
      visits?: Array<{
        __typename?: 'ContentVisit'
        visited_by?: {
          __typename?: 'UsersPermissionsUserEntityResponse'
          data?: {
            __typename?: 'UsersPermissionsUserEntity'
            id?: string | null
            attributes?: { __typename?: 'UsersPermissionsUser'; username: string } | null
          } | null
        } | null
      } | null> | null
      downloads?: Array<{
        __typename?: 'ContentDownload'
        downloaded_by?: {
          __typename?: 'UsersPermissionsUserEntityResponse'
          data?: {
            __typename?: 'UsersPermissionsUserEntity'
            id?: string | null
            attributes?: { __typename?: 'UsersPermissionsUser'; username: string } | null
          } | null
        } | null
      } | null> | null
    }
  }>
}

export type ICreateAnnouncementMutationVariables = Exact<{
  data: IAnnouncementInput
}>

export type ICreateAnnouncementMutation = {
  __typename?: 'Mutation'
  createAnnouncement?: {
    __typename?: 'AnnouncementEntityResponse'
    data?: {
      __typename?: 'AnnouncementEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Announcement'
        announcement: string
        optionalLink?: string | null
        createdAt?: any | null
      } | null
    } | null
  } | null
}

export type ICreateListingAlspMutationVariables = Exact<{
  data: IListingRequestData
}>

export type ICreateListingAlspMutation = {
  __typename?: 'Mutation'
  createListingRequestData?: {
    __typename?: 'ListingRequest'
    alspReq?: {
      __typename?: 'ComponentRequestListingReq'
      id: string
      listingName?: string | null
      userName?: string | null
      userRole?: string | null
      userEmail?: string | null
      phone?: string | null
      marketName?: string | null
      marketEmail?: string | null
      ceoName?: string | null
      ceoEmail?: string | null
      shortDescription?: string | null
      longDescription?: string | null
    } | null
    enhancedAlspReq?: {
      __typename?: 'ComponentRequestEnhancedListReq'
      founders?: Array<{ __typename?: 'ComponentAboutPropsTeamMember'; id: string } | null> | null
      specialists?: Array<{ __typename?: 'ComponentAboutPropsTeamMember'; id: string } | null> | null
      screenShots?: Array<{ __typename?: 'ComponentGlobalPicture'; id: string } | null> | null
      testimonials?: Array<{ __typename?: 'ComponentGlobalTestimonial'; id: string } | null> | null
    } | null
  } | null
}

export type ICreateListingConsultantMutationVariables = Exact<{
  data: IListingRequestData
}>

export type ICreateListingConsultantMutation = {
  __typename?: 'Mutation'
  createListingRequestData?: {
    __typename?: 'ListingRequest'
    consultantReq?: {
      __typename?: 'ComponentRequestListingReq'
      id: string
      listingName?: string | null
      userName?: string | null
      userRole?: string | null
      userEmail?: string | null
      phone?: string | null
      marketName?: string | null
      marketEmail?: string | null
      ceoName?: string | null
      ceoEmail?: string | null
      shortDescription?: string | null
      longDescription?: string | null
    } | null
    enhancedConsultantReq?: {
      __typename?: 'ComponentRequestEnhancedListReq'
      founders?: Array<{ __typename?: 'ComponentAboutPropsTeamMember'; id: string } | null> | null
      specialists?: Array<{ __typename?: 'ComponentAboutPropsTeamMember'; id: string } | null> | null
      screenShots?: Array<{ __typename?: 'ComponentGlobalPicture'; id: string } | null> | null
      testimonials?: Array<{ __typename?: 'ComponentGlobalTestimonial'; id: string } | null> | null
    } | null
  } | null
}

export type ICreateListingProductMutationVariables = Exact<{
  data: IListingRequestData
}>

export type ICreateListingProductMutation = {
  __typename?: 'Mutation'
  createListingRequestData?: {
    __typename?: 'ListingRequest'
    vendorReq?: {
      __typename?: 'ComponentRequestVendorReq'
      id: string
      companyName?: string | null
      email?: string | null
      name?: string | null
      ceoEmail?: string | null
      ceoName?: string | null
      marketEmail?: string | null
      marketName?: string | null
      website?: string | null
      productName?: string | null
      shortDescription?: string | null
      longDescription?: string | null
      isCreate?: boolean | null
      notes?: string | null
    } | null
    enhancedVendorReq?: {
      __typename?: 'ComponentRequestEnhancedVendorReq'
      screenShots?: Array<{ __typename?: 'ComponentGlobalPicture'; id: string } | null> | null
      testimonials?: Array<{ __typename?: 'ComponentGlobalTestimonial'; id: string } | null> | null
    } | null
  } | null
}

export type ICreateSolutionRequestMutationVariables = Exact<{
  data: ISolutionRequestInput
}>

export type ICreateSolutionRequestMutation = {
  __typename?: 'Mutation'
  createSolutionRequest?: {
    __typename?: 'SolutionRequestEntityResponse'
    data?: { __typename?: 'SolutionRequestEntity'; id?: string | null } | null
  } | null
}

export type ICreateUploadFileMutationVariables = Exact<{
  data: IUploadFileInput
}>

export type ICreateUploadFileMutation = {
  __typename?: 'Mutation'
  createUploadFile?: {
    __typename?: 'UploadFileEntityResponse'
    data?: {
      __typename?: 'UploadFileEntity'
      id?: string | null
      attributes?: { __typename?: 'UploadFile'; name: string; url: string; size: number } | null
    } | null
  } | null
}

export type IDownloadContentMutationVariables = Exact<{
  content_id: Scalars['ID']['input']
}>

export type IDownloadContentMutation = { __typename?: 'Mutation'; downloadContent?: boolean | null }

export type IDownloadMaterialFilesQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type IDownloadMaterialFilesQuery = {
  __typename?: 'Query'
  downloadMaterialFile: { __typename?: 'downloadMaterialFileEntity'; url?: string | null }
}

export type IEventFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type IEventFeaturesQuery = {
  __typename?: 'Query'
  eventFeatures?: {
    __typename?: 'EventFeatureEntityResponseCollection'
    data: Array<{
      __typename?: 'EventFeatureEntity'
      id?: string | null
      attributes?: { __typename?: 'EventFeature'; name: string } | null
    }>
  } | null
}

export type IExpertBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IExpertBySlugQuery = {
  __typename?: 'Query'
  experts?: {
    __typename?: 'ExpertEntityResponseCollection'
    data: Array<{
      __typename?: 'ExpertEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Expert'
        firstName: string
        lastName: string
        title?: string | null
        bio?: string | null
        linkedin?: string | null
        twitter?: string | null
        slug: string
        experience?: string | null
        avatar?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        expertiseSubTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            attributes?: {
              __typename?: 'SubTopic'
              name: string
              slug: string
              topics?: {
                __typename?: 'TopicRelationResponseCollection'
                data: Array<{
                  __typename?: 'TopicEntity'
                  id?: string | null
                  attributes?: { __typename?: 'Topic'; name: string; description?: string | null; slug: string } | null
                }>
              } | null
            } | null
          }>
        } | null
        expertiseTopics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            attributes?: { __typename?: 'Topic'; name: string; slug: string } | null
          }>
        } | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{ __typename?: 'ContentEntity'; id?: string | null }>
        } | null
      } | null
    }>
  } | null
}

export type IExpertsQueryVariables = Exact<{ [key: string]: never }>

export type IExpertsQuery = {
  __typename?: 'Query'
  experts?: {
    __typename?: 'ExpertEntityResponseCollection'
    data: Array<{
      __typename?: 'ExpertEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Expert'
        firstName: string
        lastName: string
        displayName?: string | null
        title?: string | null
        company?: string | null
        bio?: string | null
        slug: string
        avatar?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IFeaturedContentsBySubTopicSlugQueryVariables = Exact<{
  contentType: IEnum_Content_Contenttype
  subTopicSlug: Scalars['String']['input']
  start: Scalars['Int']['input']
  limit: Scalars['Int']['input']
}>

export type IFeaturedContentsBySubTopicSlugQuery = {
  __typename?: 'Query'
  featuredContents: Array<{
    __typename?: 'ContentEntity'
    id?: string | null
    attributes?: {
      __typename?: 'Content'
      title: string
      snippet: string
      createdAt?: any | null
      publishedTime?: any | null
      contentType?: IEnum_Content_Contenttype | null
      documentType?: IEnum_Content_Documenttype | null
      isMaintained?: boolean | null
      lastMaintained?: any | null
      slug: string
      defaultImage?: {
        __typename?: 'UploadFileRelationResponseCollection'
        data: Array<{ __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null }>
      } | null
      blogTaxonomies?: {
        __typename?: 'BlogTaxonomyRelationResponseCollection'
        data: Array<{
          __typename?: 'BlogTaxonomyEntity'
          id?: string | null
          attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
        }>
      } | null
      audiences?: {
        __typename?: 'PremiumAudienceRelationResponseCollection'
        data: Array<{
          __typename?: 'PremiumAudienceEntity'
          id?: string | null
          attributes?: { __typename?: 'PremiumAudience'; name: string } | null
        }>
      } | null
      subjectMatters?: {
        __typename?: 'SubjectMatterRelationResponseCollection'
        data: Array<{
          __typename?: 'SubjectMatterEntity'
          id?: string | null
          attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
        }>
      } | null
      writer?: {
        __typename?: 'ComponentContentContentWriter'
        writerOption: IEnum_Componentcontentcontentwriter_Writeroption
        oneTimeAuthorName?: string | null
        author?: {
          __typename?: 'AuthorEntityResponse'
          data?: {
            __typename?: 'AuthorEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Author'
              firstName: string
              lastName: string
              jobTitle?: string | null
              company?: string | null
              bio?: string | null
              slug: string
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
        expert?: {
          __typename?: 'ExpertEntityResponse'
          data?: {
            __typename?: 'ExpertEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Expert'
              firstName: string
              lastName: string
              displayName?: string | null
              title?: string | null
              company?: string | null
              bio?: string | null
              slug: string
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
        solution?: {
          __typename?: 'SolutionEntityResponse'
          data?: {
            __typename?: 'SolutionEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Solution'
              displayName?: string | null
              slug?: string | null
              type: IEnum_Solution_Type
              shortDescription?: string | null
              logo?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  }>
}

export type IFollowSolutionMutationVariables = Exact<{
  id: Scalars['Int']['input']
  follow?: InputMaybe<Scalars['Boolean']['input']>
}>

export type IFollowSolutionMutation = { __typename?: 'Mutation'; followSolution?: boolean | null }

export type IAllContentsSlugItemFragment = {
  __typename?: 'ContentEntity'
  id?: string | null
  attributes?: { __typename?: 'Content'; slug: string } | null
}

export type IAllFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type IAllFeaturesQuery = {
  __typename?: 'Query'
  features?: {
    __typename?: 'FeatureEntityResponseCollection'
    data: Array<{ __typename?: 'FeatureEntity'; attributes?: { __typename?: 'Feature'; name?: string | null } | null }>
  } | null
}

export type IAllSolutionsSlugItemFragment = {
  __typename?: 'SolutionEntity'
  attributes?: { __typename?: 'Solution'; slug?: string | null } | null
}

export type IAuthorCardDataFragment = {
  __typename?: 'AuthorEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Author'
    firstName: string
    lastName: string
    jobTitle?: string | null
    company?: string | null
    bio?: string | null
    slug: string
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type IAuthorFullItemFragment = {
  __typename?: 'AuthorEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Author'
    firstName: string
    lastName: string
    jobTitle?: string | null
    company?: string | null
    bio?: string | null
    linkedin?: string | null
    twitter?: string | null
    slug: string
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
    subjectMatters?: {
      __typename?: 'SubjectMatterRelationResponseCollection'
      data: Array<{
        __typename?: 'SubjectMatterEntity'
        id?: string | null
        attributes?: { __typename?: 'SubjectMatter'; name?: string | null; slug: string } | null
      }>
    } | null
    themes?: {
      __typename?: 'ThemeRelationResponseCollection'
      data: Array<{
        __typename?: 'ThemeEntity'
        id?: string | null
        attributes?: { __typename?: 'Theme'; name?: string | null; slug: string } | null
      }>
    } | null
  } | null
}

export type IAuthorItemFragment = {
  __typename?: 'AuthorEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Author'
    firstName: string
    lastName: string
    displayName?: string | null
    jobTitle?: string | null
    company?: string | null
    slug: string
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type IAutosuggestItemFragment = {
  __typename?: 'Suggest'
  keyword: string
  model: ISuggestModel
  id: number
  logo?: string | null
  slug: string
}

export type IBlogTaxonomyDataFragment = {
  __typename?: 'BlogTaxonomyEntity'
  id?: string | null
  attributes?: {
    __typename?: 'BlogTaxonomy'
    name?: string | null
    slug?: string | null
    description?: string | null
  } | null
}

export type IBlogTaxonomyFullDataFragment = {
  __typename?: 'BlogTaxonomyEntity'
  id?: string | null
  attributes?: {
    __typename?: 'BlogTaxonomy'
    name?: string | null
    slug?: string | null
    description?: string | null
    publishedAt?: any | null
    latestContent?: {
      __typename?: 'ContentEntityResponse'
      data?: {
        __typename?: 'ContentEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Content'
          title: string
          snippet: string
          createdAt?: any | null
          publishedTime?: any | null
          contentType?: IEnum_Content_Contenttype | null
          documentType?: IEnum_Content_Documenttype | null
          isMaintained?: boolean | null
          lastMaintained?: any | null
          slug: string
          defaultImage?: {
            __typename?: 'UploadFileRelationResponseCollection'
            data: Array<{
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            }>
          } | null
          blogTaxonomies?: {
            __typename?: 'BlogTaxonomyRelationResponseCollection'
            data: Array<{
              __typename?: 'BlogTaxonomyEntity'
              id?: string | null
              attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
            }>
          } | null
          audiences?: {
            __typename?: 'PremiumAudienceRelationResponseCollection'
            data: Array<{
              __typename?: 'PremiumAudienceEntity'
              id?: string | null
              attributes?: { __typename?: 'PremiumAudience'; name: string } | null
            }>
          } | null
          subjectMatters?: {
            __typename?: 'SubjectMatterRelationResponseCollection'
            data: Array<{
              __typename?: 'SubjectMatterEntity'
              id?: string | null
              attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
            }>
          } | null
          writer?: {
            __typename?: 'ComponentContentContentWriter'
            writerOption: IEnum_Componentcontentcontentwriter_Writeroption
            oneTimeAuthorName?: string | null
            author?: {
              __typename?: 'AuthorEntityResponse'
              data?: {
                __typename?: 'AuthorEntity'
                id?: string | null
                attributes?: {
                  __typename?: 'Author'
                  firstName: string
                  lastName: string
                  jobTitle?: string | null
                  company?: string | null
                  bio?: string | null
                  slug: string
                  avatar?: {
                    __typename?: 'UploadFileEntityResponse'
                    data?: {
                      __typename?: 'UploadFileEntity'
                      attributes?: { __typename?: 'UploadFile'; url: string } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
            expert?: {
              __typename?: 'ExpertEntityResponse'
              data?: {
                __typename?: 'ExpertEntity'
                id?: string | null
                attributes?: {
                  __typename?: 'Expert'
                  firstName: string
                  lastName: string
                  displayName?: string | null
                  title?: string | null
                  company?: string | null
                  bio?: string | null
                  slug: string
                  avatar?: {
                    __typename?: 'UploadFileEntityResponse'
                    data?: {
                      __typename?: 'UploadFileEntity'
                      attributes?: { __typename?: 'UploadFile'; url: string } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
            solution?: {
              __typename?: 'SolutionEntityResponse'
              data?: {
                __typename?: 'SolutionEntity'
                id?: string | null
                attributes?: {
                  __typename?: 'Solution'
                  displayName?: string | null
                  slug?: string | null
                  type: IEnum_Solution_Type
                  shortDescription?: string | null
                  logo?: {
                    __typename?: 'UploadFileEntityResponse'
                    data?: {
                      __typename?: 'UploadFileEntity'
                      attributes?: { __typename?: 'UploadFile'; url: string } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type ICompanyDataFragment = {
  __typename?: 'CompanyEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Company'
    slug: string
    name?: string | null
    description?: string | null
    website?: string | null
    services?: string | null
    yearFounded?: any | null
    size?: number | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
    solutions?: {
      __typename?: 'SolutionRelationResponseCollection'
      data: Array<{
        __typename?: 'SolutionEntity'
        attributes?: { __typename?: 'Solution'; slug?: string | null } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{ __typename?: 'HqEntity'; attributes?: { __typename?: 'Hq'; name: string } | null }>
    } | null
    iltaSponsorships?: {
      __typename?: 'IltaSponsorshipRelationResponseCollection'
      data: Array<{
        __typename?: 'IltaSponsorshipEntity'
        attributes?: {
          __typename?: 'IltaSponsorship'
          name?: string | null
          logo?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      }>
    } | null
  } | null
}

export type IContentDataFragment = {
  __typename: 'ContentEntity'
  id?: string | null
  attributes?: {
    __typename: 'Content'
    title: string
    publishedAt?: any | null
    createdAt?: any | null
    publishedTime?: any | null
    lastMaintained?: any | null
    isMaintained?: boolean | null
    contentType?: IEnum_Content_Contenttype | null
    slug: string
    snippet: string
    isPremium?: boolean | null
    isLatestBlog?: boolean | null
    defaultImage?: {
      __typename: 'UploadFileRelationResponseCollection'
      data: Array<{ __typename: 'UploadFileEntity'; attributes?: { __typename: 'UploadFile'; url: string } | null }>
    } | null
    blogTaxonomies?: {
      __typename: 'BlogTaxonomyRelationResponseCollection'
      data: Array<{
        __typename: 'BlogTaxonomyEntity'
        id?: string | null
        attributes?: { __typename: 'BlogTaxonomy'; name?: string | null; slug?: string | null } | null
      }>
    } | null
    subjectMatters?: {
      __typename: 'SubjectMatterRelationResponseCollection'
      data: Array<{
        __typename: 'SubjectMatterEntity'
        id?: string | null
        attributes?: { __typename: 'SubjectMatter'; name?: string | null; slug: string } | null
      }>
    } | null
    downloadMaterial?: {
      __typename: 'ComponentContentDownloadMaterial'
      title?: string | null
      isEnabled?: boolean | null
      format?: IEnum_Componentcontentdownloadmaterial_Format | null
      lastUpdated?: any | null
      downloadButtonName?: string | null
      files?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; name: string } | null
        } | null
      } | null
    } | null
    writer?: {
      __typename: 'ComponentContentContentWriter'
      writerOption: IEnum_Componentcontentcontentwriter_Writeroption
      oneTimeAuthorName?: string | null
      author?: {
        __typename?: 'AuthorEntityResponse'
        data?: {
          __typename?: 'AuthorEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Author'
            firstName: string
            lastName: string
            jobTitle?: string | null
            company?: string | null
            bio?: string | null
            slug: string
            avatar?: {
              __typename?: 'UploadFileEntityResponse'
              data?: {
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      expert?: {
        __typename?: 'ExpertEntityResponse'
        data?: {
          __typename?: 'ExpertEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Expert'
            firstName: string
            lastName: string
            displayName?: string | null
            title?: string | null
            company?: string | null
            bio?: string | null
            slug: string
            avatar?: {
              __typename?: 'UploadFileEntityResponse'
              data?: {
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      solution?: {
        __typename?: 'SolutionEntityResponse'
        data?: {
          __typename?: 'SolutionEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Solution'
            displayName?: string | null
            slug?: string | null
            type: IEnum_Solution_Type
            shortDescription?: string | null
            logo?: {
              __typename?: 'UploadFileEntityResponse'
              data?: {
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type IContentDataDownloadMaterialFragment = {
  __typename?: 'ComponentContentDownloadMaterial'
  title?: string | null
  isEnabled?: boolean | null
  format?: IEnum_Componentcontentdownloadmaterial_Format | null
  lastUpdated?: any | null
  downloadButtonName?: string | null
  files?: {
    __typename?: 'UploadFileEntityResponse'
    data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; name: string } | null } | null
  } | null
}

export type IContentItemFragment = {
  __typename?: 'ContentItem'
  id: number
  slug: string
  contentType?: IEnum_Content_Contenttype | null
  documentType?: IEnum_Content_Documenttype | null
  title: string
  snippet: string
  defaultImage?: string | null
  publishedTime: any
  isMaintained?: boolean | null
  lastMaintainedAt?: any | null
  writer?: {
    __typename?: 'ContentItemWriter'
    writerOption: IEnum_Componentcontentcontentwriter_Writeroption
    name: string
    avatar?: string | null
    title?: string | null
    url?: string | null
  } | null
}

export type IContentItemWriterFragment = {
  __typename?: 'ContentItemWriter'
  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
  name: string
  avatar?: string | null
  title?: string | null
  url?: string | null
}

export type IContentListItemFragment = {
  __typename?: 'ContentEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Content'
    title: string
    snippet: string
    createdAt?: any | null
    publishedTime?: any | null
    contentType?: IEnum_Content_Contenttype | null
    documentType?: IEnum_Content_Documenttype | null
    isMaintained?: boolean | null
    lastMaintained?: any | null
    slug: string
    defaultImage?: {
      __typename?: 'UploadFileRelationResponseCollection'
      data: Array<{ __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null }>
    } | null
    blogTaxonomies?: {
      __typename?: 'BlogTaxonomyRelationResponseCollection'
      data: Array<{
        __typename?: 'BlogTaxonomyEntity'
        id?: string | null
        attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
      }>
    } | null
    audiences?: {
      __typename?: 'PremiumAudienceRelationResponseCollection'
      data: Array<{
        __typename?: 'PremiumAudienceEntity'
        id?: string | null
        attributes?: { __typename?: 'PremiumAudience'; name: string } | null
      }>
    } | null
    subjectMatters?: {
      __typename?: 'SubjectMatterRelationResponseCollection'
      data: Array<{
        __typename?: 'SubjectMatterEntity'
        id?: string | null
        attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
      }>
    } | null
    writer?: {
      __typename?: 'ComponentContentContentWriter'
      writerOption: IEnum_Componentcontentcontentwriter_Writeroption
      oneTimeAuthorName?: string | null
      author?: {
        __typename?: 'AuthorEntityResponse'
        data?: {
          __typename?: 'AuthorEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Author'
            firstName: string
            lastName: string
            jobTitle?: string | null
            company?: string | null
            bio?: string | null
            slug: string
            avatar?: {
              __typename?: 'UploadFileEntityResponse'
              data?: {
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      expert?: {
        __typename?: 'ExpertEntityResponse'
        data?: {
          __typename?: 'ExpertEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Expert'
            firstName: string
            lastName: string
            displayName?: string | null
            title?: string | null
            company?: string | null
            bio?: string | null
            slug: string
            avatar?: {
              __typename?: 'UploadFileEntityResponse'
              data?: {
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      solution?: {
        __typename?: 'SolutionEntityResponse'
        data?: {
          __typename?: 'SolutionEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Solution'
            displayName?: string | null
            slug?: string | null
            type: IEnum_Solution_Type
            shortDescription?: string | null
            logo?: {
              __typename?: 'UploadFileEntityResponse'
              data?: {
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type IContentWriterFragment = {
  __typename?: 'ComponentContentContentWriter'
  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
  oneTimeAuthorName?: string | null
  author?: {
    __typename?: 'AuthorEntityResponse'
    data?: {
      __typename?: 'AuthorEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Author'
        firstName: string
        lastName: string
        jobTitle?: string | null
        company?: string | null
        bio?: string | null
        slug: string
        avatar?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
      } | null
    } | null
  } | null
  expert?: {
    __typename?: 'ExpertEntityResponse'
    data?: {
      __typename?: 'ExpertEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Expert'
        firstName: string
        lastName: string
        displayName?: string | null
        title?: string | null
        company?: string | null
        bio?: string | null
        slug: string
        avatar?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
      } | null
    } | null
  } | null
  solution?: {
    __typename?: 'SolutionEntityResponse'
    data?: {
      __typename?: 'SolutionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Solution'
        displayName?: string | null
        slug?: string | null
        type: IEnum_Solution_Type
        shortDescription?: string | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type IExpertCardDataFragment = {
  __typename?: 'ExpertEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Expert'
    firstName: string
    lastName: string
    displayName?: string | null
    title?: string | null
    company?: string | null
    bio?: string | null
    slug: string
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type IExpertFullItemFragment = {
  __typename?: 'ExpertEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Expert'
    firstName: string
    lastName: string
    title?: string | null
    bio?: string | null
    linkedin?: string | null
    twitter?: string | null
    slug: string
    experience?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
    expertiseSubTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        attributes?: {
          __typename?: 'SubTopic'
          name: string
          slug: string
          topics?: {
            __typename?: 'TopicRelationResponseCollection'
            data: Array<{
              __typename?: 'TopicEntity'
              id?: string | null
              attributes?: { __typename?: 'Topic'; name: string; description?: string | null; slug: string } | null
            }>
          } | null
        } | null
      }>
    } | null
    expertiseTopics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        attributes?: { __typename?: 'Topic'; name: string; slug: string } | null
      }>
    } | null
    contents?: {
      __typename?: 'ContentRelationResponseCollection'
      data: Array<{ __typename?: 'ContentEntity'; id?: string | null }>
    } | null
  } | null
}

export type IIltaSponsorshipItemFragment = {
  __typename?: 'IltaSponsorshipEntity'
  id?: string | null
  attributes?: {
    __typename?: 'IltaSponsorship'
    name?: string | null
    level?: number | null
    description?: string | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type IPhaseItemFragment = {
  __typename?: 'PhaseEntity'
  id?: string | null
  attributes?: { __typename?: 'Phase'; title: string; description?: string | null } | null
}

export type IPremiumAudienceItemFragment = {
  __typename?: 'PremiumAudienceEntity'
  id?: string | null
  attributes?: { __typename?: 'PremiumAudience'; name: string; shouldShowOnHomepage?: boolean | null } | null
}

export type IResourceInvitationEntityFragment = {
  __typename?: 'ResourceInvitationEntity'
  name: string
  businessEmail: string
  companyLawFirmName: string
  status: boolean
  dateInvited: any
  contents: Array<{
    __typename?: 'SharedResourceEntity'
    id?: string | null
    title?: string | null
    slug?: string | null
    sharedResources: {
      __typename?: 'ContentVisitDownloadCountResult'
      visits?: Array<{
        __typename?: 'ContentVisit'
        visited_by?: {
          __typename?: 'UsersPermissionsUserEntityResponse'
          data?: {
            __typename?: 'UsersPermissionsUserEntity'
            id?: string | null
            attributes?: { __typename?: 'UsersPermissionsUser'; username: string } | null
          } | null
        } | null
      } | null> | null
      downloads?: Array<{
        __typename?: 'ContentDownload'
        downloaded_by?: {
          __typename?: 'UsersPermissionsUserEntityResponse'
          data?: {
            __typename?: 'UsersPermissionsUserEntity'
            id?: string | null
            attributes?: { __typename?: 'UsersPermissionsUser'; username: string } | null
          } | null
        } | null
      } | null> | null
    }
  } | null>
}

export type ISimilarSolutionItemFragment = {
  __typename?: 'SimilarSolutionItem'
  type?: IEnum_Solution_Item_Type | null
  slug?: string | null
  displayName?: string | null
  logo?: string | null
  rating?: number | null
  reviewCnt?: number | null
}

export type ISolutionCarouselItemFragment = {
  __typename?: 'SolutionItem'
  id: string
  slug: string
  displayName: string
  logo?: string | null
  subTopics: Array<string>
  isEnhanced?: boolean | null
  isPremium?: boolean | null
}

export type ISolutionAlspDataFragment = {
  __typename?: 'ComponentSolutionAlspData'
  name?: string | null
  serviceName?: string | null
  size?: number | null
  partners?: string | null
  security?: {
    __typename?: 'SecurityRelationResponseCollection'
    data: Array<{
      __typename?: 'SecurityEntity'
      id?: string | null
      attributes?: { __typename?: 'Security'; name: string } | null
    }>
  } | null
  audiences?: {
    __typename?: 'DemographicRelationResponseCollection'
    data: Array<{ __typename?: 'DemographicEntity'; attributes?: { __typename?: 'Demographic'; name: string } | null }>
  } | null
  enhancedListingData?: {
    __typename?: 'ComponentSolutionPropsAlspEnhanced'
    serviceDescription?: string | null
    technologyPartners?: string | null
    averageLengthOfEngagement?: number | null
    valueProposition?: string | null
    pricingData?: string | null
    callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
    dedicatedResources?: Array<{
      __typename?: 'ComponentSolutionPropsDedicatedResource'
      id: string
      count?: number | null
      type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
    } | null> | null
    founders?: Array<{
      __typename?: 'ComponentAboutPropsTeamMember'
      id: string
      name?: string | null
      role?: string | null
      bio?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          id?: string | null
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    specialists?: Array<{
      __typename?: 'ComponentAboutPropsTeamMember'
      id: string
      name?: string | null
      role?: string | null
      bio?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    screenshots?: Array<{
      __typename?: 'ComponentGlobalPicture'
      id: string
      title?: string | null
      summary?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          id?: string | null
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    videos?: Array<{
      __typename?: 'ComponentSolutionPropsVideo'
      id: string
      title?: string | null
      summary?: string | null
      url?: string | null
    } | null> | null
    testimonials?: Array<{
      __typename?: 'ComponentGlobalTestimonial'
      id: string
      description?: string | null
      name?: string | null
      role?: string | null
      company?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type IAlspSolutionDataFragment = {
  __typename?: 'SolutionEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Solution'
    displayName?: string | null
    type: IEnum_Solution_Type
    website?: string | null
    linkedin?: string | null
    twitter?: string | null
    isEnhanced?: boolean | null
    isPremium?: boolean | null
    slug?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    rating?: number | null
    yearFounded?: number | null
    createdAt?: any | null
    publishedAt?: any | null
    alspData?: {
      __typename?: 'ComponentSolutionAlspData'
      name?: string | null
      serviceName?: string | null
      size?: number | null
      partners?: string | null
      fundingInfo?: IEnum_Componentsolutionproductdata_Fundinginfo | null
      fundingAmount?: IEnum_Componentsolutionproductdata_Fundingamount | null
      security?: {
        __typename?: 'SecurityRelationResponseCollection'
        data: Array<{
          __typename?: 'SecurityEntity'
          id?: string | null
          attributes?: { __typename?: 'Security'; name: string } | null
        }>
      } | null
      audiences?: {
        __typename?: 'DemographicRelationResponseCollection'
        data: Array<{
          __typename?: 'DemographicEntity'
          attributes?: { __typename?: 'Demographic'; name: string } | null
        }>
      } | null
      enhancedListingData?: {
        __typename?: 'ComponentSolutionPropsAlspEnhanced'
        serviceDescription?: string | null
        technologyPartners?: string | null
        averageLengthOfEngagement?: number | null
        valueProposition?: string | null
        pricingData?: string | null
        callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
        dedicatedResources?: Array<{
          __typename?: 'ComponentSolutionPropsDedicatedResource'
          id: string
          count?: number | null
          type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
        } | null> | null
        specialists?: Array<{
          __typename?: 'ComponentAboutPropsTeamMember'
          id: string
          name?: string | null
          role?: string | null
          bio?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        screenshots?: Array<{
          __typename?: 'ComponentGlobalPicture'
          id: string
          title?: string | null
          summary?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              id?: string | null
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        videos?: Array<{
          __typename?: 'ComponentSolutionPropsVideo'
          id: string
          title?: string | null
          summary?: string | null
          url?: string | null
        } | null> | null
        testimonials?: Array<{
          __typename?: 'ComponentGlobalTestimonial'
          id: string
          description?: string | null
          name?: string | null
          role?: string | null
          company?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        caseStudies?: Array<{
          __typename?: 'ComponentGlobalSuccessStory'
          id: string
          name?: string | null
          link?: string | null
        } | null> | null
      } | null
    } | null
    founders?: Array<{
      __typename?: 'ComponentAboutPropsTeamMember'
      id: string
      name?: string | null
      role?: string | null
      bio?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          id?: string | null
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    vendor?: {
      __typename?: 'VendorEntityResponse'
      data?: {
        __typename?: 'VendorEntity'
        attributes?: {
          __typename?: 'Vendor'
          users?: {
            __typename?: 'UsersPermissionsUserRelationResponseCollection'
            data: Array<{
              __typename?: 'UsersPermissionsUserEntity'
              attributes?: { __typename?: 'UsersPermissionsUser'; external_id?: string | null } | null
            }>
          } | null
        } | null
      } | null
    } | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
      }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          features?: {
            __typename?: 'FeatureRelationResponseCollection'
            data: Array<{
              __typename?: 'FeatureEntity'
              attributes?: { __typename?: 'Feature'; name?: string | null } | null
            }>
          } | null
        } | null
      }>
    } | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
    company?: {
      __typename?: 'CompanyEntityResponse'
      data?: {
        __typename?: 'CompanyEntity'
        attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
      } | null
    } | null
    reviews?: {
      __typename?: 'ReviewRelationResponseCollection'
      data: Array<{
        __typename?: 'ReviewEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Review'
          anonymous?: boolean | null
          rating?: number | null
          title?: string | null
          content?: string | null
          submitterTitle?: string | null
          submitterSubTitle?: string | null
          solutionDispName?: string | null
          createdAt?: any | null
          updatedAt?: any | null
          publishedAt?: any | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                rating?: number | null
                company?: {
                  __typename?: 'CompanyEntityResponse'
                  data?: {
                    __typename?: 'CompanyEntity'
                    attributes?: { __typename?: 'Company'; slug: string } | null
                  } | null
                } | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
    offices?: {
      __typename?: 'OfficeRelationResponseCollection'
      data: Array<{
        __typename?: 'OfficeEntity'
        id?: string | null
        attributes?: { __typename?: 'Office'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{
        __typename?: 'HqEntity'
        id?: string | null
        attributes?: { __typename?: 'Hq'; name: string } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    languages?: {
      __typename?: 'PlatformLanguageRelationResponseCollection'
      data: Array<{
        __typename?: 'PlatformLanguageEntity'
        id?: string | null
        attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
      }>
    } | null
    articles?: Array<{
      __typename?: 'ComponentGlobalArticle'
      id: string
      title?: string | null
      publishedBy?: string | null
      date?: any | null
      link?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    vendorResources?: Array<{
      __typename?: 'ComponentSolutionPropsVendorResource'
      id: string
      type?: IEnum_Componentsolutionpropsvendorresource_Type | null
      content?: {
        __typename?: 'ContentEntityResponse'
        data?: {
          __typename?: 'ContentEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Content'
            title: string
            snippet: string
            createdAt?: any | null
            publishedTime?: any | null
            contentType?: IEnum_Content_Contenttype | null
            documentType?: IEnum_Content_Documenttype | null
            isMaintained?: boolean | null
            lastMaintained?: any | null
            slug: string
            defaultImage?: {
              __typename?: 'UploadFileRelationResponseCollection'
              data: Array<{
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              }>
            } | null
            blogTaxonomies?: {
              __typename?: 'BlogTaxonomyRelationResponseCollection'
              data: Array<{
                __typename?: 'BlogTaxonomyEntity'
                id?: string | null
                attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
              }>
            } | null
            audiences?: {
              __typename?: 'PremiumAudienceRelationResponseCollection'
              data: Array<{
                __typename?: 'PremiumAudienceEntity'
                id?: string | null
                attributes?: { __typename?: 'PremiumAudience'; name: string } | null
              }>
            } | null
            subjectMatters?: {
              __typename?: 'SubjectMatterRelationResponseCollection'
              data: Array<{
                __typename?: 'SubjectMatterEntity'
                id?: string | null
                attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
              }>
            } | null
            writer?: {
              __typename?: 'ComponentContentContentWriter'
              writerOption: IEnum_Componentcontentcontentwriter_Writeroption
              oneTimeAuthorName?: string | null
              author?: {
                __typename?: 'AuthorEntityResponse'
                data?: {
                  __typename?: 'AuthorEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Author'
                    firstName: string
                    lastName: string
                    jobTitle?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              expert?: {
                __typename?: 'ExpertEntityResponse'
                data?: {
                  __typename?: 'ExpertEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Expert'
                    firstName: string
                    lastName: string
                    displayName?: string | null
                    title?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    type: IEnum_Solution_Type
                    shortDescription?: string | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      external?: {
        __typename?: 'ComponentSolutionPropsVendorResourceExternal'
        id: string
        title?: string | null
        summary?: string | null
        link?: string | null
        publishedDate?: any | null
        author?: string | null
        documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
      } | null
    } | null> | null
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
    existingCustomers?: {
      __typename?: 'ExistingCustomerRelationResponseCollection'
      data: Array<{
        __typename?: 'ExistingCustomerEntity'
        id?: string | null
        attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
      }>
    } | null
    iltaSponsorships?: {
      __typename?: 'IltaSponsorshipRelationResponseCollection'
      data: Array<{
        __typename?: 'IltaSponsorshipEntity'
        attributes?: {
          __typename?: 'IltaSponsorship'
          name?: string | null
          logo?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      }>
    } | null
    announcement?: {
      __typename?: 'AnnouncementEntityResponse'
      data?: {
        __typename?: 'AnnouncementEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Announcement'
          announcement: string
          optionalLink?: string | null
          publishedAt?: any | null
        } | null
      } | null
    } | null
  } | null
}

export type IAlspSolutionEnhancedListingDataFragment = {
  __typename?: 'ComponentSolutionPropsAlspEnhanced'
  serviceDescription?: string | null
  technologyPartners?: string | null
  averageLengthOfEngagement?: number | null
  valueProposition?: string | null
  pricingData?: string | null
  callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
  dedicatedResources?: Array<{
    __typename?: 'ComponentSolutionPropsDedicatedResource'
    id: string
    count?: number | null
    type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
  } | null> | null
  founders?: Array<{
    __typename?: 'ComponentAboutPropsTeamMember'
    id: string
    name?: string | null
    role?: string | null
    bio?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { id?: string | null; __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null> | null
  specialists?: Array<{
    __typename?: 'ComponentAboutPropsTeamMember'
    id: string
    name?: string | null
    role?: string | null
    bio?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null> | null
  screenshots?: Array<{
    __typename?: 'ComponentGlobalPicture'
    id: string
    title?: string | null
    summary?: string | null
    image?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
  } | null> | null
  videos?: Array<{
    __typename?: 'ComponentSolutionPropsVideo'
    id: string
    title?: string | null
    summary?: string | null
    url?: string | null
  } | null> | null
  testimonials?: Array<{
    __typename?: 'ComponentGlobalTestimonial'
    id: string
    description?: string | null
    name?: string | null
    role?: string | null
    company?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null> | null
}

export type ICompanyIltaSponsorshipFragment = {
  __typename?: 'IltaSponsorshipEntity'
  attributes?: {
    __typename?: 'IltaSponsorship'
    name?: string | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type ISolutionConsultancyDataFragment = {
  __typename?: 'ComponentSolutionConsultantData'
  name?: string | null
  serviceName?: string | null
  size?: number | null
  partners?: string | null
  security?: {
    __typename?: 'SecurityRelationResponseCollection'
    data: Array<{
      __typename?: 'SecurityEntity'
      id?: string | null
      attributes?: { __typename?: 'Security'; name: string } | null
    }>
  } | null
  audiences?: {
    __typename?: 'DemographicRelationResponseCollection'
    data: Array<{ __typename?: 'DemographicEntity'; attributes?: { __typename?: 'Demographic'; name: string } | null }>
  } | null
  enhancedListingData?: {
    __typename?: 'ComponentSolutionPropsConsultantEnhanced'
    serviceDescription?: string | null
    technologyPartners?: string | null
    averageLengthOfEngagement?: number | null
    valueProposition?: string | null
    pricingData?: string | null
    callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
    dedicatedResources?: Array<{
      __typename?: 'ComponentSolutionPropsDedicatedResource'
      id: string
      count?: number | null
      type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
    } | null> | null
    caseStudies?: Array<{
      __typename?: 'ComponentGlobalSuccessStory'
      id: string
      name?: string | null
      link?: string | null
    } | null> | null
    specialists?: Array<{
      __typename?: 'ComponentAboutPropsTeamMember'
      id: string
      name?: string | null
      role?: string | null
      bio?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    screenshots?: Array<{
      __typename?: 'ComponentGlobalPicture'
      id: string
      title?: string | null
      summary?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          id?: string | null
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    videos?: Array<{
      __typename?: 'ComponentSolutionPropsVideo'
      id: string
      title?: string | null
      summary?: string | null
      url?: string | null
    } | null> | null
    testimonials?: Array<{
      __typename?: 'ComponentGlobalTestimonial'
      id: string
      description?: string | null
      name?: string | null
      role?: string | null
      company?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type IConsultancySolutionDataFragment = {
  __typename?: 'SolutionEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Solution'
    displayName?: string | null
    type: IEnum_Solution_Type
    website?: string | null
    linkedin?: string | null
    twitter?: string | null
    isEnhanced?: boolean | null
    isPremium?: boolean | null
    slug?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    rating?: number | null
    yearFounded?: number | null
    createdAt?: any | null
    publishedAt?: any | null
    consultantData?: {
      __typename?: 'ComponentSolutionConsultantData'
      name?: string | null
      serviceName?: string | null
      size?: number | null
      partners?: string | null
      fundingInfo?: IEnum_Componentsolutionproductdata_Fundinginfo | null
      fundingAmount?: IEnum_Componentsolutionproductdata_Fundingamount | null
      security?: {
        __typename?: 'SecurityRelationResponseCollection'
        data: Array<{
          __typename?: 'SecurityEntity'
          id?: string | null
          attributes?: { __typename?: 'Security'; name: string } | null
        }>
      } | null
      audiences?: {
        __typename?: 'DemographicRelationResponseCollection'
        data: Array<{
          __typename?: 'DemographicEntity'
          attributes?: { __typename?: 'Demographic'; name: string } | null
        }>
      } | null
      enhancedListingData?: {
        __typename?: 'ComponentSolutionPropsConsultantEnhanced'
        serviceDescription?: string | null
        technologyPartners?: string | null
        averageLengthOfEngagement?: number | null
        valueProposition?: string | null
        pricingData?: string | null
        callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
        dedicatedResources?: Array<{
          __typename?: 'ComponentSolutionPropsDedicatedResource'
          id: string
          count?: number | null
          type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
        } | null> | null
        caseStudies?: Array<{
          __typename?: 'ComponentGlobalSuccessStory'
          id: string
          name?: string | null
          link?: string | null
        } | null> | null
        specialists?: Array<{
          __typename?: 'ComponentAboutPropsTeamMember'
          id: string
          name?: string | null
          role?: string | null
          bio?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        screenshots?: Array<{
          __typename?: 'ComponentGlobalPicture'
          id: string
          title?: string | null
          summary?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              id?: string | null
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        videos?: Array<{
          __typename?: 'ComponentSolutionPropsVideo'
          id: string
          title?: string | null
          summary?: string | null
          url?: string | null
        } | null> | null
        testimonials?: Array<{
          __typename?: 'ComponentGlobalTestimonial'
          id: string
          description?: string | null
          name?: string | null
          role?: string | null
          company?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
    founders?: Array<{
      __typename?: 'ComponentAboutPropsTeamMember'
      id: string
      name?: string | null
      role?: string | null
      bio?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: { id?: string | null; __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
      } | null
    } | null> | null
    vendor?: {
      __typename?: 'VendorEntityResponse'
      data?: {
        __typename?: 'VendorEntity'
        attributes?: {
          __typename?: 'Vendor'
          users?: {
            __typename?: 'UsersPermissionsUserRelationResponseCollection'
            data: Array<{
              __typename?: 'UsersPermissionsUserEntity'
              attributes?: { __typename?: 'UsersPermissionsUser'; external_id?: string | null } | null
            }>
          } | null
        } | null
      } | null
    } | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
      }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          features?: {
            __typename?: 'FeatureRelationResponseCollection'
            data: Array<{
              __typename?: 'FeatureEntity'
              attributes?: { __typename?: 'Feature'; name?: string | null } | null
            }>
          } | null
        } | null
      }>
    } | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
    company?: {
      __typename?: 'CompanyEntityResponse'
      data?: {
        __typename?: 'CompanyEntity'
        attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
      } | null
    } | null
    reviews?: {
      __typename?: 'ReviewRelationResponseCollection'
      data: Array<{
        __typename?: 'ReviewEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Review'
          anonymous?: boolean | null
          rating?: number | null
          title?: string | null
          content?: string | null
          submitterTitle?: string | null
          submitterSubTitle?: string | null
          solutionDispName?: string | null
          createdAt?: any | null
          updatedAt?: any | null
          publishedAt?: any | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                rating?: number | null
                company?: {
                  __typename?: 'CompanyEntityResponse'
                  data?: {
                    __typename?: 'CompanyEntity'
                    attributes?: { __typename?: 'Company'; slug: string } | null
                  } | null
                } | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
    offices?: {
      __typename?: 'OfficeRelationResponseCollection'
      data: Array<{
        __typename?: 'OfficeEntity'
        id?: string | null
        attributes?: { __typename?: 'Office'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{
        __typename?: 'HqEntity'
        id?: string | null
        attributes?: { __typename?: 'Hq'; name: string } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    languages?: {
      __typename?: 'PlatformLanguageRelationResponseCollection'
      data: Array<{
        __typename?: 'PlatformLanguageEntity'
        id?: string | null
        attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
      }>
    } | null
    articles?: Array<{
      __typename?: 'ComponentGlobalArticle'
      id: string
      title?: string | null
      publishedBy?: string | null
      date?: any | null
      link?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    vendorResources?: Array<{
      __typename?: 'ComponentSolutionPropsVendorResource'
      id: string
      type?: IEnum_Componentsolutionpropsvendorresource_Type | null
      content?: {
        __typename?: 'ContentEntityResponse'
        data?: {
          __typename?: 'ContentEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Content'
            title: string
            snippet: string
            createdAt?: any | null
            publishedTime?: any | null
            contentType?: IEnum_Content_Contenttype | null
            documentType?: IEnum_Content_Documenttype | null
            isMaintained?: boolean | null
            lastMaintained?: any | null
            slug: string
            defaultImage?: {
              __typename?: 'UploadFileRelationResponseCollection'
              data: Array<{
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              }>
            } | null
            blogTaxonomies?: {
              __typename?: 'BlogTaxonomyRelationResponseCollection'
              data: Array<{
                __typename?: 'BlogTaxonomyEntity'
                id?: string | null
                attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
              }>
            } | null
            audiences?: {
              __typename?: 'PremiumAudienceRelationResponseCollection'
              data: Array<{
                __typename?: 'PremiumAudienceEntity'
                id?: string | null
                attributes?: { __typename?: 'PremiumAudience'; name: string } | null
              }>
            } | null
            subjectMatters?: {
              __typename?: 'SubjectMatterRelationResponseCollection'
              data: Array<{
                __typename?: 'SubjectMatterEntity'
                id?: string | null
                attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
              }>
            } | null
            writer?: {
              __typename?: 'ComponentContentContentWriter'
              writerOption: IEnum_Componentcontentcontentwriter_Writeroption
              oneTimeAuthorName?: string | null
              author?: {
                __typename?: 'AuthorEntityResponse'
                data?: {
                  __typename?: 'AuthorEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Author'
                    firstName: string
                    lastName: string
                    jobTitle?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              expert?: {
                __typename?: 'ExpertEntityResponse'
                data?: {
                  __typename?: 'ExpertEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Expert'
                    firstName: string
                    lastName: string
                    displayName?: string | null
                    title?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    type: IEnum_Solution_Type
                    shortDescription?: string | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      external?: {
        __typename?: 'ComponentSolutionPropsVendorResourceExternal'
        id: string
        title?: string | null
        summary?: string | null
        link?: string | null
        publishedDate?: any | null
        author?: string | null
        documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
      } | null
    } | null> | null
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
    existingCustomers?: {
      __typename?: 'ExistingCustomerRelationResponseCollection'
      data: Array<{
        __typename?: 'ExistingCustomerEntity'
        id?: string | null
        attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
      }>
    } | null
    iltaSponsorships?: {
      __typename?: 'IltaSponsorshipRelationResponseCollection'
      data: Array<{
        __typename?: 'IltaSponsorshipEntity'
        attributes?: {
          __typename?: 'IltaSponsorship'
          name?: string | null
          logo?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      }>
    } | null
    announcement?: {
      __typename?: 'AnnouncementEntityResponse'
      data?: {
        __typename?: 'AnnouncementEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Announcement'
          announcement: string
          optionalLink?: string | null
          publishedAt?: any | null
        } | null
      } | null
    } | null
  } | null
}

export type IConsultancySolutionEnhancedListingDataFragment = {
  __typename?: 'ComponentSolutionPropsConsultantEnhanced'
  serviceDescription?: string | null
  technologyPartners?: string | null
  averageLengthOfEngagement?: number | null
  valueProposition?: string | null
  pricingData?: string | null
  callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
  dedicatedResources?: Array<{
    __typename?: 'ComponentSolutionPropsDedicatedResource'
    id: string
    count?: number | null
    type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
  } | null> | null
  caseStudies?: Array<{
    __typename?: 'ComponentGlobalSuccessStory'
    id: string
    name?: string | null
    link?: string | null
  } | null> | null
  specialists?: Array<{
    __typename?: 'ComponentAboutPropsTeamMember'
    id: string
    name?: string | null
    role?: string | null
    bio?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null> | null
  screenshots?: Array<{
    __typename?: 'ComponentGlobalPicture'
    id: string
    title?: string | null
    summary?: string | null
    image?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
  } | null> | null
  videos?: Array<{
    __typename?: 'ComponentSolutionPropsVideo'
    id: string
    title?: string | null
    summary?: string | null
    url?: string | null
  } | null> | null
  testimonials?: Array<{
    __typename?: 'ComponentGlobalTestimonial'
    id: string
    description?: string | null
    name?: string | null
    role?: string | null
    company?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null> | null
}

export type IGeneralSolutionDataFragment = {
  __typename?: 'SolutionEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Solution'
    displayName?: string | null
    type: IEnum_Solution_Type
    website?: string | null
    linkedin?: string | null
    twitter?: string | null
    isEnhanced?: boolean | null
    isPremium?: boolean | null
    slug?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    rating?: number | null
    yearFounded?: number | null
    createdAt?: any | null
    publishedAt?: any | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
      }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          features?: {
            __typename?: 'FeatureRelationResponseCollection'
            data: Array<{
              __typename?: 'FeatureEntity'
              attributes?: { __typename?: 'Feature'; name?: string | null } | null
            }>
          } | null
        } | null
      }>
    } | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
    company?: {
      __typename?: 'CompanyEntityResponse'
      data?: {
        __typename?: 'CompanyEntity'
        attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
      } | null
    } | null
    reviews?: {
      __typename?: 'ReviewRelationResponseCollection'
      data: Array<{
        __typename?: 'ReviewEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Review'
          anonymous?: boolean | null
          rating?: number | null
          title?: string | null
          content?: string | null
          submitterTitle?: string | null
          submitterSubTitle?: string | null
          solutionDispName?: string | null
          createdAt?: any | null
          updatedAt?: any | null
          publishedAt?: any | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                rating?: number | null
                company?: {
                  __typename?: 'CompanyEntityResponse'
                  data?: {
                    __typename?: 'CompanyEntity'
                    attributes?: { __typename?: 'Company'; slug: string } | null
                  } | null
                } | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
    offices?: {
      __typename?: 'OfficeRelationResponseCollection'
      data: Array<{
        __typename?: 'OfficeEntity'
        id?: string | null
        attributes?: { __typename?: 'Office'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{
        __typename?: 'HqEntity'
        id?: string | null
        attributes?: { __typename?: 'Hq'; name: string } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    languages?: {
      __typename?: 'PlatformLanguageRelationResponseCollection'
      data: Array<{
        __typename?: 'PlatformLanguageEntity'
        id?: string | null
        attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
      }>
    } | null
    articles?: Array<{
      __typename?: 'ComponentGlobalArticle'
      id: string
      title?: string | null
      publishedBy?: string | null
      date?: any | null
      link?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    vendorResources?: Array<{
      __typename?: 'ComponentSolutionPropsVendorResource'
      id: string
      type?: IEnum_Componentsolutionpropsvendorresource_Type | null
      content?: {
        __typename?: 'ContentEntityResponse'
        data?: {
          __typename?: 'ContentEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Content'
            title: string
            snippet: string
            createdAt?: any | null
            publishedTime?: any | null
            contentType?: IEnum_Content_Contenttype | null
            documentType?: IEnum_Content_Documenttype | null
            isMaintained?: boolean | null
            lastMaintained?: any | null
            slug: string
            defaultImage?: {
              __typename?: 'UploadFileRelationResponseCollection'
              data: Array<{
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              }>
            } | null
            blogTaxonomies?: {
              __typename?: 'BlogTaxonomyRelationResponseCollection'
              data: Array<{
                __typename?: 'BlogTaxonomyEntity'
                id?: string | null
                attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
              }>
            } | null
            audiences?: {
              __typename?: 'PremiumAudienceRelationResponseCollection'
              data: Array<{
                __typename?: 'PremiumAudienceEntity'
                id?: string | null
                attributes?: { __typename?: 'PremiumAudience'; name: string } | null
              }>
            } | null
            subjectMatters?: {
              __typename?: 'SubjectMatterRelationResponseCollection'
              data: Array<{
                __typename?: 'SubjectMatterEntity'
                id?: string | null
                attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
              }>
            } | null
            writer?: {
              __typename?: 'ComponentContentContentWriter'
              writerOption: IEnum_Componentcontentcontentwriter_Writeroption
              oneTimeAuthorName?: string | null
              author?: {
                __typename?: 'AuthorEntityResponse'
                data?: {
                  __typename?: 'AuthorEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Author'
                    firstName: string
                    lastName: string
                    jobTitle?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              expert?: {
                __typename?: 'ExpertEntityResponse'
                data?: {
                  __typename?: 'ExpertEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Expert'
                    firstName: string
                    lastName: string
                    displayName?: string | null
                    title?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    type: IEnum_Solution_Type
                    shortDescription?: string | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      external?: {
        __typename?: 'ComponentSolutionPropsVendorResourceExternal'
        id: string
        title?: string | null
        summary?: string | null
        link?: string | null
        publishedDate?: any | null
        author?: string | null
        documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
      } | null
    } | null> | null
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
    existingCustomers?: {
      __typename?: 'ExistingCustomerRelationResponseCollection'
      data: Array<{
        __typename?: 'ExistingCustomerEntity'
        id?: string | null
        attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
      }>
    } | null
    iltaSponsorships?: {
      __typename?: 'IltaSponsorshipRelationResponseCollection'
      data: Array<{
        __typename?: 'IltaSponsorshipEntity'
        attributes?: {
          __typename?: 'IltaSponsorship'
          name?: string | null
          logo?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      }>
    } | null
    announcement?: {
      __typename?: 'AnnouncementEntityResponse'
      data?: {
        __typename?: 'AnnouncementEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Announcement'
          announcement: string
          optionalLink?: string | null
          publishedAt?: any | null
        } | null
      } | null
    } | null
  } | null
}

export type IGeneralSolutionRequestDataFragment = {
  __typename?: 'SolutionRequestEntity'
  id?: string | null
  attributes?: {
    __typename?: 'SolutionRequest'
    website?: string | null
    slug?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    linkedin?: string | null
    twitter?: string | null
    type: IEnum_Solutionrequest_Type
    yearFounded?: number | null
    createdAt?: any | null
    publishedAt?: any | null
    updatedAt?: any | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
      }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          features?: {
            __typename?: 'FeatureRelationResponseCollection'
            data: Array<{
              __typename?: 'FeatureEntity'
              attributes?: { __typename?: 'Feature'; name?: string | null } | null
            }>
          } | null
        } | null
      }>
    } | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
    offices?: {
      __typename?: 'OfficeRelationResponseCollection'
      data: Array<{
        __typename?: 'OfficeEntity'
        id?: string | null
        attributes?: { __typename?: 'Office'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{
        __typename?: 'HqEntity'
        id?: string | null
        attributes?: { __typename?: 'Hq'; name: string } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    languages?: {
      __typename?: 'PlatformLanguageRelationResponseCollection'
      data: Array<{
        __typename?: 'PlatformLanguageEntity'
        id?: string | null
        attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
      }>
    } | null
    articles?: Array<{
      __typename?: 'ComponentGlobalArticle'
      id: string
      title?: string | null
      publishedBy?: string | null
      date?: any | null
      link?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
    existingCustomers?: {
      __typename?: 'ExistingCustomerRelationResponseCollection'
      data: Array<{
        __typename?: 'ExistingCustomerEntity'
        id?: string | null
        attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
      }>
    } | null
    announcement?: {
      __typename?: 'AnnouncementEntityResponse'
      data?: {
        __typename?: 'AnnouncementEntity'
        id?: string | null
        attributes?: { __typename?: 'Announcement'; announcement: string; optionalLink?: string | null } | null
      } | null
    } | null
  } | null
}

export type IIltaSponsorshipLogoFragment = {
  __typename?: 'UploadFileEntity'
  attributes?: { __typename?: 'UploadFile'; url: string } | null
}

export type ISolutionProductDataFragment = {
  __typename?: 'ComponentSolutionProductData'
  name?: string | null
  tool?: string | null
  fundingInfo?: IEnum_Componentsolutionproductdata_Fundinginfo | null
  fundingAmount?: IEnum_Componentsolutionproductdata_Fundingamount | null
  valueProposition?: string | null
  audiences?: {
    __typename?: 'DemographicRelationResponseCollection'
    data: Array<{ __typename?: 'DemographicEntity'; attributes?: { __typename?: 'Demographic'; name: string } | null }>
  } | null
  attributes?: {
    __typename?: 'AttributeRelationResponseCollection'
    data: Array<{ __typename?: 'AttributeEntity'; attributes?: { __typename?: 'Attribute'; name: string } | null }>
  } | null
  deployments?: {
    __typename?: 'InstallationRelationResponseCollection'
    data: Array<{
      __typename?: 'InstallationEntity'
      attributes?: { __typename?: 'Installation'; name: string } | null
    }>
  } | null
  integrations?: {
    __typename?: 'IntegrationRelationResponseCollection'
    data: Array<{
      __typename?: 'IntegrationEntity'
      id?: string | null
      attributes?: { __typename?: 'Integration'; name: string } | null
    }>
  } | null
  practiceAreas?: {
    __typename?: 'PracticeAreaRelationResponseCollection'
    data: Array<{
      __typename?: 'PracticeAreaEntity'
      id?: string | null
      attributes?: { __typename?: 'PracticeArea'; name: string } | null
    }>
  } | null
  typeOfUser?: {
    __typename?: 'TypeOfUserRelationResponseCollection'
    data: Array<{
      __typename?: 'TypeOfUserEntity'
      id?: string | null
      attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
    }>
  } | null
  security?: {
    __typename?: 'SecurityRelationResponseCollection'
    data: Array<{
      __typename?: 'SecurityEntity'
      id?: string | null
      attributes?: { __typename?: 'Security'; name: string } | null
    }>
  } | null
  dataHostingLocations?: {
    __typename?: 'OfficeRelationResponseCollection'
    data: Array<{
      __typename?: 'OfficeEntity'
      id?: string | null
      attributes?: { __typename?: 'Office'; name: string } | null
    }>
  } | null
  enhancedListingData?: {
    __typename?: 'ComponentSolutionPropsProductEnhanced'
    pricingData?: string | null
    callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
    screenshots?: Array<{
      __typename?: 'ComponentGlobalPicture'
      id: string
      title?: string | null
      summary?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          id?: string | null
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    videos?: Array<{
      __typename?: 'ComponentSolutionPropsVideo'
      id: string
      title?: string | null
      summary?: string | null
      url?: string | null
    } | null> | null
    testimonials?: Array<{
      __typename?: 'ComponentGlobalTestimonial'
      id: string
      description?: string | null
      name?: string | null
      role?: string | null
      company?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
  } | null
}

export type IProductSolutionDataFragment = {
  __typename?: 'SolutionEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Solution'
    productType?: IEnum_Solution_Producttype | null
    displayName?: string | null
    type: IEnum_Solution_Type
    website?: string | null
    linkedin?: string | null
    twitter?: string | null
    isEnhanced?: boolean | null
    isPremium?: boolean | null
    slug?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    rating?: number | null
    yearFounded?: number | null
    createdAt?: any | null
    publishedAt?: any | null
    productData?: {
      __typename?: 'ComponentSolutionProductData'
      name?: string | null
      tool?: string | null
      fundingInfo?: IEnum_Componentsolutionproductdata_Fundinginfo | null
      fundingAmount?: IEnum_Componentsolutionproductdata_Fundingamount | null
      valueProposition?: string | null
      audiences?: {
        __typename?: 'DemographicRelationResponseCollection'
        data: Array<{
          __typename?: 'DemographicEntity'
          attributes?: { __typename?: 'Demographic'; name: string } | null
        }>
      } | null
      attributes?: {
        __typename?: 'AttributeRelationResponseCollection'
        data: Array<{ __typename?: 'AttributeEntity'; attributes?: { __typename?: 'Attribute'; name: string } | null }>
      } | null
      deployments?: {
        __typename?: 'InstallationRelationResponseCollection'
        data: Array<{
          __typename?: 'InstallationEntity'
          attributes?: { __typename?: 'Installation'; name: string } | null
        }>
      } | null
      integrations?: {
        __typename?: 'IntegrationRelationResponseCollection'
        data: Array<{
          __typename?: 'IntegrationEntity'
          id?: string | null
          attributes?: { __typename?: 'Integration'; name: string } | null
        }>
      } | null
      practiceAreas?: {
        __typename?: 'PracticeAreaRelationResponseCollection'
        data: Array<{
          __typename?: 'PracticeAreaEntity'
          id?: string | null
          attributes?: { __typename?: 'PracticeArea'; name: string } | null
        }>
      } | null
      typeOfUser?: {
        __typename?: 'TypeOfUserRelationResponseCollection'
        data: Array<{
          __typename?: 'TypeOfUserEntity'
          id?: string | null
          attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
        }>
      } | null
      security?: {
        __typename?: 'SecurityRelationResponseCollection'
        data: Array<{
          __typename?: 'SecurityEntity'
          id?: string | null
          attributes?: { __typename?: 'Security'; name: string } | null
        }>
      } | null
      dataHostingLocations?: {
        __typename?: 'OfficeRelationResponseCollection'
        data: Array<{
          __typename?: 'OfficeEntity'
          id?: string | null
          attributes?: { __typename?: 'Office'; name: string } | null
        }>
      } | null
      enhancedListingData?: {
        __typename?: 'ComponentSolutionPropsProductEnhanced'
        pricingData?: string | null
        callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
        screenshots?: Array<{
          __typename?: 'ComponentGlobalPicture'
          id: string
          title?: string | null
          summary?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              id?: string | null
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        videos?: Array<{
          __typename?: 'ComponentSolutionPropsVideo'
          id: string
          title?: string | null
          summary?: string | null
          url?: string | null
        } | null> | null
        testimonials?: Array<{
          __typename?: 'ComponentGlobalTestimonial'
          id: string
          description?: string | null
          name?: string | null
          role?: string | null
          company?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
    consolidationData?: {
      __typename?: 'ComponentSolutionPropsConsolidation'
      date?: any | null
      amount?: number | null
      article?: string | null
      articleLink?: string | null
      originalTargetName?: string | null
      buyer?: string | null
    } | null
    graveyardData?: { __typename?: 'ComponentSolutionPropsGraveyard'; date?: any | null } | null
    vendor?: {
      __typename?: 'VendorEntityResponse'
      data?: {
        __typename?: 'VendorEntity'
        attributes?: {
          __typename?: 'Vendor'
          users?: {
            __typename?: 'UsersPermissionsUserRelationResponseCollection'
            data: Array<{
              __typename?: 'UsersPermissionsUserEntity'
              attributes?: { __typename?: 'UsersPermissionsUser'; external_id?: string | null } | null
            }>
          } | null
        } | null
      } | null
    } | null
    founders?: Array<{
      __typename?: 'ComponentAboutPropsTeamMember'
      id: string
      name?: string | null
      role?: string | null
      bio?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: { id?: string | null; __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
      } | null
    } | null> | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
      }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          features?: {
            __typename?: 'FeatureRelationResponseCollection'
            data: Array<{
              __typename?: 'FeatureEntity'
              attributes?: { __typename?: 'Feature'; name?: string | null } | null
            }>
          } | null
        } | null
      }>
    } | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
    company?: {
      __typename?: 'CompanyEntityResponse'
      data?: {
        __typename?: 'CompanyEntity'
        attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
      } | null
    } | null
    reviews?: {
      __typename?: 'ReviewRelationResponseCollection'
      data: Array<{
        __typename?: 'ReviewEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Review'
          anonymous?: boolean | null
          rating?: number | null
          title?: string | null
          content?: string | null
          submitterTitle?: string | null
          submitterSubTitle?: string | null
          solutionDispName?: string | null
          createdAt?: any | null
          updatedAt?: any | null
          publishedAt?: any | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                rating?: number | null
                company?: {
                  __typename?: 'CompanyEntityResponse'
                  data?: {
                    __typename?: 'CompanyEntity'
                    attributes?: { __typename?: 'Company'; slug: string } | null
                  } | null
                } | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
    offices?: {
      __typename?: 'OfficeRelationResponseCollection'
      data: Array<{
        __typename?: 'OfficeEntity'
        id?: string | null
        attributes?: { __typename?: 'Office'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{
        __typename?: 'HqEntity'
        id?: string | null
        attributes?: { __typename?: 'Hq'; name: string } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    languages?: {
      __typename?: 'PlatformLanguageRelationResponseCollection'
      data: Array<{
        __typename?: 'PlatformLanguageEntity'
        id?: string | null
        attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
      }>
    } | null
    articles?: Array<{
      __typename?: 'ComponentGlobalArticle'
      id: string
      title?: string | null
      publishedBy?: string | null
      date?: any | null
      link?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    vendorResources?: Array<{
      __typename?: 'ComponentSolutionPropsVendorResource'
      id: string
      type?: IEnum_Componentsolutionpropsvendorresource_Type | null
      content?: {
        __typename?: 'ContentEntityResponse'
        data?: {
          __typename?: 'ContentEntity'
          id?: string | null
          attributes?: {
            __typename?: 'Content'
            title: string
            snippet: string
            createdAt?: any | null
            publishedTime?: any | null
            contentType?: IEnum_Content_Contenttype | null
            documentType?: IEnum_Content_Documenttype | null
            isMaintained?: boolean | null
            lastMaintained?: any | null
            slug: string
            defaultImage?: {
              __typename?: 'UploadFileRelationResponseCollection'
              data: Array<{
                __typename?: 'UploadFileEntity'
                attributes?: { __typename?: 'UploadFile'; url: string } | null
              }>
            } | null
            blogTaxonomies?: {
              __typename?: 'BlogTaxonomyRelationResponseCollection'
              data: Array<{
                __typename?: 'BlogTaxonomyEntity'
                id?: string | null
                attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
              }>
            } | null
            audiences?: {
              __typename?: 'PremiumAudienceRelationResponseCollection'
              data: Array<{
                __typename?: 'PremiumAudienceEntity'
                id?: string | null
                attributes?: { __typename?: 'PremiumAudience'; name: string } | null
              }>
            } | null
            subjectMatters?: {
              __typename?: 'SubjectMatterRelationResponseCollection'
              data: Array<{
                __typename?: 'SubjectMatterEntity'
                id?: string | null
                attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
              }>
            } | null
            writer?: {
              __typename?: 'ComponentContentContentWriter'
              writerOption: IEnum_Componentcontentcontentwriter_Writeroption
              oneTimeAuthorName?: string | null
              author?: {
                __typename?: 'AuthorEntityResponse'
                data?: {
                  __typename?: 'AuthorEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Author'
                    firstName: string
                    lastName: string
                    jobTitle?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              expert?: {
                __typename?: 'ExpertEntityResponse'
                data?: {
                  __typename?: 'ExpertEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Expert'
                    firstName: string
                    lastName: string
                    displayName?: string | null
                    title?: string | null
                    company?: string | null
                    bio?: string | null
                    slug: string
                    avatar?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    type: IEnum_Solution_Type
                    shortDescription?: string | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
      external?: {
        __typename?: 'ComponentSolutionPropsVendorResourceExternal'
        id: string
        title?: string | null
        summary?: string | null
        link?: string | null
        publishedDate?: any | null
        author?: string | null
        documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
      } | null
    } | null> | null
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
    existingCustomers?: {
      __typename?: 'ExistingCustomerRelationResponseCollection'
      data: Array<{
        __typename?: 'ExistingCustomerEntity'
        id?: string | null
        attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
      }>
    } | null
    iltaSponsorships?: {
      __typename?: 'IltaSponsorshipRelationResponseCollection'
      data: Array<{
        __typename?: 'IltaSponsorshipEntity'
        attributes?: {
          __typename?: 'IltaSponsorship'
          name?: string | null
          logo?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      }>
    } | null
    announcement?: {
      __typename?: 'AnnouncementEntityResponse'
      data?: {
        __typename?: 'AnnouncementEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Announcement'
          announcement: string
          optionalLink?: string | null
          publishedAt?: any | null
        } | null
      } | null
    } | null
  } | null
}

export type IProductSolutionEnhancedListingDataFragment = {
  __typename?: 'ComponentSolutionPropsProductEnhanced'
  pricingData?: string | null
  callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
  screenshots?: Array<{
    __typename?: 'ComponentGlobalPicture'
    id: string
    title?: string | null
    summary?: string | null
    image?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
  } | null> | null
  videos?: Array<{
    __typename?: 'ComponentSolutionPropsVideo'
    id: string
    title?: string | null
    summary?: string | null
    url?: string | null
  } | null> | null
  testimonials?: Array<{
    __typename?: 'ComponentGlobalTestimonial'
    id: string
    description?: string | null
    name?: string | null
    role?: string | null
    company?: string | null
    avatar?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null> | null
}

export type IGetProductSolutionSecurityDataQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IGetProductSolutionSecurityDataQuery = {
  __typename?: 'Query'
  solutions?: {
    __typename?: 'SolutionEntityResponseCollection'
    data: Array<{
      __typename: 'SolutionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Solution'
        slug?: string | null
        productData?: {
          __typename: 'ComponentSolutionProductData'
          name?: string | null
          security?: {
            __typename: 'SecurityRelationResponseCollection'
            data: Array<{
              __typename: 'SecurityEntity'
              id?: string | null
              attributes?: { __typename: 'Security'; name: string } | null
            }>
          } | null
          dataHostingLocations?: {
            __typename: 'OfficeRelationResponseCollection'
            data: Array<{
              __typename: 'OfficeEntity'
              id?: string | null
              attributes?: { __typename: 'Office'; name: string } | null
            }>
          } | null
        } | null
      } | null
    }>
  } | null
}

export type ISolutionArticleFragment = {
  __typename?: 'ComponentGlobalArticle'
  id: string
  title?: string | null
  publishedBy?: string | null
  date?: any | null
  link?: string | null
  image?: {
    __typename?: 'UploadFileEntityResponse'
    data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
  } | null
}

export type ISolutionAttributeFragment = {
  __typename?: 'AttributeEntity'
  attributes?: { __typename?: 'Attribute'; name: string } | null
}

export type ISolutionAudienceFragment = {
  __typename?: 'DemographicEntity'
  attributes?: { __typename?: 'Demographic'; name: string } | null
}

export type ISolutionCallToActionFragment = {
  __typename?: 'ComponentGlobalCallToAction'
  label?: string | null
  url?: string | null
}

export type ISolutionCardDataFragment = {
  __typename?: 'SolutionEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Solution'
    displayName?: string | null
    slug?: string | null
    type: IEnum_Solution_Type
    shortDescription?: string | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type ISolutionCaseStudyFragment = {
  __typename?: 'ComponentGlobalSuccessStory'
  id: string
  name?: string | null
  link?: string | null
}

export type ISolutionCompanyFragment = {
  __typename?: 'CompanyEntity'
  attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
}

export type ISolutionConsolidationDataFragment = {
  __typename?: 'ComponentSolutionPropsConsolidation'
  date?: any | null
  amount?: number | null
  article?: string | null
  articleLink?: string | null
  originalTargetName?: string | null
  buyer?: string | null
}

export type ISolutionDedicatedResourceFragment = {
  __typename?: 'ComponentSolutionPropsDedicatedResource'
  id: string
  count?: number | null
  type?: IEnum_Componentsolutionpropsdedicatedresource_Type | null
}

export type ISolutionDeploymentFragment = {
  __typename?: 'InstallationEntity'
  attributes?: { __typename?: 'Installation'; name: string } | null
}

export type ISolutionExistingCustomerFragment = {
  __typename?: 'ExistingCustomerEntity'
  id?: string | null
  attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
}

export type ISolutionFeatureFragment = {
  __typename?: 'FeatureEntity'
  attributes?: { __typename?: 'Feature'; name?: string | null } | null
}

export type ISolutionFounderFragment = {
  __typename?: 'ComponentAboutPropsTeamMember'
  id: string
  name?: string | null
  role?: string | null
  bio?: string | null
  externalLink?: string | null
  title?: string | null
  avatar?: {
    id?: string | null
    __typename?: 'UploadFileEntityResponse'
    data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
  } | null
}

export type ISolutionGraveyardDataFragment = { __typename?: 'ComponentSolutionPropsGraveyard'; date?: any | null }

export type ISolutionHqFragment = {
  __typename?: 'HqEntity'
  id?: string | null
  attributes?: { __typename?: 'Hq'; name: string } | null
}

export type ISolutionIltaSponsorshipFragment = {
  __typename?: 'IltaSponsorshipEntity'
  attributes?: {
    __typename?: 'IltaSponsorship'
    name?: string | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
    } | null
  } | null
}

export type ISolutionIntegrationFragment = {
  __typename?: 'IntegrationEntity'
  id?: string | null
  attributes?: { __typename?: 'Integration'; name: string } | null
}

export type ISolutionLanguageFragment = {
  __typename?: 'PlatformLanguageEntity'
  id?: string | null
  attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
}

export type ISolutionLogoFragment = {
  __typename?: 'UploadFileEntity'
  id?: string | null
  attributes?: { __typename?: 'UploadFile'; url: string } | null
}

export type ISolutionOfficeFragment = {
  __typename?: 'OfficeEntity'
  id?: string | null
  attributes?: { __typename?: 'Office'; name: string } | null
}

export type ISolutionPracticeAreaFragment = {
  __typename?: 'PracticeAreaEntity'
  id?: string | null
  attributes?: { __typename?: 'PracticeArea'; name: string } | null
}

export type ISolutionRegionsServedFragment = {
  __typename?: 'RegionsServedEntity'
  attributes?: { __typename?: 'RegionsServed'; name: string } | null
}

export type ISolutionRequestEnhancedListingDataFragment = {
  __typename?: 'ComponentSolutionRequestPropsProductEnhanced'
  pricingData?: string | null
  screenshots?: Array<{
    __typename?: 'ComponentGlobalPicture'
    id: string
    title?: string | null
    summary?: string | null
    image?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
  } | null> | null
  videos?: Array<{
    __typename?: 'ComponentSolutionPropsVideo'
    id: string
    title?: string | null
    summary?: string | null
    url?: string | null
  } | null> | null
  callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
}

export type ISolutionRequestDatasFragment = {
  data: any
  __typename?: 'ComponentSolutionRequestProductData'
  name?: string | null
  fundingAmount?: IEnum_Componentsolutionrequestproductdata_Fundingamount | null
  fundingInfo?: IEnum_Componentsolutionrequestproductdata_Fundinginfo | null
  valueProposition?: string | null
  audiences?: {
    __typename?: 'DemographicRelationResponseCollection'
    data: Array<{ __typename?: 'DemographicEntity'; attributes?: { __typename?: 'Demographic'; name: string } | null }>
  } | null
  attributes?: {
    __typename?: 'AttributeRelationResponseCollection'
    data: Array<{ __typename?: 'AttributeEntity'; attributes?: { __typename?: 'Attribute'; name: string } | null }>
  } | null
  integrations?: {
    __typename?: 'IntegrationRelationResponseCollection'
    data: Array<{
      __typename?: 'IntegrationEntity'
      id?: string | null
      attributes?: { __typename?: 'Integration'; name: string } | null
    }>
  } | null
  practiceAreas?: {
    __typename?: 'PracticeAreaRelationResponseCollection'
    data: Array<{
      __typename?: 'PracticeAreaEntity'
      id?: string | null
      attributes?: { __typename?: 'PracticeArea'; name: string } | null
    }>
  } | null
  typeOfUser?: {
    __typename?: 'TypeOfUserRelationResponseCollection'
    data: Array<{
      __typename?: 'TypeOfUserEntity'
      id?: string | null
      attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
    }>
  } | null
  enhancedListingData?: {
    __typename?: 'ComponentSolutionRequestPropsProductEnhanced'
    pricingData?: string | null
    screenshots?: Array<{
      __typename?: 'ComponentGlobalPicture'
      id: string
      title?: string | null
      summary?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          id?: string | null
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    videos?: Array<{
      __typename?: 'ComponentSolutionPropsVideo'
      id: string
      title?: string | null
      summary?: string | null
      url?: string | null
    } | null> | null
    callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
  } | null
  security?: {
    __typename?: 'SecurityRelationResponseCollection'
    data: Array<{
      __typename?: 'SecurityEntity'
      id?: string | null
      attributes?: { __typename?: 'Security'; name: string } | null
    }>
  } | null
  dataHosting?: {
    __typename?: 'OfficeRelationResponseCollection'
    data: Array<{
      __typename?: 'OfficeEntity'
      id?: string | null
      attributes?: { __typename?: 'Office'; name: string } | null
    }>
  } | null
}

export type ISolutionRequestDataFragment = {
  __typename?: 'SolutionRequestEntity'
  id?: string | null
  attributes?: {
    __typename?: 'SolutionRequest'
    website?: string | null
    slug?: string | null
    shortDescription?: string | null
    longDescription?: string | null
    linkedin?: string | null
    twitter?: string | null
    type: IEnum_Solutionrequest_Type
    yearFounded?: number | null
    createdAt?: any | null
    publishedAt?: any | null
    updatedAt?: any | null
    productData?: {
      __typename?: 'ComponentSolutionRequestProductData'
      name?: string | null
      fundingAmount?: IEnum_Componentsolutionrequestproductdata_Fundingamount | null
      fundingInfo?: IEnum_Componentsolutionrequestproductdata_Fundinginfo | null
      valueProposition?: string | null
      audiences?: {
        __typename?: 'DemographicRelationResponseCollection'
        data: Array<{
          __typename?: 'DemographicEntity'
          attributes?: { __typename?: 'Demographic'; name: string } | null
        }>
      } | null
      attributes?: {
        __typename?: 'AttributeRelationResponseCollection'
        data: Array<{ __typename?: 'AttributeEntity'; attributes?: { __typename?: 'Attribute'; name: string } | null }>
      } | null
      integrations?: {
        __typename?: 'IntegrationRelationResponseCollection'
        data: Array<{
          __typename?: 'IntegrationEntity'
          id?: string | null
          attributes?: { __typename?: 'Integration'; name: string } | null
        }>
      } | null
      deployments?: {
        __typename?: 'InstallationRelationResponseCollection'
        data: Array<{
          __typename?: 'InstallationEntity'
          attributes?: { __typename?: 'Installation'; name: string } | null
        }>
      } | null
      practiceAreas?: {
        __typename?: 'PracticeAreaRelationResponseCollection'
        data: Array<{
          __typename?: 'PracticeAreaEntity'
          id?: string | null
          attributes?: { __typename?: 'PracticeArea'; name: string } | null
        }>
      } | null
      typeOfUser?: {
        __typename?: 'TypeOfUserRelationResponseCollection'
        data: Array<{
          __typename?: 'TypeOfUserEntity'
          id?: string | null
          attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
        }>
      } | null
      enhancedListingData?: {
        __typename?: 'ComponentSolutionRequestPropsProductEnhanced'
        pricingData?: string | null
        screenshots?: Array<{
          __typename?: 'ComponentGlobalPicture'
          id: string
          title?: string | null
          summary?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              id?: string | null
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        videos?: Array<{
          __typename?: 'ComponentSolutionPropsVideo'
          id: string
          title?: string | null
          summary?: string | null
          url?: string | null
        } | null> | null
        callToAction?: { __typename?: 'ComponentGlobalCallToAction'; label?: string | null; url?: string | null } | null
      } | null
      security?: {
        __typename?: 'SecurityRelationResponseCollection'
        data: Array<{
          __typename?: 'SecurityEntity'
          id?: string | null
          attributes?: { __typename?: 'Security'; name: string } | null
        }>
      } | null
      dataHosting?: {
        __typename?: 'OfficeRelationResponseCollection'
        data: Array<{
          __typename?: 'OfficeEntity'
          id?: string | null
          attributes?: { __typename?: 'Office'; name: string } | null
        }>
      } | null
    } | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
      }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          features?: {
            __typename?: 'FeatureRelationResponseCollection'
            data: Array<{
              __typename?: 'FeatureEntity'
              attributes?: { __typename?: 'Feature'; name?: string | null } | null
            }>
          } | null
        } | null
      }>
    } | null
    logo?: {
      __typename?: 'UploadFileEntityResponse'
      data?: {
        __typename?: 'UploadFileEntity'
        id?: string | null
        attributes?: { __typename?: 'UploadFile'; url: string } | null
      } | null
    } | null
    offices?: {
      __typename?: 'OfficeRelationResponseCollection'
      data: Array<{
        __typename?: 'OfficeEntity'
        id?: string | null
        attributes?: { __typename?: 'Office'; name: string } | null
      }>
    } | null
    hqs?: {
      __typename?: 'HqRelationResponseCollection'
      data: Array<{
        __typename?: 'HqEntity'
        id?: string | null
        attributes?: { __typename?: 'Hq'; name: string } | null
      }>
    } | null
    regionsServed?: {
      __typename?: 'RegionsServedRelationResponseCollection'
      data: Array<{
        __typename?: 'RegionsServedEntity'
        attributes?: { __typename?: 'RegionsServed'; name: string } | null
      }>
    } | null
    languages?: {
      __typename?: 'PlatformLanguageRelationResponseCollection'
      data: Array<{
        __typename?: 'PlatformLanguageEntity'
        id?: string | null
        attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
      }>
    } | null
    articles?: Array<{
      __typename?: 'ComponentGlobalArticle'
      id: string
      title?: string | null
      publishedBy?: string | null
      date?: any | null
      link?: string | null
      image?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null> | null
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
    existingCustomers?: {
      __typename?: 'ExistingCustomerRelationResponseCollection'
      data: Array<{
        __typename?: 'ExistingCustomerEntity'
        id?: string | null
        attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
      }>
    } | null
    announcement?: {
      __typename?: 'AnnouncementEntityResponse'
      data?: {
        __typename?: 'AnnouncementEntity'
        id?: string | null
        attributes?: { __typename?: 'Announcement'; announcement: string; optionalLink?: string | null } | null
      } | null
    } | null
  } | null
}

export type ISolutionReviewFragment = {
  __typename?: 'ReviewEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Review'
    anonymous?: boolean | null
    rating?: number | null
    title?: string | null
    content?: string | null
    submitterTitle?: string | null
    submitterSubTitle?: string | null
    solutionDispName?: string | null
    createdAt?: any | null
    updatedAt?: any | null
    publishedAt?: any | null
    solution?: {
      __typename?: 'SolutionEntityResponse'
      data?: {
        __typename?: 'SolutionEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Solution'
          displayName?: string | null
          slug?: string | null
          rating?: number | null
          company?: {
            __typename?: 'CompanyEntityResponse'
            data?: { __typename?: 'CompanyEntity'; attributes?: { __typename?: 'Company'; slug: string } | null } | null
          } | null
          logo?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type ISolutionScreenshotFragment = {
  __typename?: 'ComponentGlobalPicture'
  id: string
  title?: string | null
  summary?: string | null
  image?: {
    __typename?: 'UploadFileEntityResponse'
    data?: {
      __typename?: 'UploadFileEntity'
      id?: string | null
      attributes?: { __typename?: 'UploadFile'; url: string } | null
    } | null
  } | null
}

export type ISolutionSpecialistFragment = {
  __typename?: 'ComponentAboutPropsTeamMember'
  id: string
  name?: string | null
  role?: string | null
  bio?: string | null
  avatar?: {
    id?: string | null
    __typename?: 'UploadFileEntityResponse'
    data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
  } | null
}

export type ISolutionStoryFragment = {
  __typename?: 'ComponentGlobalSuccessStory'
  id: string
  name?: string | null
  link?: string | null
}

export type ISolutionSubTopicFragment = {
  __typename?: 'SubTopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'SubTopic'
    slug: string
    name: string
    features?: {
      __typename?: 'FeatureRelationResponseCollection'
      data: Array<{
        __typename?: 'FeatureEntity'
        attributes?: { __typename?: 'Feature'; name?: string | null } | null
      }>
    } | null
  } | null
}

export type ISolutionTestimonialFragment = {
  __typename?: 'ComponentGlobalTestimonial'
  id: string
  description?: string | null
  name?: string | null
  role?: string | null
  company?: string | null
  avatar?: {
    __typename?: 'UploadFileEntityResponse'
    data?: { __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null } | null
  } | null
}

export type ISolutionTopicFragment = {
  __typename?: 'TopicEntity'
  id?: string | null
  attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
}

export type ISolutionTypeOfUserFragment = {
  __typename?: 'TypeOfUserEntity'
  id?: string | null
  attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
}

export type ISolutionVendorResourceFragment = {
  __typename?: 'ComponentSolutionPropsVendorResource'
  id: string
  type?: IEnum_Componentsolutionpropsvendorresource_Type | null
  content?: {
    __typename?: 'ContentEntityResponse'
    data?: {
      __typename?: 'ContentEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Content'
        title: string
        snippet: string
        createdAt?: any | null
        publishedTime?: any | null
        contentType?: IEnum_Content_Contenttype | null
        documentType?: IEnum_Content_Documenttype | null
        isMaintained?: boolean | null
        lastMaintained?: any | null
        slug: string
        defaultImage?: {
          __typename?: 'UploadFileRelationResponseCollection'
          data: Array<{
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          }>
        } | null
        blogTaxonomies?: {
          __typename?: 'BlogTaxonomyRelationResponseCollection'
          data: Array<{
            __typename?: 'BlogTaxonomyEntity'
            id?: string | null
            attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
          }>
        } | null
        audiences?: {
          __typename?: 'PremiumAudienceRelationResponseCollection'
          data: Array<{
            __typename?: 'PremiumAudienceEntity'
            id?: string | null
            attributes?: { __typename?: 'PremiumAudience'; name: string } | null
          }>
        } | null
        subjectMatters?: {
          __typename?: 'SubjectMatterRelationResponseCollection'
          data: Array<{
            __typename?: 'SubjectMatterEntity'
            id?: string | null
            attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
          }>
        } | null
        writer?: {
          __typename?: 'ComponentContentContentWriter'
          writerOption: IEnum_Componentcontentcontentwriter_Writeroption
          oneTimeAuthorName?: string | null
          author?: {
            __typename?: 'AuthorEntityResponse'
            data?: {
              __typename?: 'AuthorEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Author'
                firstName: string
                lastName: string
                jobTitle?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          expert?: {
            __typename?: 'ExpertEntityResponse'
            data?: {
              __typename?: 'ExpertEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Expert'
                firstName: string
                lastName: string
                displayName?: string | null
                title?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                type: IEnum_Solution_Type
                shortDescription?: string | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
  external?: {
    __typename?: 'ComponentSolutionPropsVendorResourceExternal'
    id: string
    title?: string | null
    summary?: string | null
    link?: string | null
    publishedDate?: any | null
    author?: string | null
    documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
  } | null
}

export type ISolutionVendorResourceExternalFragment = {
  __typename?: 'ComponentSolutionPropsVendorResourceExternal'
  id: string
  title?: string | null
  summary?: string | null
  link?: string | null
  publishedDate?: any | null
  author?: string | null
  documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
}

export type ISolutionVideoFragment = {
  __typename?: 'ComponentSolutionPropsVideo'
  id: string
  title?: string | null
  summary?: string | null
  url?: string | null
}

export type ISolutionListItemFragment = {
  __typename?: 'SolutionItem'
  id: string
  slug: string
  type: IEnum_Solution_Item_Type
  displayName: string
  description?: string | null
  logo?: string | null
  subTopics: Array<string>
  follow: boolean
  rating?: number | null
  reviewCnt?: number | null
  isEnhanced?: boolean | null
  isPremium?: boolean | null
  offices?: Array<string | null> | null
  security?: Array<string | null> | null
  iltaSponsorshipLogo?: string | null
  consolidation?: {
    __typename?: 'ComponentSolutionPropsConsolidation'
    date?: any | null
    amount?: number | null
    article?: string | null
    articleLink?: string | null
    originalTargetName?: string | null
    buyer?: string | null
  } | null
  graveyard?: { __typename?: 'ComponentSolutionPropsGraveyard'; date?: any | null } | null
}

export type ISolutionRequestByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ISolutionRequestByIdQuery = {
  __typename?: 'Query'
  solutionRequest?: {
    __typename?: 'SolutionRequestEntityResponse'
    data?: {
      __typename?: 'SolutionRequestEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SolutionRequest'
        website?: string | null
        slug?: string | null
        shortDescription?: string | null
        longDescription?: string | null
        linkedin?: string | null
        twitter?: string | null
        type: IEnum_Solutionrequest_Type
        yearFounded?: number | null
        createdAt?: any | null
        publishedAt?: any | null
        updatedAt?: any | null
        productData?: {
          __typename?: 'ComponentSolutionRequestProductData'
          name?: string | null
          fundingAmount?: IEnum_Componentsolutionrequestproductdata_Fundingamount | null
          fundingInfo?: IEnum_Componentsolutionrequestproductdata_Fundinginfo | null
          valueProposition?: string | null
          audiences?: {
            __typename?: 'DemographicRelationResponseCollection'
            data: Array<{
              __typename?: 'DemographicEntity'
              attributes?: { __typename?: 'Demographic'; name: string } | null
            }>
          } | null
          attributes?: {
            __typename?: 'AttributeRelationResponseCollection'
            data: Array<{
              __typename?: 'AttributeEntity'
              attributes?: { __typename?: 'Attribute'; name: string } | null
            }>
          } | null
          integrations?: {
            __typename?: 'IntegrationRelationResponseCollection'
            data: Array<{
              __typename?: 'IntegrationEntity'
              id?: string | null
              attributes?: { __typename?: 'Integration'; name: string } | null
            }>
          } | null
          practiceAreas?: {
            __typename?: 'PracticeAreaRelationResponseCollection'
            data: Array<{
              __typename?: 'PracticeAreaEntity'
              id?: string | null
              attributes?: { __typename?: 'PracticeArea'; name: string } | null
            }>
          } | null
          typeOfUser?: {
            __typename?: 'TypeOfUserRelationResponseCollection'
            data: Array<{
              __typename?: 'TypeOfUserEntity'
              id?: string | null
              attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
            }>
          } | null
          enhancedListingData?: {
            __typename?: 'ComponentSolutionRequestPropsProductEnhanced'
            pricingData?: string | null
            screenshots?: Array<{
              __typename?: 'ComponentGlobalPicture'
              id: string
              title?: string | null
              summary?: string | null
              image?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  id?: string | null
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
            videos?: Array<{
              __typename?: 'ComponentSolutionPropsVideo'
              id: string
              title?: string | null
              summary?: string | null
              url?: string | null
            } | null> | null
            callToAction?: {
              __typename?: 'ComponentGlobalCallToAction'
              label?: string | null
              url?: string | null
            } | null
          } | null
          security?: {
            __typename?: 'SecurityRelationResponseCollection'
            data: Array<{
              __typename?: 'SecurityEntity'
              id?: string | null
              attributes?: { __typename?: 'Security'; name: string } | null
            }>
          } | null
          dataHosting?: {
            __typename?: 'OfficeRelationResponseCollection'
            data: Array<{
              __typename?: 'OfficeEntity'
              id?: string | null
              attributes?: { __typename?: 'Office'; name: string } | null
            }>
          } | null
        } | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
          }>
        } | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              features?: {
                __typename?: 'FeatureRelationResponseCollection'
                data: Array<{
                  __typename?: 'FeatureEntity'
                  attributes?: { __typename?: 'Feature'; name?: string | null } | null
                }>
              } | null
            } | null
          }>
        } | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            id?: string | null
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        offices?: {
          __typename?: 'OfficeRelationResponseCollection'
          data: Array<{
            __typename?: 'OfficeEntity'
            id?: string | null
            attributes?: { __typename?: 'Office'; name: string } | null
          }>
        } | null
        hqs?: {
          __typename?: 'HqRelationResponseCollection'
          data: Array<{
            __typename?: 'HqEntity'
            id?: string | null
            attributes?: { __typename?: 'Hq'; name: string } | null
          }>
        } | null
        regionsServed?: {
          __typename?: 'RegionsServedRelationResponseCollection'
          data: Array<{
            __typename?: 'RegionsServedEntity'
            attributes?: { __typename?: 'RegionsServed'; name: string } | null
          }>
        } | null
        languages?: {
          __typename?: 'PlatformLanguageRelationResponseCollection'
          data: Array<{
            __typename?: 'PlatformLanguageEntity'
            id?: string | null
            attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
          }>
        } | null
        articles?: Array<{
          __typename?: 'ComponentGlobalArticle'
          id: string
          title?: string | null
          publishedBy?: string | null
          date?: any | null
          link?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        features?: {
          __typename?: 'FeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'FeatureEntity'
            attributes?: { __typename?: 'Feature'; name?: string | null } | null
          }>
        } | null
        existingCustomers?: {
          __typename?: 'ExistingCustomerRelationResponseCollection'
          data: Array<{
            __typename?: 'ExistingCustomerEntity'
            id?: string | null
            attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
          }>
        } | null
        announcement?: {
          __typename?: 'AnnouncementEntityResponse'
          data?: {
            __typename?: 'AnnouncementEntity'
            id?: string | null
            attributes?: { __typename?: 'Announcement'; announcement: string; optionalLink?: string | null } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type ISubTopicFullItemFragment = {
  __typename?: 'SubTopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'SubTopic'
    slug: string
    name: string
    description?: string | null
    isPremium?: boolean | null
    publishedAt?: any | null
    expert?: {
      __typename?: 'ExpertEntityResponse'
      data?: {
        __typename?: 'ExpertEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Expert'
          firstName: string
          lastName: string
          displayName?: string | null
          title?: string | null
          company?: string | null
          bio?: string | null
          slug: string
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      } | null
    } | null
    contents?: {
      __typename?: 'ContentRelationResponseCollection'
      data: Array<{ __typename?: 'ContentEntity'; id?: string | null }>
    } | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Topic'
          slug: string
          name: string
          description?: string | null
          iconName?: string | null
          isPremium?: boolean | null
          publishedAt?: any | null
        } | null
      }>
    } | null
  } | null
}

export type ISubTopicItemFragment = {
  __typename?: 'SubTopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'SubTopic'
    slug: string
    name: string
    description?: string | null
    isPremium?: boolean | null
    publishedAt?: any | null
    topics?: {
      __typename?: 'TopicRelationResponseCollection'
      data: Array<{
        __typename?: 'TopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Topic'
          slug: string
          name: string
          description?: string | null
          iconName?: string | null
          isPremium?: boolean | null
          publishedAt?: any | null
        } | null
      }>
    } | null
  } | null
}

export type ISubTopicItemWithoutSubFragment = {
  __typename?: 'SubTopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'SubTopic'
    slug: string
    name: string
    description?: string | null
    isPremium?: boolean | null
    publishedAt?: any | null
  } | null
}

export type ITestimonialItemFragment = {
  __typename?: 'TestimonialsEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Testimonials'
    description?: string | null
    author?: {
      __typename?: 'ComponentTestimonialsPropsAuthor'
      id: string
      name?: string | null
      role?: string | null
      company?: string | null
      avatar?: {
        __typename?: 'UploadFileEntityResponse'
        data?: {
          __typename?: 'UploadFileEntity'
          attributes?: { __typename?: 'UploadFile'; url: string } | null
        } | null
      } | null
    } | null
  } | null
}

export type ITopicFullItemFragment = {
  __typename?: 'TopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Topic'
    slug: string
    name: string
    iconName?: string | null
    description?: string | null
    isPremium?: boolean | null
    publishedAt?: any | null
    expert?: {
      __typename?: 'ExpertEntityResponse'
      data?: {
        __typename?: 'ExpertEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Expert'
          firstName: string
          lastName: string
          displayName?: string | null
          title?: string | null
          company?: string | null
          bio?: string | null
          slug: string
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      } | null
    } | null
    contents?: {
      __typename?: 'ContentRelationResponseCollection'
      data: Array<{ __typename?: 'ContentEntity'; id?: string | null }>
    } | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          description?: string | null
          isPremium?: boolean | null
          publishedAt?: any | null
        } | null
      }>
    } | null
  } | null
}

export type ITopicHeaderDataFragment = {
  __typename?: 'TopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Topic'
    slug: string
    name: string
    description?: string | null
    isPremium?: boolean | null
    expert?: {
      __typename?: 'ExpertEntityResponse'
      data?: {
        __typename?: 'ExpertEntity'
        id?: string | null
        attributes?: {
          __typename?: 'Expert'
          firstName: string
          lastName: string
          displayName?: string | null
          title?: string | null
          company?: string | null
          bio?: string | null
          slug: string
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  } | null
}

export type ITopicItemFragment = {
  __typename?: 'TopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Topic'
    slug: string
    name: string
    iconName?: string | null
    description?: string | null
    isPremium?: boolean | null
    publishedAt?: any | null
    subTopics?: {
      __typename?: 'SubTopicRelationResponseCollection'
      data: Array<{
        __typename?: 'SubTopicEntity'
        id?: string | null
        attributes?: {
          __typename?: 'SubTopic'
          slug: string
          name: string
          description?: string | null
          isPremium?: boolean | null
          publishedAt?: any | null
        } | null
      }>
    } | null
  } | null
}

export type ITopicItemWithoutSubFragment = {
  __typename?: 'TopicEntity'
  id?: string | null
  attributes?: {
    __typename?: 'Topic'
    slug: string
    name: string
    description?: string | null
    iconName?: string | null
    isPremium?: boolean | null
    publishedAt?: any | null
  } | null
}

export type IGetAboutQueryVariables = Exact<{ [key: string]: never }>

export type IGetAboutQuery = {
  __typename?: 'Query'
  about?: {
    __typename?: 'AboutEntityResponse'
    data?: {
      __typename?: 'AboutEntity'
      id?: string | null
      attributes?: {
        __typename?: 'About'
        createdAt?: any | null
        updatedAt?: any | null
        title?: string | null
        description?: string | null
        who?: string | null
        what?: string | null
        email?: string | null
        phone?: any | null
        publishedAt?: any | null
        ourTeam?: Array<{
          __typename?: 'ComponentAboutPropsTeamMember'
          name?: string | null
          role?: string | null
          bio?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
      } | null
    } | null
  } | null
}

export type IGetAllCompaniesQueryVariables = Exact<{ [key: string]: never }>

export type IGetAllCompaniesQuery = {
  __typename?: 'Query'
  companies?: {
    __typename?: 'CompanyEntityResponseCollection'
    data: Array<{
      __typename?: 'CompanyEntity'
      id?: string | null
      attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
    }>
  } | null
}

export type IGetAudiencesQueryVariables = Exact<{ [key: string]: never }>

export type IGetAudiencesQuery = {
  __typename?: 'Query'
  demographics?: {
    __typename?: 'DemographicEntityResponseCollection'
    data: Array<{
      __typename?: 'DemographicEntity'
      id?: string | null
      attributes?: { __typename?: 'Demographic'; name: string } | null
    }>
  } | null
}

export type IGetCollectionsQueryVariables = Exact<{ [key: string]: never }>

export type IGetCollectionsQuery = {
  __typename?: 'Query'
  collections?: {
    __typename?: 'CollectionEntityResponseCollection'
    data: Array<{
      __typename?: 'CollectionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Collection'
        name: string
        description?: string | null
        featuredOnHomepage?: boolean | null
        slug?: string | null
        publishedAt?: any | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{
            __typename?: 'ContentEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Content'
              title: string
              slug: string
              snippet: string
              isMaintained?: boolean | null
              isPremium?: boolean | null
            } | null
          }>
        } | null
        solutions?: {
          __typename?: 'SolutionRelationResponseCollection'
          data: Array<{
            __typename?: 'SolutionEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Solution'
              displayName?: string | null
              type: IEnum_Solution_Type
              website?: string | null
              isEnhanced?: boolean | null
              isPremium?: boolean | null
              slug?: string | null
              shortDescription?: string | null
              longDescription?: string | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetCollectionsBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IGetCollectionsBySlugQuery = {
  __typename?: 'Query'
  collections?: {
    __typename?: 'CollectionEntityResponseCollection'
    data: Array<{
      __typename?: 'CollectionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Collection'
        name: string
        description?: string | null
        featuredOnHomepage?: boolean | null
        slug?: string | null
        publishedAt?: any | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{
            __typename?: 'ContentEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Content'
              title: string
              slug: string
              snippet: string
              isMaintained?: boolean | null
              isPremium?: boolean | null
              publishedTime?: any | null
              defaultImage?: {
                __typename?: 'UploadFileRelationResponseCollection'
                data: Array<{
                  __typename?: 'UploadFileEntity'
                  id?: string | null
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                }>
              } | null
            } | null
          }>
        } | null
        solutions?: {
          __typename?: 'SolutionRelationResponseCollection'
          data: Array<{
            __typename?: 'SolutionEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Solution'
              displayName?: string | null
              type: IEnum_Solution_Type
              website?: string | null
              isEnhanced?: boolean | null
              isPremium?: boolean | null
              slug?: string | null
              shortDescription?: string | null
              longDescription?: string | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetCompanyBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
  includeIlta: Scalars['Boolean']['input']
}>

export type IGetCompanyBySlugQuery = {
  __typename?: 'Query'
  companies?: {
    __typename?: 'CompanyEntityResponseCollection'
    data: Array<{
      __typename?: 'CompanyEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Company'
        slug: string
        name?: string | null
        description?: string | null
        website?: string | null
        services?: string | null
        yearFounded?: any | null
        size?: number | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        solutions?: {
          __typename?: 'SolutionRelationResponseCollection'
          data: Array<{
            __typename?: 'SolutionEntity'
            attributes?: { __typename?: 'Solution'; slug?: string | null } | null
          }>
        } | null
        regionsServed?: {
          __typename?: 'RegionsServedRelationResponseCollection'
          data: Array<{
            __typename?: 'RegionsServedEntity'
            attributes?: { __typename?: 'RegionsServed'; name: string } | null
          }>
        } | null
        hqs?: {
          __typename?: 'HqRelationResponseCollection'
          data: Array<{ __typename?: 'HqEntity'; attributes?: { __typename?: 'Hq'; name: string } | null }>
        } | null
        iltaSponsorships?: {
          __typename?: 'IltaSponsorshipRelationResponseCollection'
          data: Array<{
            __typename?: 'IltaSponsorshipEntity'
            attributes?: {
              __typename?: 'IltaSponsorship'
              name?: string | null
              logo?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetExistingCustomersPaginationQueryVariables = Exact<{
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
}>

export type IGetExistingCustomersPaginationQuery = {
  __typename?: 'Query'
  existingCustomers?: {
    __typename?: 'ExistingCustomerEntityResponseCollection'
    data: Array<{
      __typename?: 'ExistingCustomerEntity'
      id?: string | null
      attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
    }>
  } | null
}

export type IGetDurationsQueryVariables = Exact<{ [key: string]: never }>

export type IGetDurationsQuery = {
  __typename?: 'Query'
  durations?: {
    __typename?: 'DurationEntityResponseCollection'
    data: Array<{
      __typename?: 'DurationEntity'
      id?: string | null
      attributes?: { __typename?: 'Duration'; name: string; days?: number | null } | null
    }>
  } | null
}

export type IGetEnhancedRequestContentQueryVariables = Exact<{ [key: string]: never }>

export type IGetEnhancedRequestContentQuery = {
  __typename?: 'Query'
  enhancedRequestContent?: {
    __typename?: 'EnhancedRequestContentEntityResponse'
    data?: {
      __typename?: 'EnhancedRequestContentEntity'
      attributes?: {
        __typename?: 'EnhancedRequestContent'
        createdAt?: any | null
        updatedAt?: any | null
        title?: string | null
        description?: string | null
        moreVisibility?: string | null
        callToAction?: string | null
        screenshots?: string | null
        competitor?: string | null
        publishedAt?: any | null
      } | null
    } | null
  } | null
}

export type IEventsQueryVariables = Exact<{ [key: string]: never }>

export type IEventsQuery = {
  __typename?: 'Query'
  events?: {
    __typename?: 'EventEntityResponseCollection'
    data: Array<{
      __typename?: 'EventEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Event'
        title: string
        updatedAt?: any | null
        date?: any | null
        description?: string | null
        country: string
        city?: string | null
        featured?: boolean | null
        slug: string
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        durationId?: {
          __typename?: 'DurationEntityResponse'
          data?: {
            __typename?: 'DurationEntity'
            id?: string | null
            attributes?: { __typename?: 'Duration'; name: string; days?: number | null } | null
          } | null
        } | null
        formatId?: {
          __typename?: 'EventFormatEntityResponse'
          data?: {
            __typename?: 'EventFormatEntity'
            id?: string | null
            attributes?: { __typename?: 'EventFormat'; name: string } | null
          } | null
        } | null
        features?: {
          __typename?: 'EventFeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'EventFeatureEntity'
            id?: string | null
            attributes?: { __typename?: 'EventFeature'; name: string } | null
          }>
        } | null
        audiences?: {
          __typename?: 'AudienceRelationResponseCollection'
          data: Array<{
            __typename?: 'AudienceEntity'
            id?: string | null
            attributes?: { __typename?: 'Audience'; name: string } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetEventsByOffsetQueryVariables = Exact<{
  start?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type IGetEventsByOffsetQuery = {
  __typename?: 'Query'
  events?: {
    __typename?: 'EventEntityResponseCollection'
    data: Array<{
      __typename?: 'EventEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Event'
        title: string
        date?: any | null
        updatedAt?: any | null
        description?: string | null
        country: string
        city?: string | null
        featured?: boolean | null
        slug: string
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        durationId?: {
          __typename?: 'DurationEntityResponse'
          data?: {
            __typename?: 'DurationEntity'
            id?: string | null
            attributes?: { __typename?: 'Duration'; name: string } | null
          } | null
        } | null
        formatId?: {
          __typename?: 'EventFormatEntityResponse'
          data?: {
            __typename?: 'EventFormatEntity'
            id?: string | null
            attributes?: { __typename?: 'EventFormat'; name: string } | null
          } | null
        } | null
        features?: {
          __typename?: 'EventFeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'EventFeatureEntity'
            id?: string | null
            attributes?: { __typename?: 'EventFeature'; name: string } | null
          }>
        } | null
        audiences?: {
          __typename?: 'AudienceRelationResponseCollection'
          data: Array<{
            __typename?: 'AudienceEntity'
            id?: string | null
            attributes?: { __typename?: 'Audience'; name: string } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetEventsBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IGetEventsBySlugQuery = {
  __typename?: 'Query'
  events?: {
    __typename?: 'EventEntityResponseCollection'
    data: Array<{
      __typename?: 'EventEntity'
      attributes?: {
        __typename?: 'Event'
        slug: string
        createdAt?: any | null
        updatedAt?: any | null
        date?: any | null
        title: string
        website: string
        description?: string | null
        organizer: string
        notes?: string | null
        country: string
        city?: string | null
        featured?: boolean | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        formatId?: {
          __typename?: 'EventFormatEntityResponse'
          data?: {
            __typename?: 'EventFormatEntity'
            attributes?: { __typename?: 'EventFormat'; name: string } | null
          } | null
        } | null
        audiences?: {
          __typename?: 'AudienceRelationResponseCollection'
          data: Array<{ __typename?: 'AudienceEntity'; attributes?: { __typename?: 'Audience'; name: string } | null }>
        } | null
        durationId?: {
          __typename?: 'DurationEntityResponse'
          data?: { __typename?: 'DurationEntity'; attributes?: { __typename?: 'Duration'; name: string } | null } | null
        } | null
        recurrenceId?: {
          __typename?: 'RecurrenceEntityResponse'
          data?: {
            __typename?: 'RecurrenceEntity'
            attributes?: { __typename?: 'Recurrence'; name: string } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IGetExistingCustomersQueryVariables = Exact<{ [key: string]: never }>

export type IGetExistingCustomersQuery = {
  __typename?: 'Query'
  existingCustomers?: {
    __typename?: 'ExistingCustomerEntityResponseCollection'
    data: Array<{
      __typename?: 'ExistingCustomerEntity'
      id?: string | null
      attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
    }>
  } | null
}

export type IGetAttributesQueryVariables = Exact<{ [key: string]: never }>

export type IGetAttributesQuery = {
  __typename?: 'Query'
  attributes?: {
    __typename?: 'AttributeEntityResponseCollection'
    data: Array<{
      __typename?: 'AttributeEntity'
      id?: string | null
      attributes?: { __typename?: 'Attribute'; name: string } | null
    }>
  } | null
}

export type IGetFollowingTopicsQueryVariables = Exact<{ [key: string]: never }>

export type IGetFollowingTopicsQuery = {
  __typename?: 'Query'
  followingTopics?: Array<{
    __typename?: 'FollowingTopicEntity'
    id?: string | null
    attributes?: {
      __typename?: 'FollowingTopic'
      slug: string
      name: string
      description?: string | null
      isPremium?: boolean | null
      iconName?: string | null
      featuredOnHome?: boolean | null
      createdAt?: any | null
      updatedAt?: any | null
      subTopics?: {
        __typename?: 'FollowingSubTopicEntity'
        data?: Array<{
          __typename?: 'FollowingSubTopic'
          id?: string | null
          attributes?: { __typename?: 'SubTopic'; name: string; slug: string; isPremium?: boolean | null } | null
        } | null> | null
      } | null
    } | null
  } | null> | null
}

export type IGetFormatsQueryVariables = Exact<{ [key: string]: never }>

export type IGetFormatsQuery = {
  __typename?: 'Query'
  eventFormats?: {
    __typename?: 'EventFormatEntityResponseCollection'
    data: Array<{
      __typename?: 'EventFormatEntity'
      id?: string | null
      attributes?: { __typename?: 'EventFormat'; name: string } | null
    }>
  } | null
}

export type IGetIltaSponsorshipsQueryVariables = Exact<{ [key: string]: never }>

export type IGetIltaSponsorshipsQuery = {
  __typename?: 'Query'
  iltaSponsorships?: {
    __typename?: 'IltaSponsorshipEntityResponseCollection'
    data: Array<{
      __typename?: 'IltaSponsorshipEntity'
      id?: string | null
      attributes?: {
        __typename?: 'IltaSponsorship'
        name?: string | null
        level?: number | null
        description?: string | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IGetInstallationsQueryVariables = Exact<{ [key: string]: never }>

export type IGetInstallationsQuery = {
  __typename?: 'Query'
  installations?: {
    __typename?: 'InstallationEntityResponseCollection'
    data: Array<{
      __typename?: 'InstallationEntity'
      id?: string | null
      attributes?: { __typename?: 'Installation'; name: string } | null
    }>
  } | null
}

export type IGetIntegrationsQueryVariables = Exact<{ [key: string]: never }>

export type IGetIntegrationsQuery = {
  __typename?: 'Query'
  integrations?: {
    __typename?: 'IntegrationEntityResponseCollection'
    data: Array<{
      __typename?: 'IntegrationEntity'
      id?: string | null
      attributes?: { __typename?: 'Integration'; name: string } | null
    }>
  } | null
}

export type IGetIntegrationsPaginationQueryVariables = Exact<{
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
}>

export type IGetIntegrationsPaginationQuery = {
  __typename?: 'Query'
  integrations?: {
    __typename?: 'IntegrationEntityResponseCollection'
    data: Array<{
      __typename?: 'IntegrationEntity'
      id?: string | null
      attributes?: { __typename?: 'Integration'; name: string } | null
    }>
  } | null
}

export type IGetInvitedUsersQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type IGetInvitedUsersQuery = {
  __typename?: 'Query'
  invitedUsers: {
    __typename?: 'invitedUsersResult'
    total?: number | null
    invitations?: Array<{
      __typename?: 'ResourceInvitationEntity'
      name: string
      businessEmail: string
      companyLawFirmName: string
      status: boolean
      dateInvited: any
      contents: Array<{
        __typename?: 'SharedResourceEntity'
        id?: string | null
        title?: string | null
        slug?: string | null
        sharedResources: {
          __typename?: 'ContentVisitDownloadCountResult'
          visits?: Array<{
            __typename?: 'ContentVisit'
            visited_by?: {
              __typename?: 'UsersPermissionsUserEntityResponse'
              data?: {
                __typename?: 'UsersPermissionsUserEntity'
                id?: string | null
                attributes?: { __typename?: 'UsersPermissionsUser'; username: string } | null
              } | null
            } | null
          } | null> | null
          downloads?: Array<{
            __typename?: 'ContentDownload'
            downloaded_by?: {
              __typename?: 'UsersPermissionsUserEntityResponse'
              data?: {
                __typename?: 'UsersPermissionsUserEntity'
                id?: string | null
                attributes?: { __typename?: 'UsersPermissionsUser'; username: string } | null
              } | null
            } | null
          } | null> | null
        }
      } | null>
    } | null> | null
  }
}

export type IGetLanguagesQueryVariables = Exact<{ [key: string]: never }>

export type IGetLanguagesQuery = {
  __typename?: 'Query'
  platformLanguages?: {
    __typename?: 'PlatformLanguageEntityResponseCollection'
    data: Array<{
      __typename?: 'PlatformLanguageEntity'
      id?: string | null
      attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
    }>
  } | null
}

export type IGetLanguagesPaginationQueryVariables = Exact<{
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
}>

export type IGetLanguagesPaginationQuery = {
  __typename?: 'Query'
  platformLanguages?: {
    __typename?: 'PlatformLanguageEntityResponseCollection'
    data: Array<{
      __typename?: 'PlatformLanguageEntity'
      id?: string | null
      attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
    }>
  } | null
}

export type IGetLocationsQueryVariables = Exact<{ [key: string]: never }>

export type IGetLocationsQuery = { __typename?: 'Query'; location?: Array<string | null> | null }

export type IGetMyResourcesProductTypesQueryVariables = Exact<{ [key: string]: never }>

export type IGetMyResourcesProductTypesQuery = {
  __typename?: 'Query'
  myResourcesProductTypes: {
    __typename?: 'ShareableTopicsReturnType'
    topics: Array<{
      __typename?: 'ShareableTopicInfo'
      id: number
      name: string
      isPremium?: boolean | null
      subTopics?: Array<{
        __typename?: 'SubTopicInfo'
        id?: number | null
        name: string
        isPremium?: boolean | null
      }> | null
    }>
  }
}

export type IGetNewsletterCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type IGetNewsletterCategoriesQuery = {
  __typename?: 'Query'
  newsletterCategories?: {
    __typename?: 'NewsletterCategoryEntityResponseCollection'
    data: Array<{
      __typename?: 'NewsletterCategoryEntity'
      id?: string | null
      attributes?: { __typename?: 'NewsletterCategory'; name?: string | null } | null
    }>
  } | null
}

export type IGetOfficesQueryVariables = Exact<{ [key: string]: never }>

export type IGetOfficesQuery = {
  __typename?: 'Query'
  offices?: {
    __typename?: 'OfficeEntityResponseCollection'
    data: Array<{
      __typename?: 'OfficeEntity'
      id?: string | null
      attributes?: { __typename?: 'Office'; name: string } | null
    }>
  } | null
}

export type IGetOfficesPaginationQueryVariables = Exact<{
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
}>

export type IGetOfficesPaginationQuery = {
  __typename?: 'Query'
  offices?: {
    __typename?: 'OfficeEntityResponseCollection'
    data: Array<{
      __typename?: 'OfficeEntity'
      id?: string | null
      attributes?: { __typename?: 'Office'; name: string } | null
    }>
  } | null
}

export type IGetOrganizationQueryVariables = Exact<{ [key: string]: never }>

export type IGetOrganizationQuery = { __typename?: 'Query'; organization?: Array<string | null> | null }

export type IGetOrganizationNamesQueryVariables = Exact<{ [key: string]: never }>

export type IGetOrganizationNamesQuery = {
  __typename?: 'Query'
  premiumOrganizations?: {
    __typename?: 'PremiumOrganizationEntityResponseCollection'
    data: Array<{
      __typename?: 'PremiumOrganizationEntity'
      id?: string | null
      attributes?: { __typename?: 'PremiumOrganization'; name: string } | null
    }>
  } | null
}

export type IPhasesQueryVariables = Exact<{ [key: string]: never }>

export type IPhasesQuery = {
  __typename?: 'Query'
  phases?: {
    __typename?: 'PhaseEntityResponseCollection'
    data: Array<{
      __typename?: 'PhaseEntity'
      id?: string | null
      attributes?: { __typename?: 'Phase'; title: string; description?: string | null } | null
    }>
  } | null
}

export type IGetPracticeAreasQueryVariables = Exact<{ [key: string]: never }>

export type IGetPracticeAreasQuery = {
  __typename?: 'Query'
  practiceAreas?: {
    __typename?: 'PracticeAreaEntityResponseCollection'
    data: Array<{
      __typename?: 'PracticeAreaEntity'
      id?: string | null
      attributes?: { __typename?: 'PracticeArea'; name: string } | null
    }>
  } | null
}

export type IGetPracticeAreasPaginationQueryVariables = Exact<{
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
}>

export type IGetPracticeAreasPaginationQuery = {
  __typename?: 'Query'
  practiceAreas?: {
    __typename?: 'PracticeAreaEntityResponseCollection'
    data: Array<{
      __typename?: 'PracticeAreaEntity'
      id?: string | null
      attributes?: { __typename?: 'PracticeArea'; name: string } | null
    }>
  } | null
}

export type IGetPremiumOrganizationsByDomainEmailQueryVariables = Exact<{
  domainEmail: Scalars['String']['input']
}>

export type IGetPremiumOrganizationsByDomainEmailQuery = {
  __typename?: 'Query'
  premiumOrganizations?: {
    __typename?: 'PremiumOrganizationEntityResponseCollection'
    data: Array<{ __typename?: 'PremiumOrganizationEntity'; id?: string | null }>
  } | null
}

export type IGetPricingPlansQueryVariables = Exact<{ [key: string]: never }>

export type IGetPricingPlansQuery = {
  __typename?: 'Query'
  premiumPricingPlans?: {
    __typename?: 'PremiumPricingPlanEntityResponseCollection'
    data: Array<{
      __typename?: 'PremiumPricingPlanEntity'
      id?: string | null
      attributes?: {
        __typename?: 'PremiumPricingPlan'
        name: string
        pricePerYear: number
        stripeRedirectUrl: string
      } | null
    }>
  } | null
}

export type IGetPrivacyPolicyQueryVariables = Exact<{ [key: string]: never }>

export type IGetPrivacyPolicyQuery = {
  __typename?: 'Query'
  privacyPolicy?: {
    __typename?: 'PrivacyPolicyEntityResponse'
    data?: {
      __typename?: 'PrivacyPolicyEntity'
      attributes?: {
        __typename?: 'PrivacyPolicy'
        summary?: string | null
        content?: string | null
        date?: any | null
      } | null
    } | null
  } | null
}

export type IGetRegionsServedQueryVariables = Exact<{ [key: string]: never }>

export type IGetRegionsServedQuery = {
  __typename?: 'Query'
  regionsServeds?: {
    __typename?: 'RegionsServedEntityResponseCollection'
    data: Array<{
      __typename?: 'RegionsServedEntity'
      id?: string | null
      attributes?: { __typename?: 'RegionsServed'; name: string } | null
    }>
  } | null
}

export type IGetSsoOrganizationCognitoProvidersQueryVariables = Exact<{ [key: string]: never }>

export type IGetSsoOrganizationCognitoProvidersQuery = {
  __typename?: 'Query'
  premiumOrganizations?: {
    __typename?: 'PremiumOrganizationEntityResponseCollection'
    data: Array<{
      __typename?: 'PremiumOrganizationEntity'
      attributes?: { __typename?: 'PremiumOrganization'; cognitoProviderId?: string | null } | null
    }>
  } | null
}

export type IGetSecuritiesQueryVariables = Exact<{ [key: string]: never }>

export type IGetSecuritiesQuery = {
  __typename?: 'Query'
  securities?: {
    __typename?: 'SecurityEntityResponseCollection'
    data: Array<{
      __typename?: 'SecurityEntity'
      id?: string | null
      attributes?: { __typename?: 'Security'; name: string } | null
    }>
  } | null
}

export type IGetShareableContentsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  query: IMySharedContentsFilters
}>

export type IGetShareableContentsQuery = {
  __typename?: 'Query'
  shareableContents: {
    __typename?: 'ShareableContentsReturnType'
    total?: number | null
    contents?: Array<{
      __typename: 'ContentEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Content'
        title: string
        slug: string
        snippet: string
        createdAt?: any | null
        publishedTime?: any | null
        contentType?: IEnum_Content_Contenttype | null
        documentType?: IEnum_Content_Documenttype | null
        publishedAt?: any | null
      } | null
    }> | null
  }
}

export type IGetShareableTopicsQueryVariables = Exact<{ [key: string]: never }>

export type IGetShareableTopicsQuery = {
  __typename?: 'Query'
  shareableTopics: {
    __typename?: 'ShareableTopicsReturnType'
    topics: Array<{
      __typename?: 'ShareableTopicInfo'
      id: number
      name: string
      isPremium?: boolean | null
      subTopics?: Array<{
        __typename?: 'SubTopicInfo'
        id?: number | null
        name: string
        isPremium?: boolean | null
      }> | null
    }>
  }
}

export type IGetSlugUpdatesQueryVariables = Exact<{
  type: Scalars['String']['input']
  oldSlug: Scalars['String']['input']
}>

export type IGetSlugUpdatesQuery = {
  __typename?: 'Query'
  slugUpdates?: {
    __typename?: 'SlugUpdateEntityResponseCollection'
    data: Array<{
      __typename?: 'SlugUpdateEntity'
      attributes?: { __typename?: 'SlugUpdate'; newSlug?: string | null } | null
    }>
  } | null
}

export type ISolutionsForVendorQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type ISolutionsForVendorQuery = {
  __typename?: 'Query'
  searchVendorSolutions: {
    __typename?: 'SearchSolutionsResult'
    total?: number | null
    solutions?: Array<{
      __typename?: 'SolutionItem'
      id: string
      slug: string
      type: IEnum_Solution_Item_Type
      displayName: string
      isPremium?: boolean | null
      isEnhanced?: boolean | null
      subTopics: Array<string>
      rating?: number | null
      reviewCnt?: number | null
      description?: string | null
      logo?: string | null
      offices: Array<{
        id?: number
        __typename?: 'Locations'
        name: string
      }>
      security: Array<{
        id?: number
        __typename?: 'Security'
        name: string
      }>
    } | null> | null
  }
}

export type IGetSubTopicBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IGetSubTopicBySlugQuery = {
  __typename?: 'Query'
  subTopics?: {
    __typename?: 'SubTopicEntityResponseCollection'
    data: Array<{
      __typename?: 'SubTopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SubTopic'
        slug: string
        name: string
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        expert?: {
          __typename?: 'ExpertEntityResponse'
          data?: {
            __typename?: 'ExpertEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Expert'
              firstName: string
              lastName: string
              displayName?: string | null
              title?: string | null
              company?: string | null
              bio?: string | null
              slug: string
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{ __typename?: 'ContentEntity'; id?: string | null }>
        } | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Topic'
              slug: string
              name: string
              description?: string | null
              iconName?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetSubTopicsQueryVariables = Exact<{
  start: Scalars['Int']['input']
}>

export type IGetSubTopicsQuery = {
  __typename?: 'Query'
  subTopics?: {
    __typename?: 'SubTopicEntityResponseCollection'
    data: Array<{
      __typename?: 'SubTopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SubTopic'
        slug: string
        name: string
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Topic'
              slug: string
              name: string
              description?: string | null
              iconName?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetFeaturesQueryVariables = Exact<{ [key: string]: never }>

export type IGetFeaturesQuery = {
  __typename?: 'Query'
  subTopics?: {
    __typename?: 'SubTopicEntityResponseCollection'
    data: Array<{
      __typename?: 'SubTopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SubTopic'
        features?: {
          __typename?: 'FeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'FeatureEntity'
            id?: string | null
            attributes?: { __typename?: 'Feature'; name?: string | null } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetSubjectMatterQueryVariables = Exact<{ [key: string]: never }>

export type IGetSubjectMatterQuery = {
  __typename?: 'Query'
  subjectMatters?: {
    __typename?: 'SubjectMatterEntityResponseCollection'
    data: Array<{
      __typename?: 'SubjectMatterEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SubjectMatter'
        name?: string | null
        slug: string
        description?: string | null
      } | null
    }>
  } | null
}

export type IGetTermsConditionsQueryVariables = Exact<{ [key: string]: never }>

export type IGetTermsConditionsQuery = {
  __typename?: 'Query'
  termsConditions?: {
    __typename?: 'TermsConditionsEntityResponse'
    data?: {
      __typename?: 'TermsConditionsEntity'
      attributes?: {
        __typename?: 'TermsConditions'
        summary?: string | null
        content?: string | null
        date?: any | null
      } | null
    } | null
  } | null
}

export type IGetThemeQueryVariables = Exact<{ [key: string]: never }>

export type IGetThemeQuery = {
  __typename?: 'Query'
  themes?: {
    __typename?: 'ThemeEntityResponseCollection'
    data: Array<{
      __typename?: 'ThemeEntity'
      id?: string | null
      attributes?: { __typename?: 'Theme'; name?: string | null; slug: string; description?: string | null } | null
    }>
  } | null
}

export type IGetTopicBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IGetTopicBySlugQuery = {
  __typename?: 'Query'
  topics?: {
    __typename?: 'TopicEntityResponseCollection'
    data: Array<{
      __typename?: 'TopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Topic'
        slug: string
        name: string
        iconName?: string | null
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        expert?: {
          __typename?: 'ExpertEntityResponse'
          data?: {
            __typename?: 'ExpertEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Expert'
              firstName: string
              lastName: string
              displayName?: string | null
              title?: string | null
              company?: string | null
              bio?: string | null
              slug: string
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{ __typename?: 'ContentEntity'; id?: string | null }>
        } | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              description?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetTopicsQueryVariables = Exact<{ [key: string]: never }>

export type IGetTopicsQuery = {
  __typename?: 'Query'
  topics?: {
    __typename?: 'TopicEntityResponseCollection'
    data: Array<{
      __typename?: 'TopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Topic'
        slug: string
        name: string
        iconName?: string | null
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              description?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetTopicsForHeaderQueryVariables = Exact<{ [key: string]: never }>

export type IGetTopicsForHeaderQuery = {
  __typename?: 'Query'
  topics?: {
    __typename?: 'TopicEntityResponseCollection'
    data: Array<{
      __typename?: 'TopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Topic'
        slug: string
        name: string
        iconName?: string | null
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              description?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetTopicsWithoutSubsQueryVariables = Exact<{ [key: string]: never }>

export type IGetTopicsWithoutSubsQuery = {
  __typename?: 'Query'
  topics?: {
    __typename?: 'TopicEntityResponseCollection'
    data: Array<{
      __typename?: 'TopicEntity'
      attributes?: {
        __typename?: 'Topic'
        name: string
        slug: string
        iconName?: string | null
        isPremium?: boolean | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            attributes?: { __typename?: 'SubTopic'; name: string; slug: string } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IGetTypeOfUsersQueryVariables = Exact<{ [key: string]: never }>

export type IGetTypeOfUsersQuery = {
  __typename?: 'Query'
  typeOfUsers?: {
    __typename?: 'TypeOfUserEntityResponseCollection'
    data: Array<{
      __typename?: 'TypeOfUserEntity'
      id?: string | null
      attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
    }>
  } | null
}

export type IGetVendorTopicsQueryVariables = Exact<{ [key: string]: never }>

export type IGetVendorTopicsQuery = {
  __typename?: 'Query'
  vendorTopics?: {
    __typename?: 'VendorTopicEntityResponseCollection'
    data: Array<{
      __typename?: 'VendorTopicEntity'
      id?: string | null
      attributes?: { __typename?: 'VendorTopic'; name?: string | null } | null
    }>
  } | null
}

export type IGlobalTestimonialsQueryVariables = Exact<{ [key: string]: never }>

export type IGlobalTestimonialsQuery = {
  __typename?: 'Query'
  testimonialsPlural?: {
    __typename?: 'TestimonialsEntityResponseCollection'
    data: Array<{
      __typename?: 'TestimonialsEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Testimonials'
        description?: string | null
        author?: {
          __typename?: 'ComponentTestimonialsPropsAuthor'
          id: string
          name?: string | null
          role?: string | null
          company?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IHqsQueryVariables = Exact<{ [key: string]: never }>

export type IHqsQuery = {
  __typename?: 'Query'
  hqs?: {
    __typename?: 'HqEntityResponseCollection'
    data: Array<{
      __typename?: 'HqEntity'
      id?: string | null
      attributes?: { __typename?: 'Hq'; name: string } | null
    }>
  } | null
}

export type IHqsPaginationQueryVariables = Exact<{
  page: Scalars['Int']['input']
  pageSize: Scalars['Int']['input']
  search?: InputMaybe<Scalars['String']['input']>
}>

export type IHqsPaginationQuery = {
  __typename?: 'Query'
  hqs?: {
    __typename?: 'HqEntityResponseCollection'
    data: Array<{
      __typename?: 'HqEntity'
      id?: string | null
      attributes?: { __typename?: 'Hq'; name: string } | null
    }>
  } | null
}

export type IIsFollowQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type IIsFollowQuery = { __typename?: 'Query'; isFollow?: boolean | null }

export type ILoginMutationVariables = Exact<{
  input: IUsersPermissionsLoginInput
}>

export type ILoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'UsersPermissionsLoginPayload'
    jwt?: string | null
    user: {
      __typename?: 'UsersPermissionsMe'
      id: string
      username: string
      email?: string | null
      confirmed?: boolean | null
    }
  }
}

export type IMySharedContentsQueryVariables = Exact<{
  offset: Scalars['Int']['input']
  limit: Scalars['Int']['input']
  query: IMySharedContentsFilters
}>

export type IMySharedContentsQuery = {
  __typename?: 'Query'
  mySharedContents: {
    __typename?: 'MySharedContentsReturnType'
    total: number
    contents: Array<{
      __typename?: 'ContentEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Content'
        title: string
        snippet: string
        createdAt?: any | null
        publishedTime?: any | null
        contentType?: IEnum_Content_Contenttype | null
        documentType?: IEnum_Content_Documenttype | null
        isMaintained?: boolean | null
        lastMaintained?: any | null
        slug: string
        defaultImage?: {
          __typename?: 'UploadFileRelationResponseCollection'
          data: Array<{
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          }>
        } | null
        blogTaxonomies?: {
          __typename?: 'BlogTaxonomyRelationResponseCollection'
          data: Array<{
            __typename?: 'BlogTaxonomyEntity'
            id?: string | null
            attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
          }>
        } | null
        audiences?: {
          __typename?: 'PremiumAudienceRelationResponseCollection'
          data: Array<{
            __typename?: 'PremiumAudienceEntity'
            id?: string | null
            attributes?: { __typename?: 'PremiumAudience'; name: string } | null
          }>
        } | null
        subjectMatters?: {
          __typename?: 'SubjectMatterRelationResponseCollection'
          data: Array<{
            __typename?: 'SubjectMatterEntity'
            id?: string | null
            attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
          }>
        } | null
        writer?: {
          __typename?: 'ComponentContentContentWriter'
          writerOption: IEnum_Componentcontentcontentwriter_Writeroption
          oneTimeAuthorName?: string | null
          author?: {
            __typename?: 'AuthorEntityResponse'
            data?: {
              __typename?: 'AuthorEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Author'
                firstName: string
                lastName: string
                jobTitle?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          expert?: {
            __typename?: 'ExpertEntityResponse'
            data?: {
              __typename?: 'ExpertEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Expert'
                firstName: string
                lastName: string
                displayName?: string | null
                title?: string | null
                company?: string | null
                bio?: string | null
                slug: string
                avatar?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                displayName?: string | null
                slug?: string | null
                type: IEnum_Solution_Type
                shortDescription?: string | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    }>
  }
}

export type IPhasesForSubTopicQueryVariables = Exact<{ [key: string]: never }>

export type IPhasesForSubTopicQuery = {
  __typename?: 'Query'
  phases?: {
    __typename?: 'PhaseEntityResponseCollection'
    data: Array<{
      __typename?: 'PhaseEntity'
      id?: string | null
      attributes?: { __typename?: 'Phase'; title: string; description?: string | null } | null
    }>
  } | null
}

export type IPopularTopicsQueryVariables = Exact<{
  vendorLimit: Scalars['Int']['input']
}>

export type IPopularTopicsQuery = {
  __typename?: 'Query'
  popularTopics: Array<{
    __typename?: 'PopularTopic'
    id: number
    name: string
    slug: string
    solutions: Array<{
      __typename?: 'PopularTopicListing'
      slug: string
      type?: IEnum_Solution_Type | null
      logo?: { __typename?: 'PopularTopicListingLogo'; url?: string | null } | null
    }>
  }>
}

export type IPostShareContentsMutationVariables = Exact<{
  data: IResourceInvitationInput
}>
export type IPostShareVendorStatusMutationVariables = Exact<{
  data: IVendorNameInput
}>
export type IPostShareContentsMutation = { __typename?: 'Mutation'; shareContents?: boolean | null }

export type IPostShareVendorStatusMutation = { __typename?: 'Mutation'; shareContents?: boolean | null }

export type IPremiumAudiencesQueryVariables = Exact<{
  filters?: InputMaybe<IPremiumAudienceFiltersInput>
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>
}>

export type IPremiumAudiencesQuery = {
  __typename?: 'Query'
  premiumAudiences?: {
    __typename?: 'PremiumAudienceEntityResponseCollection'
    data: Array<{
      __typename?: 'PremiumAudienceEntity'
      id?: string | null
      attributes?: { __typename?: 'PremiumAudience'; name: string; shouldShowOnHomepage?: boolean | null } | null
    }>
  } | null
}

export type IPremiumSubTopicsQueryVariables = Exact<{ [key: string]: never }>

export type IPremiumSubTopicsQuery = {
  __typename?: 'Query'
  subTopics?: {
    __typename?: 'SubTopicEntityResponseCollection'
    data: Array<{
      __typename?: 'SubTopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SubTopic'
        slug: string
        name: string
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Topic'
              slug: string
              name: string
              description?: string | null
              iconName?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IPremiumSubTopicsWithContentsQueryVariables = Exact<{ [key: string]: never }>

export type IPremiumSubTopicsWithContentsQuery = {
  __typename?: 'Query'
  subTopics?: {
    __typename?: 'SubTopicEntityResponseCollection'
    data: Array<{
      __typename?: 'SubTopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'SubTopic'
        slug: string
        name: string
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{
            __typename?: 'ContentEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Content'
              title: string
              snippet: string
              createdAt?: any | null
              publishedTime?: any | null
              contentType?: IEnum_Content_Contenttype | null
              documentType?: IEnum_Content_Documenttype | null
              isMaintained?: boolean | null
              lastMaintained?: any | null
              slug: string
              defaultImage?: {
                __typename?: 'UploadFileRelationResponseCollection'
                data: Array<{
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                }>
              } | null
              blogTaxonomies?: {
                __typename?: 'BlogTaxonomyRelationResponseCollection'
                data: Array<{
                  __typename?: 'BlogTaxonomyEntity'
                  id?: string | null
                  attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
                }>
              } | null
              audiences?: {
                __typename?: 'PremiumAudienceRelationResponseCollection'
                data: Array<{
                  __typename?: 'PremiumAudienceEntity'
                  id?: string | null
                  attributes?: { __typename?: 'PremiumAudience'; name: string } | null
                }>
              } | null
              subjectMatters?: {
                __typename?: 'SubjectMatterRelationResponseCollection'
                data: Array<{
                  __typename?: 'SubjectMatterEntity'
                  id?: string | null
                  attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
                }>
              } | null
              writer?: {
                __typename?: 'ComponentContentContentWriter'
                writerOption: IEnum_Componentcontentcontentwriter_Writeroption
                oneTimeAuthorName?: string | null
                author?: {
                  __typename?: 'AuthorEntityResponse'
                  data?: {
                    __typename?: 'AuthorEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Author'
                      firstName: string
                      lastName: string
                      jobTitle?: string | null
                      company?: string | null
                      bio?: string | null
                      slug: string
                      avatar?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
                expert?: {
                  __typename?: 'ExpertEntityResponse'
                  data?: {
                    __typename?: 'ExpertEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Expert'
                      firstName: string
                      lastName: string
                      displayName?: string | null
                      title?: string | null
                      company?: string | null
                      bio?: string | null
                      slug: string
                      avatar?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
                solution?: {
                  __typename?: 'SolutionEntityResponse'
                  data?: {
                    __typename?: 'SolutionEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Solution'
                      displayName?: string | null
                      slug?: string | null
                      type: IEnum_Solution_Type
                      shortDescription?: string | null
                      logo?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Topic'
              slug: string
              name: string
              description?: string | null
              iconName?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IPremiumTopicsQueryVariables = Exact<{ [key: string]: never }>

export type IPremiumTopicsQuery = {
  __typename?: 'Query'
  topics?: {
    __typename?: 'TopicEntityResponseCollection'
    data: Array<{
      __typename?: 'TopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Topic'
        slug: string
        name: string
        iconName?: string | null
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              description?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IPremiumTopicsWithContentsQueryVariables = Exact<{ [key: string]: never }>

export type IPremiumTopicsWithContentsQuery = {
  __typename?: 'Query'
  topics?: {
    __typename?: 'TopicEntityResponseCollection'
    data: Array<{
      __typename?: 'TopicEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Topic'
        slug: string
        name: string
        iconName?: string | null
        description?: string | null
        isPremium?: boolean | null
        publishedAt?: any | null
        contents?: {
          __typename?: 'ContentRelationResponseCollection'
          data: Array<{
            __typename?: 'ContentEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Content'
              title: string
              snippet: string
              createdAt?: any | null
              publishedTime?: any | null
              contentType?: IEnum_Content_Contenttype | null
              documentType?: IEnum_Content_Documenttype | null
              isMaintained?: boolean | null
              lastMaintained?: any | null
              slug: string
              defaultImage?: {
                __typename?: 'UploadFileRelationResponseCollection'
                data: Array<{
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                }>
              } | null
              blogTaxonomies?: {
                __typename?: 'BlogTaxonomyRelationResponseCollection'
                data: Array<{
                  __typename?: 'BlogTaxonomyEntity'
                  id?: string | null
                  attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
                }>
              } | null
              audiences?: {
                __typename?: 'PremiumAudienceRelationResponseCollection'
                data: Array<{
                  __typename?: 'PremiumAudienceEntity'
                  id?: string | null
                  attributes?: { __typename?: 'PremiumAudience'; name: string } | null
                }>
              } | null
              subjectMatters?: {
                __typename?: 'SubjectMatterRelationResponseCollection'
                data: Array<{
                  __typename?: 'SubjectMatterEntity'
                  id?: string | null
                  attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
                }>
              } | null
              writer?: {
                __typename?: 'ComponentContentContentWriter'
                writerOption: IEnum_Componentcontentcontentwriter_Writeroption
                oneTimeAuthorName?: string | null
                author?: {
                  __typename?: 'AuthorEntityResponse'
                  data?: {
                    __typename?: 'AuthorEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Author'
                      firstName: string
                      lastName: string
                      jobTitle?: string | null
                      company?: string | null
                      bio?: string | null
                      slug: string
                      avatar?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
                expert?: {
                  __typename?: 'ExpertEntityResponse'
                  data?: {
                    __typename?: 'ExpertEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Expert'
                      firstName: string
                      lastName: string
                      displayName?: string | null
                      title?: string | null
                      company?: string | null
                      bio?: string | null
                      slug: string
                      avatar?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
                solution?: {
                  __typename?: 'SolutionEntityResponse'
                  data?: {
                    __typename?: 'SolutionEntity'
                    id?: string | null
                    attributes?: {
                      __typename?: 'Solution'
                      displayName?: string | null
                      slug?: string | null
                      type: IEnum_Solution_Type
                      shortDescription?: string | null
                      logo?: {
                        __typename?: 'UploadFileEntityResponse'
                        data?: {
                          __typename?: 'UploadFileEntity'
                          attributes?: { __typename?: 'UploadFile'; url: string } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              description?: string | null
              isPremium?: boolean | null
              publishedAt?: any | null
            } | null
          }>
        } | null
      } | null
    }>
  } | null
}

export type IProductBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
  includeIlta: Scalars['Boolean']['input']
}>

export type IProductBySlugQuery = {
  __typename?: 'Query'
  solutions?: {
    __typename?: 'SolutionEntityResponseCollection'
    data: Array<{
      __typename?: 'SolutionEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Solution'
        productType?: IEnum_Solution_Producttype | null
        displayName?: string | null
        type: IEnum_Solution_Type
        website?: string | null
        linkedin?: string | null
        twitter?: string | null
        isEnhanced?: boolean | null
        isPremium?: boolean | null
        slug?: string | null
        shortDescription?: string | null
        longDescription?: string | null
        rating?: number | null
        yearFounded?: number | null
        createdAt?: any | null
        publishedAt?: any | null
        productData?: {
          __typename?: 'ComponentSolutionProductData'
          name?: string | null
          tool?: string | null
          fundingInfo?: IEnum_Componentsolutionproductdata_Fundinginfo | null
          fundingAmount?: IEnum_Componentsolutionproductdata_Fundingamount | null
          valueProposition?: string | null
          audiences?: {
            __typename?: 'DemographicRelationResponseCollection'
            data: Array<{
              __typename?: 'DemographicEntity'
              attributes?: { __typename?: 'Demographic'; name: string } | null
            }>
          } | null
          attributes?: {
            __typename?: 'AttributeRelationResponseCollection'
            data: Array<{
              __typename?: 'AttributeEntity'
              attributes?: { __typename?: 'Attribute'; name: string } | null
            }>
          } | null
          deployments?: {
            __typename?: 'InstallationRelationResponseCollection'
            data: Array<{
              __typename?: 'InstallationEntity'
              attributes?: { __typename?: 'Installation'; name: string } | null
            }>
          } | null
          integrations?: {
            __typename?: 'IntegrationRelationResponseCollection'
            data: Array<{
              __typename?: 'IntegrationEntity'
              id?: string | null
              attributes?: { __typename?: 'Integration'; name: string } | null
            }>
          } | null
          practiceAreas?: {
            __typename?: 'PracticeAreaRelationResponseCollection'
            data: Array<{
              __typename?: 'PracticeAreaEntity'
              id?: string | null
              attributes?: { __typename?: 'PracticeArea'; name: string } | null
            }>
          } | null
          typeOfUser?: {
            __typename?: 'TypeOfUserRelationResponseCollection'
            data: Array<{
              __typename?: 'TypeOfUserEntity'
              id?: string | null
              attributes?: { __typename?: 'TypeOfUser'; name?: string | null } | null
            }>
          } | null
          security?: {
            __typename?: 'SecurityRelationResponseCollection'
            data: Array<{
              __typename?: 'SecurityEntity'
              id?: string | null
              attributes?: { __typename?: 'Security'; name: string } | null
            }>
          } | null
          dataHostingLocations?: {
            __typename?: 'OfficeRelationResponseCollection'
            data: Array<{
              __typename?: 'OfficeEntity'
              id?: string | null
              attributes?: { __typename?: 'Office'; name: string } | null
            }>
          } | null
          enhancedListingData?: {
            __typename?: 'ComponentSolutionPropsProductEnhanced'
            pricingData?: string | null
            callToAction?: {
              __typename?: 'ComponentGlobalCallToAction'
              label?: string | null
              url?: string | null
            } | null
            screenshots?: Array<{
              __typename?: 'ComponentGlobalPicture'
              id: string
              title?: string | null
              summary?: string | null
              image?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  id?: string | null
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
            videos?: Array<{
              __typename?: 'ComponentSolutionPropsVideo'
              id: string
              title?: string | null
              summary?: string | null
              url?: string | null
            } | null> | null
            testimonials?: Array<{
              __typename?: 'ComponentGlobalTestimonial'
              id: string
              description?: string | null
              name?: string | null
              role?: string | null
              company?: string | null
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null> | null
          } | null
        } | null
        founders?: Array<{
          __typename?: 'ComponentAboutPropsTeamMember'
          id: string
          name?: string | null
          role?: string | null
          bio?: string | null
          avatar?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              id?: string | null
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        consolidationData?: {
          __typename?: 'ComponentSolutionPropsConsolidation'
          date?: any | null
          amount?: number | null
          article?: string | null
          articleLink?: string | null
          originalTargetName?: string | null
          buyer?: string | null
        } | null
        graveyardData?: { __typename?: 'ComponentSolutionPropsGraveyard'; date?: any | null } | null
        vendor?: {
          __typename?: 'VendorEntityResponse'
          data?: {
            __typename?: 'VendorEntity'
            attributes?: {
              __typename?: 'Vendor'
              users?: {
                __typename?: 'UsersPermissionsUserRelationResponseCollection'
                data: Array<{
                  __typename?: 'UsersPermissionsUserEntity'
                  attributes?: { __typename?: 'UsersPermissionsUser'; external_id?: string | null } | null
                }>
              } | null
            } | null
          } | null
        } | null
        topics?: {
          __typename?: 'TopicRelationResponseCollection'
          data: Array<{
            __typename?: 'TopicEntity'
            id?: string | null
            attributes?: { __typename?: 'Topic'; slug: string; name: string } | null
          }>
        } | null
        subTopics?: {
          __typename?: 'SubTopicRelationResponseCollection'
          data: Array<{
            __typename?: 'SubTopicEntity'
            id?: string | null
            attributes?: {
              __typename?: 'SubTopic'
              slug: string
              name: string
              features?: {
                __typename?: 'FeatureRelationResponseCollection'
                data: Array<{
                  __typename?: 'FeatureEntity'
                  attributes?: { __typename?: 'Feature'; name?: string | null } | null
                }>
              } | null
            } | null
          }>
        } | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            id?: string | null
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        company?: {
          __typename?: 'CompanyEntityResponse'
          data?: {
            __typename?: 'CompanyEntity'
            attributes?: { __typename?: 'Company'; name?: string | null; slug: string } | null
          } | null
        } | null
        reviews?: {
          __typename?: 'ReviewRelationResponseCollection'
          data: Array<{
            __typename?: 'ReviewEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Review'
              anonymous?: boolean | null
              rating?: number | null
              title?: string | null
              content?: string | null
              submitterTitle?: string | null
              submitterSubTitle?: string | null
              solutionDispName?: string | null
              createdAt?: any | null
              updatedAt?: any | null
              publishedAt?: any | null
              solution?: {
                __typename?: 'SolutionEntityResponse'
                data?: {
                  __typename?: 'SolutionEntity'
                  id?: string | null
                  attributes?: {
                    __typename?: 'Solution'
                    displayName?: string | null
                    slug?: string | null
                    rating?: number | null
                    company?: {
                      __typename?: 'CompanyEntityResponse'
                      data?: {
                        __typename?: 'CompanyEntity'
                        attributes?: { __typename?: 'Company'; slug: string } | null
                      } | null
                    } | null
                    logo?: {
                      __typename?: 'UploadFileEntityResponse'
                      data?: {
                        __typename?: 'UploadFileEntity'
                        attributes?: { __typename?: 'UploadFile'; url: string } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          }>
        } | null
        offices?: {
          __typename?: 'OfficeRelationResponseCollection'
          data: Array<{
            __typename?: 'OfficeEntity'
            id?: string | null
            attributes?: { __typename?: 'Office'; name: string } | null
          }>
        } | null
        hqs?: {
          __typename?: 'HqRelationResponseCollection'
          data: Array<{
            __typename?: 'HqEntity'
            id?: string | null
            attributes?: { __typename?: 'Hq'; name: string } | null
          }>
        } | null
        regionsServed?: {
          __typename?: 'RegionsServedRelationResponseCollection'
          data: Array<{
            __typename?: 'RegionsServedEntity'
            attributes?: { __typename?: 'RegionsServed'; name: string } | null
          }>
        } | null
        languages?: {
          __typename?: 'PlatformLanguageRelationResponseCollection'
          data: Array<{
            __typename?: 'PlatformLanguageEntity'
            id?: string | null
            attributes?: { __typename?: 'PlatformLanguage'; name: string } | null
          }>
        } | null
        articles?: Array<{
          __typename?: 'ComponentGlobalArticle'
          id: string
          title?: string | null
          publishedBy?: string | null
          date?: any | null
          link?: string | null
          image?: {
            __typename?: 'UploadFileEntityResponse'
            data?: {
              __typename?: 'UploadFileEntity'
              attributes?: { __typename?: 'UploadFile'; url: string } | null
            } | null
          } | null
        } | null> | null
        vendorResources?: Array<{
          __typename?: 'ComponentSolutionPropsVendorResource'
          id: string
          type?: IEnum_Componentsolutionpropsvendorresource_Type | null
          content?: {
            __typename?: 'ContentEntityResponse'
            data?: {
              __typename?: 'ContentEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Content'
                title: string
                snippet: string
                createdAt?: any | null
                publishedTime?: any | null
                contentType?: IEnum_Content_Contenttype | null
                documentType?: IEnum_Content_Documenttype | null
                isMaintained?: boolean | null
                lastMaintained?: any | null
                slug: string
                defaultImage?: {
                  __typename?: 'UploadFileRelationResponseCollection'
                  data: Array<{
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  }>
                } | null
                blogTaxonomies?: {
                  __typename?: 'BlogTaxonomyRelationResponseCollection'
                  data: Array<{
                    __typename?: 'BlogTaxonomyEntity'
                    id?: string | null
                    attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
                  }>
                } | null
                audiences?: {
                  __typename?: 'PremiumAudienceRelationResponseCollection'
                  data: Array<{
                    __typename?: 'PremiumAudienceEntity'
                    id?: string | null
                    attributes?: { __typename?: 'PremiumAudience'; name: string } | null
                  }>
                } | null
                subjectMatters?: {
                  __typename?: 'SubjectMatterRelationResponseCollection'
                  data: Array<{
                    __typename?: 'SubjectMatterEntity'
                    id?: string | null
                    attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
                  }>
                } | null
                writer?: {
                  __typename?: 'ComponentContentContentWriter'
                  writerOption: IEnum_Componentcontentcontentwriter_Writeroption
                  oneTimeAuthorName?: string | null
                  author?: {
                    __typename?: 'AuthorEntityResponse'
                    data?: {
                      __typename?: 'AuthorEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Author'
                        firstName: string
                        lastName: string
                        jobTitle?: string | null
                        company?: string | null
                        bio?: string | null
                        slug: string
                        avatar?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                  expert?: {
                    __typename?: 'ExpertEntityResponse'
                    data?: {
                      __typename?: 'ExpertEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Expert'
                        firstName: string
                        lastName: string
                        displayName?: string | null
                        title?: string | null
                        company?: string | null
                        bio?: string | null
                        slug: string
                        avatar?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                  solution?: {
                    __typename?: 'SolutionEntityResponse'
                    data?: {
                      __typename?: 'SolutionEntity'
                      id?: string | null
                      attributes?: {
                        __typename?: 'Solution'
                        displayName?: string | null
                        slug?: string | null
                        type: IEnum_Solution_Type
                        shortDescription?: string | null
                        logo?: {
                          __typename?: 'UploadFileEntityResponse'
                          data?: {
                            __typename?: 'UploadFileEntity'
                            attributes?: { __typename?: 'UploadFile'; url: string } | null
                          } | null
                        } | null
                      } | null
                    } | null
                  } | null
                } | null
              } | null
            } | null
          } | null
          external?: {
            __typename?: 'ComponentSolutionPropsVendorResourceExternal'
            id: string
            title?: string | null
            summary?: string | null
            link?: string | null
            publishedDate?: any | null
            author?: string | null
            documentType?: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype | null
          } | null
        } | null> | null
        features?: {
          __typename?: 'FeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'FeatureEntity'
            attributes?: { __typename?: 'Feature'; name?: string | null } | null
          }>
        } | null
        existingCustomers?: {
          __typename?: 'ExistingCustomerRelationResponseCollection'
          data: Array<{
            __typename?: 'ExistingCustomerEntity'
            id?: string | null
            attributes?: { __typename?: 'ExistingCustomer'; name: string } | null
          }>
        } | null
        iltaSponsorships?: {
          __typename?: 'IltaSponsorshipRelationResponseCollection'
          data: Array<{
            __typename?: 'IltaSponsorshipEntity'
            attributes?: {
              __typename?: 'IltaSponsorship'
              name?: string | null
              logo?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          }>
        } | null
        announcement?: {
          __typename?: 'AnnouncementEntityResponse'
          data?: {
            __typename?: 'AnnouncementEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Announcement'
              announcement: string
              optionalLink?: string | null
              publishedAt?: any | null
            } | null
          } | null
        } | null
      } | null
    }>
  } | null
}

export type IRegisterMutationVariables = Exact<{
  input: IUsersPermissionsRegisterInput
}>

export type IRegisterMutation = {
  __typename?: 'Mutation'
  register: {
    __typename?: 'UsersPermissionsLoginPayload'
    jwt?: string | null
    user: { __typename?: 'UsersPermissionsMe'; id: string; username: string; email?: string | null }
  }
}

export type IRegisterPremiumOrganizationMutationVariables = Exact<{
  name: Scalars['String']['input']
  registrationContact:
    | Array<IComponentRegistrationContactRegistrationContactInput>
    | IComponentRegistrationContactRegistrationContactInput
  billingAddress: IComponentBillingAddressBillingAddressInput
  emailDomain: Scalars['String']['input']
  pricingPlan: Scalars['ID']['input']
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  leadSource?: InputMaybe<Scalars['String']['input']>
  shouldSendResponse: Scalars['Boolean']['input']
}>

export type IRegisterPremiumOrganizationMutation = {
  __typename?: 'Mutation'
  registerPremiumOrganization?: { __typename?: 'PremiumOrganization'; name: string } | null
}

export type IRegisterVendorOrganizationMutationVariables = Exact<{
  name: Scalars['String']['input']
  companySize: Scalars['String']['input']
  registrationContact:
    | Array<IComponentRegistrationContactRegistrationContactInput>
    | IComponentRegistrationContactRegistrationContactInput
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
  shouldSendResponse: Scalars['Boolean']['input']
}>

export type IRegisterVendorOrganizationMutation = {
  __typename?: 'Mutation'
  registerVendorOrganization?: { __typename?: 'Vendor'; name: string } | null
}

export type ISearchContentsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
  gReCaptchaToken?: InputMaybe<Scalars['String']['input']>
}>

export type ISearchContentsQuery = {
  __typename?: 'Query'
  contentResults: {
    __typename?: 'ContentSearchReturnType'
    success?: boolean | null
    data?: {
      __typename?: 'ContentSearchResult'
      total?: number | null
      contents?: Array<{
        __typename?: 'ContentItem'
        id: number
        slug: string
        contentType?: IEnum_Content_Contenttype | null
        documentType?: IEnum_Content_Documenttype | null
        title: string
        snippet: string
        defaultImage?: string | null
        publishedTime: any
        isMaintained?: boolean | null
        lastMaintainedAt?: any | null
        writer?: {
          __typename?: 'ContentItemWriter'
          writerOption: IEnum_Componentcontentcontentwriter_Writeroption
          name: string
          avatar?: string | null
          title?: string | null
          url?: string | null
        } | null
      } | null> | null
    } | null
  }
}

export type ISearchEventsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type ISearchEventsQuery = {
  __typename?: 'Query'
  eventResults: {
    __typename?: 'EventSearchReturnType'
    data?: {
      __typename?: 'EventSearchResult'
      total?: number | null
      events?: Array<{
        __typename?: 'Event'
        title: string
        description?: string | null
        date?: any | null
        updatedAt?: any | null
        city?: string | null
        country: string
        slug: string
        createdAt?: any | null
        organizer: string
        featured?: boolean | null
        website: string
        notes?: string | null
        logo?: {
          __typename?: 'UploadFileEntityResponse'
          data?: {
            __typename?: 'UploadFileEntity'
            attributes?: { __typename?: 'UploadFile'; url: string } | null
          } | null
        } | null
        durationId?: {
          __typename?: 'DurationEntityResponse'
          data?: {
            __typename?: 'DurationEntity'
            id?: string | null
            attributes?: { __typename?: 'Duration'; name: string; days?: number | null } | null
          } | null
        } | null
        formatId?: {
          __typename?: 'EventFormatEntityResponse'
          data?: {
            __typename?: 'EventFormatEntity'
            id?: string | null
            attributes?: { __typename?: 'EventFormat'; name: string } | null
          } | null
        } | null
        recurrenceId?: {
          __typename?: 'RecurrenceEntityResponse'
          data?: {
            __typename?: 'RecurrenceEntity'
            id?: string | null
            attributes?: { __typename?: 'Recurrence'; name: string } | null
          } | null
        } | null
        features?: {
          __typename?: 'EventFeatureRelationResponseCollection'
          data: Array<{
            __typename?: 'EventFeatureEntity'
            id?: string | null
            attributes?: { __typename?: 'EventFeature'; name: string } | null
          }>
        } | null
      } | null> | null
    } | null
  }
}

export type ISearchGraveyardConsolidationQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type ISearchGraveyardConsolidationQuery = {
  __typename?: 'Query'
  searchSolutions: {
    __typename?: 'SearchSolutionsResult'
    total?: number | null
    solutions?: Array<{
      __typename?: 'SolutionItem'
      id: string
      slug: string
      type: IEnum_Solution_Item_Type
      displayName: string
      description?: string | null
      logo?: string | null
      subTopics: Array<string>
      follow: boolean
      rating?: number | null
      reviewCnt?: number | null
      isEnhanced?: boolean | null
      isPremium?: boolean | null
      offices?: Array<string | null> | null
      iltaSponsorshipLogo?: string | null
      consolidation?: {
        __typename?: 'ComponentSolutionPropsConsolidation'
        date?: any | null
        amount?: number | null
        article?: string | null
        articleLink?: string | null
        originalTargetName?: string | null
        buyer?: string | null
      } | null
      graveyard?: { __typename?: 'ComponentSolutionPropsGraveyard'; date?: any | null } | null
    } | null> | null
  }
}

export type ISearchSolutionsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['JSON']['input']>
}>

export type ISearchSolutionsQuery = {
  __typename?: 'Query'
  searchSolutions: {
    __typename?: 'SearchSolutionsResult'
    total?: number | null
    solutions?: Array<{
      __typename?: 'SolutionItem'
      id: string
      slug: string
      type: IEnum_Solution_Item_Type
      displayName: string
      description?: string | null
      logo?: string | null
      subTopics: Array<string>
      follow: boolean
      rating?: number | null
      reviewCnt?: number | null
      isEnhanced?: boolean | null
      isPremium?: boolean | null
      offices?: Array<string | null> | null
      iltaSponsorshipLogo?: string | null
      consolidation?: {
        __typename?: 'ComponentSolutionPropsConsolidation'
        date?: any | null
        amount?: number | null
        article?: string | null
        articleLink?: string | null
        originalTargetName?: string | null
        buyer?: string | null
      } | null
      graveyard?: { __typename?: 'ComponentSolutionPropsGraveyard'; date?: any | null } | null
    } | null> | null
  }
}

export type ISimilarSolutionsQueryVariables = Exact<{
  id: Scalars['Int']['input']
  from?: InputMaybe<Scalars['String']['input']>
}>

export type ISimilarSolutionsQuery = {
  __typename?: 'Query'
  similarSolutions: {
    __typename?: 'SimilarSolutionsResult'
    solutions: Array<{
      __typename?: 'SimilarSolutionItem'
      type?: IEnum_Solution_Item_Type | null
      slug?: string | null
      displayName?: string | null
      logo?: string | null
      rating?: number | null
      reviewCnt?: number | null
    }>
  }
}

export type ISolutionRequestsBySulgQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type ISolutionRequestsBySulgQuery = {
  __typename?: 'Query'
  solutionRequests?: {
    __typename?: 'SolutionRequestEntityResponseCollection'
    data: Array<{
      __typename?: 'SolutionRequestEntity'
      id?: string | null
      attributes?: { __typename?: 'SolutionRequest'; slug?: string | null } | null
    }>
  } | null
}

export type ISubmitEnhancedRequestMutationVariables = Exact<{
  solution_id: Scalars['ID']['input']
}>

export type ISubmitEnhancedRequestMutation = { __typename?: 'Mutation'; submitEnhancedRequest?: boolean | null }

export type ISubmitNewsletterSubscriptionMutationVariables = Exact<{
  data: INewsletterSubscriptionInput
}>

export type ISubmitNewsletterSubscriptionMutation = {
  __typename?: 'Mutation'
  submitNewsletterSubscription?: boolean | null
}

export type ISubmitReviewMutationVariables = Exact<{
  data: IReviewInput
}>

export type ISubmitReviewMutation = { __typename?: 'Mutation'; submitReview?: boolean | null }

export type ITrendingContentsQueryVariables = Exact<{
  audience: Scalars['String']['input']
}>

export type ITrendingContentsQuery = {
  __typename?: 'Query'
  trendingContents: Array<{
    __typename?: 'ContentEntity'
    id?: string | null
    attributes?: {
      __typename?: 'Content'
      title: string
      snippet: string
      createdAt?: any | null
      publishedTime?: any | null
      contentType?: IEnum_Content_Contenttype | null
      documentType?: IEnum_Content_Documenttype | null
      isMaintained?: boolean | null
      lastMaintained?: any | null
      slug: string
      defaultImage?: {
        __typename?: 'UploadFileRelationResponseCollection'
        data: Array<{ __typename?: 'UploadFileEntity'; attributes?: { __typename?: 'UploadFile'; url: string } | null }>
      } | null
      blogTaxonomies?: {
        __typename?: 'BlogTaxonomyRelationResponseCollection'
        data: Array<{
          __typename?: 'BlogTaxonomyEntity'
          id?: string | null
          attributes?: { __typename?: 'BlogTaxonomy'; slug?: string | null; name?: string | null } | null
        }>
      } | null
      audiences?: {
        __typename?: 'PremiumAudienceRelationResponseCollection'
        data: Array<{
          __typename?: 'PremiumAudienceEntity'
          id?: string | null
          attributes?: { __typename?: 'PremiumAudience'; name: string } | null
        }>
      } | null
      subjectMatters?: {
        __typename?: 'SubjectMatterRelationResponseCollection'
        data: Array<{
          __typename?: 'SubjectMatterEntity'
          id?: string | null
          attributes?: { __typename?: 'SubjectMatter'; name?: string | null } | null
        }>
      } | null
      writer?: {
        __typename?: 'ComponentContentContentWriter'
        writerOption: IEnum_Componentcontentcontentwriter_Writeroption
        oneTimeAuthorName?: string | null
        author?: {
          __typename?: 'AuthorEntityResponse'
          data?: {
            __typename?: 'AuthorEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Author'
              firstName: string
              lastName: string
              jobTitle?: string | null
              company?: string | null
              bio?: string | null
              slug: string
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
        expert?: {
          __typename?: 'ExpertEntityResponse'
          data?: {
            __typename?: 'ExpertEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Expert'
              firstName: string
              lastName: string
              displayName?: string | null
              title?: string | null
              company?: string | null
              bio?: string | null
              slug: string
              avatar?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
        solution?: {
          __typename?: 'SolutionEntityResponse'
          data?: {
            __typename?: 'SolutionEntity'
            id?: string | null
            attributes?: {
              __typename?: 'Solution'
              displayName?: string | null
              slug?: string | null
              type: IEnum_Solution_Type
              shortDescription?: string | null
              logo?: {
                __typename?: 'UploadFileEntityResponse'
                data?: {
                  __typename?: 'UploadFileEntity'
                  attributes?: { __typename?: 'UploadFile'; url: string } | null
                } | null
              } | null
            } | null
          } | null
        } | null
      } | null
    } | null
  }>
}

export type IUpdateAnnouncementMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: IAnnouncementInput
}>

export type IUpdateAnnouncementMutation = {
  __typename?: 'Mutation'
  updateAnnouncement?: {
    __typename?: 'AnnouncementEntityResponse'
    data?: {
      __typename?: 'AnnouncementEntity'
      id?: string | null
      attributes?: {
        __typename?: 'Announcement'
        announcement: string
        optionalLink?: string | null
        updatedAt?: any | null
      } | null
    } | null
  } | null
}

export type IUpdateSolutionRequestMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: ISolutionRequestInput
}>

export type IUpdateSolutionRequestMutation = {
  __typename?: 'Mutation'
  updateSolutionRequest?: {
    __typename?: 'SolutionRequestEntityResponse'
    data?: {
      __typename?: 'SolutionRequestEntity'
      id?: string | null
      attributes?: { __typename?: 'SolutionRequest'; updatedAt?: any | null } | null
    } | null
  } | null
}

export type IUpdateUsersPermissionsUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  data: IUsersPermissionsUserInput
}>

export type IUpdateUsersPermissionsUserMutation = {
  __typename?: 'Mutation'
  updateUsersPermissionsUser: {
    __typename?: 'UsersPermissionsUserEntityResponse'
    data?: {
      __typename?: 'UsersPermissionsUserEntity'
      id?: string | null
      attributes?: { __typename?: 'UsersPermissionsUser'; username: string; email: string } | null
    } | null
  }
}

export type IUserInfoQueryVariables = Exact<{
  token: Scalars['String']['input']
}>

export type IUserInfoQuery = {
  __typename?: 'Query'
  userInfo?: {
    __typename?: 'UsersPermissionsUser'
    external_id?: string | null
    username: string
    email: string
    provider?: string | null
    confirmed?: boolean | null
    blocked?: boolean | null
    companyType?: IEnum_Userspermissionsuser_Companytype | null
    companySize?: IEnum_Userspermissionsuser_Companysize | null
    isPremium?: boolean | null
    isVendor?: boolean | null
    jobTitle?: string | null
    companyName?: string | null
    subscribed?: boolean | null
    createdAt?: any | null
    updatedAt?: any | null
    role?: {
      __typename?: 'UsersPermissionsRoleEntityResponse'
      data?: {
        __typename?: 'UsersPermissionsRoleEntity'
        attributes?: { __typename?: 'UsersPermissionsRole'; name: string } | null
      } | null
    } | null
    country?: {
      __typename?: 'OfficeEntityResponse'
      data?: { __typename?: 'OfficeEntity'; attributes?: { __typename?: 'Office'; name: string } | null } | null
    } | null
    subscribeNewsletterCategories?: {
      __typename?: 'NewsletterCategoryRelationResponseCollection'
      data: Array<{
        __typename?: 'NewsletterCategoryEntity'
        id?: string | null
        attributes?: { __typename?: 'NewsletterCategory'; name?: string | null } | null
      }>
    } | null
    reviews?: {
      __typename?: 'ReviewRelationResponseCollection'
      data: Array<{
        __typename?: 'ReviewEntity'
        attributes?: {
          __typename?: 'Review'
          rating?: number | null
          title?: string | null
          content?: string | null
          ip?: string | null
          notes?: string | null
          status?: IEnum_Review_Status | null
          solutionDispName?: string | null
          submitterTitle?: string | null
          submitterSubTitle?: string | null
          createdAt?: any | null
          updatedAt?: any | null
          publishedAt?: any | null
          solution?: {
            __typename?: 'SolutionEntityResponse'
            data?: {
              __typename?: 'SolutionEntity'
              id?: string | null
              attributes?: {
                __typename?: 'Solution'
                website?: string | null
                slug?: string | null
                displayName?: string | null
                rating?: number | null
                logo?: {
                  __typename?: 'UploadFileEntityResponse'
                  data?: {
                    __typename?: 'UploadFileEntity'
                    attributes?: { __typename?: 'UploadFile'; url: string } | null
                  } | null
                } | null
                reviews?: {
                  __typename?: 'ReviewRelationResponseCollection'
                  data: Array<{
                    __typename?: 'ReviewEntity'
                    attributes?: { __typename?: 'Review'; rating?: number | null } | null
                  }>
                } | null
              } | null
            } | null
          } | null
        } | null
      }>
    } | null
  } | null
}

export type IVendorAnalysisForSolutionFragment = {
  __typename: 'ContentEntity'
  id?: string | null
  attributes?: { __typename?: 'Content'; slug: string; snippet: string } | null
}

export type IVendorAnalysisBySolutionIdQueryVariables = Exact<{
  solutionId: Scalars['ID']['input']
}>

export type IVendorAnalysisBySolutionIdQuery = {
  __typename?: 'Query'
  contents?: {
    __typename?: 'ContentEntityResponseCollection'
    data: Array<{
      __typename: 'ContentEntity'
      id?: string | null
      attributes?: { __typename?: 'Content'; slug: string; snippet: string } | null
    }>
  } | null
}

export type IVisitContentMutationVariables = Exact<{
  content_id: Scalars['ID']['input']
}>

export type IVisitContentMutation = { __typename?: 'Mutation'; visitContent?: boolean | null }

export const AllContentsSlugItemFragmentDoc = gql`
  fragment allContentsSlugItem on ContentEntity {
    id
    attributes {
      slug
    }
  }
`
export const AllSolutionsSlugItemFragmentDoc = gql`
  fragment allSolutionsSlugItem on SolutionEntity {
    attributes {
      slug
    }
  }
`
export const AuthorFullItemFragmentDoc = gql`
  fragment authorFullItem on AuthorEntity {
    id
    attributes {
      firstName
      lastName
      jobTitle
      company
      bio
      linkedin
      twitter
      slug
      avatar {
        data {
          attributes {
            url
          }
        }
      }
      subjectMatters {
        data {
          id
          attributes {
            name
            slug
          }
        }
      }
      themes {
        data {
          id
          attributes {
            name
            slug
          }
        }
      }
    }
  }
`
export const AuthorItemFragmentDoc = gql`
  fragment authorItem on AuthorEntity {
    id
    attributes {
      firstName
      lastName
      displayName
      jobTitle
      company
      slug
      avatar {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
`
export const AutosuggestItemFragmentDoc = gql`
  fragment autosuggestItem on Suggest {
    keyword
    model
    id
    logo
    slug
  }
`
export const BlogTaxonomyDataFragmentDoc = gql`
  fragment blogTaxonomyData on BlogTaxonomyEntity {
    id
    attributes {
      name
      slug
      description
    }
  }
`
export const AuthorCardDataFragmentDoc = gql`
  fragment authorCardData on AuthorEntity {
    id
    attributes {
      firstName
      lastName
      jobTitle
      company
      bio
      slug
      avatar {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
`
export const ExpertCardDataFragmentDoc = gql`
  fragment expertCardData on ExpertEntity {
    id
    attributes {
      firstName
      lastName
      displayName
      title
      company
      bio
      slug
      avatar {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
`
export const SolutionCardDataFragmentDoc = gql`
  fragment solutionCardData on SolutionEntity {
    id
    attributes {
      displayName
      slug
      type
      shortDescription
      logo {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
`
export const ContentWriterFragmentDoc = gql`
  fragment contentWriter on ComponentContentContentWriter {
    writerOption
    author {
      data {
        ...authorCardData
      }
    }
    expert {
      data {
        ...expertCardData
      }
    }
    solution {
      data {
        ...solutionCardData
      }
    }
    oneTimeAuthorName
  }
  ${AuthorCardDataFragmentDoc}
  ${ExpertCardDataFragmentDoc}
  ${SolutionCardDataFragmentDoc}
`
export const ContentListItemFragmentDoc = gql`
  fragment contentListItem on ContentEntity {
    id
    attributes {
      title
      snippet
      createdAt
      publishedTime
      contentType
      documentType
      isMaintained
      lastMaintained
      slug
      defaultImage {
        data {
          attributes {
            url
          }
        }
      }
      blogTaxonomies {
        data {
          id
          attributes {
            slug
            name
          }
        }
      }
      audiences {
        data {
          id
          attributes {
            name
          }
        }
      }
      subjectMatters {
        data {
          id
          attributes {
            name
          }
        }
      }
      writer {
        ...contentWriter
      }
    }
  }
  ${ContentWriterFragmentDoc}
`
export const BlogTaxonomyFullDataFragmentDoc = gql`
  fragment blogTaxonomyFullData on BlogTaxonomyEntity {
    id
    attributes {
      name
      slug
      description
      publishedAt
      latestContent {
        data {
          ...contentListItem
        }
      }
    }
  }
  ${ContentListItemFragmentDoc}
`
export const IltaSponsorshipLogoFragmentDoc = gql`
  fragment iltaSponsorshipLogo on UploadFileEntity {
    attributes {
      url
    }
  }
`
export const CompanyIltaSponsorshipFragmentDoc = gql`
  fragment companyIltaSponsorship on IltaSponsorshipEntity {
    attributes {
      name
      logo {
        data {
          ...iltaSponsorshipLogo
        }
      }
    }
  }
  ${IltaSponsorshipLogoFragmentDoc}
`
export const CompanyDataFragmentDoc = gql`
  fragment companyData on CompanyEntity {
    id
    attributes {
      slug
      name
      description
      website
      logo {
        data {
          attributes {
            url
          }
        }
      }
      solutions {
        data {
          attributes {
            slug
          }
        }
      }
      description
      services
      yearFounded
      size
      regionsServed {
        data {
          attributes {
            name
          }
        }
      }
      hqs {
        data {
          attributes {
            name
          }
        }
      }
      iltaSponsorships @include(if: $includeIlta) {
        data {
          ...companyIltaSponsorship
        }
      }
    }
  }
  ${CompanyIltaSponsorshipFragmentDoc}
`
export const ContentDataDownloadMaterialFragmentDoc = gql`
  fragment contentDataDownloadMaterial on ComponentContentDownloadMaterial {
    title
    isEnabled
    format
    lastUpdated
    downloadButtonName
    files {
      data {
        attributes {
          name
        }
      }
    }
  }
`
export const ContentDataFragmentDoc = gql`
  fragment contentData on ContentEntity {
    id
    attributes {
      title
      publishedAt
      createdAt
      publishedTime
      lastMaintained
      isMaintained
      contentType
      slug
      snippet
      isPremium
      isLatestBlog
      defaultImage {
        data {
          attributes {
            url
            __typename
          }
          __typename
        }
        __typename
      }
      blogTaxonomies {
        data {
          id
          attributes {
            name
            slug
            __typename
          }
          __typename
        }
        __typename
      }
      subjectMatters {
        data {
          id
          attributes {
            name
            slug
            __typename
          }
          __typename
        }
        __typename
      }
      downloadMaterial {
        ...contentDataDownloadMaterial
        __typename
      }
      writer {
        ...contentWriter
        __typename
      }
      __typename
    }
    __typename
  }
  ${ContentDataDownloadMaterialFragmentDoc}
  ${ContentWriterFragmentDoc}
`
export const ContentItemWriterFragmentDoc = gql`
  fragment contentItemWriter on ContentItemWriter {
    writerOption
    name
    avatar
    title
    url
  }
`
export const ContentItemFragmentDoc = gql`
  fragment contentItem on ContentItem {
    id
    slug
    contentType
    documentType
    title
    snippet
    defaultImage
    writer {
      ...contentItemWriter
    }
    publishedTime
    isMaintained
    lastMaintainedAt
  }
  ${ContentItemWriterFragmentDoc}
`
export const ExpertFullItemFragmentDoc = gql`
  fragment expertFullItem on ExpertEntity {
    id
    attributes {
      firstName
      lastName
      title
      bio
      linkedin
      twitter
      slug
      experience
      avatar {
        data {
          attributes {
            url
          }
        }
      }
      expertiseSubTopics {
        data {
          attributes {
            name
            slug
            topics {
              data {
                id
                attributes {
                  name
                  description
                  slug
                }
              }
            }
          }
        }
      }
      expertiseTopics {
        data {
          attributes {
            name
            slug
          }
        }
      }
      contents {
        data {
          id
        }
      }
    }
  }
`
export const IltaSponsorshipItemFragmentDoc = gql`
  fragment iltaSponsorshipItem on IltaSponsorshipEntity {
    id
    attributes {
      name
      logo {
        data {
          attributes {
            url
          }
        }
      }
      level
      description
    }
  }
`
export const PhaseItemFragmentDoc = gql`
  fragment phaseItem on PhaseEntity {
    id
    attributes {
      title
      description
    }
  }
`
export const PremiumAudienceItemFragmentDoc = gql`
  fragment premiumAudienceItem on PremiumAudienceEntity {
    id
    attributes {
      name
      shouldShowOnHomepage
    }
  }
`
export const ResourceInvitationEntityFragmentDoc = gql`
  fragment resourceInvitationEntity on ResourceInvitationEntity {
    name
    businessEmail
    companyLawFirmName
    status
    dateInvited
    contents {
      id
      title
      slug
      sharedResources {
        visits {
          visited_by {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
        downloads {
          downloaded_by {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
      }
    }
  }
`
export const SimilarSolutionItemFragmentDoc = gql`
  fragment similarSolutionItem on SimilarSolutionItem {
    type
    slug
    displayName
    logo
    rating
    reviewCnt
  }
`
export const SolutionCarouselItemFragmentDoc = gql`
  fragment solutionCarouselItem on SolutionItem {
    id
    slug
    displayName
    logo
    subTopics
    isEnhanced
    isPremium
  }
`
export const SolutionTopicFragmentDoc = gql`
  fragment solutionTopic on TopicEntity {
    id
    attributes {
      slug
      name
    }
  }
`
export const SolutionFeatureFragmentDoc = gql`
  fragment solutionFeature on FeatureEntity {
    attributes {
      name
    }
  }
`
export const SolutionSubTopicFragmentDoc = gql`
  fragment solutionSubTopic on SubTopicEntity {
    id
    attributes {
      slug
      name
      features {
        data {
          ...solutionFeature
        }
      }
    }
  }
  ${SolutionFeatureFragmentDoc}
`
export const SolutionLogoFragmentDoc = gql`
  fragment solutionLogo on UploadFileEntity {
    id
    attributes {
      url
    }
  }
`
export const SolutionCompanyFragmentDoc = gql`
  fragment solutionCompany on CompanyEntity {
    attributes {
      name
      slug
    }
  }
`
export const SolutionReviewFragmentDoc = gql`
  fragment solutionReview on ReviewEntity {
    id
    attributes {
      anonymous
      rating
      title
      content
      submitterTitle
      submitterSubTitle
      solutionDispName
      createdAt
      updatedAt
      publishedAt
      solution {
        data {
          id
          attributes {
            displayName
            slug
            rating
            company {
              data {
                attributes {
                  slug
                }
              }
            }
            logo {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
export const SolutionOfficeFragmentDoc = gql`
  fragment solutionOffice on OfficeEntity {
    id
    attributes {
      name
    }
  }
`
export const SolutionHqFragmentDoc = gql`
  fragment solutionHq on HqEntity {
    id
    attributes {
      name
    }
  }
`
export const SolutionRegionsServedFragmentDoc = gql`
  fragment solutionRegionsServed on RegionsServedEntity {
    attributes {
      name
    }
  }
`
export const SolutionLanguageFragmentDoc = gql`
  fragment solutionLanguage on PlatformLanguageEntity {
    id
    attributes {
      name
    }
  }
`
export const SolutionArticleFragmentDoc = gql`
  fragment solutionArticle on ComponentGlobalArticle {
    id
    image {
      data {
        attributes {
          url
        }
      }
    }
    title
    publishedBy
    date
    link
  }
`
export const SolutionVendorResourceExternalFragmentDoc = gql`
  fragment solutionVendorResourceExternal on ComponentSolutionPropsVendorResourceExternal {
    id
    title
    summary
    link
    publishedDate
    author
    documentType
  }
`
export const SolutionVendorResourceFragmentDoc = gql`
  fragment solutionVendorResource on ComponentSolutionPropsVendorResource {
    id
    type
    content {
      data {
        ...contentListItem
      }
    }
    external {
      ...solutionVendorResourceExternal
    }
  }
  ${ContentListItemFragmentDoc}
  ${SolutionVendorResourceExternalFragmentDoc}
`
export const SolutionExistingCustomerFragmentDoc = gql`
  fragment solutionExistingCustomer on ExistingCustomerEntity {
    id
    attributes {
      name
    }
  }
`
export const SolutionIltaSponsorshipFragmentDoc = gql`
  fragment solutionIltaSponsorship on IltaSponsorshipEntity {
    attributes {
      name
      logo {
        data {
          ...iltaSponsorshipLogo
        }
      }
    }
  }
  ${IltaSponsorshipLogoFragmentDoc}
`
export const GeneralSolutionDataFragmentDoc = gql`
  fragment generalSolutionData on SolutionEntity {
    id
    attributes {
      displayName
      type
      topics {
        data {
          ...solutionTopic
        }
      }
      subTopics {
        data {
          ...solutionSubTopic
        }
      }
      logo {
        data {
          ...solutionLogo
        }
      }
      company {
        data {
          ...solutionCompany
        }
      }
      website
      linkedin
      twitter
      isEnhanced
      isPremium
      slug
      shortDescription
      longDescription
      reviews {
        data {
          ...solutionReview
        }
      }
      rating
      offices {
        data {
          ...solutionOffice
        }
      }
      hqs {
        data {
          ...solutionHq
        }
      }
      regionsServed {
        data {
          ...solutionRegionsServed
        }
      }
      languages {
        data {
          ...solutionLanguage
        }
      }
      articles {
        ...solutionArticle
      }
      vendorResources {
        ...solutionVendorResource
      }
      features {
        data {
          ...solutionFeature
        }
      }
      existingCustomers {
        data {
          ...solutionExistingCustomer
        }
      }
      iltaSponsorships @include(if: $includeIlta) {
        data {
          ...solutionIltaSponsorship
        }
      }
      yearFounded
      createdAt
      publishedAt
      announcement {
        data {
          id
          attributes {
            announcement
            optionalLink
            publishedAt
          }
        }
      }
    }
  }
  ${SolutionTopicFragmentDoc}
  ${SolutionSubTopicFragmentDoc}
  ${SolutionLogoFragmentDoc}
  ${SolutionCompanyFragmentDoc}
  ${SolutionReviewFragmentDoc}
  ${SolutionOfficeFragmentDoc}
  ${SolutionHqFragmentDoc}
  ${SolutionRegionsServedFragmentDoc}
  ${SolutionLanguageFragmentDoc}
  ${SolutionArticleFragmentDoc}
  ${SolutionVendorResourceFragmentDoc}
  ${SolutionFeatureFragmentDoc}
  ${SolutionExistingCustomerFragmentDoc}
  ${SolutionIltaSponsorshipFragmentDoc}
`
export const SolutionAudienceFragmentDoc = gql`
  fragment solutionAudience on DemographicEntity {
    attributes {
      name
    }
  }
`
export const SolutionCallToActionFragmentDoc = gql`
  fragment solutionCallToAction on ComponentGlobalCallToAction {
    label
    url
  }
`
export const SolutionDedicatedResourceFragmentDoc = gql`
  fragment solutionDedicatedResource on ComponentSolutionPropsDedicatedResource {
    id
    count
    type
  }
`
export const SolutionFounderFragmentDoc = gql`
  fragment solutionFounder on ComponentAboutPropsTeamMember {
    id
    avatar {
      data {
        attributes {
          url
        }
      }
    }
    name
    role
    bio
  }
`
export const SolutionSpecialistFragmentDoc = gql`
  fragment solutionSpecialist on ComponentAboutPropsTeamMember {
    id
    avatar {
      data {
        attributes {
          url
        }
      }
    }
    name
    role
    bio
  }
`
export const SolutionScreenshotFragmentDoc = gql`
  fragment solutionScreenshot on ComponentGlobalPicture {
    id
    title
    summary
    image {
      data {
        id
        attributes {
          url
        }
      }
    }
  }
`
export const SolutionVideoFragmentDoc = gql`
  fragment solutionVideo on ComponentSolutionPropsVideo {
    id
    title
    summary
    url
  }
`
export const AlspSolutionEnhancedListingDataFragmentDoc = gql`
  fragment alspSolutionEnhancedListingData on ComponentSolutionPropsAlspEnhanced {
    callToAction {
      ...solutionCallToAction
    }
    serviceDescription
    dedicatedResources {
      ...solutionDedicatedResource
    }
    technologyPartners
    averageLengthOfEngagement
    pricingData
    founders {
      ...solutionFounder
    }
    specialists {
      ...solutionSpecialist
    }
    screenshots {
      ...solutionScreenshot
    }
    videos {
      ...solutionVideo
    }
  }
  ${SolutionCallToActionFragmentDoc}
  ${SolutionDedicatedResourceFragmentDoc}
  ${SolutionFounderFragmentDoc}
  ${SolutionSpecialistFragmentDoc}
  ${SolutionScreenshotFragmentDoc}
  ${SolutionVideoFragmentDoc}
`
export const SolutionAlspDataFragmentDoc = gql`
  fragment solutionAlspData on ComponentSolutionAlspData {
    name
    serviceName
    size
    partners
    security {
      data {
        id
        attributes {
          name
        }
      }
    }
    audiences {
      data {
        ...solutionAudience
      }
    }
    enhancedListingData {
      ...alspSolutionEnhancedListingData
    }
  }
  ${SolutionAudienceFragmentDoc}
  ${AlspSolutionEnhancedListingDataFragmentDoc}
`
export const AlspSolutionDataFragmentDoc = gql`
  fragment alspSolutionData on SolutionEntity {
    ...generalSolutionData
    attributes {
      alspData {
        ...solutionAlspData
      }
      vendor {
        data {
          attributes {
            users {
              data {
                attributes {
                  external_id
                }
              }
            }
          }
        }
      }
    }
  }
  ${GeneralSolutionDataFragmentDoc}
  ${SolutionAlspDataFragmentDoc}
`
export const SolutionCaseStudyFragmentDoc = gql`
  fragment solutionCaseStudy on ComponentGlobalSuccessStory {
    id
    name
    link
  }
`
export const SolutionTestimonialFragmentDoc = gql`
  fragment solutionTestimonial on ComponentGlobalTestimonial {
    id
    description
    avatar {
      data {
        attributes {
          url
        }
      }
    }
    name
    role
    company
  }
`
export const ConsultancySolutionEnhancedListingDataFragmentDoc = gql`
  fragment consultancySolutionEnhancedListingData on ComponentSolutionPropsConsultantEnhanced {
    callToAction {
      ...solutionCallToAction
    }
    serviceDescription
    dedicatedResources {
      ...solutionDedicatedResource
    }
    technologyPartners
    averageLengthOfEngagement
    caseStudies {
      ...solutionCaseStudy
    }
    valueProposition
    specialists {
      ...solutionSpecialist
    }
    screenshots {
      ...solutionScreenshot
    }
    videos {
      ...solutionVideo
    }
    pricingData
    testimonials {
      ...solutionTestimonial
    }
  }
  ${SolutionCallToActionFragmentDoc}
  ${SolutionDedicatedResourceFragmentDoc}
  ${SolutionCaseStudyFragmentDoc}
  ${SolutionFounderFragmentDoc}
  ${SolutionSpecialistFragmentDoc}
  ${SolutionScreenshotFragmentDoc}
  ${SolutionVideoFragmentDoc}
  ${SolutionTestimonialFragmentDoc}
`
export const SolutionConsultancyDataFragmentDoc = gql`
  fragment solutionConsultancyData on ComponentSolutionConsultantData {
    name
    serviceName
    size
    partners
    security {
      data {
        id
        attributes {
          name
        }
      }
    }
    audiences {
      data {
        ...solutionAudience
      }
    }
    enhancedListingData {
      ...consultancySolutionEnhancedListingData
    }
  }
  ${SolutionAudienceFragmentDoc}
  ${ConsultancySolutionEnhancedListingDataFragmentDoc}
`
export const ConsultancySolutionDataFragmentDoc = gql`
  fragment consultancySolutionData on SolutionEntity {
    ...generalSolutionData
    attributes {
      consultantData {
        ...solutionConsultancyData
      }
      vendor {
        data {
          attributes {
            users {
              data {
                attributes {
                  external_id
                }
              }
            }
          }
        }
      }
    }
  }
  ${GeneralSolutionDataFragmentDoc}
  ${SolutionConsultancyDataFragmentDoc}
`
export const SolutionAttributeFragmentDoc = gql`
  fragment solutionAttribute on AttributeEntity {
    attributes {
      name
    }
  }
`
export const SolutionDeploymentFragmentDoc = gql`
  fragment solutionDeployment on InstallationEntity {
    attributes {
      name
    }
  }
`
export const SolutionIntegrationFragmentDoc = gql`
  fragment solutionIntegration on IntegrationEntity {
    id
    attributes {
      name
    }
  }
`
export const SolutionPracticeAreaFragmentDoc = gql`
  fragment solutionPracticeArea on PracticeAreaEntity {
    id
    attributes {
      name
    }
  }
`
export const SolutionTypeOfUserFragmentDoc = gql`
  fragment solutionTypeOfUser on TypeOfUserEntity {
    id
    attributes {
      name
    }
  }
`
export const ProductSolutionEnhancedListingDataFragmentDoc = gql`
  fragment productSolutionEnhancedListingData on ComponentSolutionPropsProductEnhanced {
    callToAction {
      ...solutionCallToAction
    }
    screenshots {
      ...solutionScreenshot
    }
    videos {
      ...solutionVideo
    }
    pricingData
    testimonials {
      ...solutionTestimonial
    }
  }
  ${SolutionCallToActionFragmentDoc}
  ${SolutionScreenshotFragmentDoc}
  ${SolutionVideoFragmentDoc}
  ${SolutionTestimonialFragmentDoc}
`
export const SolutionProductDataFragmentDoc = gql`
  fragment solutionProductData on ComponentSolutionProductData {
    name
    tool
    audiences {
      data {
        ...solutionAudience
      }
    }
    attributes {
      data {
        ...solutionAttribute
      }
    }
    deployments {
      data {
        ...solutionDeployment
      }
    }
    integrations {
      data {
        ...solutionIntegration
      }
    }
    practiceAreas {
      data {
        ...solutionPracticeArea
      }
    }
    typeOfUser {
      data {
        ...solutionTypeOfUser
      }
    }
    fundingInfo
    fundingAmount
    valueProposition
    security {
      data {
        id
        attributes {
          name
        }
      }
    }
    dataHostingLocations {
      data {
        id
        attributes {
          name
        }
      }
    }
    enhancedListingData {
      ...productSolutionEnhancedListingData
    }
  }
  ${SolutionAudienceFragmentDoc}
  ${SolutionAttributeFragmentDoc}
  ${SolutionDeploymentFragmentDoc}
  ${SolutionIntegrationFragmentDoc}
  ${SolutionPracticeAreaFragmentDoc}
  ${SolutionTypeOfUserFragmentDoc}
  ${ProductSolutionEnhancedListingDataFragmentDoc}
`
export const SolutionConsolidationDataFragmentDoc = gql`
  fragment solutionConsolidationData on ComponentSolutionPropsConsolidation {
    date
    amount
    article
    articleLink
    originalTargetName
    buyer
  }
`
export const SolutionGraveyardDataFragmentDoc = gql`
  fragment solutionGraveyardData on ComponentSolutionPropsGraveyard {
    date
  }
`
export const ProductSolutionDataFragmentDoc = gql`
  fragment productSolutionData on SolutionEntity {
    ...generalSolutionData
    attributes {
      productType
      productData {
        ...solutionProductData
      }
      consolidationData {
        ...solutionConsolidationData
      }
      graveyardData {
        ...solutionGraveyardData
      }
      vendor {
        data {
          attributes {
            users {
              data {
                attributes {
                  external_id
                }
              }
            }
          }
        }
      }
    }
  }
  ${GeneralSolutionDataFragmentDoc}
  ${SolutionProductDataFragmentDoc}
  ${SolutionConsolidationDataFragmentDoc}
  ${SolutionGraveyardDataFragmentDoc}
`
export const GeneralSolutionRequestDataFragmentDoc = gql`
  fragment generalSolutionRequestData on SolutionRequestEntity {
    id
    attributes {
      topics {
        data {
          ...solutionTopic
        }
      }
      subTopics {
        data {
          ...solutionSubTopic
        }
      }
      logo {
        data {
          ...solutionLogo
        }
      }
      website
      slug
      shortDescription
      longDescription
      linkedin
      twitter
      type
      offices {
        data {
          ...solutionOffice
        }
      }
      hqs {
        data {
          ...solutionHq
        }
      }
      regionsServed {
        data {
          ...solutionRegionsServed
        }
      }
      languages {
        data {
          ...solutionLanguage
        }
      }
      articles {
        ...solutionArticle
      }
      features {
        data {
          ...solutionFeature
        }
      }
      existingCustomers {
        data {
          ...solutionExistingCustomer
        }
      }
      yearFounded
      createdAt
      publishedAt
      updatedAt
      announcement {
        data {
          id
          attributes {
            announcement
            optionalLink
          }
        }
      }
    }
  }
  ${SolutionTopicFragmentDoc}
  ${SolutionSubTopicFragmentDoc}
  ${SolutionLogoFragmentDoc}
  ${SolutionOfficeFragmentDoc}
  ${SolutionHqFragmentDoc}
  ${SolutionRegionsServedFragmentDoc}
  ${SolutionLanguageFragmentDoc}
  ${SolutionArticleFragmentDoc}
  ${SolutionFeatureFragmentDoc}
  ${SolutionExistingCustomerFragmentDoc}
`
export const SolutionRequestEnhancedListingDataFragmentDoc = gql`
  fragment solutionRequestEnhancedListingData on ComponentSolutionRequestPropsProductEnhanced {
    screenshots {
      ...solutionScreenshot
    }
    videos {
      ...solutionVideo
    }
    pricingData
    callToAction {
      ...solutionCallToAction
    }
  }
  ${SolutionScreenshotFragmentDoc}
  ${SolutionVideoFragmentDoc}
  ${SolutionCallToActionFragmentDoc}
`
export const SolutionRequestDatasFragmentDoc = gql`
  fragment solutionRequestDatas on ComponentSolutionRequestProductData {
    name
    audiences {
      data {
        ...solutionAudience
      }
    }
    attributes {
      data {
        ...solutionAttribute
      }
    }
    integrations {
      data {
        ...solutionIntegration
      }
    }
    fundingAmount
    fundingInfo
    practiceAreas {
      data {
        ...solutionPracticeArea
      }
    }
    typeOfUser {
      data {
        ...solutionTypeOfUser
      }
    }
    enhancedListingData {
      ...solutionRequestEnhancedListingData
    }
    valueProposition
    security {
      data {
        id
        attributes {
          name
        }
      }
    }
    dataHosting {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
  ${SolutionAudienceFragmentDoc}
  ${SolutionAttributeFragmentDoc}
  ${SolutionIntegrationFragmentDoc}
  ${SolutionPracticeAreaFragmentDoc}
  ${SolutionTypeOfUserFragmentDoc}
  ${SolutionRequestEnhancedListingDataFragmentDoc}
`
export const SolutionRequestDataFragmentDoc = gql`
  fragment solutionRequestData on SolutionRequestEntity {
    ...generalSolutionRequestData
    attributes {
      productData {
        ...solutionRequestDatas
      }
    }
  }
  ${GeneralSolutionRequestDataFragmentDoc}
  ${SolutionRequestDatasFragmentDoc}
`
export const SolutionStoryFragmentDoc = gql`
  fragment solutionStory on ComponentGlobalSuccessStory {
    id
    name
    link
  }
`

export const SolutionListItemFragmentDoc = gql`
  fragment solutionListItem on SolutionItem {
    id
    slug
    type
    displayName
    description
    logo
    subTopics
    follow
    rating
    reviewCnt
    isEnhanced
    isPremium
    consolidation {
      ...solutionConsolidationData
    }
    graveyard {
      ...solutionGraveyardData
    }
    iltaSponsorshipLogo
  }
  ${SolutionConsolidationDataFragmentDoc}
  ${SolutionGraveyardDataFragmentDoc}
`
export const TopicItemWithoutSubFragmentDoc = gql`
  fragment topicItemWithoutSub on TopicEntity {
    id
    attributes {
      slug
      name
      description
      iconName
      isPremium
      publishedAt
    }
  }
`
export const SubTopicItemFragmentDoc = gql`
  fragment subTopicItem on SubTopicEntity {
    id
    attributes {
      slug
      name
      description
      isPremium
      publishedAt
      topics {
        data {
          ...topicItemWithoutSub
        }
      }
    }
  }
  ${TopicItemWithoutSubFragmentDoc}
`
export const SubTopicFullItemFragmentDoc = gql`
  fragment subTopicFullItem on SubTopicEntity {
    ...subTopicItem
    attributes {
      expert {
        data {
          ...expertCardData
        }
      }
      contents {
        data {
          id
        }
      }
    }
  }
  ${SubTopicItemFragmentDoc}
  ${ExpertCardDataFragmentDoc}
`
export const TestimonialItemFragmentDoc = gql`
  fragment testimonialItem on TestimonialsEntity {
    id
    attributes {
      description
      author {
        id
        name
        role
        company
        avatar {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
`
export const SubTopicItemWithoutSubFragmentDoc = gql`
  fragment subTopicItemWithoutSub on SubTopicEntity {
    id
    attributes {
      slug
      name
      description
      isPremium
      publishedAt
    }
  }
`
export const TopicItemFragmentDoc = gql`
  fragment topicItem on TopicEntity {
    id
    attributes {
      slug
      name
      iconName
      description
      isPremium
      publishedAt
      subTopics(sort: "isPremium:asc") {
        data {
          ...subTopicItemWithoutSub
        }
      }
    }
  }
  ${SubTopicItemWithoutSubFragmentDoc}
`
export const TopicFullItemFragmentDoc = gql`
  fragment topicFullItem on TopicEntity {
    ...topicItem
    attributes {
      expert {
        data {
          ...expertCardData
        }
      }
      contents {
        data {
          id
        }
      }
      subTopics(sort: "isPremium:asc") {
        data {
          ...subTopicItemWithoutSub
        }
      }
    }
  }
  ${TopicItemFragmentDoc}
  ${ExpertCardDataFragmentDoc}
  ${SubTopicItemWithoutSubFragmentDoc}
`
export const TopicHeaderDataFragmentDoc = gql`
  fragment topicHeaderData on TopicEntity {
    id
    attributes {
      slug
      name
      description
      isPremium
      expert {
        data {
          ...expertCardData
        }
      }
    }
  }
  ${ExpertCardDataFragmentDoc}
`
export const VendorAnalysisForSolutionFragmentDoc = gql`
  fragment vendorAnalysisForSolution on ContentEntity {
    id
    __typename
    attributes {
      slug
      snippet
    }
  }
`
export const SearchContentsTotalDocument = gql`
  query searchContentsTotal($query: JSON) {
    contentResults(query: $query) {
      success
      data {
        total
      }
    }
  }
`
export const AllSolutionRequestsItemsFragmentDoc = gql`
  fragment allSolutionRequestsItems on SolutionRequestEntity {
    id
    attributes {
      topics {
        data {
          ...solutionTopic
        }
      }
      subTopics {
        data {
          ...solutionSubTopic
        }
      }
      logo {
        data {
          ...solutionLogo
        }
      }
      website
      slug
      shortDescription
      longDescription
      linkedin
      twitter
      type
      offices {
        data {
          ...solutionOffice
        }
      }
      hqs {
        data {
          ...solutionHq
        }
      }
      regionsServed {
        data {
          ...solutionRegionsServed
        }
      }
      languages {
        data {
          ...solutionLanguage
        }
      }
      articles {
        ...solutionArticle
      }
      features {
        data {
          ...solutionFeature
        }
      }
      existingCustomers {
        data {
          ...solutionExistingCustomer
        }
      }
      yearFounded
      createdAt
      publishedAt
      updatedAt
      announcement {
        data {
          id
          attributes {
            announcement
            optionalLink
          }
        }
      }
    }
  }
  ${SolutionTopicFragmentDoc}
  ${SolutionSubTopicFragmentDoc}
  ${SolutionLogoFragmentDoc}
  ${SolutionOfficeFragmentDoc}
  ${SolutionHqFragmentDoc}
  ${SolutionRegionsServedFragmentDoc}
  ${SolutionLanguageFragmentDoc}
  ${SolutionArticleFragmentDoc}
  ${SolutionFeatureFragmentDoc}
  ${SolutionExistingCustomerFragmentDoc}
`

/**
 * __useSearchContentsTotalQuery__
 *
 * To run a query within a React component, call `useSearchContentsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentsTotalQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchContentsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<ISearchContentsTotalQuery, ISearchContentsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISearchContentsTotalQuery, ISearchContentsTotalQueryVariables>(
    SearchContentsTotalDocument,
    options,
  )
}
export function useSearchContentsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchContentsTotalQuery, ISearchContentsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISearchContentsTotalQuery, ISearchContentsTotalQueryVariables>(
    SearchContentsTotalDocument,
    options,
  )
}
export function useSearchContentsTotalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchContentsTotalQuery, ISearchContentsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISearchContentsTotalQuery, ISearchContentsTotalQueryVariables>(
    SearchContentsTotalDocument,
    options,
  )
}
export type SearchContentsTotalQueryHookResult = ReturnType<typeof useSearchContentsTotalQuery>
export type SearchContentsTotalLazyQueryHookResult = ReturnType<typeof useSearchContentsTotalLazyQuery>
export type SearchContentsTotalSuspenseQueryHookResult = ReturnType<typeof useSearchContentsTotalSuspenseQuery>
export type SearchContentsTotalQueryResult = Apollo.QueryResult<
  ISearchContentsTotalQuery,
  ISearchContentsTotalQueryVariables
>
export const AllAuthorsDocument = gql`
  query allAuthors {
    authors(sort: "firstName:asc") {
      data {
        ...authorItem
      }
    }
  }
  ${AuthorItemFragmentDoc}
`

/**
 * __useAllAuthorsQuery__
 *
 * To run a query within a React component, call `useAllAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAuthorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAuthorsQuery(baseOptions?: Apollo.QueryHookOptions<IAllAuthorsQuery, IAllAuthorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllAuthorsQuery, IAllAuthorsQueryVariables>(AllAuthorsDocument, options)
}
export function useAllAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllAuthorsQuery, IAllAuthorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllAuthorsQuery, IAllAuthorsQueryVariables>(AllAuthorsDocument, options)
}
export function useAllAuthorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllAuthorsQuery, IAllAuthorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllAuthorsQuery, IAllAuthorsQueryVariables>(AllAuthorsDocument, options)
}
export type AllAuthorsQueryHookResult = ReturnType<typeof useAllAuthorsQuery>
export type AllAuthorsLazyQueryHookResult = ReturnType<typeof useAllAuthorsLazyQuery>
export type AllAuthorsSuspenseQueryHookResult = ReturnType<typeof useAllAuthorsSuspenseQuery>
export type AllAuthorsQueryResult = Apollo.QueryResult<IAllAuthorsQuery, IAllAuthorsQueryVariables>
export const AllAuthorSlugsDocument = gql`
  query allAuthorSlugs {
    authors(sort: "firstName:asc") {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`

/**
 * __useAllAuthorSlugsQuery__
 *
 * To run a query within a React component, call `useAllAuthorSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAuthorSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAuthorSlugsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAuthorSlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>(AllAuthorSlugsDocument, options)
}
export function useAllAuthorSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>(AllAuthorSlugsDocument, options)
}
export function useAllAuthorSlugsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>(AllAuthorSlugsDocument, options)
}
export type AllAuthorSlugsQueryHookResult = ReturnType<typeof useAllAuthorSlugsQuery>
export type AllAuthorSlugsLazyQueryHookResult = ReturnType<typeof useAllAuthorSlugsLazyQuery>
export type AllAuthorSlugsSuspenseQueryHookResult = ReturnType<typeof useAllAuthorSlugsSuspenseQuery>
export type AllAuthorSlugsQueryResult = Apollo.QueryResult<IAllAuthorSlugsQuery, IAllAuthorSlugsQueryVariables>
export const AllBlogTaxonomySlugsDocument = gql`
  query allBlogTaxonomySlugs {
    blogTaxonomys {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`

/**
 * __useAllBlogTaxonomySlugsQuery__
 *
 * To run a query within a React component, call `useAllBlogTaxonomySlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBlogTaxonomySlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBlogTaxonomySlugsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBlogTaxonomySlugsQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllBlogTaxonomySlugsQuery, IAllBlogTaxonomySlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllBlogTaxonomySlugsQuery, IAllBlogTaxonomySlugsQueryVariables>(
    AllBlogTaxonomySlugsDocument,
    options,
  )
}
export function useAllBlogTaxonomySlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllBlogTaxonomySlugsQuery, IAllBlogTaxonomySlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllBlogTaxonomySlugsQuery, IAllBlogTaxonomySlugsQueryVariables>(
    AllBlogTaxonomySlugsDocument,
    options,
  )
}
export function useAllBlogTaxonomySlugsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllBlogTaxonomySlugsQuery, IAllBlogTaxonomySlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllBlogTaxonomySlugsQuery, IAllBlogTaxonomySlugsQueryVariables>(
    AllBlogTaxonomySlugsDocument,
    options,
  )
}
export type AllBlogTaxonomySlugsQueryHookResult = ReturnType<typeof useAllBlogTaxonomySlugsQuery>
export type AllBlogTaxonomySlugsLazyQueryHookResult = ReturnType<typeof useAllBlogTaxonomySlugsLazyQuery>
export type AllBlogTaxonomySlugsSuspenseQueryHookResult = ReturnType<typeof useAllBlogTaxonomySlugsSuspenseQuery>
export type AllBlogTaxonomySlugsQueryResult = Apollo.QueryResult<
  IAllBlogTaxonomySlugsQuery,
  IAllBlogTaxonomySlugsQueryVariables
>
export const AllCollectionSlugDocument = gql`
  query allCollectionSlug {
    collections(sort: "name:asc") {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`

/**
 * __useAllCollectionSlugQuery__
 *
 * To run a query within a React component, call `useAllCollectionSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCollectionSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCollectionSlugQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCollectionSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>(AllCollectionSlugDocument, options)
}
export function useAllCollectionSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>(
    AllCollectionSlugDocument,
    options,
  )
}
export function useAllCollectionSlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>(
    AllCollectionSlugDocument,
    options,
  )
}
export type AllCollectionSlugQueryHookResult = ReturnType<typeof useAllCollectionSlugQuery>
export type AllCollectionSlugLazyQueryHookResult = ReturnType<typeof useAllCollectionSlugLazyQuery>
export type AllCollectionSlugSuspenseQueryHookResult = ReturnType<typeof useAllCollectionSlugSuspenseQuery>
export type AllCollectionSlugQueryResult = Apollo.QueryResult<IAllCollectionSlugQuery, IAllCollectionSlugQueryVariables>
export const AllContentSlugsDocument = gql`
  query allContentSlugs($start: Int!) {
    contents(filters: { contentType: { ne: "Industry Analysis" } }, pagination: { start: $start }) {
      data {
        ...allContentsSlugItem
      }
    }
  }
  ${AllContentsSlugItemFragmentDoc}
`

/**
 * __useAllContentSlugsQuery__
 *
 * To run a query within a React component, call `useAllContentSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllContentSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllContentSlugsQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAllContentSlugsQuery(
  baseOptions: Apollo.QueryHookOptions<IAllContentSlugsQuery, IAllContentSlugsQueryVariables> &
    ({ variables: IAllContentSlugsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllContentSlugsQuery, IAllContentSlugsQueryVariables>(AllContentSlugsDocument, options)
}
export function useAllContentSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllContentSlugsQuery, IAllContentSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllContentSlugsQuery, IAllContentSlugsQueryVariables>(AllContentSlugsDocument, options)
}
export function useAllContentSlugsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllContentSlugsQuery, IAllContentSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllContentSlugsQuery, IAllContentSlugsQueryVariables>(
    AllContentSlugsDocument,
    options,
  )
}
export type AllContentSlugsQueryHookResult = ReturnType<typeof useAllContentSlugsQuery>
export type AllContentSlugsLazyQueryHookResult = ReturnType<typeof useAllContentSlugsLazyQuery>
export type AllContentSlugsSuspenseQueryHookResult = ReturnType<typeof useAllContentSlugsSuspenseQuery>
export type AllContentSlugsQueryResult = Apollo.QueryResult<IAllContentSlugsQuery, IAllContentSlugsQueryVariables>
export const AllIndustryAnalysisDocument = gql`
  query allIndustryAnalysis($start: Int!) {
    contents(filters: { contentType: { eq: "Industry Analysis" } }, pagination: { start: $start }) {
      data {
        ...contentListItem
      }
    }
  }
  ${ContentListItemFragmentDoc}
`

/**
 * __useAllIndustryAnalysisQuery__
 *
 * To run a query within a React component, call `useAllIndustryAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIndustryAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIndustryAnalysisQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAllIndustryAnalysisQuery(
  baseOptions: Apollo.QueryHookOptions<IAllIndustryAnalysisQuery, IAllIndustryAnalysisQueryVariables> &
    ({ variables: IAllIndustryAnalysisQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllIndustryAnalysisQuery, IAllIndustryAnalysisQueryVariables>(
    AllIndustryAnalysisDocument,
    options,
  )
}
export function useAllIndustryAnalysisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllIndustryAnalysisQuery, IAllIndustryAnalysisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllIndustryAnalysisQuery, IAllIndustryAnalysisQueryVariables>(
    AllIndustryAnalysisDocument,
    options,
  )
}
export function useAllIndustryAnalysisSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllIndustryAnalysisQuery, IAllIndustryAnalysisQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllIndustryAnalysisQuery, IAllIndustryAnalysisQueryVariables>(
    AllIndustryAnalysisDocument,
    options,
  )
}
export type AllIndustryAnalysisQueryHookResult = ReturnType<typeof useAllIndustryAnalysisQuery>
export type AllIndustryAnalysisLazyQueryHookResult = ReturnType<typeof useAllIndustryAnalysisLazyQuery>
export type AllIndustryAnalysisSuspenseQueryHookResult = ReturnType<typeof useAllIndustryAnalysisSuspenseQuery>
export type AllIndustryAnalysisQueryResult = Apollo.QueryResult<
  IAllIndustryAnalysisQuery,
  IAllIndustryAnalysisQueryVariables
>
export const AllIndustryAnalysisSlugsDocument = gql`
  query allIndustryAnalysisSlugs($start: Int!) {
    contents(filters: { contentType: { eq: "Industry Analysis" } }, pagination: { start: $start }) {
      data {
        ...allContentsSlugItem
      }
    }
  }
  ${AllContentsSlugItemFragmentDoc}
`

/**
 * __useAllIndustryAnalysisSlugsQuery__
 *
 * To run a query within a React component, call `useAllIndustryAnalysisSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIndustryAnalysisSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIndustryAnalysisSlugsQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAllIndustryAnalysisSlugsQuery(
  baseOptions: Apollo.QueryHookOptions<IAllIndustryAnalysisSlugsQuery, IAllIndustryAnalysisSlugsQueryVariables> &
    ({ variables: IAllIndustryAnalysisSlugsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllIndustryAnalysisSlugsQuery, IAllIndustryAnalysisSlugsQueryVariables>(
    AllIndustryAnalysisSlugsDocument,
    options,
  )
}
export function useAllIndustryAnalysisSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllIndustryAnalysisSlugsQuery, IAllIndustryAnalysisSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllIndustryAnalysisSlugsQuery, IAllIndustryAnalysisSlugsQueryVariables>(
    AllIndustryAnalysisSlugsDocument,
    options,
  )
}
export function useAllIndustryAnalysisSlugsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IAllIndustryAnalysisSlugsQuery,
    IAllIndustryAnalysisSlugsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllIndustryAnalysisSlugsQuery, IAllIndustryAnalysisSlugsQueryVariables>(
    AllIndustryAnalysisSlugsDocument,
    options,
  )
}
export type AllIndustryAnalysisSlugsQueryHookResult = ReturnType<typeof useAllIndustryAnalysisSlugsQuery>
export type AllIndustryAnalysisSlugsLazyQueryHookResult = ReturnType<typeof useAllIndustryAnalysisSlugsLazyQuery>
export type AllIndustryAnalysisSlugsSuspenseQueryHookResult = ReturnType<
  typeof useAllIndustryAnalysisSlugsSuspenseQuery
>
export type AllIndustryAnalysisSlugsQueryResult = Apollo.QueryResult<
  IAllIndustryAnalysisSlugsQuery,
  IAllIndustryAnalysisSlugsQueryVariables
>
export const AllSolutionRequestsSlugsDocument = gql`
  query allSolutionRequestsSlugs($filters: SolutionRequestFiltersInput, $pagination: PaginationArg, $sort: [String], $publicationState: PublicationState) {
    solutionRequests(filters: $filters, pagination: $pagination, sort: $sort, publicationState: $publicationState) {
      data {
        ...allSolutionRequestsItems
      }
    }
  }
  ${AllSolutionRequestsItemsFragmentDoc}
`
export const EmailReceiversDocument = gql`
  query emailReceivers($filters: EmailReceiverFiltersInput, $pagination: PaginationArg, $sort: [String], $publicationState: PublicationState) {
    emailReceivers(filters: $filters, pagination: $pagination, sort: $sort, publicationState: $publicationState) {
      data {
        id
        attributes {
          email
          enable
        }
      }
    }
  }
`

/**
 * __useAllSolutionRequestsSlugsQuery__
 *
 * To run a query within a React component, call `useAllSolutionRequestsSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSolutionRequestsSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSolutionRequestsSlugsQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAllSolutionRequestsSlugsQuery(
  baseOptions: Apollo.QueryHookOptions<IAllSolutionRequestsSlugsQuery, IAllSolutionRequestsSlugsQueryVariables> &
    ({ variables: IAllSolutionRequestsSlugsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllSolutionRequestsSlugsQuery, IAllSolutionRequestsSlugsQueryVariables>(
    AllSolutionRequestsSlugsDocument,
    options,
  )
}

export function useEmailReceiversQuery(
  baseOptions?: Apollo.QueryHookOptions<IEmailReceiversQuery, IEmailReceiversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IEmailReceiversQuery, IEmailReceiversQueryVariables>(
    EmailReceiversDocument,
    options,
  )
}

export function useAllSolutionRequestsSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllSolutionRequestsSlugsQuery, IAllSolutionRequestsSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllSolutionRequestsSlugsQuery, IAllSolutionRequestsSlugsQueryVariables>(
    AllSolutionRequestsSlugsDocument,
    options,
  )
}
export function useAllSolutionRequestsSlugsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IAllSolutionRequestsSlugsQuery,
    IAllSolutionRequestsSlugsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllSolutionRequestsSlugsQuery, IAllSolutionRequestsSlugsQueryVariables>(
    AllSolutionRequestsSlugsDocument,
    options,
  )
}
export type AllSolutionRequestsSlugsQueryHookResult = ReturnType<typeof useAllSolutionRequestsSlugsQuery>
export type AllSolutionRequestsSlugsLazyQueryHookResult = ReturnType<typeof useAllSolutionRequestsSlugsLazyQuery>
export type AllSolutionRequestsSlugsSuspenseQueryHookResult = ReturnType<
  typeof useAllSolutionRequestsSlugsSuspenseQuery
>
export type AllSolutionRequestsSlugsQueryResult = Apollo.QueryResult<
  IAllSolutionRequestsSlugsQuery,
  IAllSolutionRequestsSlugsQueryVariables
>
export const AllSolutionSlugsDocument = gql`
  query allSolutionSlugs($type: String!, $start: Int!) {
    solutions(filters: { type: { eq: $type } }, pagination: { start: $start }) {
      data {
        ...allSolutionsSlugItem
      }
    }
  }
  ${AllSolutionsSlugItemFragmentDoc}
`

/**
 * __useAllSolutionSlugsQuery__
 *
 * To run a query within a React component, call `useAllSolutionSlugsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSolutionSlugsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSolutionSlugsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useAllSolutionSlugsQuery(
  baseOptions: Apollo.QueryHookOptions<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables> &
    ({ variables: IAllSolutionSlugsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables>(AllSolutionSlugsDocument, options)
}
export function useAllSolutionSlugsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables>(AllSolutionSlugsDocument, options)
}
export function useAllSolutionSlugsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables>(
    AllSolutionSlugsDocument,
    options,
  )
}
export type AllSolutionSlugsQueryHookResult = ReturnType<typeof useAllSolutionSlugsQuery>
export type AllSolutionSlugsLazyQueryHookResult = ReturnType<typeof useAllSolutionSlugsLazyQuery>
export type AllSolutionSlugsSuspenseQueryHookResult = ReturnType<typeof useAllSolutionSlugsSuspenseQuery>
export type AllSolutionSlugsQueryResult = Apollo.QueryResult<IAllSolutionSlugsQuery, IAllSolutionSlugsQueryVariables>
export const AlspBySlugDocument = gql`
  query alspBySlug($slug: String!, $includeIlta: Boolean!) {
    solutions(filters: { slug: { eq: $slug }, type: { eq: "ALSP" } }) {
      data {
        ...alspSolutionData
      }
    }
  }
  ${AlspSolutionDataFragmentDoc}
`

/**
 * __useAlspBySlugQuery__
 *
 * To run a query within a React component, call `useAlspBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlspBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlspBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      includeIlta: // value for 'includeIlta'
 *   },
 * });
 */
export function useAlspBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IAlspBySlugQuery, IAlspBySlugQueryVariables> &
    ({ variables: IAlspBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAlspBySlugQuery, IAlspBySlugQueryVariables>(AlspBySlugDocument, options)
}
export function useAlspBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAlspBySlugQuery, IAlspBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAlspBySlugQuery, IAlspBySlugQueryVariables>(AlspBySlugDocument, options)
}
export function useAlspBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAlspBySlugQuery, IAlspBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAlspBySlugQuery, IAlspBySlugQueryVariables>(AlspBySlugDocument, options)
}
export type AlspBySlugQueryHookResult = ReturnType<typeof useAlspBySlugQuery>
export type AlspBySlugLazyQueryHookResult = ReturnType<typeof useAlspBySlugLazyQuery>
export type AlspBySlugSuspenseQueryHookResult = ReturnType<typeof useAlspBySlugSuspenseQuery>
export type AlspBySlugQueryResult = Apollo.QueryResult<IAlspBySlugQuery, IAlspBySlugQueryVariables>
export const AuthorBySlugDocument = gql`
  query authorBySlug($slug: String!) {
    authors(filters: { slug: { eq: $slug } }) {
      data {
        ...authorFullItem
      }
    }
  }
  ${AuthorFullItemFragmentDoc}
`

/**
 * __useAuthorBySlugQuery__
 *
 * To run a query within a React component, call `useAuthorBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAuthorBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IAuthorBySlugQuery, IAuthorBySlugQueryVariables> &
    ({ variables: IAuthorBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAuthorBySlugQuery, IAuthorBySlugQueryVariables>(AuthorBySlugDocument, options)
}
export function useAuthorBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAuthorBySlugQuery, IAuthorBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAuthorBySlugQuery, IAuthorBySlugQueryVariables>(AuthorBySlugDocument, options)
}
export function useAuthorBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAuthorBySlugQuery, IAuthorBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAuthorBySlugQuery, IAuthorBySlugQueryVariables>(AuthorBySlugDocument, options)
}
export type AuthorBySlugQueryHookResult = ReturnType<typeof useAuthorBySlugQuery>
export type AuthorBySlugLazyQueryHookResult = ReturnType<typeof useAuthorBySlugLazyQuery>
export type AuthorBySlugSuspenseQueryHookResult = ReturnType<typeof useAuthorBySlugSuspenseQuery>
export type AuthorBySlugQueryResult = Apollo.QueryResult<IAuthorBySlugQuery, IAuthorBySlugQueryVariables>
export const AutosuggestDocument = gql`
  query autosuggest($keyword: String!) {
    autosuggest(keyword: $keyword) {
      success
      solutions {
        ...autosuggestItem
      }
      events {
        ...autosuggestItem
      }
      categories {
        ...autosuggestItem
      }
      companies {
        ...autosuggestItem
      }
      contents {
        ...autosuggestItem
      }
    }
  }
  ${AutosuggestItemFragmentDoc}
`

/**
 * __useAutosuggestQuery__
 *
 * To run a query within a React component, call `useAutosuggestQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutosuggestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutosuggestQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useAutosuggestQuery(
  baseOptions: Apollo.QueryHookOptions<IAutosuggestQuery, IAutosuggestQueryVariables> &
    ({ variables: IAutosuggestQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAutosuggestQuery, IAutosuggestQueryVariables>(AutosuggestDocument, options)
}
export function useAutosuggestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAutosuggestQuery, IAutosuggestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAutosuggestQuery, IAutosuggestQueryVariables>(AutosuggestDocument, options)
}
export function useAutosuggestSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAutosuggestQuery, IAutosuggestQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAutosuggestQuery, IAutosuggestQueryVariables>(AutosuggestDocument, options)
}
export type AutosuggestQueryHookResult = ReturnType<typeof useAutosuggestQuery>
export type AutosuggestLazyQueryHookResult = ReturnType<typeof useAutosuggestLazyQuery>
export type AutosuggestSuspenseQueryHookResult = ReturnType<typeof useAutosuggestSuspenseQuery>
export type AutosuggestQueryResult = Apollo.QueryResult<IAutosuggestQuery, IAutosuggestQueryVariables>
export const BlogTaxonomiesDocument = gql`
  query blogTaxonomies {
    blogTaxonomys {
      data {
        ...blogTaxonomyData
      }
    }
  }
  ${BlogTaxonomyDataFragmentDoc}
`

/**
 * __useBlogTaxonomiesQuery__
 *
 * To run a query within a React component, call `useBlogTaxonomiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogTaxonomiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogTaxonomiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogTaxonomiesQuery(
  baseOptions?: Apollo.QueryHookOptions<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>(BlogTaxonomiesDocument, options)
}
export function useBlogTaxonomiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>(BlogTaxonomiesDocument, options)
}
export function useBlogTaxonomiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>(BlogTaxonomiesDocument, options)
}
export type BlogTaxonomiesQueryHookResult = ReturnType<typeof useBlogTaxonomiesQuery>
export type BlogTaxonomiesLazyQueryHookResult = ReturnType<typeof useBlogTaxonomiesLazyQuery>
export type BlogTaxonomiesSuspenseQueryHookResult = ReturnType<typeof useBlogTaxonomiesSuspenseQuery>
export type BlogTaxonomiesQueryResult = Apollo.QueryResult<IBlogTaxonomiesQuery, IBlogTaxonomiesQueryVariables>
export const BlogTaxonomyBySlugDocument = gql`
  query blogTaxonomyBySlug($slug: String!) {
    blogTaxonomys(filters: { slug: { eq: $slug } }) {
      data {
        ...blogTaxonomyFullData
        attributes {
          showVendorTopics
        }
      }
    }
  }
  ${BlogTaxonomyFullDataFragmentDoc}
`

/**
 * __useBlogTaxonomyBySlugQuery__
 *
 * To run a query within a React component, call `useBlogTaxonomyBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogTaxonomyBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogTaxonomyBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useBlogTaxonomyBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IBlogTaxonomyBySlugQuery, IBlogTaxonomyBySlugQueryVariables> &
    ({ variables: IBlogTaxonomyBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IBlogTaxonomyBySlugQuery, IBlogTaxonomyBySlugQueryVariables>(
    BlogTaxonomyBySlugDocument,
    options,
  )
}
export function useBlogTaxonomyBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IBlogTaxonomyBySlugQuery, IBlogTaxonomyBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IBlogTaxonomyBySlugQuery, IBlogTaxonomyBySlugQueryVariables>(
    BlogTaxonomyBySlugDocument,
    options,
  )
}
export function useBlogTaxonomyBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IBlogTaxonomyBySlugQuery, IBlogTaxonomyBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IBlogTaxonomyBySlugQuery, IBlogTaxonomyBySlugQueryVariables>(
    BlogTaxonomyBySlugDocument,
    options,
  )
}
export type BlogTaxonomyBySlugQueryHookResult = ReturnType<typeof useBlogTaxonomyBySlugQuery>
export type BlogTaxonomyBySlugLazyQueryHookResult = ReturnType<typeof useBlogTaxonomyBySlugLazyQuery>
export type BlogTaxonomyBySlugSuspenseQueryHookResult = ReturnType<typeof useBlogTaxonomyBySlugSuspenseQuery>
export type BlogTaxonomyBySlugQueryResult = Apollo.QueryResult<
  IBlogTaxonomyBySlugQuery,
  IBlogTaxonomyBySlugQueryVariables
>
export const CarouselSolutionsBySubTopicSlugDocument = gql`
  query carouselSolutionsBySubTopicSlug($query: JSON) {
    searchSolutions(query: $query) {
      total
      solutions {
        ...solutionCarouselItem
      }
    }
  }
  ${SolutionCarouselItemFragmentDoc}
`

/**
 * __useCarouselSolutionsBySubTopicSlugQuery__
 *
 * To run a query within a React component, call `useCarouselSolutionsBySubTopicSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarouselSolutionsBySubTopicSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarouselSolutionsBySubTopicSlugQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCarouselSolutionsBySubTopicSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ICarouselSolutionsBySubTopicSlugQuery,
    ICarouselSolutionsBySubTopicSlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ICarouselSolutionsBySubTopicSlugQuery, ICarouselSolutionsBySubTopicSlugQueryVariables>(
    CarouselSolutionsBySubTopicSlugDocument,
    options,
  )
}
export function useCarouselSolutionsBySubTopicSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICarouselSolutionsBySubTopicSlugQuery,
    ICarouselSolutionsBySubTopicSlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ICarouselSolutionsBySubTopicSlugQuery, ICarouselSolutionsBySubTopicSlugQueryVariables>(
    CarouselSolutionsBySubTopicSlugDocument,
    options,
  )
}
export function useCarouselSolutionsBySubTopicSlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ICarouselSolutionsBySubTopicSlugQuery,
    ICarouselSolutionsBySubTopicSlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ICarouselSolutionsBySubTopicSlugQuery, ICarouselSolutionsBySubTopicSlugQueryVariables>(
    CarouselSolutionsBySubTopicSlugDocument,
    options,
  )
}
export type CarouselSolutionsBySubTopicSlugQueryHookResult = ReturnType<typeof useCarouselSolutionsBySubTopicSlugQuery>
export type CarouselSolutionsBySubTopicSlugLazyQueryHookResult = ReturnType<
  typeof useCarouselSolutionsBySubTopicSlugLazyQuery
>
export type CarouselSolutionsBySubTopicSlugSuspenseQueryHookResult = ReturnType<
  typeof useCarouselSolutionsBySubTopicSlugSuspenseQuery
>
export type CarouselSolutionsBySubTopicSlugQueryResult = Apollo.QueryResult<
  ICarouselSolutionsBySubTopicSlugQuery,
  ICarouselSolutionsBySubTopicSlugQueryVariables
>
export const ChangeMyPasswordDocument = gql`
  mutation changeMyPassword($input: ChangePasswordRequest!) {
    changeMyPassword(input: $input) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`
export type IChangeMyPasswordMutationFn = Apollo.MutationFunction<
  IChangeMyPasswordMutation,
  IChangeMyPasswordMutationVariables
>

/**
 * __useChangeMyPasswordMutation__
 *
 * To run a mutation, you first call `useChangeMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMyPasswordMutation, { data, loading, error }] = useChangeMyPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeMyPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<IChangeMyPasswordMutation, IChangeMyPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IChangeMyPasswordMutation, IChangeMyPasswordMutationVariables>(
    ChangeMyPasswordDocument,
    options,
  )
}
export type ChangeMyPasswordMutationHookResult = ReturnType<typeof useChangeMyPasswordMutation>
export type ChangeMyPasswordMutationResult = Apollo.MutationResult<IChangeMyPasswordMutation>
export type ChangeMyPasswordMutationOptions = Apollo.BaseMutationOptions<
  IChangeMyPasswordMutation,
  IChangeMyPasswordMutationVariables
>
export const ConsultancyBySlugDocument = gql`
  query consultancyBySlug($slug: String!, $includeIlta: Boolean!) {
    solutions(filters: { slug: { eq: $slug }, type: { eq: "Consultancy" } }) {
      data {
        ...consultancySolutionData
      }
    }
  }
  ${ConsultancySolutionDataFragmentDoc}
`

/**
 * __useConsultancyBySlugQuery__
 *
 * To run a query within a React component, call `useConsultancyBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultancyBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultancyBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      includeIlta: // value for 'includeIlta'
 *   },
 * });
 */
export function useConsultancyBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables> &
    ({ variables: IConsultancyBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables>(ConsultancyBySlugDocument, options)
}
export function useConsultancyBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables>(
    ConsultancyBySlugDocument,
    options,
  )
}
export function useConsultancyBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables>(
    ConsultancyBySlugDocument,
    options,
  )
}
export type ConsultancyBySlugQueryHookResult = ReturnType<typeof useConsultancyBySlugQuery>
export type ConsultancyBySlugLazyQueryHookResult = ReturnType<typeof useConsultancyBySlugLazyQuery>
export type ConsultancyBySlugSuspenseQueryHookResult = ReturnType<typeof useConsultancyBySlugSuspenseQuery>
export type ConsultancyBySlugQueryResult = Apollo.QueryResult<IConsultancyBySlugQuery, IConsultancyBySlugQueryVariables>
export const ContentBySlugDocument = gql`
  query contentBySlug($slug: String!) {
    contentBySlug(slug: $slug) {
      isInvited
      resourcePublishedAt
      vendorSelfContent
      content {
        ...contentData
        __typename
      }
      __typename
    }
  }
  ${ContentDataFragmentDoc}
`

/**
 * __useContentBySlugQuery__
 *
 * To run a query within a React component, call `useContentBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useContentBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IContentBySlugQuery, IContentBySlugQueryVariables> &
    ({ variables: IContentBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IContentBySlugQuery, IContentBySlugQueryVariables>(ContentBySlugDocument, options)
}
export function useContentBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IContentBySlugQuery, IContentBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IContentBySlugQuery, IContentBySlugQueryVariables>(ContentBySlugDocument, options)
}
export function useContentBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IContentBySlugQuery, IContentBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IContentBySlugQuery, IContentBySlugQueryVariables>(ContentBySlugDocument, options)
}
export type ContentBySlugQueryHookResult = ReturnType<typeof useContentBySlugQuery>
export type ContentBySlugLazyQueryHookResult = ReturnType<typeof useContentBySlugLazyQuery>
export type ContentBySlugSuspenseQueryHookResult = ReturnType<typeof useContentBySlugSuspenseQuery>
export type ContentBySlugQueryResult = Apollo.QueryResult<IContentBySlugQuery, IContentBySlugQueryVariables>
export const ContentVisitDownloadsDocument = gql`
  query contentVisitDownloads {
    contentVisitDownloads {
      id
      title
      slug
      sharedResources {
        visits {
          visited_by {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
        downloads {
          downloaded_by {
            data {
              id
              attributes {
                username
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useContentVisitDownloadsQuery__
 *
 * To run a query within a React component, call `useContentVisitDownloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentVisitDownloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentVisitDownloadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentVisitDownloadsQuery(
  baseOptions?: Apollo.QueryHookOptions<IContentVisitDownloadsQuery, IContentVisitDownloadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IContentVisitDownloadsQuery, IContentVisitDownloadsQueryVariables>(
    ContentVisitDownloadsDocument,
    options,
  )
}
export function useContentVisitDownloadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IContentVisitDownloadsQuery, IContentVisitDownloadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IContentVisitDownloadsQuery, IContentVisitDownloadsQueryVariables>(
    ContentVisitDownloadsDocument,
    options,
  )
}
export function useContentVisitDownloadsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IContentVisitDownloadsQuery, IContentVisitDownloadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IContentVisitDownloadsQuery, IContentVisitDownloadsQueryVariables>(
    ContentVisitDownloadsDocument,
    options,
  )
}
export type ContentVisitDownloadsQueryHookResult = ReturnType<typeof useContentVisitDownloadsQuery>
export type ContentVisitDownloadsLazyQueryHookResult = ReturnType<typeof useContentVisitDownloadsLazyQuery>
export type ContentVisitDownloadsSuspenseQueryHookResult = ReturnType<typeof useContentVisitDownloadsSuspenseQuery>
export type ContentVisitDownloadsQueryResult = Apollo.QueryResult<
  IContentVisitDownloadsQuery,
  IContentVisitDownloadsQueryVariables
>
export const CreateAnnouncementDocument = gql`
  mutation createAnnouncement($data: AnnouncementInput!) {
    createAnnouncement(data: $data) {
      data {
        id
        attributes {
          announcement
          optionalLink
          createdAt
        }
      }
    }
  }
`
export type ICreateAnnouncementMutationFn = Apollo.MutationFunction<
  ICreateAnnouncementMutation,
  ICreateAnnouncementMutationVariables
>

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnouncementMutation, { data, loading, error }] = useCreateAnnouncementMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateAnnouncementMutation, ICreateAnnouncementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateAnnouncementMutation, ICreateAnnouncementMutationVariables>(
    CreateAnnouncementDocument,
    options,
  )
}
export type CreateAnnouncementMutationHookResult = ReturnType<typeof useCreateAnnouncementMutation>
export type CreateAnnouncementMutationResult = Apollo.MutationResult<ICreateAnnouncementMutation>
export type CreateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  ICreateAnnouncementMutation,
  ICreateAnnouncementMutationVariables
>
export const CreateListingAlspDocument = gql`
  mutation createListingAlsp($data: ListingRequestData!) {
    createListingRequestData(data: $data) {
      alspReq {
        id
        listingName
        userName
        userRole
        userEmail
        phone
        marketName
        marketEmail
        ceoName
        ceoEmail
        shortDescription
        longDescription
      }
      enhancedAlspReq {
        founders {
          id
        }
        specialists {
          id
        }
        screenShots {
          id
        }
        testimonials {
          id
        }
      }
    }
  }
`
export type ICreateListingAlspMutationFn = Apollo.MutationFunction<
  ICreateListingAlspMutation,
  ICreateListingAlspMutationVariables
>

/**
 * __useCreateListingAlspMutation__
 *
 * To run a mutation, you first call `useCreateListingAlspMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingAlspMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingAlspMutation, { data, loading, error }] = useCreateListingAlspMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateListingAlspMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateListingAlspMutation, ICreateListingAlspMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateListingAlspMutation, ICreateListingAlspMutationVariables>(
    CreateListingAlspDocument,
    options,
  )
}
export type CreateListingAlspMutationHookResult = ReturnType<typeof useCreateListingAlspMutation>
export type CreateListingAlspMutationResult = Apollo.MutationResult<ICreateListingAlspMutation>
export type CreateListingAlspMutationOptions = Apollo.BaseMutationOptions<
  ICreateListingAlspMutation,
  ICreateListingAlspMutationVariables
>
export const CreateListingConsultantDocument = gql`
  mutation createListingConsultant($data: ListingRequestData!) {
    createListingRequestData(data: $data) {
      consultantReq {
        id
        listingName
        userName
        userRole
        userEmail
        phone
        marketName
        marketEmail
        ceoName
        ceoEmail
        shortDescription
        longDescription
      }
      enhancedConsultantReq {
        founders {
          id
        }
        specialists {
          id
        }
        screenShots {
          id
        }
        testimonials {
          id
        }
      }
    }
  }
`
export type ICreateListingConsultantMutationFn = Apollo.MutationFunction<
  ICreateListingConsultantMutation,
  ICreateListingConsultantMutationVariables
>

/**
 * __useCreateListingConsultantMutation__
 *
 * To run a mutation, you first call `useCreateListingConsultantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingConsultantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingConsultantMutation, { data, loading, error }] = useCreateListingConsultantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateListingConsultantMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateListingConsultantMutation, ICreateListingConsultantMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateListingConsultantMutation, ICreateListingConsultantMutationVariables>(
    CreateListingConsultantDocument,
    options,
  )
}
export type CreateListingConsultantMutationHookResult = ReturnType<typeof useCreateListingConsultantMutation>
export type CreateListingConsultantMutationResult = Apollo.MutationResult<ICreateListingConsultantMutation>
export type CreateListingConsultantMutationOptions = Apollo.BaseMutationOptions<
  ICreateListingConsultantMutation,
  ICreateListingConsultantMutationVariables
>
export const CreateListingProductDocument = gql`
  mutation createListingProduct($data: ListingRequestData!) {
    createListingRequestData(data: $data) {
      vendorReq {
        id
        companyName
        email
        name
        ceoEmail
        ceoName
        marketEmail
        marketName
        website
        productName
        shortDescription
        longDescription
        isCreate
        notes
      }
      enhancedVendorReq {
        screenShots {
          id
        }
        testimonials {
          id
        }
      }
    }
  }
`
export type ICreateListingProductMutationFn = Apollo.MutationFunction<
  ICreateListingProductMutation,
  ICreateListingProductMutationVariables
>

/**
 * __useCreateListingProductMutation__
 *
 * To run a mutation, you first call `useCreateListingProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListingProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListingProductMutation, { data, loading, error }] = useCreateListingProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateListingProductMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateListingProductMutation, ICreateListingProductMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateListingProductMutation, ICreateListingProductMutationVariables>(
    CreateListingProductDocument,
    options,
  )
}
export type CreateListingProductMutationHookResult = ReturnType<typeof useCreateListingProductMutation>
export type CreateListingProductMutationResult = Apollo.MutationResult<ICreateListingProductMutation>
export type CreateListingProductMutationOptions = Apollo.BaseMutationOptions<
  ICreateListingProductMutation,
  ICreateListingProductMutationVariables
>
export const CreateSolutionRequestDocument = gql`
  mutation createSolutionRequest($data: SolutionRequestInput!) {
    createSolutionRequest(data: $data) {
      data {
        id
      }
    }
  }
`
export type ICreateSolutionRequestMutationFn = Apollo.MutationFunction<
  ICreateSolutionRequestMutation,
  ICreateSolutionRequestMutationVariables
>

/**
 * __useCreateSolutionRequestMutation__
 *
 * To run a mutation, you first call `useCreateSolutionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSolutionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSolutionRequestMutation, { data, loading, error }] = useCreateSolutionRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSolutionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateSolutionRequestMutation, ICreateSolutionRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateSolutionRequestMutation, ICreateSolutionRequestMutationVariables>(
    CreateSolutionRequestDocument,
    options,
  )
}
export type CreateSolutionRequestMutationHookResult = ReturnType<typeof useCreateSolutionRequestMutation>
export type CreateSolutionRequestMutationResult = Apollo.MutationResult<ICreateSolutionRequestMutation>
export type CreateSolutionRequestMutationOptions = Apollo.BaseMutationOptions<
  ICreateSolutionRequestMutation,
  ICreateSolutionRequestMutationVariables
>
export const CreateUploadFileDocument = gql`
  mutation createUploadFile($data: UploadFileInput!) {
    createUploadFile(data: $data) {
      data {
        id
        attributes {
          name
          url
          size
        }
      }
    }
  }
`
export type ICreateUploadFileMutationFn = Apollo.MutationFunction<
  ICreateUploadFileMutation,
  ICreateUploadFileMutationVariables
>

/**
 * __useCreateUploadFileMutation__
 *
 * To run a mutation, you first call `useCreateUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadFileMutation, { data, loading, error }] = useCreateUploadFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<ICreateUploadFileMutation, ICreateUploadFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ICreateUploadFileMutation, ICreateUploadFileMutationVariables>(
    CreateUploadFileDocument,
    options,
  )
}
export type CreateUploadFileMutationHookResult = ReturnType<typeof useCreateUploadFileMutation>
export type CreateUploadFileMutationResult = Apollo.MutationResult<ICreateUploadFileMutation>
export type CreateUploadFileMutationOptions = Apollo.BaseMutationOptions<
  ICreateUploadFileMutation,
  ICreateUploadFileMutationVariables
>
export const DownloadContentDocument = gql`
  mutation downloadContent($content_id: ID!) {
    downloadContent(data: { content_id: $content_id })
  }
`
export type IDownloadContentMutationFn = Apollo.MutationFunction<
  IDownloadContentMutation,
  IDownloadContentMutationVariables
>

/**
 * __useDownloadContentMutation__
 *
 * To run a mutation, you first call `useDownloadContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadContentMutation, { data, loading, error }] = useDownloadContentMutation({
 *   variables: {
 *      content_id: // value for 'content_id'
 *   },
 * });
 */
export function useDownloadContentMutation(
  baseOptions?: Apollo.MutationHookOptions<IDownloadContentMutation, IDownloadContentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IDownloadContentMutation, IDownloadContentMutationVariables>(
    DownloadContentDocument,
    options,
  )
}
export type DownloadContentMutationHookResult = ReturnType<typeof useDownloadContentMutation>
export type DownloadContentMutationResult = Apollo.MutationResult<IDownloadContentMutation>
export type DownloadContentMutationOptions = Apollo.BaseMutationOptions<
  IDownloadContentMutation,
  IDownloadContentMutationVariables
>
export const DownloadMaterialFilesDocument = gql`
  query downloadMaterialFiles($id: ID!) {
    downloadMaterialFile(id: $id) {
      url
    }
  }
`

/**
 * __useDownloadMaterialFilesQuery__
 *
 * To run a query within a React component, call `useDownloadMaterialFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadMaterialFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadMaterialFilesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDownloadMaterialFilesQuery(
  baseOptions: Apollo.QueryHookOptions<IDownloadMaterialFilesQuery, IDownloadMaterialFilesQueryVariables> &
    ({ variables: IDownloadMaterialFilesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IDownloadMaterialFilesQuery, IDownloadMaterialFilesQueryVariables>(
    DownloadMaterialFilesDocument,
    options,
  )
}
export function useDownloadMaterialFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IDownloadMaterialFilesQuery, IDownloadMaterialFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IDownloadMaterialFilesQuery, IDownloadMaterialFilesQueryVariables>(
    DownloadMaterialFilesDocument,
    options,
  )
}
export function useDownloadMaterialFilesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IDownloadMaterialFilesQuery, IDownloadMaterialFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IDownloadMaterialFilesQuery, IDownloadMaterialFilesQueryVariables>(
    DownloadMaterialFilesDocument,
    options,
  )
}
export type DownloadMaterialFilesQueryHookResult = ReturnType<typeof useDownloadMaterialFilesQuery>
export type DownloadMaterialFilesLazyQueryHookResult = ReturnType<typeof useDownloadMaterialFilesLazyQuery>
export type DownloadMaterialFilesSuspenseQueryHookResult = ReturnType<typeof useDownloadMaterialFilesSuspenseQuery>
export type DownloadMaterialFilesQueryResult = Apollo.QueryResult<
  IDownloadMaterialFilesQuery,
  IDownloadMaterialFilesQueryVariables
>
export const EventFeaturesDocument = gql`
  query eventFeatures {
    eventFeatures(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useEventFeaturesQuery__
 *
 * To run a query within a React component, call `useEventFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<IEventFeaturesQuery, IEventFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IEventFeaturesQuery, IEventFeaturesQueryVariables>(EventFeaturesDocument, options)
}
export function useEventFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IEventFeaturesQuery, IEventFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IEventFeaturesQuery, IEventFeaturesQueryVariables>(EventFeaturesDocument, options)
}
export function useEventFeaturesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IEventFeaturesQuery, IEventFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IEventFeaturesQuery, IEventFeaturesQueryVariables>(EventFeaturesDocument, options)
}
export type EventFeaturesQueryHookResult = ReturnType<typeof useEventFeaturesQuery>
export type EventFeaturesLazyQueryHookResult = ReturnType<typeof useEventFeaturesLazyQuery>
export type EventFeaturesSuspenseQueryHookResult = ReturnType<typeof useEventFeaturesSuspenseQuery>
export type EventFeaturesQueryResult = Apollo.QueryResult<IEventFeaturesQuery, IEventFeaturesQueryVariables>
export const ExpertBySlugDocument = gql`
  query expertBySlug($slug: String!) {
    experts(filters: { slug: { eq: $slug } }) {
      data {
        ...expertFullItem
      }
    }
  }
  ${ExpertFullItemFragmentDoc}
`

/**
 * __useExpertBySlugQuery__
 *
 * To run a query within a React component, call `useExpertBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useExpertBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IExpertBySlugQuery, IExpertBySlugQueryVariables> &
    ({ variables: IExpertBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IExpertBySlugQuery, IExpertBySlugQueryVariables>(ExpertBySlugDocument, options)
}
export function useExpertBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IExpertBySlugQuery, IExpertBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IExpertBySlugQuery, IExpertBySlugQueryVariables>(ExpertBySlugDocument, options)
}
export function useExpertBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IExpertBySlugQuery, IExpertBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IExpertBySlugQuery, IExpertBySlugQueryVariables>(ExpertBySlugDocument, options)
}
export type ExpertBySlugQueryHookResult = ReturnType<typeof useExpertBySlugQuery>
export type ExpertBySlugLazyQueryHookResult = ReturnType<typeof useExpertBySlugLazyQuery>
export type ExpertBySlugSuspenseQueryHookResult = ReturnType<typeof useExpertBySlugSuspenseQuery>
export type ExpertBySlugQueryResult = Apollo.QueryResult<IExpertBySlugQuery, IExpertBySlugQueryVariables>
export const ExpertsDocument = gql`
  query experts {
    experts(sort: "firstName:asc") {
      data {
        ...expertCardData
      }
    }
  }
  ${ExpertCardDataFragmentDoc}
`

/**
 * __useExpertsQuery__
 *
 * To run a query within a React component, call `useExpertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpertsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpertsQuery(baseOptions?: Apollo.QueryHookOptions<IExpertsQuery, IExpertsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IExpertsQuery, IExpertsQueryVariables>(ExpertsDocument, options)
}
export function useExpertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IExpertsQuery, IExpertsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IExpertsQuery, IExpertsQueryVariables>(ExpertsDocument, options)
}
export function useExpertsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IExpertsQuery, IExpertsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IExpertsQuery, IExpertsQueryVariables>(ExpertsDocument, options)
}
export type ExpertsQueryHookResult = ReturnType<typeof useExpertsQuery>
export type ExpertsLazyQueryHookResult = ReturnType<typeof useExpertsLazyQuery>
export type ExpertsSuspenseQueryHookResult = ReturnType<typeof useExpertsSuspenseQuery>
export type ExpertsQueryResult = Apollo.QueryResult<IExpertsQuery, IExpertsQueryVariables>
export const FeaturedContentsBySubTopicSlugDocument = gql`
  query featuredContentsBySubTopicSlug(
    $contentType: ENUM_CONTENT_CONTENTTYPE!
    $subTopicSlug: String!
    $start: Int!
    $limit: Int!
  ) {
    featuredContents(contentType: $contentType, subTopicSlug: $subTopicSlug, start: $start, limit: $limit) {
      ...contentListItem
    }
  }
  ${ContentListItemFragmentDoc}
`

/**
 * __useFeaturedContentsBySubTopicSlugQuery__
 *
 * To run a query within a React component, call `useFeaturedContentsBySubTopicSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedContentsBySubTopicSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedContentsBySubTopicSlugQuery({
 *   variables: {
 *      contentType: // value for 'contentType'
 *      subTopicSlug: // value for 'subTopicSlug'
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFeaturedContentsBySubTopicSlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    IFeaturedContentsBySubTopicSlugQuery,
    IFeaturedContentsBySubTopicSlugQueryVariables
  > &
    ({ variables: IFeaturedContentsBySubTopicSlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IFeaturedContentsBySubTopicSlugQuery, IFeaturedContentsBySubTopicSlugQueryVariables>(
    FeaturedContentsBySubTopicSlugDocument,
    options,
  )
}
export function useFeaturedContentsBySubTopicSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IFeaturedContentsBySubTopicSlugQuery,
    IFeaturedContentsBySubTopicSlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IFeaturedContentsBySubTopicSlugQuery, IFeaturedContentsBySubTopicSlugQueryVariables>(
    FeaturedContentsBySubTopicSlugDocument,
    options,
  )
}
export function useFeaturedContentsBySubTopicSlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IFeaturedContentsBySubTopicSlugQuery,
    IFeaturedContentsBySubTopicSlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IFeaturedContentsBySubTopicSlugQuery, IFeaturedContentsBySubTopicSlugQueryVariables>(
    FeaturedContentsBySubTopicSlugDocument,
    options,
  )
}
export type FeaturedContentsBySubTopicSlugQueryHookResult = ReturnType<typeof useFeaturedContentsBySubTopicSlugQuery>
export type FeaturedContentsBySubTopicSlugLazyQueryHookResult = ReturnType<
  typeof useFeaturedContentsBySubTopicSlugLazyQuery
>
export type FeaturedContentsBySubTopicSlugSuspenseQueryHookResult = ReturnType<
  typeof useFeaturedContentsBySubTopicSlugSuspenseQuery
>
export type FeaturedContentsBySubTopicSlugQueryResult = Apollo.QueryResult<
  IFeaturedContentsBySubTopicSlugQuery,
  IFeaturedContentsBySubTopicSlugQueryVariables
>
export const FollowSolutionDocument = gql`
  mutation followSolution($id: Int!, $follow: Boolean) {
    followSolution(id: $id, follow: $follow)
  }
`
export type IFollowSolutionMutationFn = Apollo.MutationFunction<
  IFollowSolutionMutation,
  IFollowSolutionMutationVariables
>

/**
 * __useFollowSolutionMutation__
 *
 * To run a mutation, you first call `useFollowSolutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowSolutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followSolutionMutation, { data, loading, error }] = useFollowSolutionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      follow: // value for 'follow'
 *   },
 * });
 */
export function useFollowSolutionMutation(
  baseOptions?: Apollo.MutationHookOptions<IFollowSolutionMutation, IFollowSolutionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IFollowSolutionMutation, IFollowSolutionMutationVariables>(FollowSolutionDocument, options)
}
export type FollowSolutionMutationHookResult = ReturnType<typeof useFollowSolutionMutation>
export type FollowSolutionMutationResult = Apollo.MutationResult<IFollowSolutionMutation>
export type FollowSolutionMutationOptions = Apollo.BaseMutationOptions<
  IFollowSolutionMutation,
  IFollowSolutionMutationVariables
>
export const AllFeaturesDocument = gql`
  query allFeatures {
    features {
      data {
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useAllFeaturesQuery__
 *
 * To run a query within a React component, call `useAllFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<IAllFeaturesQuery, IAllFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IAllFeaturesQuery, IAllFeaturesQueryVariables>(AllFeaturesDocument, options)
}
export function useAllFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IAllFeaturesQuery, IAllFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IAllFeaturesQuery, IAllFeaturesQueryVariables>(AllFeaturesDocument, options)
}
export function useAllFeaturesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IAllFeaturesQuery, IAllFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IAllFeaturesQuery, IAllFeaturesQueryVariables>(AllFeaturesDocument, options)
}
export type AllFeaturesQueryHookResult = ReturnType<typeof useAllFeaturesQuery>
export type AllFeaturesLazyQueryHookResult = ReturnType<typeof useAllFeaturesLazyQuery>
export type AllFeaturesSuspenseQueryHookResult = ReturnType<typeof useAllFeaturesSuspenseQuery>
export type AllFeaturesQueryResult = Apollo.QueryResult<IAllFeaturesQuery, IAllFeaturesQueryVariables>
export const GetProductSolutionSecurityDataDocument = gql`
  query getProductSolutionSecurityData($slug: String!) {
    solutions(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          slug
          productData {
            name
            security {
              data {
                id
                attributes {
                  name
                  __typename
                }
                __typename
              }
              __typename
            }
            dataHostingLocations {
              data {
                id
                attributes {
                  name
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
        }
        __typename
      }
    }
  }
`

/**
 * __useGetProductSolutionSecurityDataQuery__
 *
 * To run a query within a React component, call `useGetProductSolutionSecurityDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductSolutionSecurityDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductSolutionSecurityDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetProductSolutionSecurityDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProductSolutionSecurityDataQuery,
    IGetProductSolutionSecurityDataQueryVariables
  > &
    ({ variables: IGetProductSolutionSecurityDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetProductSolutionSecurityDataQuery, IGetProductSolutionSecurityDataQueryVariables>(
    GetProductSolutionSecurityDataDocument,
    options,
  )
}
export function useGetProductSolutionSecurityDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProductSolutionSecurityDataQuery,
    IGetProductSolutionSecurityDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetProductSolutionSecurityDataQuery, IGetProductSolutionSecurityDataQueryVariables>(
    GetProductSolutionSecurityDataDocument,
    options,
  )
}
export function useGetProductSolutionSecurityDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetProductSolutionSecurityDataQuery,
    IGetProductSolutionSecurityDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetProductSolutionSecurityDataQuery, IGetProductSolutionSecurityDataQueryVariables>(
    GetProductSolutionSecurityDataDocument,
    options,
  )
}
export type GetProductSolutionSecurityDataQueryHookResult = ReturnType<typeof useGetProductSolutionSecurityDataQuery>
export type GetProductSolutionSecurityDataLazyQueryHookResult = ReturnType<
  typeof useGetProductSolutionSecurityDataLazyQuery
>
export type GetProductSolutionSecurityDataSuspenseQueryHookResult = ReturnType<
  typeof useGetProductSolutionSecurityDataSuspenseQuery
>
export type GetProductSolutionSecurityDataQueryResult = Apollo.QueryResult<
  IGetProductSolutionSecurityDataQuery,
  IGetProductSolutionSecurityDataQueryVariables
>
export const SolutionRequestByIdDocument = gql`
  query solutionRequestByID($id: ID!) {
    solutionRequest(id: $id) {
      data {
        ...solutionRequestData
      }
    }
  }
  ${SolutionRequestDataFragmentDoc}
`

/**
 * __useSolutionRequestByIdQuery__
 *
 * To run a query within a React component, call `useSolutionRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolutionRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolutionRequestByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSolutionRequestByIdQuery(
  baseOptions: Apollo.QueryHookOptions<ISolutionRequestByIdQuery, ISolutionRequestByIdQueryVariables> &
    ({ variables: ISolutionRequestByIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISolutionRequestByIdQuery, ISolutionRequestByIdQueryVariables>(
    SolutionRequestByIdDocument,
    options,
  )
}
export function useSolutionRequestByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISolutionRequestByIdQuery, ISolutionRequestByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISolutionRequestByIdQuery, ISolutionRequestByIdQueryVariables>(
    SolutionRequestByIdDocument,
    options,
  )
}
export function useSolutionRequestByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISolutionRequestByIdQuery, ISolutionRequestByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISolutionRequestByIdQuery, ISolutionRequestByIdQueryVariables>(
    SolutionRequestByIdDocument,
    options,
  )
}
export type SolutionRequestByIdQueryHookResult = ReturnType<typeof useSolutionRequestByIdQuery>
export type SolutionRequestByIdLazyQueryHookResult = ReturnType<typeof useSolutionRequestByIdLazyQuery>
export type SolutionRequestByIdSuspenseQueryHookResult = ReturnType<typeof useSolutionRequestByIdSuspenseQuery>
export type SolutionRequestByIdQueryResult = Apollo.QueryResult<
  ISolutionRequestByIdQuery,
  ISolutionRequestByIdQueryVariables
>
export const GetAboutDocument = gql`
  query getAbout {
    about {
      data {
        id
        attributes {
          createdAt
          updatedAt
          title
          description
          who
          what
          email
          phone
          ourTeam {
            avatar {
              data {
                attributes {
                  url
                }
              }
            }
            name
            role
            bio
            externalLink
          }
          publishedAt
        }
      }
    }
  }
`

/**
 * __useGetAboutQuery__
 *
 * To run a query within a React component, call `useGetAboutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAboutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAboutQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAboutQuery(baseOptions?: Apollo.QueryHookOptions<IGetAboutQuery, IGetAboutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetAboutQuery, IGetAboutQueryVariables>(GetAboutDocument, options)
}
export function useGetAboutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetAboutQuery, IGetAboutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetAboutQuery, IGetAboutQueryVariables>(GetAboutDocument, options)
}
export function useGetAboutSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAboutQuery, IGetAboutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetAboutQuery, IGetAboutQueryVariables>(GetAboutDocument, options)
}
export type GetAboutQueryHookResult = ReturnType<typeof useGetAboutQuery>
export type GetAboutLazyQueryHookResult = ReturnType<typeof useGetAboutLazyQuery>
export type GetAboutSuspenseQueryHookResult = ReturnType<typeof useGetAboutSuspenseQuery>
export type GetAboutQueryResult = Apollo.QueryResult<IGetAboutQuery, IGetAboutQueryVariables>
export const GetAllCompaniesDocument = gql`
  query getAllCompanies {
    companies(sort: "name:asc") {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
  }
`

/**
 * __useGetAllCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>(GetAllCompaniesDocument, options)
}
export function useGetAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>(GetAllCompaniesDocument, options)
}
export function useGetAllCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>(
    GetAllCompaniesDocument,
    options,
  )
}
export type GetAllCompaniesQueryHookResult = ReturnType<typeof useGetAllCompaniesQuery>
export type GetAllCompaniesLazyQueryHookResult = ReturnType<typeof useGetAllCompaniesLazyQuery>
export type GetAllCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetAllCompaniesSuspenseQuery>
export type GetAllCompaniesQueryResult = Apollo.QueryResult<IGetAllCompaniesQuery, IGetAllCompaniesQueryVariables>
export const GetAudiencesDocument = gql`
  query getAudiences {
    demographics(sort: "updatedAt") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetAudiencesQuery__
 *
 * To run a query within a React component, call `useGetAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudiencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAudiencesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetAudiencesQuery, IGetAudiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetAudiencesQuery, IGetAudiencesQueryVariables>(GetAudiencesDocument, options)
}
export function useGetAudiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetAudiencesQuery, IGetAudiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetAudiencesQuery, IGetAudiencesQueryVariables>(GetAudiencesDocument, options)
}
export function useGetAudiencesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAudiencesQuery, IGetAudiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetAudiencesQuery, IGetAudiencesQueryVariables>(GetAudiencesDocument, options)
}
export type GetAudiencesQueryHookResult = ReturnType<typeof useGetAudiencesQuery>
export type GetAudiencesLazyQueryHookResult = ReturnType<typeof useGetAudiencesLazyQuery>
export type GetAudiencesSuspenseQueryHookResult = ReturnType<typeof useGetAudiencesSuspenseQuery>
export type GetAudiencesQueryResult = Apollo.QueryResult<IGetAudiencesQuery, IGetAudiencesQueryVariables>
export const GetCollectionsDocument = gql`
  query getCollections {
    collections(sort: "name:asc") {
      data {
        id
        attributes {
          name
          description
          featuredOnHomepage
          slug
          publishedAt
          contents(sort: "title:asc") {
            data {
              id
              attributes {
                title
                slug
                snippet
                isMaintained
                isPremium
              }
            }
          }
          solutions(sort: "displayName:asc") {
            data {
              id
              attributes {
                displayName
                type
                website
                isEnhanced
                isPremium
                slug
                shortDescription
                longDescription
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCollectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetCollectionsQuery, IGetCollectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetCollectionsQuery, IGetCollectionsQueryVariables>(GetCollectionsDocument, options)
}
export function useGetCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetCollectionsQuery, IGetCollectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetCollectionsQuery, IGetCollectionsQueryVariables>(GetCollectionsDocument, options)
}
export function useGetCollectionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCollectionsQuery, IGetCollectionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetCollectionsQuery, IGetCollectionsQueryVariables>(GetCollectionsDocument, options)
}
export type GetCollectionsQueryHookResult = ReturnType<typeof useGetCollectionsQuery>
export type GetCollectionsLazyQueryHookResult = ReturnType<typeof useGetCollectionsLazyQuery>
export type GetCollectionsSuspenseQueryHookResult = ReturnType<typeof useGetCollectionsSuspenseQuery>
export type GetCollectionsQueryResult = Apollo.QueryResult<IGetCollectionsQuery, IGetCollectionsQueryVariables>
export const GetCollectionsBySlugDocument = gql`
  query getCollectionsBySlug($slug: String!) {
    collections(filters: { slug: { eq: $slug } }) {
      data {
        id
        attributes {
          name
          description
          featuredOnHomepage
          slug
          publishedAt
          contents(sort: "title:asc") {
            data {
              id
              attributes {
                title
                slug
                snippet
                isMaintained
                isPremium
                publishedTime
                defaultImage {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          solutions(sort: "displayName:asc") {
            data {
              id
              attributes {
                displayName
                type
                website
                isEnhanced
                isPremium
                slug
                shortDescription
                longDescription
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetCollectionsBySlugQuery__
 *
 * To run a query within a React component, call `useGetCollectionsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCollectionsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IGetCollectionsBySlugQuery, IGetCollectionsBySlugQueryVariables> &
    ({ variables: IGetCollectionsBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetCollectionsBySlugQuery, IGetCollectionsBySlugQueryVariables>(
    GetCollectionsBySlugDocument,
    options,
  )
}
export function useGetCollectionsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetCollectionsBySlugQuery, IGetCollectionsBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetCollectionsBySlugQuery, IGetCollectionsBySlugQueryVariables>(
    GetCollectionsBySlugDocument,
    options,
  )
}
export function useGetCollectionsBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCollectionsBySlugQuery, IGetCollectionsBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetCollectionsBySlugQuery, IGetCollectionsBySlugQueryVariables>(
    GetCollectionsBySlugDocument,
    options,
  )
}
export type GetCollectionsBySlugQueryHookResult = ReturnType<typeof useGetCollectionsBySlugQuery>
export type GetCollectionsBySlugLazyQueryHookResult = ReturnType<typeof useGetCollectionsBySlugLazyQuery>
export type GetCollectionsBySlugSuspenseQueryHookResult = ReturnType<typeof useGetCollectionsBySlugSuspenseQuery>
export type GetCollectionsBySlugQueryResult = Apollo.QueryResult<
  IGetCollectionsBySlugQuery,
  IGetCollectionsBySlugQueryVariables
>
export const GetCompanyBySlugDocument = gql`
  query getCompanyBySlug($slug: String!, $includeIlta: Boolean!) {
    companies(filters: { slug: { eq: $slug } }) {
      data {
        ...companyData
      }
    }
  }
  ${CompanyDataFragmentDoc}
`

/**
 * __useGetCompanyBySlugQuery__
 *
 * To run a query within a React component, call `useGetCompanyBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      includeIlta: // value for 'includeIlta'
 *   },
 * });
 */
export function useGetCompanyBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables> &
    ({ variables: IGetCompanyBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables>(GetCompanyBySlugDocument, options)
}
export function useGetCompanyBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables>(GetCompanyBySlugDocument, options)
}
export function useGetCompanyBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables>(
    GetCompanyBySlugDocument,
    options,
  )
}
export type GetCompanyBySlugQueryHookResult = ReturnType<typeof useGetCompanyBySlugQuery>
export type GetCompanyBySlugLazyQueryHookResult = ReturnType<typeof useGetCompanyBySlugLazyQuery>
export type GetCompanyBySlugSuspenseQueryHookResult = ReturnType<typeof useGetCompanyBySlugSuspenseQuery>
export type GetCompanyBySlugQueryResult = Apollo.QueryResult<IGetCompanyBySlugQuery, IGetCompanyBySlugQueryVariables>
export const GetExistingCustomersPaginationDocument = gql`
  query getExistingCustomersPagination($page: Int!, $pageSize: Int!, $search: String) {
    existingCustomers(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "name"
      filters: { name: { containsi: $search } }
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetExistingCustomersPaginationQuery__
 *
 * To run a query within a React component, call `useGetExistingCustomersPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingCustomersPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingCustomersPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetExistingCustomersPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetExistingCustomersPaginationQuery,
    IGetExistingCustomersPaginationQueryVariables
  > &
    ({ variables: IGetExistingCustomersPaginationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetExistingCustomersPaginationQuery, IGetExistingCustomersPaginationQueryVariables>(
    GetExistingCustomersPaginationDocument,
    options,
  )
}
export function useGetExistingCustomersPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetExistingCustomersPaginationQuery,
    IGetExistingCustomersPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetExistingCustomersPaginationQuery, IGetExistingCustomersPaginationQueryVariables>(
    GetExistingCustomersPaginationDocument,
    options,
  )
}
export function useGetExistingCustomersPaginationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetExistingCustomersPaginationQuery,
    IGetExistingCustomersPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetExistingCustomersPaginationQuery, IGetExistingCustomersPaginationQueryVariables>(
    GetExistingCustomersPaginationDocument,
    options,
  )
}
export type GetExistingCustomersPaginationQueryHookResult = ReturnType<typeof useGetExistingCustomersPaginationQuery>
export type GetExistingCustomersPaginationLazyQueryHookResult = ReturnType<
  typeof useGetExistingCustomersPaginationLazyQuery
>
export type GetExistingCustomersPaginationSuspenseQueryHookResult = ReturnType<
  typeof useGetExistingCustomersPaginationSuspenseQuery
>
export type GetExistingCustomersPaginationQueryResult = Apollo.QueryResult<
  IGetExistingCustomersPaginationQuery,
  IGetExistingCustomersPaginationQueryVariables
>
export const GetDurationsDocument = gql`
  query getDurations {
    durations {
      data {
        id
        attributes {
          name
          days
        }
      }
    }
  }
`

/**
 * __useGetDurationsQuery__
 *
 * To run a query within a React component, call `useGetDurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDurationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetDurationsQuery, IGetDurationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetDurationsQuery, IGetDurationsQueryVariables>(GetDurationsDocument, options)
}
export function useGetDurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetDurationsQuery, IGetDurationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetDurationsQuery, IGetDurationsQueryVariables>(GetDurationsDocument, options)
}
export function useGetDurationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetDurationsQuery, IGetDurationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetDurationsQuery, IGetDurationsQueryVariables>(GetDurationsDocument, options)
}
export type GetDurationsQueryHookResult = ReturnType<typeof useGetDurationsQuery>
export type GetDurationsLazyQueryHookResult = ReturnType<typeof useGetDurationsLazyQuery>
export type GetDurationsSuspenseQueryHookResult = ReturnType<typeof useGetDurationsSuspenseQuery>
export type GetDurationsQueryResult = Apollo.QueryResult<IGetDurationsQuery, IGetDurationsQueryVariables>
export const GetEnhancedRequestContentDocument = gql`
  query getEnhancedRequestContent {
    enhancedRequestContent {
      data {
        attributes {
          createdAt
          updatedAt
          title
          description
          moreVisibility
          callToAction
          screenshots
          competitor
          publishedAt
        }
      }
    }
  }
`

/**
 * __useGetEnhancedRequestContentQuery__
 *
 * To run a query within a React component, call `useGetEnhancedRequestContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnhancedRequestContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnhancedRequestContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnhancedRequestContentQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetEnhancedRequestContentQuery, IGetEnhancedRequestContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetEnhancedRequestContentQuery, IGetEnhancedRequestContentQueryVariables>(
    GetEnhancedRequestContentDocument,
    options,
  )
}
export function useGetEnhancedRequestContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetEnhancedRequestContentQuery, IGetEnhancedRequestContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetEnhancedRequestContentQuery, IGetEnhancedRequestContentQueryVariables>(
    GetEnhancedRequestContentDocument,
    options,
  )
}
export function useGetEnhancedRequestContentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetEnhancedRequestContentQuery,
    IGetEnhancedRequestContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetEnhancedRequestContentQuery, IGetEnhancedRequestContentQueryVariables>(
    GetEnhancedRequestContentDocument,
    options,
  )
}
export type GetEnhancedRequestContentQueryHookResult = ReturnType<typeof useGetEnhancedRequestContentQuery>
export type GetEnhancedRequestContentLazyQueryHookResult = ReturnType<typeof useGetEnhancedRequestContentLazyQuery>
export type GetEnhancedRequestContentSuspenseQueryHookResult = ReturnType<
  typeof useGetEnhancedRequestContentSuspenseQuery
>
export type GetEnhancedRequestContentQueryResult = Apollo.QueryResult<
  IGetEnhancedRequestContentQuery,
  IGetEnhancedRequestContentQueryVariables
>
export const EventsDocument = gql`
  query events {
    events {
      data {
        id
        attributes {
          title
          updatedAt
          date
          description
          logo {
            data {
              attributes {
                url
              }
            }
          }
          country
          city
          durationId {
            data {
              id
              attributes {
                name
                days
              }
            }
          }
          formatId {
            data {
              id
              attributes {
                name
              }
            }
          }
          featured
          slug
          features {
            data {
              id
              attributes {
                name
              }
            }
          }
          audiences {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<IEventsQuery, IEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IEventsQuery, IEventsQueryVariables>(EventsDocument, options)
}
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IEventsQuery, IEventsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IEventsQuery, IEventsQueryVariables>(EventsDocument, options)
}
export function useEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IEventsQuery, IEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IEventsQuery, IEventsQueryVariables>(EventsDocument, options)
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>
export type EventsSuspenseQueryHookResult = ReturnType<typeof useEventsSuspenseQuery>
export type EventsQueryResult = Apollo.QueryResult<IEventsQuery, IEventsQueryVariables>
export const GetEventsByOffsetDocument = gql`
  query getEventsByOffset($start: Int, $limit: Int) {
    events(pagination: { start: $start, limit: $limit }) {
      data {
        id
        attributes {
          title
          date
          updatedAt
          description
          logo {
            data {
              attributes {
                url
              }
            }
          }
          country
          city
          durationId {
            data {
              id
              attributes {
                name
              }
            }
          }
          formatId {
            data {
              id
              attributes {
                name
              }
            }
          }
          featured
          slug
          features {
            data {
              id
              attributes {
                name
              }
            }
          }
          audiences {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetEventsByOffsetQuery__
 *
 * To run a query within a React component, call `useGetEventsByOffsetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsByOffsetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsByOffsetQuery({
 *   variables: {
 *      start: // value for 'start'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEventsByOffsetQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>(GetEventsByOffsetDocument, options)
}
export function useGetEventsByOffsetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>(
    GetEventsByOffsetDocument,
    options,
  )
}
export function useGetEventsByOffsetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>(
    GetEventsByOffsetDocument,
    options,
  )
}
export type GetEventsByOffsetQueryHookResult = ReturnType<typeof useGetEventsByOffsetQuery>
export type GetEventsByOffsetLazyQueryHookResult = ReturnType<typeof useGetEventsByOffsetLazyQuery>
export type GetEventsByOffsetSuspenseQueryHookResult = ReturnType<typeof useGetEventsByOffsetSuspenseQuery>
export type GetEventsByOffsetQueryResult = Apollo.QueryResult<IGetEventsByOffsetQuery, IGetEventsByOffsetQueryVariables>
export const GetEventsBySlugDocument = gql`
  query getEventsBySlug($slug: String!) {
    events(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          slug
          createdAt
          updatedAt
          date
          title
          website
          description
          organizer
          logo {
            data {
              attributes {
                url
              }
            }
          }
          notes
          country
          city
          featured
          slug
          formatId {
            data {
              attributes {
                name
              }
            }
          }
          audiences {
            data {
              attributes {
                name
              }
            }
          }
          durationId {
            data {
              attributes {
                name
              }
            }
          }
          recurrenceId {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetEventsBySlugQuery__
 *
 * To run a query within a React component, call `useGetEventsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetEventsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables> &
    ({ variables: IGetEventsBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables>(GetEventsBySlugDocument, options)
}
export function useGetEventsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables>(GetEventsBySlugDocument, options)
}
export function useGetEventsBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables>(
    GetEventsBySlugDocument,
    options,
  )
}
export type GetEventsBySlugQueryHookResult = ReturnType<typeof useGetEventsBySlugQuery>
export type GetEventsBySlugLazyQueryHookResult = ReturnType<typeof useGetEventsBySlugLazyQuery>
export type GetEventsBySlugSuspenseQueryHookResult = ReturnType<typeof useGetEventsBySlugSuspenseQuery>
export type GetEventsBySlugQueryResult = Apollo.QueryResult<IGetEventsBySlugQuery, IGetEventsBySlugQueryVariables>
export const GetExistingCustomersDocument = gql`
  query getExistingCustomers {
    existingCustomers(pagination: { limit: -1 }, sort: "name") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetExistingCustomersQuery__
 *
 * To run a query within a React component, call `useGetExistingCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExistingCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExistingCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExistingCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetExistingCustomersQuery, IGetExistingCustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetExistingCustomersQuery, IGetExistingCustomersQueryVariables>(
    GetExistingCustomersDocument,
    options,
  )
}
export function useGetExistingCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetExistingCustomersQuery, IGetExistingCustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetExistingCustomersQuery, IGetExistingCustomersQueryVariables>(
    GetExistingCustomersDocument,
    options,
  )
}
export function useGetExistingCustomersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetExistingCustomersQuery, IGetExistingCustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetExistingCustomersQuery, IGetExistingCustomersQueryVariables>(
    GetExistingCustomersDocument,
    options,
  )
}
export type GetExistingCustomersQueryHookResult = ReturnType<typeof useGetExistingCustomersQuery>
export type GetExistingCustomersLazyQueryHookResult = ReturnType<typeof useGetExistingCustomersLazyQuery>
export type GetExistingCustomersSuspenseQueryHookResult = ReturnType<typeof useGetExistingCustomersSuspenseQuery>
export type GetExistingCustomersQueryResult = Apollo.QueryResult<
  IGetExistingCustomersQuery,
  IGetExistingCustomersQueryVariables
>
export const GetAttributesDocument = gql`
  query getAttributes {
    attributes(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetAttributesQuery__
 *
 * To run a query within a React component, call `useGetAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttributesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetAttributesQuery, IGetAttributesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetAttributesQuery, IGetAttributesQueryVariables>(GetAttributesDocument, options)
}
export function useGetAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetAttributesQuery, IGetAttributesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetAttributesQuery, IGetAttributesQueryVariables>(GetAttributesDocument, options)
}
export function useGetAttributesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetAttributesQuery, IGetAttributesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetAttributesQuery, IGetAttributesQueryVariables>(GetAttributesDocument, options)
}
export type GetAttributesQueryHookResult = ReturnType<typeof useGetAttributesQuery>
export type GetAttributesLazyQueryHookResult = ReturnType<typeof useGetAttributesLazyQuery>
export type GetAttributesSuspenseQueryHookResult = ReturnType<typeof useGetAttributesSuspenseQuery>
export type GetAttributesQueryResult = Apollo.QueryResult<IGetAttributesQuery, IGetAttributesQueryVariables>
export const GetFollowingTopicsDocument = gql`
  query getFollowingTopics {
    followingTopics {
      id
      attributes {
        slug
        name
        description
        isPremium
        iconName
        featuredOnHome
        createdAt
        updatedAt
        subTopics {
          data {
            id
            attributes {
              name
              slug
              isPremium
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetFollowingTopicsQuery__
 *
 * To run a query within a React component, call `useGetFollowingTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowingTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowingTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFollowingTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetFollowingTopicsQuery, IGetFollowingTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetFollowingTopicsQuery, IGetFollowingTopicsQueryVariables>(
    GetFollowingTopicsDocument,
    options,
  )
}
export function useGetFollowingTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetFollowingTopicsQuery, IGetFollowingTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetFollowingTopicsQuery, IGetFollowingTopicsQueryVariables>(
    GetFollowingTopicsDocument,
    options,
  )
}
export function useGetFollowingTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFollowingTopicsQuery, IGetFollowingTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetFollowingTopicsQuery, IGetFollowingTopicsQueryVariables>(
    GetFollowingTopicsDocument,
    options,
  )
}
export type GetFollowingTopicsQueryHookResult = ReturnType<typeof useGetFollowingTopicsQuery>
export type GetFollowingTopicsLazyQueryHookResult = ReturnType<typeof useGetFollowingTopicsLazyQuery>
export type GetFollowingTopicsSuspenseQueryHookResult = ReturnType<typeof useGetFollowingTopicsSuspenseQuery>
export type GetFollowingTopicsQueryResult = Apollo.QueryResult<
  IGetFollowingTopicsQuery,
  IGetFollowingTopicsQueryVariables
>
export const GetFormatsDocument = gql`
  query getFormats {
    eventFormats {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetFormatsQuery__
 *
 * To run a query within a React component, call `useGetFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormatsQuery(baseOptions?: Apollo.QueryHookOptions<IGetFormatsQuery, IGetFormatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetFormatsQuery, IGetFormatsQueryVariables>(GetFormatsDocument, options)
}
export function useGetFormatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetFormatsQuery, IGetFormatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetFormatsQuery, IGetFormatsQueryVariables>(GetFormatsDocument, options)
}
export function useGetFormatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFormatsQuery, IGetFormatsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetFormatsQuery, IGetFormatsQueryVariables>(GetFormatsDocument, options)
}
export type GetFormatsQueryHookResult = ReturnType<typeof useGetFormatsQuery>
export type GetFormatsLazyQueryHookResult = ReturnType<typeof useGetFormatsLazyQuery>
export type GetFormatsSuspenseQueryHookResult = ReturnType<typeof useGetFormatsSuspenseQuery>
export type GetFormatsQueryResult = Apollo.QueryResult<IGetFormatsQuery, IGetFormatsQueryVariables>
export const GetIltaSponsorshipsDocument = gql`
  query getIltaSponsorships {
    iltaSponsorships(sort: "level:asc") {
      data {
        ...iltaSponsorshipItem
      }
    }
  }
  ${IltaSponsorshipItemFragmentDoc}
`

/**
 * __useGetIltaSponsorshipsQuery__
 *
 * To run a query within a React component, call `useGetIltaSponsorshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIltaSponsorshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIltaSponsorshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIltaSponsorshipsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetIltaSponsorshipsQuery, IGetIltaSponsorshipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetIltaSponsorshipsQuery, IGetIltaSponsorshipsQueryVariables>(
    GetIltaSponsorshipsDocument,
    options,
  )
}
export function useGetIltaSponsorshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetIltaSponsorshipsQuery, IGetIltaSponsorshipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetIltaSponsorshipsQuery, IGetIltaSponsorshipsQueryVariables>(
    GetIltaSponsorshipsDocument,
    options,
  )
}
export function useGetIltaSponsorshipsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetIltaSponsorshipsQuery, IGetIltaSponsorshipsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetIltaSponsorshipsQuery, IGetIltaSponsorshipsQueryVariables>(
    GetIltaSponsorshipsDocument,
    options,
  )
}
export type GetIltaSponsorshipsQueryHookResult = ReturnType<typeof useGetIltaSponsorshipsQuery>
export type GetIltaSponsorshipsLazyQueryHookResult = ReturnType<typeof useGetIltaSponsorshipsLazyQuery>
export type GetIltaSponsorshipsSuspenseQueryHookResult = ReturnType<typeof useGetIltaSponsorshipsSuspenseQuery>
export type GetIltaSponsorshipsQueryResult = Apollo.QueryResult<
  IGetIltaSponsorshipsQuery,
  IGetIltaSponsorshipsQueryVariables
>
export const GetInstallationsDocument = gql`
  query getInstallations {
    installations {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetInstallationsQuery__
 *
 * To run a query within a React component, call `useGetInstallationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstallationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstallationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInstallationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetInstallationsQuery, IGetInstallationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetInstallationsQuery, IGetInstallationsQueryVariables>(GetInstallationsDocument, options)
}
export function useGetInstallationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetInstallationsQuery, IGetInstallationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetInstallationsQuery, IGetInstallationsQueryVariables>(GetInstallationsDocument, options)
}
export function useGetInstallationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetInstallationsQuery, IGetInstallationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetInstallationsQuery, IGetInstallationsQueryVariables>(
    GetInstallationsDocument,
    options,
  )
}
export type GetInstallationsQueryHookResult = ReturnType<typeof useGetInstallationsQuery>
export type GetInstallationsLazyQueryHookResult = ReturnType<typeof useGetInstallationsLazyQuery>
export type GetInstallationsSuspenseQueryHookResult = ReturnType<typeof useGetInstallationsSuspenseQuery>
export type GetInstallationsQueryResult = Apollo.QueryResult<IGetInstallationsQuery, IGetInstallationsQueryVariables>
export const GetIntegrationsDocument = gql`
  query getIntegrations {
    integrations(sort: "name") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>(GetIntegrationsDocument, options)
}
export function useGetIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>(GetIntegrationsDocument, options)
}
export function useGetIntegrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>(
    GetIntegrationsDocument,
    options,
  )
}
export type GetIntegrationsQueryHookResult = ReturnType<typeof useGetIntegrationsQuery>
export type GetIntegrationsLazyQueryHookResult = ReturnType<typeof useGetIntegrationsLazyQuery>
export type GetIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationsSuspenseQuery>
export type GetIntegrationsQueryResult = Apollo.QueryResult<IGetIntegrationsQuery, IGetIntegrationsQueryVariables>
export const GetIntegrationsPaginationDocument = gql`
  query getIntegrationsPagination($page: Int!, $pageSize: Int!, $search: String) {
    integrations(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "name"
      filters: { name: { containsi: $search } }
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetIntegrationsPaginationQuery__
 *
 * To run a query within a React component, call `useGetIntegrationsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationsPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetIntegrationsPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<IGetIntegrationsPaginationQuery, IGetIntegrationsPaginationQueryVariables> &
    ({ variables: IGetIntegrationsPaginationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetIntegrationsPaginationQuery, IGetIntegrationsPaginationQueryVariables>(
    GetIntegrationsPaginationDocument,
    options,
  )
}
export function useGetIntegrationsPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetIntegrationsPaginationQuery, IGetIntegrationsPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetIntegrationsPaginationQuery, IGetIntegrationsPaginationQueryVariables>(
    GetIntegrationsPaginationDocument,
    options,
  )
}
export function useGetIntegrationsPaginationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetIntegrationsPaginationQuery,
    IGetIntegrationsPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetIntegrationsPaginationQuery, IGetIntegrationsPaginationQueryVariables>(
    GetIntegrationsPaginationDocument,
    options,
  )
}
export type GetIntegrationsPaginationQueryHookResult = ReturnType<typeof useGetIntegrationsPaginationQuery>
export type GetIntegrationsPaginationLazyQueryHookResult = ReturnType<typeof useGetIntegrationsPaginationLazyQuery>
export type GetIntegrationsPaginationSuspenseQueryHookResult = ReturnType<
  typeof useGetIntegrationsPaginationSuspenseQuery
>
export type GetIntegrationsPaginationQueryResult = Apollo.QueryResult<
  IGetIntegrationsPaginationQuery,
  IGetIntegrationsPaginationQueryVariables
>
export const GetInvitedUsersDocument = gql`
  query getInvitedUsers($query: JSON) {
    invitedUsers(query: $query) {
      total
      invitations {
        ...resourceInvitationEntity
      }
    }
  }
  ${ResourceInvitationEntityFragmentDoc}
`

/**
 * __useGetInvitedUsersQuery__
 *
 * To run a query within a React component, call `useGetInvitedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetInvitedUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>(GetInvitedUsersDocument, options)
}
export function useGetInvitedUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>(GetInvitedUsersDocument, options)
}
export function useGetInvitedUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>(
    GetInvitedUsersDocument,
    options,
  )
}
export type GetInvitedUsersQueryHookResult = ReturnType<typeof useGetInvitedUsersQuery>
export type GetInvitedUsersLazyQueryHookResult = ReturnType<typeof useGetInvitedUsersLazyQuery>
export type GetInvitedUsersSuspenseQueryHookResult = ReturnType<typeof useGetInvitedUsersSuspenseQuery>
export type GetInvitedUsersQueryResult = Apollo.QueryResult<IGetInvitedUsersQuery, IGetInvitedUsersQueryVariables>
export const GetLanguagesDocument = gql`
  query getLanguages {
    platformLanguages(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetLanguagesQuery__
 *
 * To run a query within a React component, call `useGetLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetLanguagesQuery, IGetLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetLanguagesQuery, IGetLanguagesQueryVariables>(GetLanguagesDocument, options)
}
export function useGetLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetLanguagesQuery, IGetLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetLanguagesQuery, IGetLanguagesQueryVariables>(GetLanguagesDocument, options)
}
export function useGetLanguagesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetLanguagesQuery, IGetLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetLanguagesQuery, IGetLanguagesQueryVariables>(GetLanguagesDocument, options)
}
export type GetLanguagesQueryHookResult = ReturnType<typeof useGetLanguagesQuery>
export type GetLanguagesLazyQueryHookResult = ReturnType<typeof useGetLanguagesLazyQuery>
export type GetLanguagesSuspenseQueryHookResult = ReturnType<typeof useGetLanguagesSuspenseQuery>
export type GetLanguagesQueryResult = Apollo.QueryResult<IGetLanguagesQuery, IGetLanguagesQueryVariables>
export const GetLanguagesPaginationDocument = gql`
  query getLanguagesPagination($page: Int!, $pageSize: Int!, $search: String) {
    platformLanguages(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "name:asc"
      filters: { name: { containsi: $search } }
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetLanguagesPaginationQuery__
 *
 * To run a query within a React component, call `useGetLanguagesPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLanguagesPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLanguagesPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetLanguagesPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<IGetLanguagesPaginationQuery, IGetLanguagesPaginationQueryVariables> &
    ({ variables: IGetLanguagesPaginationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetLanguagesPaginationQuery, IGetLanguagesPaginationQueryVariables>(
    GetLanguagesPaginationDocument,
    options,
  )
}
export function useGetLanguagesPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetLanguagesPaginationQuery, IGetLanguagesPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetLanguagesPaginationQuery, IGetLanguagesPaginationQueryVariables>(
    GetLanguagesPaginationDocument,
    options,
  )
}
export function useGetLanguagesPaginationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetLanguagesPaginationQuery, IGetLanguagesPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetLanguagesPaginationQuery, IGetLanguagesPaginationQueryVariables>(
    GetLanguagesPaginationDocument,
    options,
  )
}
export type GetLanguagesPaginationQueryHookResult = ReturnType<typeof useGetLanguagesPaginationQuery>
export type GetLanguagesPaginationLazyQueryHookResult = ReturnType<typeof useGetLanguagesPaginationLazyQuery>
export type GetLanguagesPaginationSuspenseQueryHookResult = ReturnType<typeof useGetLanguagesPaginationSuspenseQuery>
export type GetLanguagesPaginationQueryResult = Apollo.QueryResult<
  IGetLanguagesPaginationQuery,
  IGetLanguagesPaginationQueryVariables
>
export const GetLocationsDocument = gql`
  query getLocations {
    location
  }
`

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetLocationsQuery, IGetLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetLocationsQuery, IGetLocationsQueryVariables>(GetLocationsDocument, options)
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetLocationsQuery, IGetLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetLocationsQuery, IGetLocationsQueryVariables>(GetLocationsDocument, options)
}
export function useGetLocationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetLocationsQuery, IGetLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetLocationsQuery, IGetLocationsQueryVariables>(GetLocationsDocument, options)
}
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>
export type GetLocationsSuspenseQueryHookResult = ReturnType<typeof useGetLocationsSuspenseQuery>
export type GetLocationsQueryResult = Apollo.QueryResult<IGetLocationsQuery, IGetLocationsQueryVariables>
export const GetMyResourcesProductTypesDocument = gql`
  query getMyResourcesProductTypes {
    myResourcesProductTypes {
      topics {
        id
        name
        isPremium
        subTopics {
          id
          name
          isPremium
        }
      }
    }
  }
`

/**
 * __useGetMyResourcesProductTypesQuery__
 *
 * To run a query within a React component, call `useGetMyResourcesProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyResourcesProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyResourcesProductTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyResourcesProductTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetMyResourcesProductTypesQuery, IGetMyResourcesProductTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetMyResourcesProductTypesQuery, IGetMyResourcesProductTypesQueryVariables>(
    GetMyResourcesProductTypesDocument,
    options,
  )
}
export function useGetMyResourcesProductTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetMyResourcesProductTypesQuery,
    IGetMyResourcesProductTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetMyResourcesProductTypesQuery, IGetMyResourcesProductTypesQueryVariables>(
    GetMyResourcesProductTypesDocument,
    options,
  )
}
export function useGetMyResourcesProductTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetMyResourcesProductTypesQuery,
    IGetMyResourcesProductTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetMyResourcesProductTypesQuery, IGetMyResourcesProductTypesQueryVariables>(
    GetMyResourcesProductTypesDocument,
    options,
  )
}
export type GetMyResourcesProductTypesQueryHookResult = ReturnType<typeof useGetMyResourcesProductTypesQuery>
export type GetMyResourcesProductTypesLazyQueryHookResult = ReturnType<typeof useGetMyResourcesProductTypesLazyQuery>
export type GetMyResourcesProductTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetMyResourcesProductTypesSuspenseQuery
>
export type GetMyResourcesProductTypesQueryResult = Apollo.QueryResult<
  IGetMyResourcesProductTypesQuery,
  IGetMyResourcesProductTypesQueryVariables
>
export const GetNewsletterCategoriesDocument = gql`
  query getNewsletterCategories {
    newsletterCategories {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetNewsletterCategoriesQuery__
 *
 * To run a query within a React component, call `useGetNewsletterCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsletterCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsletterCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewsletterCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetNewsletterCategoriesQuery, IGetNewsletterCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetNewsletterCategoriesQuery, IGetNewsletterCategoriesQueryVariables>(
    GetNewsletterCategoriesDocument,
    options,
  )
}
export function useGetNewsletterCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetNewsletterCategoriesQuery, IGetNewsletterCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetNewsletterCategoriesQuery, IGetNewsletterCategoriesQueryVariables>(
    GetNewsletterCategoriesDocument,
    options,
  )
}
export function useGetNewsletterCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetNewsletterCategoriesQuery, IGetNewsletterCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetNewsletterCategoriesQuery, IGetNewsletterCategoriesQueryVariables>(
    GetNewsletterCategoriesDocument,
    options,
  )
}
export type GetNewsletterCategoriesQueryHookResult = ReturnType<typeof useGetNewsletterCategoriesQuery>
export type GetNewsletterCategoriesLazyQueryHookResult = ReturnType<typeof useGetNewsletterCategoriesLazyQuery>
export type GetNewsletterCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetNewsletterCategoriesSuspenseQuery>
export type GetNewsletterCategoriesQueryResult = Apollo.QueryResult<
  IGetNewsletterCategoriesQuery,
  IGetNewsletterCategoriesQueryVariables
>
export const GetOfficesDocument = gql`
  query getOffices {
    offices(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetOfficesQuery__
 *
 * To run a query within a React component, call `useGetOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOfficesQuery(baseOptions?: Apollo.QueryHookOptions<IGetOfficesQuery, IGetOfficesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetOfficesQuery, IGetOfficesQueryVariables>(GetOfficesDocument, options)
}
export function useGetOfficesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetOfficesQuery, IGetOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetOfficesQuery, IGetOfficesQueryVariables>(GetOfficesDocument, options)
}
export function useGetOfficesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetOfficesQuery, IGetOfficesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetOfficesQuery, IGetOfficesQueryVariables>(GetOfficesDocument, options)
}
export type GetOfficesQueryHookResult = ReturnType<typeof useGetOfficesQuery>
export type GetOfficesLazyQueryHookResult = ReturnType<typeof useGetOfficesLazyQuery>
export type GetOfficesSuspenseQueryHookResult = ReturnType<typeof useGetOfficesSuspenseQuery>
export type GetOfficesQueryResult = Apollo.QueryResult<IGetOfficesQuery, IGetOfficesQueryVariables>
export const GetOfficesPaginationDocument = gql`
  query getOfficesPagination($page: Int!, $pageSize: Int!, $search: String) {
    offices(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "name:asc"
      filters: { name: { containsi: $search } }
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetOfficesPaginationQuery__
 *
 * To run a query within a React component, call `useGetOfficesPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetOfficesPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<IGetOfficesPaginationQuery, IGetOfficesPaginationQueryVariables> &
    ({ variables: IGetOfficesPaginationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetOfficesPaginationQuery, IGetOfficesPaginationQueryVariables>(
    GetOfficesPaginationDocument,
    options,
  )
}
export function useGetOfficesPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetOfficesPaginationQuery, IGetOfficesPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetOfficesPaginationQuery, IGetOfficesPaginationQueryVariables>(
    GetOfficesPaginationDocument,
    options,
  )
}
export function useGetOfficesPaginationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetOfficesPaginationQuery, IGetOfficesPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetOfficesPaginationQuery, IGetOfficesPaginationQueryVariables>(
    GetOfficesPaginationDocument,
    options,
  )
}
export type GetOfficesPaginationQueryHookResult = ReturnType<typeof useGetOfficesPaginationQuery>
export type GetOfficesPaginationLazyQueryHookResult = ReturnType<typeof useGetOfficesPaginationLazyQuery>
export type GetOfficesPaginationSuspenseQueryHookResult = ReturnType<typeof useGetOfficesPaginationSuspenseQuery>
export type GetOfficesPaginationQueryResult = Apollo.QueryResult<
  IGetOfficesPaginationQuery,
  IGetOfficesPaginationQueryVariables
>
export const GetOrganizationDocument = gql`
  query getOrganization {
    organization
  }
`

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetOrganizationQuery, IGetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetOrganizationQuery, IGetOrganizationQueryVariables>(GetOrganizationDocument, options)
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetOrganizationQuery, IGetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetOrganizationQuery, IGetOrganizationQueryVariables>(GetOrganizationDocument, options)
}
export function useGetOrganizationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetOrganizationQuery, IGetOrganizationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetOrganizationQuery, IGetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options,
  )
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>
export type GetOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSuspenseQuery>
export type GetOrganizationQueryResult = Apollo.QueryResult<IGetOrganizationQuery, IGetOrganizationQueryVariables>
export const GetOrganizationNamesDocument = gql`
  query getOrganizationNames {
    premiumOrganizations {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetOrganizationNamesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetOrganizationNamesQuery, IGetOrganizationNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetOrganizationNamesQuery, IGetOrganizationNamesQueryVariables>(
    GetOrganizationNamesDocument,
    options,
  )
}
export function useGetOrganizationNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetOrganizationNamesQuery, IGetOrganizationNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetOrganizationNamesQuery, IGetOrganizationNamesQueryVariables>(
    GetOrganizationNamesDocument,
    options,
  )
}
export function useGetOrganizationNamesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetOrganizationNamesQuery, IGetOrganizationNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetOrganizationNamesQuery, IGetOrganizationNamesQueryVariables>(
    GetOrganizationNamesDocument,
    options,
  )
}
export type GetOrganizationNamesQueryHookResult = ReturnType<typeof useGetOrganizationNamesQuery>
export type GetOrganizationNamesLazyQueryHookResult = ReturnType<typeof useGetOrganizationNamesLazyQuery>
export type GetOrganizationNamesSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationNamesSuspenseQuery>
export type GetOrganizationNamesQueryResult = Apollo.QueryResult<
  IGetOrganizationNamesQuery,
  IGetOrganizationNamesQueryVariables
>
export const PhasesDocument = gql`
  query phases {
    phases(sort: "title:asc", pagination: { limit: -1 }) {
      data {
        id
        attributes {
          title
          description
        }
      }
    }
  }
`

/**
 * __usePhasesQuery__
 *
 * To run a query within a React component, call `usePhasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhasesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhasesQuery(baseOptions?: Apollo.QueryHookOptions<IPhasesQuery, IPhasesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPhasesQuery, IPhasesQueryVariables>(PhasesDocument, options)
}
export function usePhasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IPhasesQuery, IPhasesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPhasesQuery, IPhasesQueryVariables>(PhasesDocument, options)
}
export function usePhasesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPhasesQuery, IPhasesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPhasesQuery, IPhasesQueryVariables>(PhasesDocument, options)
}
export type PhasesQueryHookResult = ReturnType<typeof usePhasesQuery>
export type PhasesLazyQueryHookResult = ReturnType<typeof usePhasesLazyQuery>
export type PhasesSuspenseQueryHookResult = ReturnType<typeof usePhasesSuspenseQuery>
export type PhasesQueryResult = Apollo.QueryResult<IPhasesQuery, IPhasesQueryVariables>
export const GetPracticeAreasDocument = gql`
  query getPracticeAreas {
    practiceAreas(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetPracticeAreasQuery__
 *
 * To run a query within a React component, call `useGetPracticeAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPracticeAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>(GetPracticeAreasDocument, options)
}
export function useGetPracticeAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>(GetPracticeAreasDocument, options)
}
export function useGetPracticeAreasSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>(
    GetPracticeAreasDocument,
    options,
  )
}
export type GetPracticeAreasQueryHookResult = ReturnType<typeof useGetPracticeAreasQuery>
export type GetPracticeAreasLazyQueryHookResult = ReturnType<typeof useGetPracticeAreasLazyQuery>
export type GetPracticeAreasSuspenseQueryHookResult = ReturnType<typeof useGetPracticeAreasSuspenseQuery>
export type GetPracticeAreasQueryResult = Apollo.QueryResult<IGetPracticeAreasQuery, IGetPracticeAreasQueryVariables>
export const GetPracticeAreasPaginationDocument = gql`
  query getPracticeAreasPagination($page: Int!, $pageSize: Int!, $search: String) {
    practiceAreas(
      pagination: { page: $page, pageSize: $pageSize }
      sort: "name:asc"
      filters: { name: { containsi: $search } }
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetPracticeAreasPaginationQuery__
 *
 * To run a query within a React component, call `useGetPracticeAreasPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeAreasPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeAreasPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetPracticeAreasPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<IGetPracticeAreasPaginationQuery, IGetPracticeAreasPaginationQueryVariables> &
    ({ variables: IGetPracticeAreasPaginationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetPracticeAreasPaginationQuery, IGetPracticeAreasPaginationQueryVariables>(
    GetPracticeAreasPaginationDocument,
    options,
  )
}
export function useGetPracticeAreasPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetPracticeAreasPaginationQuery,
    IGetPracticeAreasPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetPracticeAreasPaginationQuery, IGetPracticeAreasPaginationQueryVariables>(
    GetPracticeAreasPaginationDocument,
    options,
  )
}
export function useGetPracticeAreasPaginationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetPracticeAreasPaginationQuery,
    IGetPracticeAreasPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetPracticeAreasPaginationQuery, IGetPracticeAreasPaginationQueryVariables>(
    GetPracticeAreasPaginationDocument,
    options,
  )
}
export type GetPracticeAreasPaginationQueryHookResult = ReturnType<typeof useGetPracticeAreasPaginationQuery>
export type GetPracticeAreasPaginationLazyQueryHookResult = ReturnType<typeof useGetPracticeAreasPaginationLazyQuery>
export type GetPracticeAreasPaginationSuspenseQueryHookResult = ReturnType<
  typeof useGetPracticeAreasPaginationSuspenseQuery
>
export type GetPracticeAreasPaginationQueryResult = Apollo.QueryResult<
  IGetPracticeAreasPaginationQuery,
  IGetPracticeAreasPaginationQueryVariables
>
export const GetPremiumOrganizationsByDomainEmailDocument = gql`
  query getPremiumOrganizationsByDomainEmail($domainEmail: String!) {
    premiumOrganizations(filters: { cognitoProviderId: { eq: $domainEmail } }) {
      data {
        id
      }
    }
  }
`

/**
 * __useGetPremiumOrganizationsByDomainEmailQuery__
 *
 * To run a query within a React component, call `useGetPremiumOrganizationsByDomainEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPremiumOrganizationsByDomainEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPremiumOrganizationsByDomainEmailQuery({
 *   variables: {
 *      domainEmail: // value for 'domainEmail'
 *   },
 * });
 */
export function useGetPremiumOrganizationsByDomainEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetPremiumOrganizationsByDomainEmailQuery,
    IGetPremiumOrganizationsByDomainEmailQueryVariables
  > &
    ({ variables: IGetPremiumOrganizationsByDomainEmailQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetPremiumOrganizationsByDomainEmailQuery,
    IGetPremiumOrganizationsByDomainEmailQueryVariables
  >(GetPremiumOrganizationsByDomainEmailDocument, options)
}
export function useGetPremiumOrganizationsByDomainEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetPremiumOrganizationsByDomainEmailQuery,
    IGetPremiumOrganizationsByDomainEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetPremiumOrganizationsByDomainEmailQuery,
    IGetPremiumOrganizationsByDomainEmailQueryVariables
  >(GetPremiumOrganizationsByDomainEmailDocument, options)
}
export function useGetPremiumOrganizationsByDomainEmailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetPremiumOrganizationsByDomainEmailQuery,
    IGetPremiumOrganizationsByDomainEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    IGetPremiumOrganizationsByDomainEmailQuery,
    IGetPremiumOrganizationsByDomainEmailQueryVariables
  >(GetPremiumOrganizationsByDomainEmailDocument, options)
}
export type GetPremiumOrganizationsByDomainEmailQueryHookResult = ReturnType<
  typeof useGetPremiumOrganizationsByDomainEmailQuery
>
export type GetPremiumOrganizationsByDomainEmailLazyQueryHookResult = ReturnType<
  typeof useGetPremiumOrganizationsByDomainEmailLazyQuery
>
export type GetPremiumOrganizationsByDomainEmailSuspenseQueryHookResult = ReturnType<
  typeof useGetPremiumOrganizationsByDomainEmailSuspenseQuery
>
export type GetPremiumOrganizationsByDomainEmailQueryResult = Apollo.QueryResult<
  IGetPremiumOrganizationsByDomainEmailQuery,
  IGetPremiumOrganizationsByDomainEmailQueryVariables
>
export const GetPricingPlansDocument = gql`
  query getPricingPlans {
    premiumPricingPlans(filters: { isActive: { eq: true } }, sort: "id", pagination: { limit: -1 }) {
      data {
        id
        attributes {
          name
          pricePerYear
          stripeRedirectUrl
        }
      }
    }
  }
`

/**
 * __useGetPricingPlansQuery__
 *
 * To run a query within a React component, call `useGetPricingPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPricingPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPricingPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPricingPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>(GetPricingPlansDocument, options)
}
export function useGetPricingPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>(GetPricingPlansDocument, options)
}
export function useGetPricingPlansSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>(
    GetPricingPlansDocument,
    options,
  )
}
export type GetPricingPlansQueryHookResult = ReturnType<typeof useGetPricingPlansQuery>
export type GetPricingPlansLazyQueryHookResult = ReturnType<typeof useGetPricingPlansLazyQuery>
export type GetPricingPlansSuspenseQueryHookResult = ReturnType<typeof useGetPricingPlansSuspenseQuery>
export type GetPricingPlansQueryResult = Apollo.QueryResult<IGetPricingPlansQuery, IGetPricingPlansQueryVariables>
export const GetPrivacyPolicyDocument = gql`
  query getPrivacyPolicy {
    privacyPolicy {
      data {
        attributes {
          summary
          content
          date
        }
      }
    }
  }
`

/**
 * __useGetPrivacyPolicyQuery__
 *
 * To run a query within a React component, call `useGetPrivacyPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrivacyPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrivacyPolicyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrivacyPolicyQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>(GetPrivacyPolicyDocument, options)
}
export function useGetPrivacyPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>(GetPrivacyPolicyDocument, options)
}
export function useGetPrivacyPolicySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>(
    GetPrivacyPolicyDocument,
    options,
  )
}
export type GetPrivacyPolicyQueryHookResult = ReturnType<typeof useGetPrivacyPolicyQuery>
export type GetPrivacyPolicyLazyQueryHookResult = ReturnType<typeof useGetPrivacyPolicyLazyQuery>
export type GetPrivacyPolicySuspenseQueryHookResult = ReturnType<typeof useGetPrivacyPolicySuspenseQuery>
export type GetPrivacyPolicyQueryResult = Apollo.QueryResult<IGetPrivacyPolicyQuery, IGetPrivacyPolicyQueryVariables>
export const GetRegionsServedDocument = gql`
  query getRegionsServed {
    regionsServeds(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetRegionsServedQuery__
 *
 * To run a query within a React component, call `useGetRegionsServedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsServedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsServedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegionsServedQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>(GetRegionsServedDocument, options)
}
export function useGetRegionsServedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>(GetRegionsServedDocument, options)
}
export function useGetRegionsServedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>(
    GetRegionsServedDocument,
    options,
  )
}
export type GetRegionsServedQueryHookResult = ReturnType<typeof useGetRegionsServedQuery>
export type GetRegionsServedLazyQueryHookResult = ReturnType<typeof useGetRegionsServedLazyQuery>
export type GetRegionsServedSuspenseQueryHookResult = ReturnType<typeof useGetRegionsServedSuspenseQuery>
export type GetRegionsServedQueryResult = Apollo.QueryResult<IGetRegionsServedQuery, IGetRegionsServedQueryVariables>
export const GetSsoOrganizationCognitoProvidersDocument = gql`
  query getSSOOrganizationCognitoProviders {
    premiumOrganizations(filters: { cognitoProviderId: { notNull: true } }) {
      data {
        attributes {
          cognitoProviderId
        }
      }
    }
  }
`

/**
 * __useGetSsoOrganizationCognitoProvidersQuery__
 *
 * To run a query within a React component, call `useGetSsoOrganizationCognitoProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSsoOrganizationCognitoProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSsoOrganizationCognitoProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSsoOrganizationCognitoProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IGetSsoOrganizationCognitoProvidersQuery,
    IGetSsoOrganizationCognitoProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSsoOrganizationCognitoProvidersQuery, IGetSsoOrganizationCognitoProvidersQueryVariables>(
    GetSsoOrganizationCognitoProvidersDocument,
    options,
  )
}
export function useGetSsoOrganizationCognitoProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSsoOrganizationCognitoProvidersQuery,
    IGetSsoOrganizationCognitoProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSsoOrganizationCognitoProvidersQuery,
    IGetSsoOrganizationCognitoProvidersQueryVariables
  >(GetSsoOrganizationCognitoProvidersDocument, options)
}
export function useGetSsoOrganizationCognitoProvidersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IGetSsoOrganizationCognitoProvidersQuery,
    IGetSsoOrganizationCognitoProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    IGetSsoOrganizationCognitoProvidersQuery,
    IGetSsoOrganizationCognitoProvidersQueryVariables
  >(GetSsoOrganizationCognitoProvidersDocument, options)
}
export type GetSsoOrganizationCognitoProvidersQueryHookResult = ReturnType<
  typeof useGetSsoOrganizationCognitoProvidersQuery
>
export type GetSsoOrganizationCognitoProvidersLazyQueryHookResult = ReturnType<
  typeof useGetSsoOrganizationCognitoProvidersLazyQuery
>
export type GetSsoOrganizationCognitoProvidersSuspenseQueryHookResult = ReturnType<
  typeof useGetSsoOrganizationCognitoProvidersSuspenseQuery
>
export type GetSsoOrganizationCognitoProvidersQueryResult = Apollo.QueryResult<
  IGetSsoOrganizationCognitoProvidersQuery,
  IGetSsoOrganizationCognitoProvidersQueryVariables
>
export const GetSecuritiesDocument = gql`
  query getSecurities {
    securities(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetSecuritiesQuery__
 *
 * To run a query within a React component, call `useGetSecuritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecuritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecuritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSecuritiesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>(GetSecuritiesDocument, options)
}
export function useGetSecuritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>(GetSecuritiesDocument, options)
}
export function useGetSecuritiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>(GetSecuritiesDocument, options)
}
export type GetSecuritiesQueryHookResult = ReturnType<typeof useGetSecuritiesQuery>
export type GetSecuritiesLazyQueryHookResult = ReturnType<typeof useGetSecuritiesLazyQuery>
export type GetSecuritiesSuspenseQueryHookResult = ReturnType<typeof useGetSecuritiesSuspenseQuery>
export type GetSecuritiesQueryResult = Apollo.QueryResult<IGetSecuritiesQuery, IGetSecuritiesQueryVariables>
export const GetShareableContentsDocument = gql`
  query getShareableContents($offset: Int!, $limit: Int!, $query: MySharedContentsFilters!) {
    shareableContents(offset: $offset, limit: $limit, filters: $query) {
      total
      contents {
        id
        attributes {
          title
          slug
          snippet
          createdAt
          publishedTime
          contentType
          documentType
          publishedTime
          publishedAt
        }
        __typename
      }
    }
  }
`

/**
 * __useGetShareableContentsQuery__
 *
 * To run a query within a React component, call `useGetShareableContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareableContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareableContentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetShareableContentsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetShareableContentsQuery, IGetShareableContentsQueryVariables> &
    ({ variables: IGetShareableContentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetShareableContentsQuery, IGetShareableContentsQueryVariables>(
    GetShareableContentsDocument,
    options,
  )
}
export function useGetShareableContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetShareableContentsQuery, IGetShareableContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetShareableContentsQuery, IGetShareableContentsQueryVariables>(
    GetShareableContentsDocument,
    options,
  )
}
export function useGetShareableContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetShareableContentsQuery, IGetShareableContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetShareableContentsQuery, IGetShareableContentsQueryVariables>(
    GetShareableContentsDocument,
    options,
  )
}
export type GetShareableContentsQueryHookResult = ReturnType<typeof useGetShareableContentsQuery>
export type GetShareableContentsLazyQueryHookResult = ReturnType<typeof useGetShareableContentsLazyQuery>
export type GetShareableContentsSuspenseQueryHookResult = ReturnType<typeof useGetShareableContentsSuspenseQuery>
export type GetShareableContentsQueryResult = Apollo.QueryResult<
  IGetShareableContentsQuery,
  IGetShareableContentsQueryVariables
>
export const GetShareableTopicsDocument = gql`
  query getShareableTopics {
    shareableTopics {
      topics {
        id
        name
        isPremium
        subTopics {
          id
          name
          isPremium
        }
      }
    }
  }
`

/**
 * __useGetShareableTopicsQuery__
 *
 * To run a query within a React component, call `useGetShareableTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShareableTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShareableTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShareableTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetShareableTopicsQuery, IGetShareableTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetShareableTopicsQuery, IGetShareableTopicsQueryVariables>(
    GetShareableTopicsDocument,
    options,
  )
}
export function useGetShareableTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetShareableTopicsQuery, IGetShareableTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetShareableTopicsQuery, IGetShareableTopicsQueryVariables>(
    GetShareableTopicsDocument,
    options,
  )
}
export function useGetShareableTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetShareableTopicsQuery, IGetShareableTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetShareableTopicsQuery, IGetShareableTopicsQueryVariables>(
    GetShareableTopicsDocument,
    options,
  )
}
export type GetShareableTopicsQueryHookResult = ReturnType<typeof useGetShareableTopicsQuery>
export type GetShareableTopicsLazyQueryHookResult = ReturnType<typeof useGetShareableTopicsLazyQuery>
export type GetShareableTopicsSuspenseQueryHookResult = ReturnType<typeof useGetShareableTopicsSuspenseQuery>
export type GetShareableTopicsQueryResult = Apollo.QueryResult<
  IGetShareableTopicsQuery,
  IGetShareableTopicsQueryVariables
>
export const GetSlugUpdatesDocument = gql`
  query getSlugUpdates($type: String!, $oldSlug: String!) {
    slugUpdates(filters: { type: { eq: $type }, oldSlug: { eq: $oldSlug } }) {
      data {
        attributes {
          newSlug
        }
      }
    }
  }
`

/**
 * __useGetSlugUpdatesQuery__
 *
 * To run a query within a React component, call `useGetSlugUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlugUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlugUpdatesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      oldSlug: // value for 'oldSlug'
 *   },
 * });
 */
export function useGetSlugUpdatesQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables> &
    ({ variables: IGetSlugUpdatesQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables>(GetSlugUpdatesDocument, options)
}
export function useGetSlugUpdatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables>(GetSlugUpdatesDocument, options)
}
export function useGetSlugUpdatesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables>(GetSlugUpdatesDocument, options)
}
export type GetSlugUpdatesQueryHookResult = ReturnType<typeof useGetSlugUpdatesQuery>
export type GetSlugUpdatesLazyQueryHookResult = ReturnType<typeof useGetSlugUpdatesLazyQuery>
export type GetSlugUpdatesSuspenseQueryHookResult = ReturnType<typeof useGetSlugUpdatesSuspenseQuery>
export type GetSlugUpdatesQueryResult = Apollo.QueryResult<IGetSlugUpdatesQuery, IGetSlugUpdatesQueryVariables>
export const SolutionsForVendorDocument = gql`
  query solutionsForVendor($query: JSON) {
    searchVendorSolutions(query: $query) {
      total
      solutions {
        id
        slug
        type
        displayName
        isPremium
        isEnhanced
        subTopics
        rating
        reviewCnt
        description
        logo
        valueProposition
        offices {
          id
          name
        }
        security {
          id
          name
        }
      }
    }
  }
`

/**
 * __useSolutionsForVendorQuery__
 *
 * To run a query within a React component, call `useSolutionsForVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolutionsForVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolutionsForVendorQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSolutionsForVendorQuery(
  baseOptions?: Apollo.QueryHookOptions<ISolutionsForVendorQuery, ISolutionsForVendorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISolutionsForVendorQuery, ISolutionsForVendorQueryVariables>(
    SolutionsForVendorDocument,
    options,
  )
}
export function useSolutionsForVendorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISolutionsForVendorQuery, ISolutionsForVendorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISolutionsForVendorQuery, ISolutionsForVendorQueryVariables>(
    SolutionsForVendorDocument,
    options,
  )
}
export function useSolutionsForVendorSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISolutionsForVendorQuery, ISolutionsForVendorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISolutionsForVendorQuery, ISolutionsForVendorQueryVariables>(
    SolutionsForVendorDocument,
    options,
  )
}
export type SolutionsForVendorQueryHookResult = ReturnType<typeof useSolutionsForVendorQuery>
export type SolutionsForVendorLazyQueryHookResult = ReturnType<typeof useSolutionsForVendorLazyQuery>
export type SolutionsForVendorSuspenseQueryHookResult = ReturnType<typeof useSolutionsForVendorSuspenseQuery>
export type SolutionsForVendorQueryResult = Apollo.QueryResult<
  ISolutionsForVendorQuery,
  ISolutionsForVendorQueryVariables
>
export const GetSubTopicBySlugDocument = gql`
  query getSubTopicBySlug($slug: String!) {
    subTopics(filters: { slug: { eq: $slug } }) {
      data {
        ...subTopicFullItem
      }
    }
  }
  ${SubTopicFullItemFragmentDoc}
`

/**
 * __useGetSubTopicBySlugQuery__
 *
 * To run a query within a React component, call `useGetSubTopicBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubTopicBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubTopicBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetSubTopicBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables> &
    ({ variables: IGetSubTopicBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables>(GetSubTopicBySlugDocument, options)
}
export function useGetSubTopicBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables>(
    GetSubTopicBySlugDocument,
    options,
  )
}
export function useGetSubTopicBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables>(
    GetSubTopicBySlugDocument,
    options,
  )
}
export type GetSubTopicBySlugQueryHookResult = ReturnType<typeof useGetSubTopicBySlugQuery>
export type GetSubTopicBySlugLazyQueryHookResult = ReturnType<typeof useGetSubTopicBySlugLazyQuery>
export type GetSubTopicBySlugSuspenseQueryHookResult = ReturnType<typeof useGetSubTopicBySlugSuspenseQuery>
export type GetSubTopicBySlugQueryResult = Apollo.QueryResult<IGetSubTopicBySlugQuery, IGetSubTopicBySlugQueryVariables>
export const GetSubTopicsDocument = gql`
  query getSubTopics($start: Int!) {
    subTopics(sort: "name:asc", pagination: { start: $start }) {
      data {
        ...subTopicItem
      }
    }
  }
  ${SubTopicItemFragmentDoc}
`

/**
 * __useGetSubTopicsQuery__
 *
 * To run a query within a React component, call `useGetSubTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubTopicsQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetSubTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<IGetSubTopicsQuery, IGetSubTopicsQueryVariables> &
    ({ variables: IGetSubTopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSubTopicsQuery, IGetSubTopicsQueryVariables>(GetSubTopicsDocument, options)
}
export function useGetSubTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSubTopicsQuery, IGetSubTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetSubTopicsQuery, IGetSubTopicsQueryVariables>(GetSubTopicsDocument, options)
}
export function useGetSubTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSubTopicsQuery, IGetSubTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetSubTopicsQuery, IGetSubTopicsQueryVariables>(GetSubTopicsDocument, options)
}
export type GetSubTopicsQueryHookResult = ReturnType<typeof useGetSubTopicsQuery>
export type GetSubTopicsLazyQueryHookResult = ReturnType<typeof useGetSubTopicsLazyQuery>
export type GetSubTopicsSuspenseQueryHookResult = ReturnType<typeof useGetSubTopicsSuspenseQuery>
export type GetSubTopicsQueryResult = Apollo.QueryResult<IGetSubTopicsQuery, IGetSubTopicsQueryVariables>
export const GetFeaturesDocument = gql`
  query getFeatures {
    subTopics {
      data {
        id
        attributes {
          features {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturesQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetFeaturesQuery, IGetFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetFeaturesQuery, IGetFeaturesQueryVariables>(GetFeaturesDocument, options)
}
export function useGetFeaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetFeaturesQuery, IGetFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetFeaturesQuery, IGetFeaturesQueryVariables>(GetFeaturesDocument, options)
}
export function useGetFeaturesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetFeaturesQuery, IGetFeaturesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetFeaturesQuery, IGetFeaturesQueryVariables>(GetFeaturesDocument, options)
}
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>
export type GetFeaturesSuspenseQueryHookResult = ReturnType<typeof useGetFeaturesSuspenseQuery>
export type GetFeaturesQueryResult = Apollo.QueryResult<IGetFeaturesQuery, IGetFeaturesQueryVariables>
export const GetSubjectMatterDocument = gql`
  query getSubjectMatter {
    subjectMatters(sort: "name:asc") {
      data {
        id
        attributes {
          name
          slug
          description
        }
      }
    }
  }
`

/**
 * __useGetSubjectMatterQuery__
 *
 * To run a query within a React component, call `useGetSubjectMatterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectMatterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectMatterQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectMatterQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>(GetSubjectMatterDocument, options)
}
export function useGetSubjectMatterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>(GetSubjectMatterDocument, options)
}
export function useGetSubjectMatterSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>(
    GetSubjectMatterDocument,
    options,
  )
}
export type GetSubjectMatterQueryHookResult = ReturnType<typeof useGetSubjectMatterQuery>
export type GetSubjectMatterLazyQueryHookResult = ReturnType<typeof useGetSubjectMatterLazyQuery>
export type GetSubjectMatterSuspenseQueryHookResult = ReturnType<typeof useGetSubjectMatterSuspenseQuery>
export type GetSubjectMatterQueryResult = Apollo.QueryResult<IGetSubjectMatterQuery, IGetSubjectMatterQueryVariables>
export const GetTermsConditionsDocument = gql`
  query getTermsConditions {
    termsConditions {
      data {
        attributes {
          summary
          content
          date
        }
      }
    }
  }
`

/**
 * __useGetTermsConditionsQuery__
 *
 * To run a query within a React component, call `useGetTermsConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTermsConditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetTermsConditionsQuery, IGetTermsConditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetTermsConditionsQuery, IGetTermsConditionsQueryVariables>(
    GetTermsConditionsDocument,
    options,
  )
}
export function useGetTermsConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTermsConditionsQuery, IGetTermsConditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetTermsConditionsQuery, IGetTermsConditionsQueryVariables>(
    GetTermsConditionsDocument,
    options,
  )
}
export function useGetTermsConditionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTermsConditionsQuery, IGetTermsConditionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetTermsConditionsQuery, IGetTermsConditionsQueryVariables>(
    GetTermsConditionsDocument,
    options,
  )
}
export type GetTermsConditionsQueryHookResult = ReturnType<typeof useGetTermsConditionsQuery>
export type GetTermsConditionsLazyQueryHookResult = ReturnType<typeof useGetTermsConditionsLazyQuery>
export type GetTermsConditionsSuspenseQueryHookResult = ReturnType<typeof useGetTermsConditionsSuspenseQuery>
export type GetTermsConditionsQueryResult = Apollo.QueryResult<
  IGetTermsConditionsQuery,
  IGetTermsConditionsQueryVariables
>
export const GetThemeDocument = gql`
  query getTheme {
    themes(sort: "name:asc") {
      data {
        id
        attributes {
          name
          slug
          description
        }
      }
    }
  }
`

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThemeQuery(baseOptions?: Apollo.QueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options)
}
export function useGetThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options)
}
export function useGetThemeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetThemeQuery, IGetThemeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetThemeQuery, IGetThemeQueryVariables>(GetThemeDocument, options)
}
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>
export type GetThemeLazyQueryHookResult = ReturnType<typeof useGetThemeLazyQuery>
export type GetThemeSuspenseQueryHookResult = ReturnType<typeof useGetThemeSuspenseQuery>
export type GetThemeQueryResult = Apollo.QueryResult<IGetThemeQuery, IGetThemeQueryVariables>
export const GetTopicBySlugDocument = gql`
  query getTopicBySlug($slug: String!) {
    topics(filters: { slug: { eq: $slug } }) {
      data {
        ...topicFullItem
      }
    }
  }
  ${TopicFullItemFragmentDoc}
`

/**
 * __useGetTopicBySlugQuery__
 *
 * To run a query within a React component, call `useGetTopicBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetTopicBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables> &
    ({ variables: IGetTopicBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables>(GetTopicBySlugDocument, options)
}
export function useGetTopicBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables>(GetTopicBySlugDocument, options)
}
export function useGetTopicBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables>(GetTopicBySlugDocument, options)
}
export type GetTopicBySlugQueryHookResult = ReturnType<typeof useGetTopicBySlugQuery>
export type GetTopicBySlugLazyQueryHookResult = ReturnType<typeof useGetTopicBySlugLazyQuery>
export type GetTopicBySlugSuspenseQueryHookResult = ReturnType<typeof useGetTopicBySlugSuspenseQuery>
export type GetTopicBySlugQueryResult = Apollo.QueryResult<IGetTopicBySlugQuery, IGetTopicBySlugQueryVariables>
export const GetTopicsDocument = gql`
  query getTopics {
    topics(sort: "name:asc", pagination: { limit: -1 }) {
      data {
        ...topicItem
      }
    }
  }
  ${TopicItemFragmentDoc}
`

/**
 * __useGetTopicsQuery__
 *
 * To run a query within a React component, call `useGetTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicsQuery(baseOptions?: Apollo.QueryHookOptions<IGetTopicsQuery, IGetTopicsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetTopicsQuery, IGetTopicsQueryVariables>(GetTopicsDocument, options)
}
export function useGetTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTopicsQuery, IGetTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetTopicsQuery, IGetTopicsQueryVariables>(GetTopicsDocument, options)
}
export function useGetTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTopicsQuery, IGetTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetTopicsQuery, IGetTopicsQueryVariables>(GetTopicsDocument, options)
}
export type GetTopicsQueryHookResult = ReturnType<typeof useGetTopicsQuery>
export type GetTopicsLazyQueryHookResult = ReturnType<typeof useGetTopicsLazyQuery>
export type GetTopicsSuspenseQueryHookResult = ReturnType<typeof useGetTopicsSuspenseQuery>
export type GetTopicsQueryResult = Apollo.QueryResult<IGetTopicsQuery, IGetTopicsQueryVariables>
export const GetTopicsForHeaderDocument = gql`
  query getTopicsForHeader {
    topics(sort: "name:asc", pagination: { limit: -1 }) {
      data {
        id
        attributes {
          slug
          name
          iconName
          description
          isPremium
          publishedAt
          subTopics(sort: "name:asc") {
            data {
              ...subTopicItemWithoutSub
            }
          }
        }
      }
    }
  }
  ${SubTopicItemWithoutSubFragmentDoc}
`

/**
 * __useGetTopicsForHeaderQuery__
 *
 * To run a query within a React component, call `useGetTopicsForHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsForHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsForHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicsForHeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetTopicsForHeaderQuery, IGetTopicsForHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetTopicsForHeaderQuery, IGetTopicsForHeaderQueryVariables>(
    GetTopicsForHeaderDocument,
    options,
  )
}
export function useGetTopicsForHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTopicsForHeaderQuery, IGetTopicsForHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetTopicsForHeaderQuery, IGetTopicsForHeaderQueryVariables>(
    GetTopicsForHeaderDocument,
    options,
  )
}
export function useGetTopicsForHeaderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTopicsForHeaderQuery, IGetTopicsForHeaderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetTopicsForHeaderQuery, IGetTopicsForHeaderQueryVariables>(
    GetTopicsForHeaderDocument,
    options,
  )
}
export type GetTopicsForHeaderQueryHookResult = ReturnType<typeof useGetTopicsForHeaderQuery>
export type GetTopicsForHeaderLazyQueryHookResult = ReturnType<typeof useGetTopicsForHeaderLazyQuery>
export type GetTopicsForHeaderSuspenseQueryHookResult = ReturnType<typeof useGetTopicsForHeaderSuspenseQuery>
export type GetTopicsForHeaderQueryResult = Apollo.QueryResult<
  IGetTopicsForHeaderQuery,
  IGetTopicsForHeaderQueryVariables
>
export const GetTopicsWithoutSubsDocument = gql`
  query getTopicsWithoutSubs {
    topics(sort: "name:asc", pagination: { limit: -1 }) {
      data {
        attributes {
          name
          slug
          iconName
          isPremium
          subTopics {
            data {
              attributes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetTopicsWithoutSubsQuery__
 *
 * To run a query within a React component, call `useGetTopicsWithoutSubsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopicsWithoutSubsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopicsWithoutSubsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTopicsWithoutSubsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetTopicsWithoutSubsQuery, IGetTopicsWithoutSubsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetTopicsWithoutSubsQuery, IGetTopicsWithoutSubsQueryVariables>(
    GetTopicsWithoutSubsDocument,
    options,
  )
}
export function useGetTopicsWithoutSubsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTopicsWithoutSubsQuery, IGetTopicsWithoutSubsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetTopicsWithoutSubsQuery, IGetTopicsWithoutSubsQueryVariables>(
    GetTopicsWithoutSubsDocument,
    options,
  )
}
export function useGetTopicsWithoutSubsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTopicsWithoutSubsQuery, IGetTopicsWithoutSubsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetTopicsWithoutSubsQuery, IGetTopicsWithoutSubsQueryVariables>(
    GetTopicsWithoutSubsDocument,
    options,
  )
}
export type GetTopicsWithoutSubsQueryHookResult = ReturnType<typeof useGetTopicsWithoutSubsQuery>
export type GetTopicsWithoutSubsLazyQueryHookResult = ReturnType<typeof useGetTopicsWithoutSubsLazyQuery>
export type GetTopicsWithoutSubsSuspenseQueryHookResult = ReturnType<typeof useGetTopicsWithoutSubsSuspenseQuery>
export type GetTopicsWithoutSubsQueryResult = Apollo.QueryResult<
  IGetTopicsWithoutSubsQuery,
  IGetTopicsWithoutSubsQueryVariables
>
export const GetTypeOfUsersDocument = gql`
  query getTypeOfUsers {
    typeOfUsers {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetTypeOfUsersQuery__
 *
 * To run a query within a React component, call `useGetTypeOfUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTypeOfUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTypeOfUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTypeOfUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>(GetTypeOfUsersDocument, options)
}
export function useGetTypeOfUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>(GetTypeOfUsersDocument, options)
}
export function useGetTypeOfUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>(GetTypeOfUsersDocument, options)
}
export type GetTypeOfUsersQueryHookResult = ReturnType<typeof useGetTypeOfUsersQuery>
export type GetTypeOfUsersLazyQueryHookResult = ReturnType<typeof useGetTypeOfUsersLazyQuery>
export type GetTypeOfUsersSuspenseQueryHookResult = ReturnType<typeof useGetTypeOfUsersSuspenseQuery>
export type GetTypeOfUsersQueryResult = Apollo.QueryResult<IGetTypeOfUsersQuery, IGetTypeOfUsersQueryVariables>
export const GetVendorTopicsDocument = gql`
  query getVendorTopics {
    vendorTopics {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useGetVendorTopicsQuery__
 *
 * To run a query within a React component, call `useGetVendorTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVendorTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVendorTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVendorTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>(GetVendorTopicsDocument, options)
}
export function useGetVendorTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>(GetVendorTopicsDocument, options)
}
export function useGetVendorTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>(
    GetVendorTopicsDocument,
    options,
  )
}
export type GetVendorTopicsQueryHookResult = ReturnType<typeof useGetVendorTopicsQuery>
export type GetVendorTopicsLazyQueryHookResult = ReturnType<typeof useGetVendorTopicsLazyQuery>
export type GetVendorTopicsSuspenseQueryHookResult = ReturnType<typeof useGetVendorTopicsSuspenseQuery>
export type GetVendorTopicsQueryResult = Apollo.QueryResult<IGetVendorTopicsQuery, IGetVendorTopicsQueryVariables>
export const GlobalTestimonialsDocument = gql`
  query globalTestimonials {
    testimonialsPlural {
      data {
        ...testimonialItem
      }
    }
  }
  ${TestimonialItemFragmentDoc}
`

/**
 * __useGlobalTestimonialsQuery__
 *
 * To run a query within a React component, call `useGlobalTestimonialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalTestimonialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalTestimonialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalTestimonialsQuery(
  baseOptions?: Apollo.QueryHookOptions<IGlobalTestimonialsQuery, IGlobalTestimonialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IGlobalTestimonialsQuery, IGlobalTestimonialsQueryVariables>(
    GlobalTestimonialsDocument,
    options,
  )
}
export function useGlobalTestimonialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IGlobalTestimonialsQuery, IGlobalTestimonialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IGlobalTestimonialsQuery, IGlobalTestimonialsQueryVariables>(
    GlobalTestimonialsDocument,
    options,
  )
}
export function useGlobalTestimonialsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IGlobalTestimonialsQuery, IGlobalTestimonialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IGlobalTestimonialsQuery, IGlobalTestimonialsQueryVariables>(
    GlobalTestimonialsDocument,
    options,
  )
}
export type GlobalTestimonialsQueryHookResult = ReturnType<typeof useGlobalTestimonialsQuery>
export type GlobalTestimonialsLazyQueryHookResult = ReturnType<typeof useGlobalTestimonialsLazyQuery>
export type GlobalTestimonialsSuspenseQueryHookResult = ReturnType<typeof useGlobalTestimonialsSuspenseQuery>
export type GlobalTestimonialsQueryResult = Apollo.QueryResult<
  IGlobalTestimonialsQuery,
  IGlobalTestimonialsQueryVariables
>
export const HqsDocument = gql`
  query hqs {
    hqs(sort: "name:asc") {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useHqsQuery__
 *
 * To run a query within a React component, call `useHqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHqsQuery(baseOptions?: Apollo.QueryHookOptions<IHqsQuery, IHqsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IHqsQuery, IHqsQueryVariables>(HqsDocument, options)
}
export function useHqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IHqsQuery, IHqsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IHqsQuery, IHqsQueryVariables>(HqsDocument, options)
}
export function useHqsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IHqsQuery, IHqsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IHqsQuery, IHqsQueryVariables>(HqsDocument, options)
}
export type HqsQueryHookResult = ReturnType<typeof useHqsQuery>
export type HqsLazyQueryHookResult = ReturnType<typeof useHqsLazyQuery>
export type HqsSuspenseQueryHookResult = ReturnType<typeof useHqsSuspenseQuery>
export type HqsQueryResult = Apollo.QueryResult<IHqsQuery, IHqsQueryVariables>
export const HqsPaginationDocument = gql`
  query hqsPagination($page: Int!, $pageSize: Int!, $search: String) {
    hqs(pagination: { page: $page, pageSize: $pageSize }, sort: "name:asc", filters: { name: { containsi: $search } }) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`

/**
 * __useHqsPaginationQuery__
 *
 * To run a query within a React component, call `useHqsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useHqsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHqsPaginationQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useHqsPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<IHqsPaginationQuery, IHqsPaginationQueryVariables> &
    ({ variables: IHqsPaginationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IHqsPaginationQuery, IHqsPaginationQueryVariables>(HqsPaginationDocument, options)
}
export function useHqsPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IHqsPaginationQuery, IHqsPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IHqsPaginationQuery, IHqsPaginationQueryVariables>(HqsPaginationDocument, options)
}
export function useHqsPaginationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IHqsPaginationQuery, IHqsPaginationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IHqsPaginationQuery, IHqsPaginationQueryVariables>(HqsPaginationDocument, options)
}
export type HqsPaginationQueryHookResult = ReturnType<typeof useHqsPaginationQuery>
export type HqsPaginationLazyQueryHookResult = ReturnType<typeof useHqsPaginationLazyQuery>
export type HqsPaginationSuspenseQueryHookResult = ReturnType<typeof useHqsPaginationSuspenseQuery>
export type HqsPaginationQueryResult = Apollo.QueryResult<IHqsPaginationQuery, IHqsPaginationQueryVariables>
export const IsFollowDocument = gql`
  query isFollow($id: Int!) {
    isFollow(id: $id)
  }
`

/**
 * __useIsFollowQuery__
 *
 * To run a query within a React component, call `useIsFollowQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsFollowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsFollowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsFollowQuery(
  baseOptions: Apollo.QueryHookOptions<IIsFollowQuery, IIsFollowQueryVariables> &
    ({ variables: IIsFollowQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IIsFollowQuery, IIsFollowQueryVariables>(IsFollowDocument, options)
}
export function useIsFollowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IIsFollowQuery, IIsFollowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IIsFollowQuery, IIsFollowQueryVariables>(IsFollowDocument, options)
}
export function useIsFollowSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IIsFollowQuery, IIsFollowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IIsFollowQuery, IIsFollowQueryVariables>(IsFollowDocument, options)
}
export type IsFollowQueryHookResult = ReturnType<typeof useIsFollowQuery>
export type IsFollowLazyQueryHookResult = ReturnType<typeof useIsFollowLazyQuery>
export type IsFollowSuspenseQueryHookResult = ReturnType<typeof useIsFollowSuspenseQuery>
export type IsFollowQueryResult = Apollo.QueryResult<IIsFollowQuery, IIsFollowQueryVariables>
export const LoginDocument = gql`
  mutation login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        username
        email
        confirmed
      }
    }
  }
`
export type ILoginMutationFn = Apollo.MutationFunction<ILoginMutation, ILoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<ILoginMutation, ILoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ILoginMutation, ILoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<ILoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<ILoginMutation, ILoginMutationVariables>
export const MySharedContentsDocument = gql`
  query mySharedContents($offset: Int!, $limit: Int!, $query: MySharedContentsFilters!) {
    mySharedContents(offset: $offset, limit: $limit, filters: $query) {
      total
      contents {
        ...contentListItem
      }
    }
  }
  ${ContentListItemFragmentDoc}
`

/**
 * __useMySharedContentsQuery__
 *
 * To run a query within a React component, call `useMySharedContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySharedContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySharedContentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMySharedContentsQuery(
  baseOptions: Apollo.QueryHookOptions<IMySharedContentsQuery, IMySharedContentsQueryVariables> &
    ({ variables: IMySharedContentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IMySharedContentsQuery, IMySharedContentsQueryVariables>(MySharedContentsDocument, options)
}
export function useMySharedContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IMySharedContentsQuery, IMySharedContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IMySharedContentsQuery, IMySharedContentsQueryVariables>(MySharedContentsDocument, options)
}
export function useMySharedContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IMySharedContentsQuery, IMySharedContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IMySharedContentsQuery, IMySharedContentsQueryVariables>(
    MySharedContentsDocument,
    options,
  )
}
export type MySharedContentsQueryHookResult = ReturnType<typeof useMySharedContentsQuery>
export type MySharedContentsLazyQueryHookResult = ReturnType<typeof useMySharedContentsLazyQuery>
export type MySharedContentsSuspenseQueryHookResult = ReturnType<typeof useMySharedContentsSuspenseQuery>
export type MySharedContentsQueryResult = Apollo.QueryResult<IMySharedContentsQuery, IMySharedContentsQueryVariables>
export const PhasesForSubTopicDocument = gql`
  query phasesForSubTopic {
    phases(sort: "priority:asc", pagination: { limit: -1 }) {
      data {
        ...phaseItem
      }
    }
  }
  ${PhaseItemFragmentDoc}
`

/**
 * __usePhasesForSubTopicQuery__
 *
 * To run a query within a React component, call `usePhasesForSubTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhasesForSubTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhasesForSubTopicQuery({
 *   variables: {
 *   },
 * });
 */
export function usePhasesForSubTopicQuery(
  baseOptions?: Apollo.QueryHookOptions<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>(PhasesForSubTopicDocument, options)
}
export function usePhasesForSubTopicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>(
    PhasesForSubTopicDocument,
    options,
  )
}
export function usePhasesForSubTopicSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>(
    PhasesForSubTopicDocument,
    options,
  )
}
export type PhasesForSubTopicQueryHookResult = ReturnType<typeof usePhasesForSubTopicQuery>
export type PhasesForSubTopicLazyQueryHookResult = ReturnType<typeof usePhasesForSubTopicLazyQuery>
export type PhasesForSubTopicSuspenseQueryHookResult = ReturnType<typeof usePhasesForSubTopicSuspenseQuery>
export type PhasesForSubTopicQueryResult = Apollo.QueryResult<IPhasesForSubTopicQuery, IPhasesForSubTopicQueryVariables>
export const PopularTopicsDocument = gql`
  query popularTopics($vendorLimit: Int!) {
    popularTopics(vendorLimit: $vendorLimit) {
      id
      name
      slug
      solutions {
        logo {
          url
        }
        slug
        type
      }
    }
  }
`

/**
 * __usePopularTopicsQuery__
 *
 * To run a query within a React component, call `usePopularTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularTopicsQuery({
 *   variables: {
 *      vendorLimit: // value for 'vendorLimit'
 *   },
 * });
 */
export function usePopularTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<IPopularTopicsQuery, IPopularTopicsQueryVariables> &
    ({ variables: IPopularTopicsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPopularTopicsQuery, IPopularTopicsQueryVariables>(PopularTopicsDocument, options)
}
export function usePopularTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPopularTopicsQuery, IPopularTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPopularTopicsQuery, IPopularTopicsQueryVariables>(PopularTopicsDocument, options)
}
export function usePopularTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPopularTopicsQuery, IPopularTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPopularTopicsQuery, IPopularTopicsQueryVariables>(PopularTopicsDocument, options)
}
export type PopularTopicsQueryHookResult = ReturnType<typeof usePopularTopicsQuery>
export type PopularTopicsLazyQueryHookResult = ReturnType<typeof usePopularTopicsLazyQuery>
export type PopularTopicsSuspenseQueryHookResult = ReturnType<typeof usePopularTopicsSuspenseQuery>
export type PopularTopicsQueryResult = Apollo.QueryResult<IPopularTopicsQuery, IPopularTopicsQueryVariables>
export const PostShareContentsDocument = gql`
  mutation postShareContents($data: ResourceInvitationInput!) {
    shareContents(data: $data)
  }
`
export const PostShareVendorStatusDocument = gql`
  mutation postshareVendorStatus($data: vendorName) {
    shareVendorStatus(data: $data) {
      data {
        status
      }
    }
  }
`
export type IPostShareContentsMutationFn = Apollo.MutationFunction<
  IPostShareContentsMutation,
  IPostShareContentsMutationVariables
>

/**
 * __usePostShareContentsMutation__
 *
 * To run a mutation, you first call `usePostShareContentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostShareContentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postShareContentsMutation, { data, loading, error }] = usePostShareContentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePostShareContentsMutation(
  baseOptions?: Apollo.MutationHookOptions<IPostShareContentsMutation, IPostShareContentsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IPostShareContentsMutation, IPostShareContentsMutationVariables>(
    PostShareContentsDocument,
    options,
  )
}
export type PostShareContentsMutationHookResult = ReturnType<typeof usePostShareContentsMutation>
export type PostShareContentsMutationResult = Apollo.MutationResult<IPostShareContentsMutation>
export type PostShareContentsMutationOptions = Apollo.BaseMutationOptions<
  IPostShareContentsMutation,
  IPostShareContentsMutationVariables
>
export function usePostShareVendorStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<IPostShareVendorStatusMutation, IPostShareVendorStatusMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IPostShareVendorStatusMutation, IPostShareVendorStatusMutationVariables>(
    PostShareVendorStatusDocument,
    options,
  )
}
export const PremiumAudiencesDocument = gql`
  query premiumAudiences($filters: PremiumAudienceFiltersInput, $sort: [String] = []) {
    premiumAudiences(filters: $filters, sort: $sort) {
      data {
        ...premiumAudienceItem
      }
    }
  }
  ${PremiumAudienceItemFragmentDoc}
`

/**
 * __usePremiumAudiencesQuery__
 *
 * To run a query within a React component, call `usePremiumAudiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumAudiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumAudiencesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function usePremiumAudiencesQuery(
  baseOptions?: Apollo.QueryHookOptions<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>(PremiumAudiencesDocument, options)
}
export function usePremiumAudiencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>(PremiumAudiencesDocument, options)
}
export function usePremiumAudiencesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>(
    PremiumAudiencesDocument,
    options,
  )
}
export type PremiumAudiencesQueryHookResult = ReturnType<typeof usePremiumAudiencesQuery>
export type PremiumAudiencesLazyQueryHookResult = ReturnType<typeof usePremiumAudiencesLazyQuery>
export type PremiumAudiencesSuspenseQueryHookResult = ReturnType<typeof usePremiumAudiencesSuspenseQuery>
export type PremiumAudiencesQueryResult = Apollo.QueryResult<IPremiumAudiencesQuery, IPremiumAudiencesQueryVariables>
export const PremiumSubTopicsDocument = gql`
  query premiumSubTopics {
    subTopics(filters: { isPremium: { eq: true } }, sort: "name:asc") {
      data {
        ...subTopicItem
      }
    }
  }
  ${SubTopicItemFragmentDoc}
`

/**
 * __usePremiumSubTopicsQuery__
 *
 * To run a query within a React component, call `usePremiumSubTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumSubTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumSubTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePremiumSubTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>(PremiumSubTopicsDocument, options)
}
export function usePremiumSubTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>(PremiumSubTopicsDocument, options)
}
export function usePremiumSubTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>(
    PremiumSubTopicsDocument,
    options,
  )
}
export type PremiumSubTopicsQueryHookResult = ReturnType<typeof usePremiumSubTopicsQuery>
export type PremiumSubTopicsLazyQueryHookResult = ReturnType<typeof usePremiumSubTopicsLazyQuery>
export type PremiumSubTopicsSuspenseQueryHookResult = ReturnType<typeof usePremiumSubTopicsSuspenseQuery>
export type PremiumSubTopicsQueryResult = Apollo.QueryResult<IPremiumSubTopicsQuery, IPremiumSubTopicsQueryVariables>
export const PremiumSubTopicsWithContentsDocument = gql`
  query premiumSubTopicsWithContents {
    subTopics(filters: { isPremium: { eq: true } }, sort: "name:asc") {
      data {
        ...subTopicItem
        attributes {
          contents(
            filters: { isPremium: { eq: true } }
            pagination: { start: 0, limit: 3 }
            sort: "publishedTime:desc"
          ) {
            data {
              ...contentListItem
            }
          }
        }
      }
    }
  }
  ${SubTopicItemFragmentDoc}
  ${ContentListItemFragmentDoc}
`

/**
 * __usePremiumSubTopicsWithContentsQuery__
 *
 * To run a query within a React component, call `usePremiumSubTopicsWithContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumSubTopicsWithContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumSubTopicsWithContentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePremiumSubTopicsWithContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IPremiumSubTopicsWithContentsQuery,
    IPremiumSubTopicsWithContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPremiumSubTopicsWithContentsQuery, IPremiumSubTopicsWithContentsQueryVariables>(
    PremiumSubTopicsWithContentsDocument,
    options,
  )
}
export function usePremiumSubTopicsWithContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IPremiumSubTopicsWithContentsQuery,
    IPremiumSubTopicsWithContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPremiumSubTopicsWithContentsQuery, IPremiumSubTopicsWithContentsQueryVariables>(
    PremiumSubTopicsWithContentsDocument,
    options,
  )
}
export function usePremiumSubTopicsWithContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPremiumSubTopicsWithContentsQuery,
    IPremiumSubTopicsWithContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPremiumSubTopicsWithContentsQuery, IPremiumSubTopicsWithContentsQueryVariables>(
    PremiumSubTopicsWithContentsDocument,
    options,
  )
}
export type PremiumSubTopicsWithContentsQueryHookResult = ReturnType<typeof usePremiumSubTopicsWithContentsQuery>
export type PremiumSubTopicsWithContentsLazyQueryHookResult = ReturnType<
  typeof usePremiumSubTopicsWithContentsLazyQuery
>
export type PremiumSubTopicsWithContentsSuspenseQueryHookResult = ReturnType<
  typeof usePremiumSubTopicsWithContentsSuspenseQuery
>
export type PremiumSubTopicsWithContentsQueryResult = Apollo.QueryResult<
  IPremiumSubTopicsWithContentsQuery,
  IPremiumSubTopicsWithContentsQueryVariables
>
export const PremiumTopicsDocument = gql`
  query premiumTopics {
    topics(filters: { isPremium: { eq: true } }, sort: "name:asc") {
      data {
        ...topicItem
      }
    }
  }
  ${TopicItemFragmentDoc}
`

/**
 * __usePremiumTopicsQuery__
 *
 * To run a query within a React component, call `usePremiumTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePremiumTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>(PremiumTopicsDocument, options)
}
export function usePremiumTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>(PremiumTopicsDocument, options)
}
export function usePremiumTopicsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>(PremiumTopicsDocument, options)
}
export type PremiumTopicsQueryHookResult = ReturnType<typeof usePremiumTopicsQuery>
export type PremiumTopicsLazyQueryHookResult = ReturnType<typeof usePremiumTopicsLazyQuery>
export type PremiumTopicsSuspenseQueryHookResult = ReturnType<typeof usePremiumTopicsSuspenseQuery>
export type PremiumTopicsQueryResult = Apollo.QueryResult<IPremiumTopicsQuery, IPremiumTopicsQueryVariables>
export const PremiumTopicsWithContentsDocument = gql`
  query premiumTopicsWithContents {
    topics(filters: { isPremium: { eq: true } }, sort: "name:asc") {
      data {
        ...topicItem
        attributes {
          contents(
            filters: { isPremium: { eq: true } }
            pagination: { start: 0, limit: 3 }
            sort: "publishedTime:desc"
          ) {
            data {
              ...contentListItem
            }
          }
        }
      }
    }
  }
  ${TopicItemFragmentDoc}
  ${ContentListItemFragmentDoc}
`

/**
 * __usePremiumTopicsWithContentsQuery__
 *
 * To run a query within a React component, call `usePremiumTopicsWithContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePremiumTopicsWithContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePremiumTopicsWithContentsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePremiumTopicsWithContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<IPremiumTopicsWithContentsQuery, IPremiumTopicsWithContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IPremiumTopicsWithContentsQuery, IPremiumTopicsWithContentsQueryVariables>(
    PremiumTopicsWithContentsDocument,
    options,
  )
}
export function usePremiumTopicsWithContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IPremiumTopicsWithContentsQuery, IPremiumTopicsWithContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IPremiumTopicsWithContentsQuery, IPremiumTopicsWithContentsQueryVariables>(
    PremiumTopicsWithContentsDocument,
    options,
  )
}
export function usePremiumTopicsWithContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IPremiumTopicsWithContentsQuery,
    IPremiumTopicsWithContentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IPremiumTopicsWithContentsQuery, IPremiumTopicsWithContentsQueryVariables>(
    PremiumTopicsWithContentsDocument,
    options,
  )
}
export type PremiumTopicsWithContentsQueryHookResult = ReturnType<typeof usePremiumTopicsWithContentsQuery>
export type PremiumTopicsWithContentsLazyQueryHookResult = ReturnType<typeof usePremiumTopicsWithContentsLazyQuery>
export type PremiumTopicsWithContentsSuspenseQueryHookResult = ReturnType<
  typeof usePremiumTopicsWithContentsSuspenseQuery
>
export type PremiumTopicsWithContentsQueryResult = Apollo.QueryResult<
  IPremiumTopicsWithContentsQuery,
  IPremiumTopicsWithContentsQueryVariables
>
export const ProductBySlugDocument = gql`
  query productBySlug($slug: String!, $includeIlta: Boolean!) {
    solutions(filters: { slug: { eq: $slug }, type: { eq: "Product" } }) {
      data {
        ...productSolutionData
      }
    }
  }
  ${ProductSolutionDataFragmentDoc}
`

/**
 * __useProductBySlugQuery__
 *
 * To run a query within a React component, call `useProductBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      includeIlta: // value for 'includeIlta'
 *   },
 * });
 */
export function useProductBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<IProductBySlugQuery, IProductBySlugQueryVariables> &
    ({ variables: IProductBySlugQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IProductBySlugQuery, IProductBySlugQueryVariables>(ProductBySlugDocument, options)
}
export function useProductBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IProductBySlugQuery, IProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IProductBySlugQuery, IProductBySlugQueryVariables>(ProductBySlugDocument, options)
}
export function useProductBySlugSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IProductBySlugQuery, IProductBySlugQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IProductBySlugQuery, IProductBySlugQueryVariables>(ProductBySlugDocument, options)
}
export type ProductBySlugQueryHookResult = ReturnType<typeof useProductBySlugQuery>
export type ProductBySlugLazyQueryHookResult = ReturnType<typeof useProductBySlugLazyQuery>
export type ProductBySlugSuspenseQueryHookResult = ReturnType<typeof useProductBySlugSuspenseQuery>
export type ProductBySlugQueryResult = Apollo.QueryResult<IProductBySlugQuery, IProductBySlugQueryVariables>
export const RegisterDocument = gql`
  mutation register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`
export type IRegisterMutationFn = Apollo.MutationFunction<IRegisterMutation, IRegisterMutationVariables>

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<IRegisterMutation, IRegisterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IRegisterMutation, IRegisterMutationVariables>(RegisterDocument, options)
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>
export type RegisterMutationResult = Apollo.MutationResult<IRegisterMutation>
export type RegisterMutationOptions = Apollo.BaseMutationOptions<IRegisterMutation, IRegisterMutationVariables>
export const RegisterPremiumOrganizationDocument = gql`
  mutation registerPremiumOrganization(
    $name: String!
    $registrationContact: [ComponentRegistrationContactRegistrationContactInput!]!
    $billingAddress: ComponentBillingAddressBillingAddressInput!
    $emailDomain: String!
    $pricingPlan: ID!
    $gReCaptchaToken: String
    $leadSource: String
    $shouldSendResponse: Boolean!
  ) {
    registerPremiumOrganization(
      data: {
        name: $name
        registrationContact: $registrationContact
        billingAddress: $billingAddress
        emailDomain: $emailDomain
        pricingPlan: $pricingPlan
        gReCaptchaToken: $gReCaptchaToken
        leadSource: $leadSource
        shouldSendResponse: $shouldSendResponse
      }
    ) {
      name
    }
  }
`
export type IRegisterPremiumOrganizationMutationFn = Apollo.MutationFunction<
  IRegisterPremiumOrganizationMutation,
  IRegisterPremiumOrganizationMutationVariables
>

/**
 * __useRegisterPremiumOrganizationMutation__
 *
 * To run a mutation, you first call `useRegisterPremiumOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPremiumOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPremiumOrganizationMutation, { data, loading, error }] = useRegisterPremiumOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      registrationContact: // value for 'registrationContact'
 *      billingAddress: // value for 'billingAddress'
 *      emailDomain: // value for 'emailDomain'
 *      pricingPlan: // value for 'pricingPlan'
 *      gReCaptchaToken: // value for 'gReCaptchaToken'
 *      leadSource: // value for 'leadSource'
 *      shouldSendResponse: // value for 'shouldSendResponse'
 *   },
 * });
 */
export function useRegisterPremiumOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRegisterPremiumOrganizationMutation,
    IRegisterPremiumOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IRegisterPremiumOrganizationMutation, IRegisterPremiumOrganizationMutationVariables>(
    RegisterPremiumOrganizationDocument,
    options,
  )
}
export type RegisterPremiumOrganizationMutationHookResult = ReturnType<typeof useRegisterPremiumOrganizationMutation>
export type RegisterPremiumOrganizationMutationResult = Apollo.MutationResult<IRegisterPremiumOrganizationMutation>
export type RegisterPremiumOrganizationMutationOptions = Apollo.BaseMutationOptions<
  IRegisterPremiumOrganizationMutation,
  IRegisterPremiumOrganizationMutationVariables
>
export const RegisterVendorOrganizationDocument = gql`
  mutation registerVendorOrganization(
    $name: String!
    $companySize: String!
    $registrationContact: [ComponentRegistrationContactRegistrationContactInput!]!
    $gReCaptchaToken: String
    $shouldSendResponse: Boolean!
  ) {
    registerVendorOrganization(
      data: {
        name: $name
        companySize: $companySize
        registrationContact: $registrationContact
        gReCaptchaToken: $gReCaptchaToken
        shouldSendResponse: $shouldSendResponse
      }
    ) {
      name
    }
  }
`
export type IRegisterVendorOrganizationMutationFn = Apollo.MutationFunction<
  IRegisterVendorOrganizationMutation,
  IRegisterVendorOrganizationMutationVariables
>

/**
 * __useRegisterVendorOrganizationMutation__
 *
 * To run a mutation, you first call `useRegisterVendorOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterVendorOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerVendorOrganizationMutation, { data, loading, error }] = useRegisterVendorOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companySize: // value for 'companySize'
 *      registrationContact: // value for 'registrationContact'
 *      gReCaptchaToken: // value for 'gReCaptchaToken'
 *      shouldSendResponse: // value for 'shouldSendResponse'
 *   },
 * });
 */
export function useRegisterVendorOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IRegisterVendorOrganizationMutation,
    IRegisterVendorOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IRegisterVendorOrganizationMutation, IRegisterVendorOrganizationMutationVariables>(
    RegisterVendorOrganizationDocument,
    options,
  )
}
export type RegisterVendorOrganizationMutationHookResult = ReturnType<typeof useRegisterVendorOrganizationMutation>
export type RegisterVendorOrganizationMutationResult = Apollo.MutationResult<IRegisterVendorOrganizationMutation>
export type RegisterVendorOrganizationMutationOptions = Apollo.BaseMutationOptions<
  IRegisterVendorOrganizationMutation,
  IRegisterVendorOrganizationMutationVariables
>
export const SearchContentsDocument = gql`
  query searchContents($query: JSON, $gReCaptchaToken: String) {
    contentResults(query: $query, gReCaptchaToken: $gReCaptchaToken) {
      success
      data {
        total
        contents {
          ...contentItem
        }
      }
    }
  }
  ${ContentItemFragmentDoc}
`

/**
 * __useSearchContentsQuery__
 *
 * To run a query within a React component, call `useSearchContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchContentsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      gReCaptchaToken: // value for 'gReCaptchaToken'
 *   },
 * });
 */
export function useSearchContentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISearchContentsQuery, ISearchContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISearchContentsQuery, ISearchContentsQueryVariables>(SearchContentsDocument, options)
}
export function useSearchContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchContentsQuery, ISearchContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISearchContentsQuery, ISearchContentsQueryVariables>(SearchContentsDocument, options)
}
export function useSearchContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchContentsQuery, ISearchContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISearchContentsQuery, ISearchContentsQueryVariables>(SearchContentsDocument, options)
}
export type SearchContentsQueryHookResult = ReturnType<typeof useSearchContentsQuery>
export type SearchContentsLazyQueryHookResult = ReturnType<typeof useSearchContentsLazyQuery>
export type SearchContentsSuspenseQueryHookResult = ReturnType<typeof useSearchContentsSuspenseQuery>
export type SearchContentsQueryResult = Apollo.QueryResult<ISearchContentsQuery, ISearchContentsQueryVariables>
export const SearchEventsDocument = gql`
  query searchEvents($query: JSON) {
    eventResults(query: $query) {
      data {
        total
        events {
          title
          logo {
            data {
              attributes {
                url
              }
            }
          }
          description
          date
          updatedAt
          city
          country
          slug
          createdAt
          organizer
          featured
          website
          notes
          durationId {
            data {
              id
              attributes {
                name
                days
              }
            }
          }
          formatId {
            data {
              id
              attributes {
                name
              }
            }
          }
          recurrenceId {
            data {
              id
              attributes {
                name
              }
            }
          }
          features {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useSearchEventsQuery__
 *
 * To run a query within a React component, call `useSearchEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEventsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISearchEventsQuery, ISearchEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISearchEventsQuery, ISearchEventsQueryVariables>(SearchEventsDocument, options)
}
export function useSearchEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchEventsQuery, ISearchEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISearchEventsQuery, ISearchEventsQueryVariables>(SearchEventsDocument, options)
}
export function useSearchEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchEventsQuery, ISearchEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISearchEventsQuery, ISearchEventsQueryVariables>(SearchEventsDocument, options)
}
export type SearchEventsQueryHookResult = ReturnType<typeof useSearchEventsQuery>
export type SearchEventsLazyQueryHookResult = ReturnType<typeof useSearchEventsLazyQuery>
export type SearchEventsSuspenseQueryHookResult = ReturnType<typeof useSearchEventsSuspenseQuery>
export type SearchEventsQueryResult = Apollo.QueryResult<ISearchEventsQuery, ISearchEventsQueryVariables>
export const SearchGraveyardConsolidationDocument = gql`
  query searchGraveyardConsolidation($query: JSON) {
    searchSolutions(query: $query) {
      total
      solutions {
        ...solutionListItem
      }
    }
  }
  ${SolutionListItemFragmentDoc}
`

/**
 * __useSearchGraveyardConsolidationQuery__
 *
 * To run a query within a React component, call `useSearchGraveyardConsolidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGraveyardConsolidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGraveyardConsolidationQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchGraveyardConsolidationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ISearchGraveyardConsolidationQuery,
    ISearchGraveyardConsolidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISearchGraveyardConsolidationQuery, ISearchGraveyardConsolidationQueryVariables>(
    SearchGraveyardConsolidationDocument,
    options,
  )
}
export function useSearchGraveyardConsolidationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ISearchGraveyardConsolidationQuery,
    ISearchGraveyardConsolidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISearchGraveyardConsolidationQuery, ISearchGraveyardConsolidationQueryVariables>(
    SearchGraveyardConsolidationDocument,
    options,
  )
}
export function useSearchGraveyardConsolidationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ISearchGraveyardConsolidationQuery,
    ISearchGraveyardConsolidationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISearchGraveyardConsolidationQuery, ISearchGraveyardConsolidationQueryVariables>(
    SearchGraveyardConsolidationDocument,
    options,
  )
}
export type SearchGraveyardConsolidationQueryHookResult = ReturnType<typeof useSearchGraveyardConsolidationQuery>
export type SearchGraveyardConsolidationLazyQueryHookResult = ReturnType<
  typeof useSearchGraveyardConsolidationLazyQuery
>
export type SearchGraveyardConsolidationSuspenseQueryHookResult = ReturnType<
  typeof useSearchGraveyardConsolidationSuspenseQuery
>
export type SearchGraveyardConsolidationQueryResult = Apollo.QueryResult<
  ISearchGraveyardConsolidationQuery,
  ISearchGraveyardConsolidationQueryVariables
>
export const SearchSolutionsDocument = gql`
  query searchSolutions($query: JSON) {
    searchSolutions(query: $query) {
      total
      solutions {
        ...solutionListItem
      }
    }
  }
  ${SolutionListItemFragmentDoc}
`

/**
 * __useSearchSolutionsQuery__
 *
 * To run a query within a React component, call `useSearchSolutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSolutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSolutionsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchSolutionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>(SearchSolutionsDocument, options)
}
export function useSearchSolutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>(SearchSolutionsDocument, options)
}
export function useSearchSolutionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>(
    SearchSolutionsDocument,
    options,
  )
}
export type SearchSolutionsQueryHookResult = ReturnType<typeof useSearchSolutionsQuery>
export type SearchSolutionsLazyQueryHookResult = ReturnType<typeof useSearchSolutionsLazyQuery>
export type SearchSolutionsSuspenseQueryHookResult = ReturnType<typeof useSearchSolutionsSuspenseQuery>
export type SearchSolutionsQueryResult = Apollo.QueryResult<ISearchSolutionsQuery, ISearchSolutionsQueryVariables>
export const SimilarSolutionsDocument = gql`
  query similarSolutions($id: Int!, $from: String) {
    similarSolutions(id: $id, from: $from) {
      solutions {
        ...similarSolutionItem
      }
    }
  }
  ${SimilarSolutionItemFragmentDoc}
`

/**
 * __useSimilarSolutionsQuery__
 *
 * To run a query within a React component, call `useSimilarSolutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarSolutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarSolutionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSimilarSolutionsQuery(
  baseOptions: Apollo.QueryHookOptions<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables> &
    ({ variables: ISimilarSolutionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables>(SimilarSolutionsDocument, options)
}
export function useSimilarSolutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables>(SimilarSolutionsDocument, options)
}
export function useSimilarSolutionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables>(
    SimilarSolutionsDocument,
    options,
  )
}
export type SimilarSolutionsQueryHookResult = ReturnType<typeof useSimilarSolutionsQuery>
export type SimilarSolutionsLazyQueryHookResult = ReturnType<typeof useSimilarSolutionsLazyQuery>
export type SimilarSolutionsSuspenseQueryHookResult = ReturnType<typeof useSimilarSolutionsSuspenseQuery>
export type SimilarSolutionsQueryResult = Apollo.QueryResult<ISimilarSolutionsQuery, ISimilarSolutionsQueryVariables>
export const SolutionRequestsBySulgDocument = gql`
  query solutionRequestsBySulg($slug: String!) {
    solutionRequests(filters: { slug: { eq: $slug } }, publicationState: PREVIEW) {
      data {
        id
        attributes {
          slug
        }
      }
    }
  }
`

/**
 * __useSolutionRequestsBySulgQuery__
 *
 * To run a query within a React component, call `useSolutionRequestsBySulgQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolutionRequestsBySulgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolutionRequestsBySulgQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSolutionRequestsBySulgQuery(
  baseOptions: Apollo.QueryHookOptions<ISolutionRequestsBySulgQuery, ISolutionRequestsBySulgQueryVariables> &
    ({ variables: ISolutionRequestsBySulgQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ISolutionRequestsBySulgQuery, ISolutionRequestsBySulgQueryVariables>(
    SolutionRequestsBySulgDocument,
    options,
  )
}
export function useSolutionRequestsBySulgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ISolutionRequestsBySulgQuery, ISolutionRequestsBySulgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ISolutionRequestsBySulgQuery, ISolutionRequestsBySulgQueryVariables>(
    SolutionRequestsBySulgDocument,
    options,
  )
}
export function useSolutionRequestsBySulgSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ISolutionRequestsBySulgQuery, ISolutionRequestsBySulgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ISolutionRequestsBySulgQuery, ISolutionRequestsBySulgQueryVariables>(
    SolutionRequestsBySulgDocument,
    options,
  )
}
export type SolutionRequestsBySulgQueryHookResult = ReturnType<typeof useSolutionRequestsBySulgQuery>
export type SolutionRequestsBySulgLazyQueryHookResult = ReturnType<typeof useSolutionRequestsBySulgLazyQuery>
export type SolutionRequestsBySulgSuspenseQueryHookResult = ReturnType<typeof useSolutionRequestsBySulgSuspenseQuery>
export type SolutionRequestsBySulgQueryResult = Apollo.QueryResult<
  ISolutionRequestsBySulgQuery,
  ISolutionRequestsBySulgQueryVariables
>
export const SubmitEnhancedRequestDocument = gql`
  mutation submitEnhancedRequest($solution_id: ID!) {
    submitEnhancedRequest(data: { solution_id: $solution_id })
  }
`
export type ISubmitEnhancedRequestMutationFn = Apollo.MutationFunction<
  ISubmitEnhancedRequestMutation,
  ISubmitEnhancedRequestMutationVariables
>

/**
 * __useSubmitEnhancedRequestMutation__
 *
 * To run a mutation, you first call `useSubmitEnhancedRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEnhancedRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEnhancedRequestMutation, { data, loading, error }] = useSubmitEnhancedRequestMutation({
 *   variables: {
 *      solution_id: // value for 'solution_id'
 *   },
 * });
 */
export function useSubmitEnhancedRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<ISubmitEnhancedRequestMutation, ISubmitEnhancedRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISubmitEnhancedRequestMutation, ISubmitEnhancedRequestMutationVariables>(
    SubmitEnhancedRequestDocument,
    options,
  )
}
export type SubmitEnhancedRequestMutationHookResult = ReturnType<typeof useSubmitEnhancedRequestMutation>
export type SubmitEnhancedRequestMutationResult = Apollo.MutationResult<ISubmitEnhancedRequestMutation>
export type SubmitEnhancedRequestMutationOptions = Apollo.BaseMutationOptions<
  ISubmitEnhancedRequestMutation,
  ISubmitEnhancedRequestMutationVariables
>
export const SubmitNewsletterSubscriptionDocument = gql`
  mutation submitNewsletterSubscription($data: NewsletterSubscriptionInput!) {
    submitNewsletterSubscription(data: $data)
  }
`
export type ISubmitNewsletterSubscriptionMutationFn = Apollo.MutationFunction<
  ISubmitNewsletterSubscriptionMutation,
  ISubmitNewsletterSubscriptionMutationVariables
>

/**
 * __useSubmitNewsletterSubscriptionMutation__
 *
 * To run a mutation, you first call `useSubmitNewsletterSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitNewsletterSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitNewsletterSubscriptionMutation, { data, loading, error }] = useSubmitNewsletterSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitNewsletterSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISubmitNewsletterSubscriptionMutation,
    ISubmitNewsletterSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISubmitNewsletterSubscriptionMutation, ISubmitNewsletterSubscriptionMutationVariables>(
    SubmitNewsletterSubscriptionDocument,
    options,
  )
}
export type SubmitNewsletterSubscriptionMutationHookResult = ReturnType<typeof useSubmitNewsletterSubscriptionMutation>
export type SubmitNewsletterSubscriptionMutationResult = Apollo.MutationResult<ISubmitNewsletterSubscriptionMutation>
export type SubmitNewsletterSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  ISubmitNewsletterSubscriptionMutation,
  ISubmitNewsletterSubscriptionMutationVariables
>
export const SubmitReviewDocument = gql`
  mutation submitReview($data: ReviewInput!) {
    submitReview(data: $data)
  }
`
export type ISubmitReviewMutationFn = Apollo.MutationFunction<ISubmitReviewMutation, ISubmitReviewMutationVariables>

/**
 * __useSubmitReviewMutation__
 *
 * To run a mutation, you first call `useSubmitReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReviewMutation, { data, loading, error }] = useSubmitReviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSubmitReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<ISubmitReviewMutation, ISubmitReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ISubmitReviewMutation, ISubmitReviewMutationVariables>(SubmitReviewDocument, options)
}
export type SubmitReviewMutationHookResult = ReturnType<typeof useSubmitReviewMutation>
export type SubmitReviewMutationResult = Apollo.MutationResult<ISubmitReviewMutation>
export type SubmitReviewMutationOptions = Apollo.BaseMutationOptions<
  ISubmitReviewMutation,
  ISubmitReviewMutationVariables
>
export const TrendingContentsDocument = gql`
  query trendingContents($audience: String!) {
    trendingContents(audience: $audience) {
      ...contentListItem
    }
  }
  ${ContentListItemFragmentDoc}
`

/**
 * __useTrendingContentsQuery__
 *
 * To run a query within a React component, call `useTrendingContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingContentsQuery({
 *   variables: {
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useTrendingContentsQuery(
  baseOptions: Apollo.QueryHookOptions<ITrendingContentsQuery, ITrendingContentsQueryVariables> &
    ({ variables: ITrendingContentsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ITrendingContentsQuery, ITrendingContentsQueryVariables>(TrendingContentsDocument, options)
}
export function useTrendingContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ITrendingContentsQuery, ITrendingContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ITrendingContentsQuery, ITrendingContentsQueryVariables>(TrendingContentsDocument, options)
}
export function useTrendingContentsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ITrendingContentsQuery, ITrendingContentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ITrendingContentsQuery, ITrendingContentsQueryVariables>(
    TrendingContentsDocument,
    options,
  )
}
export type TrendingContentsQueryHookResult = ReturnType<typeof useTrendingContentsQuery>
export type TrendingContentsLazyQueryHookResult = ReturnType<typeof useTrendingContentsLazyQuery>
export type TrendingContentsSuspenseQueryHookResult = ReturnType<typeof useTrendingContentsSuspenseQuery>
export type TrendingContentsQueryResult = Apollo.QueryResult<ITrendingContentsQuery, ITrendingContentsQueryVariables>
export const UpdateAnnouncementDocument = gql`
  mutation updateAnnouncement($id: ID!, $data: AnnouncementInput!) {
    updateAnnouncement(id: $id, data: $data) {
      data {
        id
        attributes {
          announcement
          optionalLink
          updatedAt
        }
      }
    }
  }
`
export type IUpdateAnnouncementMutationFn = Apollo.MutationFunction<
  IUpdateAnnouncementMutation,
  IUpdateAnnouncementMutationVariables
>

/**
 * __useUpdateAnnouncementMutation__
 *
 * To run a mutation, you first call `useUpdateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnouncementMutation, { data, loading, error }] = useUpdateAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAnnouncementMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateAnnouncementMutation, IUpdateAnnouncementMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateAnnouncementMutation, IUpdateAnnouncementMutationVariables>(
    UpdateAnnouncementDocument,
    options,
  )
}
export type UpdateAnnouncementMutationHookResult = ReturnType<typeof useUpdateAnnouncementMutation>
export type UpdateAnnouncementMutationResult = Apollo.MutationResult<IUpdateAnnouncementMutation>
export type UpdateAnnouncementMutationOptions = Apollo.BaseMutationOptions<
  IUpdateAnnouncementMutation,
  IUpdateAnnouncementMutationVariables
>
export const UpdateSolutionRequestDocument = gql`
  mutation updateSolutionRequest($id: ID!, $data: SolutionRequestInput!) {
    updateSolutionRequest(id: $id, data: $data) {
      data {
        id
        attributes {
          updatedAt
        }
      }
    }
  }
`
export type IUpdateSolutionRequestMutationFn = Apollo.MutationFunction<
  IUpdateSolutionRequestMutation,
  IUpdateSolutionRequestMutationVariables
>

/**
 * __useUpdateSolutionRequestMutation__
 *
 * To run a mutation, you first call `useUpdateSolutionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSolutionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSolutionRequestMutation, { data, loading, error }] = useUpdateSolutionRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSolutionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<IUpdateSolutionRequestMutation, IUpdateSolutionRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateSolutionRequestMutation, IUpdateSolutionRequestMutationVariables>(
    UpdateSolutionRequestDocument,
    options,
  )
}
export type UpdateSolutionRequestMutationHookResult = ReturnType<typeof useUpdateSolutionRequestMutation>
export type UpdateSolutionRequestMutationResult = Apollo.MutationResult<IUpdateSolutionRequestMutation>
export type UpdateSolutionRequestMutationOptions = Apollo.BaseMutationOptions<
  IUpdateSolutionRequestMutation,
  IUpdateSolutionRequestMutationVariables
>
export const UpdateUsersPermissionsUserDocument = gql`
  mutation updateUsersPermissionsUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
        attributes {
          username
          email
        }
      }
    }
  }
`
export type IUpdateUsersPermissionsUserMutationFn = Apollo.MutationFunction<
  IUpdateUsersPermissionsUserMutation,
  IUpdateUsersPermissionsUserMutationVariables
>

/**
 * __useUpdateUsersPermissionsUserMutation__
 *
 * To run a mutation, you first call `useUpdateUsersPermissionsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersPermissionsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersPermissionsUserMutation, { data, loading, error }] = useUpdateUsersPermissionsUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUsersPermissionsUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IUpdateUsersPermissionsUserMutation,
    IUpdateUsersPermissionsUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IUpdateUsersPermissionsUserMutation, IUpdateUsersPermissionsUserMutationVariables>(
    UpdateUsersPermissionsUserDocument,
    options,
  )
}
export type UpdateUsersPermissionsUserMutationHookResult = ReturnType<typeof useUpdateUsersPermissionsUserMutation>
export type UpdateUsersPermissionsUserMutationResult = Apollo.MutationResult<IUpdateUsersPermissionsUserMutation>
export type UpdateUsersPermissionsUserMutationOptions = Apollo.BaseMutationOptions<
  IUpdateUsersPermissionsUserMutation,
  IUpdateUsersPermissionsUserMutationVariables
>
export const UserInfoDocument = gql`
  query userInfo($token: String!) {
    userInfo(token: $token) {
      external_id
      username
      email
      provider
      confirmed
      blocked
      role {
        data {
          attributes {
            name
          }
        }
      }
      companyType
      companySize
      isPremium
      isVendor
      country {
        data {
          attributes {
            name
          }
        }
      }
      jobTitle
      companyName
      subscribed
      subscribeNewsletterCategories {
        data {
          id
          attributes {
            name
          }
        }
      }
      vendor {
        data {
          id
          attributes {
            name
            publishedAt
          }
        }
      }
      createdAt
      updatedAt
      reviews {
        data {
          attributes {
            rating
            title
            content
            ip
            notes
            status
            solutionDispName
            submitterTitle
            submitterSubTitle
            createdAt
            updatedAt
            publishedAt
            solution {
              data {
                id
                attributes {
                  logo {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  website
                  slug
                  displayName
                  rating
                  reviews {
                    data {
                      attributes {
                        rating
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUserInfoQuery(
  baseOptions: Apollo.QueryHookOptions<IUserInfoQuery, IUserInfoQueryVariables> &
    ({ variables: IUserInfoQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IUserInfoQuery, IUserInfoQueryVariables>(UserInfoDocument, options)
}
export function useUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IUserInfoQuery, IUserInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IUserInfoQuery, IUserInfoQueryVariables>(UserInfoDocument, options)
}
export function useUserInfoSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IUserInfoQuery, IUserInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IUserInfoQuery, IUserInfoQueryVariables>(UserInfoDocument, options)
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>
export type UserInfoSuspenseQueryHookResult = ReturnType<typeof useUserInfoSuspenseQuery>
export type UserInfoQueryResult = Apollo.QueryResult<IUserInfoQuery, IUserInfoQueryVariables>
export const VendorAnalysisBySolutionIdDocument = gql`
  query vendorAnalysisBySolutionId($solutionId: ID!) {
    contents(filters: { contentType: { eq: "Vendor Analysis" }, resourceSolutions: { id: { eq: $solutionId } } }) {
      data {
        ...vendorAnalysisForSolution
      }
    }
  }
  ${VendorAnalysisForSolutionFragmentDoc}
`

/**
 * __useVendorAnalysisBySolutionIdQuery__
 *
 * To run a query within a React component, call `useVendorAnalysisBySolutionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorAnalysisBySolutionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorAnalysisBySolutionIdQuery({
 *   variables: {
 *      solutionId: // value for 'solutionId'
 *   },
 * });
 */
export function useVendorAnalysisBySolutionIdQuery(
  baseOptions: Apollo.QueryHookOptions<IVendorAnalysisBySolutionIdQuery, IVendorAnalysisBySolutionIdQueryVariables> &
    ({ variables: IVendorAnalysisBySolutionIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IVendorAnalysisBySolutionIdQuery, IVendorAnalysisBySolutionIdQueryVariables>(
    VendorAnalysisBySolutionIdDocument,
    options,
  )
}
export function useVendorAnalysisBySolutionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IVendorAnalysisBySolutionIdQuery,
    IVendorAnalysisBySolutionIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IVendorAnalysisBySolutionIdQuery, IVendorAnalysisBySolutionIdQueryVariables>(
    VendorAnalysisBySolutionIdDocument,
    options,
  )
}
export function useVendorAnalysisBySolutionIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IVendorAnalysisBySolutionIdQuery,
    IVendorAnalysisBySolutionIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IVendorAnalysisBySolutionIdQuery, IVendorAnalysisBySolutionIdQueryVariables>(
    VendorAnalysisBySolutionIdDocument,
    options,
  )
}
export type VendorAnalysisBySolutionIdQueryHookResult = ReturnType<typeof useVendorAnalysisBySolutionIdQuery>
export type VendorAnalysisBySolutionIdLazyQueryHookResult = ReturnType<typeof useVendorAnalysisBySolutionIdLazyQuery>
export type VendorAnalysisBySolutionIdSuspenseQueryHookResult = ReturnType<
  typeof useVendorAnalysisBySolutionIdSuspenseQuery
>
export type VendorAnalysisBySolutionIdQueryResult = Apollo.QueryResult<
  IVendorAnalysisBySolutionIdQuery,
  IVendorAnalysisBySolutionIdQueryVariables
>
export const VisitContentDocument = gql`
  mutation visitContent($content_id: ID!) {
    visitContent(data: { content_id: $content_id })
  }
`
export type IVisitContentMutationFn = Apollo.MutationFunction<IVisitContentMutation, IVisitContentMutationVariables>

/**
 * __useVisitContentMutation__
 *
 * To run a mutation, you first call `useVisitContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitContentMutation, { data, loading, error }] = useVisitContentMutation({
 *   variables: {
 *      content_id: // value for 'content_id'
 *   },
 * });
 */
export function useVisitContentMutation(
  baseOptions?: Apollo.MutationHookOptions<IVisitContentMutation, IVisitContentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<IVisitContentMutation, IVisitContentMutationVariables>(VisitContentDocument, options)
}
export type VisitContentMutationHookResult = ReturnType<typeof useVisitContentMutation>
export type VisitContentMutationResult = Apollo.MutationResult<IVisitContentMutation>
export type VisitContentMutationOptions = Apollo.BaseMutationOptions<
  IVisitContentMutation,
  IVisitContentMutationVariables
>
